import { createAction } from '../../common/actions/actionHelpers';
import { batchActions } from 'redux-batched-actions';

import { UPDATE_FIRST_DEPOSIT_BONUSES, SET_ACL_ATTRIBUTES } from './aclActionsTypes';

import { get } from '../../common/Ajax';
import { changeLanguage, addLanguageResources } from '../application/applicationActions';
import { addFeatures } from '../featureToggles/featureTogglesActions';
import { updateTheme } from '../theme/themeActions';
import { openModal } from '../modal/modalActions';
import env from '../../common/EnvironmentVariables';

/**
 * Sets the First Deposit Bonuses
 *
 * @param bonuses
 */
export const setFirstDepositBonuses = (bonuses) => {
	return createAction(UPDATE_FIRST_DEPOSIT_BONUSES, bonuses);
};

/**
 * Sets the ACL Attributes
 *
 * @param attributes
 */
export const setAclAttributes = (attributes) => {
	return createAction(SET_ACL_ATTRIBUTES, attributes);
};

/**
 * Get the ACL data from the API response
 *
 * @param apiResponse
 * @returns {*}
 */
const getAclData = (apiResponse) => {
	if (!apiResponse || !apiResponse.data || !apiResponse.data.data || !apiResponse.data.data.acl) {
		return {};
	}
	return apiResponse.data.data.acl;
};

/**
 * Handle Google Tag Manager
 * @param tagContent
 * @returns {Promise<void>}
 */

const handleGoogleTagManager = (tagContent) => {
	const url = `https://www.googletagmanager.com/gtag/js?id=${tagContent}`;
	const exist = document.querySelector(`script[src="${url}"]`);
	if (exist) {
		return;
	}

	const newScript = document.createElement('script');
	newScript.src = url;
	newScript.async = true;
	document.head.appendChild(newScript);
	newScript.onload = () => {
		window.dataLayer = window.dataLayer || [];
		function gtag() {
			window.dataLayer.push(arguments);
		}
		gtag('js', new Date());
		gtag('config', tagContent);
	};
};

/**
 * Fetch the acl attributes from env configuration and server and save it in state
 *
 * There is support for fetching the acl, as well as providing a base template to extend from
 *
 * @param affiliateId - The affiliate ID
 * @param campaignId
 * @param baseAffiliateId - The base/template affiliate ID
 * @param baseCampaignId
 * @param params
 */
export const fetchAcl = (
	affiliateId = null,
	campaignId = null,
	baseAffiliateId = null,
	baseCampaignId = null,
	params = {},
) => (dispatch, getState) => {
	const state = getState();

	/**
	 * Set the default values for when env is empty.
	 * Env doesn't have any values at compile time, so it can't be set in the parameters.
	 */
	if (!affiliateId) {
		affiliateId = env('defaultAffiliateId');
	}
	if (!campaignId) {
		campaignId = env('defaultCampaignId');
	}
	if (!baseAffiliateId) {
		baseAffiliateId = env('baseAffiliateId');
	}
	if (!baseCampaignId) {
		baseCampaignId = env('baseCampaignId');
	}

	// Initial ACL from our env file (without a default)
	const aclState = state.acl;
	const envAcl = env('acl'); // The ACL really shouldn't be set in the env file, even for testing

	const initialAcl = { ...aclState, ...envAcl };
	dispatch(setAclAttributes(initialAcl));

	// Setup an array of requests to be made for the ACL. Expect the default affiliates to exist, but the
	// base affiliate is optional. Theses requests will be initiated as soon as they are added to the array, Promise.all
	// doesn't care if the promises are already resolved by the time you call it.
	const affiliateRequests = [get(`/affiliate/${affiliateId}/acl/${campaignId}`)];

	if (baseAffiliateId && baseCampaignId) {
		affiliateRequests.push(get(`/affiliate/${baseAffiliateId}/acl/${baseCampaignId}`));
	}

	return Promise.all(affiliateRequests)
		.then((responses) => {
			const [defaultResponse = {}, baseResponse = {}] = responses;
			// Optional base ACL
			const baseAcl = getAclData(baseResponse);

			// Our defined ACL
			const defaultAcl = getAclData(defaultResponse);
			
			// example G-3FRYVRY3L2
			

			if (defaultAcl.seoContent && defaultAcl.seoContent.footer && defaultAcl.seoContent.footer.googleTag) {
				handleGoogleTagManager(defaultAcl.seoContent.footer.googleTag);
			}

			// These fields are outside of the second data key in the payload
			initialAcl.acl_description = defaultResponse.data
				? defaultResponse.data.acl_description
				: initialAcl.acl_description || '';
			initialAcl.affiliate_description = defaultResponse.data
				? defaultResponse.data.affiliate_description
				: initialAcl.affiliate_description || '';
			initialAcl.affiliate_name = defaultResponse.data
				? defaultResponse.data.affiliate_name
				: initialAcl.affiliate_name || '';
			dispatch(setAclConfig(initialAcl, defaultAcl, baseAcl));
			// configure greenId
			window.insertScripts(defaultAcl.environment ? defaultAcl.environment : 'test');
		})
		.catch((error) => {
			console.error('Error fetching ACL', error);
		});
};

export const setAclConfig = (initialAcl = {}, defaultAcl = {}, baseAcl = {}) => (dispatch) => {
	const mergedTheme = {
		...initialAcl.theme,
		...baseAcl.theme,
		...defaultAcl.theme,
	};

	const mergedFeatures = {
		...initialAcl.features,
		...baseAcl.features,
		...defaultAcl.features,
	};

	const mergedBrandDetails = {
		...initialAcl.brandDetails,
		...baseAcl.brandDetails,
		...defaultAcl.brandDetails,
	};

	const mergedCurrencyConfiguration = {
		...initialAcl.currencyConfiguration,
		...baseAcl.currencyConfiguration,
		...defaultAcl.currencyConfiguration,
	};

	const mergedAcl = {
		...initialAcl,
		...baseAcl,
		...defaultAcl,
		brandDetails: mergedBrandDetails,
		currencyConfiguration: mergedCurrencyConfiguration,
	};

	// Delete keys that all have separate reducers, so they don't need to be duplicated
	delete mergedAcl.acl;
	delete mergedAcl.theme;
	delete mergedAcl.features;

	// Ensure the language is always going to be set. The default language is set in the RootApplication
	changeLanguage(mergedAcl.languageCode);

	// Add translation overrides under language -> translation key pairs
	if (mergedAcl.languageTranslationOverrides) {
		addLanguageResources(mergedAcl.languageTranslationOverrides);
	}

	dispatch(batchActions([dispatch(updateTheme(mergedTheme)), dispatch(setAclAttributes(mergedAcl))]));
	dispatch(addFeatures(mergedFeatures));
};

/**
 * Fetch the configuration from the acl and return a Backbone model
 *
 * @param configType (E.g.: 'moduleConfig' or 'subAppConfig')
 * @param configName
 */
export const fetchAclConfig = (configType, configName) => (dispatch, getState) => {
	const subAppConfig = getState().acl[configType];

	return new Promise((resolve) => {
		if (subAppConfig) {
			resolve(new Backbone.Model(subAppConfig[configName]));
		}

		resolve(new Backbone.Model());
	});
};

/**
 * Opens the Acl Manager screen
 */
export const openAclManager = () => (dispatch) => {
	return dispatch(
		openModal({
			id: 'AclManagerContainer',
			Component: 'AclManagerContainer',
			config: {
				wide: true,
			},
		}),
	);
};

export const handleTaglines = (taglines, type) => {
	if (!type || !taglines) {
		return;
	}

	let msg = '';

	for (let key in taglines) {
		if (taglines[key]) {
			if (taglines[key][type]) {
				msg = taglines[key].tagline;
				break;
			}
		}
	}

	return msg;
};
