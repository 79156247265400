import styled, { css } from 'react-emotion';
import { brand, typography } from '@tbh/ui-kit';
import { COLORS, DEFAULT_COLORS, WEIGHTS, TYPE_SCALE, MASTHEAD_COLORS } from '../../../constants/themes';

export const BUTTON_MODIFIERS = {
	small: () => `
        padding: 6px;
        font-size: ${TYPE_SCALE.helpText};
    `,
	large: () => `
        padding: 16px 24px;
        font-size: ${TYPE_SCALE.header5};
    `,
	nav: () => `
        color: ${COLORS.white};
        padding: 4px 12px;
        font-size: ${TYPE_SCALE.paragraph};
        width: fit-content;
        border-radius: 4px;
        height: 100%;
    `
};

const Button = styled('button')(
	(props) => css`
		border: 0;
		color: ${COLORS.red[400]};
		font-weight: ${WEIGHTS.medium};
		font-size: ${TYPE_SCALE.paragraph};
		font-family: ${typography(props).base_font};
		text-transform: uppercase;
		padding: 8px 16px;
		transition: all 200ms ease;
		cursor: pointer;
	`
);

export const SetPrimaryButton = styled(Button)(
	(props) => css`
		background-color: ${brand(props).button_primary_bg_color
			? brand(props).button_primary_bg_color
			: DEFAULT_COLORS.df_secondary_color};
		color: ${brand(props).button_primary_font_color
			? brand(props).button_primary_font_color
			: DEFAULT_COLORS.df_secondary_text_color};
		width: 100%;
		height: 100%;

		&:hover {
			background-color: ${brand(props).button_primary_hover_bg_color
				? brand(props).button_primary_hover_bg_color
				: DEFAULT_COLORS.df_secondary_hover_color};
			color: ${brand(props).button_primary_hover_font_color
				? brand(props).button_primary_hover_font_color
				: DEFAULT_COLORS.df_secondary_text_color};
		}
	`
);

export const PrimaryButton = styled(SetPrimaryButton)`
	${(props) => (props.modifiers && BUTTON_MODIFIERS[props.modifiers] ? BUTTON_MODIFIERS[props.modifiers] : '')}
`;

export const SecondaryButton = styled(Button)`
	background-color: ${COLORS.white};
	color: ${COLORS.red[300]};
	${(props) => (props.modifiers && BUTTON_MODIFIERS[props.modifiers] ? BUTTON_MODIFIERS[props.modifiers] : '')}
`;

export const TertiaryButton = styled(Button)`
	background: transparent;
	color: ${MASTHEAD_COLORS.font_color};
	&:focus {
		outline: none;
		box-shadow: none;
	}

	&:hover {
		border-bottom: 2px solid ${MASTHEAD_COLORS.hover_font_color};
		color: ${MASTHEAD_COLORS.hover_font_color};
	}

	${(props) =>
		props.active &&
		`
		border-bottom: 2px solid ${MASTHEAD_COLORS.hover_font_color};
		color: ${MASTHEAD_COLORS.hover_font_color};
	`}

	${(props) => (props.modifiers && BUTTON_MODIFIERS[props.modifiers] ? BUTTON_MODIFIERS[props.modifiers] : '')}
`;
