import React, { useEffect, useState } from 'react';

import styled, { css } from 'react-emotion';
import RacingIcon from '../../components/features/Racing/RacingIcon/RacingIcon';
import { ArrowIcon, LoadingMask, Text, Ticker } from '@tbh/ui-kit';
import { countdown, serverDateTime } from '../../legacy/core/format';
import { connect } from 'react-redux';
import { SizeMe } from 'react-sizeme';
import moment from 'moment';
import { PageComponentContainer } from '../../components/features/Application/PageComponentContainer/PageComponentContainer';
import { fetchNextToJumpRaces_GRS, nextNtjRace } from '../../store/entities/actions/NextToJumpRaceActions';
import { navigateToRaceWithType } from '../../store/GRSracingHome/GRSracingHomeActions';
import { getNextToJumpRaces } from '../Racing/RacingHome/racingSelectorsGRS';
import { Skeleton } from '../../components/UI/Skeleton';
import {
	RaceStatusEventById,
	RACING_GREYHOUNDS_CODE,
	RACING_HARNESS_CODE,
	RACING_THOROUGHBRED_CODE
} from '../../common/constants/Racing';
import RaceNextJumpFilter from '../../components/features/Racing/RaceNextJumpV3/RaceNextJumpFilter/RaceNextJumpFilter';
import { routeTo } from '../../store/application/applicationActions';
import { LoadingSpanner } from '../../components/features/Application/LoadingSpinner/LoadingSpinner';
import { RACE_CARD_COLOR } from '../../constants/themes';

// Color Palette
const colors = {
	primary: '#1a73e8',
	secondary: '#34a853',
	accent: RACE_CARD_COLOR.arffilter_bg_Color,
	dark: '#161a1d',
	light: '#ffffff',
	lightGrey: '#f5f7fa',
	midGrey: '#e6eaef',
	textDark: '#202124',
	textLight: '#ffffff',
	warning: '#e65100',
	highlight: '#fbbc05',
	border: '#dadce0'
};

export const modeChangeOverSize = 751;

const StyledNextToJumpItem = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		border: 1px solid #ddd;
		padding: 8px 12px;
		gap: 12px;
		width: 100%;
		border-radius: 6px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
		transition: all 0.2s ease-in-out;

		&:hover {
			background-color: ${colors.midGrey};
			transform: translateY(-1px);
			box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
		}

		&:active {
			background-color: ${colors.midGrey};
			transform: translateY(0);
		}

		&:not(:last-child) {
			border-bottom: 1px solid ${colors.border};
		}

		&:last-child {
			margin-bottom: 0;
		}
	`
);

const RaceIconContainer = styled('div')(
	(props) => css`
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2);
	`
);

const RaceInfo = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		align-items: center;
	`
);

const RaceDetails = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	`
);

const CountdownLabel = styled(Text)(
	(props) => css`
		display: flex;
		align-items: center;
		background-color: ${props.urgent ? `rgba(${colors.accent}, 0.1)` : 'transparent'};
		padding: ${props.urgent ? '4px 8px' : '0'};
		border-radius: ${props.urgent ? '4px' : '0'};
		transition: all 0.2s ease;
	`
);

const NextToJumpItem = ({
	id,
	race_number,
	meeting_name,
	distance,
	type,
	country,
	start_datetime,
	event_status_id,
	onClick
}) => {
	const status = RaceStatusEventById(event_status_id);
	const secondsToStart = moment(start_datetime).diff(serverDateTime(moment()), 'seconds');
	const lessThanOneMinute = secondsToStart < 60;
	// Dynamic color based on race type
	const getTypeColor = () => {
		switch (type) {
			case RACING_THOROUGHBRED_CODE:
				return colors.primary;
			case RACING_HARNESS_CODE:
				return colors.secondary;
			case RACING_GREYHOUNDS_CODE:
				return colors.accent;
			default:
				return colors.dark;
		}
	};

	return (
		<StyledNextToJumpItem key={id} onClick={onClick}>
			<RaceIconContainer>
				<RacingIcon type={type} color={'black'} />
			</RaceIconContainer>
			<RaceInfo>
				<RaceDetails>
					<Text size="-3" style={{ color: colors.textDark, fontWeight: 500 }}>
						R{race_number} - {meeting_name}
					</Text>
					<Text size="-4" style={{ color: '#666', marginTop: '2px' }}>
						{distance ? `${distance}m` : ''}
					</Text>
				</RaceDetails>
				<CountdownLabel
					size="-3"
					strong
					urgent={lessThanOneMinute}
					style={{
						color: lessThanOneMinute ? colors.warning : colors.black,
					}}
				>
					{status === 'selling' ? countdown(serverDateTime(moment(start_datetime))) : status}{' '}
					<ArrowIcon direction={'right'} size="-2" style={{ marginLeft: '4px' }} />
				</CountdownLabel>
			</RaceInfo>
		</StyledNextToJumpItem>
	);
};

const NextToJumpItemLoading = () => {
	return (
		<StyledNextToJumpItem>
			<RaceIconContainer>
				<Skeleton width="24px" height="24px" black={true} />
			</RaceIconContainer>
			<RaceInfo>
				<RaceDetails>
					<Skeleton width="140px" height="16px" black={true} />
					<Skeleton width="80px" height="12px" black={true} style={{ marginTop: '6px' }} />
				</RaceDetails>
				<Skeleton width="64px" height="16px" black={true} />
			</RaceInfo>
		</StyledNextToJumpItem>
	);
};

const StyledHeader = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 12px;
		background: linear-gradient(135deg, ${colors.dark} 0%, #252c33 100%);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		width: 100%;
		box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
	`
);

const StyledNextToJumpContainer = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		height: 100%;
		background-color: ${colors.light};
		border-radius: 8px;
		padding: 0;
		box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
	`
);

const RacesContainer = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 12px;
		gap: 8px;
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-track {
			background: ${colors.lightGrey};
		}

		&::-webkit-scrollbar-thumb {
			background-color: #ccc;
			border-radius: 6px;
		}
	`
);

const EmptyStateMessage = styled(Text)(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 32px 16px;
		color: #666;
		text-align: center;
	`
);

const NextToJump = ({ items, navigateToRaceWithType, loadingContainer, headerHeight, footerHeight }) => {
	const [raceTypeFilters, setRaceTypeFilter] = useState([
		RACING_THOROUGHBRED_CODE,
		RACING_HARNESS_CODE,
		RACING_GREYHOUNDS_CODE
	]); // Default to one type

	const handleFilterItemClick = (type) => {
		const newType = [...raceTypeFilters];
		if (raceTypeFilters.includes(type)) {
			const index = raceTypeFilters.indexOf(type);
			newType.splice(index, 1);
		} else {
			newType.push(type);
		}

		if (newType.length === 0) {
			setRaceTypeFilter([RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE]);
		} else {
			setRaceTypeFilter(newType);
		}

		// setRaceTypeFilter(type); // Allow only one filter to be active
	};

	const filteredItems = items.filter((item) => raceTypeFilters.includes(item.type));

	return (
		<PageComponentContainer>
			<SizeMe noPlaceholder>
				{({ size }) => (
					<React.Fragment>
						<StyledNextToJumpContainer
							style={
								size.width < modeChangeOverSize
									? {
											minHeight: `max(100vh - ${headerHeight}px - ${footerHeight}px, 340px)`
									  }
									: {}
							}
						>
							<StyledHeader>
								<Text size="0" strong type="alternate" transform="uppercase" style={{ letterSpacing: '0.5px' }}>
									Next to Jump Races
								</Text>
								<RaceNextJumpFilter
									onFilterItemClick={handleFilterItemClick}
									types={raceTypeFilters}
									// types={[raceTypeFilter]}
									size={size}
									setSelectedType={setRaceTypeFilter}
									selectedType={raceTypeFilters}
									showAllButton={false}
								/>
							</StyledHeader>
							<RacesContainer>
								{loadingContainer && [...Array(10)].map((_, index) => <NextToJumpItemLoading key={index} />)}

								{!loadingContainer && filteredItems.length === 0 && (
									<EmptyStateMessage size="-3">No Races Available</EmptyStateMessage>
								)}

								{!loadingContainer &&
									filteredItems.map((item) => (
										<Ticker every={10000} key={item.id}>
											<NextToJumpItem
												{...item}
												onClick={() =>
													navigateToRaceWithType(
														item.type,
														item.start_datetime,
														item.id,
														item.meeting_id,
														item.meeting_name
													)
												}
											/>
										</Ticker>
									))}
							</RacesContainer>
						</StyledNextToJumpContainer>
					</React.Fragment>
				)}
			</SizeMe>
		</PageComponentContainer>
	);
};

const mapStateToProps = (state) => {
	const authenticatedUser = state.application.authenticatedUser;
	const user = state.entities.users[authenticatedUser] || {};
	const settings = user.settings || {};
	return {
		headerHeight: document.getElementById('headerWrapper') ? document.getElementById('headerWrapper').offsetHeight : 0,
		footerHeight: document.getElementById('footerWrapper') ? document.getElementById('footerWrapper').offsetHeight : 0,
		items: getNextToJumpRaces(state),
		raceType: state.grsRacingHome.selectedType,
		raceDate: state.grsRacingHome.selectedDate,
		selectedPage: state.application.selectedPage,
		loadingContainer: state.application.isApplicationLoading || state.application.isBodyRegionLoading,
		showN2J: settings.showN2J != undefined ? settings.showN2J : true
	};
};

const mapDispatchToProps = (dispatch) => ({
	navigateToRaceWithType: (type, date, raceId, meetingId, meetingName, raceNumber) => {
		dispatch(navigateToRaceWithType(type, date, raceId, meetingId));
		App.startSubApp('RacingNewPage');
	},
	goToHome: () => {
		return dispatch(routeTo('/welcome')); // change by @HW 21NOV2019
	},
	updateNextToRaces: (races) => dispatch(nextNtjRace(races)),
	fetchNextToJumpRaces: () => {
		dispatch(fetchNextToJumpRaces_GRS());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(NextToJump);
