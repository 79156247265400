import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import numeral from 'numeral';
import { spacings, ui, Text, CustomBorder, PlotElements, Table, typography } from '@tbh/ui-kit';

import { DEFAULT_BET_AMOUNT_PLACEHOLDER_TEXT } from '../../../../../store/entities/constants/BetConstants';

import CurrencyDisplay from '../../../Application/Currency/CurrencyDisplay/CurrencyDisplay';
import { QUADDIES_BET_TYPES, RACING_EXOTIC_BET_TYPES, SRMTypes } from '../../../../../common/constants/Racing';
import { groupBetSelectionByRaceNumberButNotName } from '../../../../../common/GroupBetSelectionByRaceNumber';
import { legsByResultString } from '../../../../../common/ExoticBets';

const positionByNumber = (number) => {
	if (number === 1) return '1st';
	if (number === 2) return '2nd';
	if (number === 3) return '3rd';
	return `${number}th`;
};

const StyledMultiBetSummary = styled('div')(
	(props) => css`
		label: MultiBetSummary;

		padding: ${spacings(props).compact}px;
		background: ${ui(props).color_3};
	`,
);

const StyledMultiBetSummary__Header = styled(Text)(
	(props) => css`
		label: BetSelectionExotic__Header;

		margin: ${spacings(props).tight}px;
	`,
);

const StyledMultiBetSummary__HorizontalRule = styled('hr')(
	(props) => css`
		label: BetSelectionExotic__HorizontalRule;

		margin: ${spacings(props).compact}px 0;
		border-bottom-color: ${ui(props).color_4};
	`,
);
const StyledBetPositionDetail__Label = styled('div')(
	(props) => css`
		label: BetPositionDetail__Label;

		margin: auto;
		width: 16px;
		height: 16px;
		background: ${ui(props).color_info};
		color: ${typography(props).color_1_alternate};
		font-size: ${typography(props)['size_-2']};
		font-weight: bold;
		line-height: 16px;
		text-align: center;

		${props.isStandout &&
			css`
				flex: 0 0 32px;
				height: 19px;
				line-height: 19.2px;
				margin-right: ${spacings(props).compact}px;
			`};

		${props.isLegs &&
			css`
				margin: unset;
				flex: 0 0 48px;
				height: 19px;
				line-height: 19.2px;
				margin-right: ${spacings(props).compact}px;
			`};
	`,
);

const formatString = (str) => {
	return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};

const sortRaceByTime = (a, b) => {
	const aTime = new Date(a.date);
	const bTime = new Date(b.date);
	if (aTime < bTime) return -1;
	if (aTime > bTime) return 1;
	return 0;
}

const MultiBetSummary = (props) => {
	const { className, bets, totalPotential, totalStake, t } = props;

	const componentClasses = cx({
		[className]: className,
	});
	return (
		<React.Fragment>
			<StyledMultiBetSummary className={componentClasses}>
				<StyledMultiBetSummary__Header strong size="-2">
					{t('MultiBetSummary__Title')}
				</StyledMultiBetSummary__Header>
				<Table density="cozy">
					{bets.map(({ name, subName, number, price, bet_type, selections, ...bet }) => {
						selections.sort(sortRaceByTime);
						if (bet_type === 'sameracemulti') {
							return (
								<React.Fragment key={bet.name}>
									<div style={{ padding: ' 0 4px', display: 'flex', flexDirection: 'column', gap: 4 }}>
										<Table.TR>
											<Table.TD>
												<Text strong size="-2">
													R{selections[0].race_number} {selections[0].competition_name}
												</Text>
											</Table.TD>
										</Table.TR>
										{selections.map((selection) => (
											<Table.TR key={`${selection.selection_name}${selection.price}`}>
												<Table.TD>
													<Text size="-3">
														<span
															className={css`
																font-weight: 600;
																flex: 0 0 auto;
															`}
														>
															{SRMTypes[selection.product]}:
														</span>
														<span
															className={css`
																flex: 0 0 auto;
															`}
														>
															&nbsp;
														</span>
														<span
															className={css`
																margin-left: 5px;
																font-weight: 400;
																flex: 1 1 auto;
															`}
														>
															#{selection.selection_number} {selection.selection_name}
														</span>
													</Text>
												</Table.TD>
											</Table.TR>
										))}
									</div>
								</React.Fragment>
							);
						}
						if (bet_type === 'eachway') {
							return selections.map((selection, i) => {
								if (i % 2 === 0) {
									return (
										<Table.TR key={`${selection.selection_name}${selection.price}`}>
											<Table.TD>
												<Text size="-4">
													#{selection.selection_number} {selection.selection_name}
												</Text>
											</Table.TD>
										</Table.TR>
									);
								}
							});
						}

						if (RACING_EXOTIC_BET_TYPES.includes(bet_type)) {
							const legsFormat = legsByResultString(bet.selection_string);

							const keys = Object.keys(legsFormat);

							if (keys.length === 1) {
								return (
									<Table.TR key={`${keys[0]}Leg${1}`}>
										<Table.TD>
											<Text size="-3">
												<span
													className={css`
														margin-left: 5px;
														font-weight: 400;
														flex: 1 1 auto;
													`}
												>
													{legsFormat[keys[0]].join(', ')}
												</span>
											</Text>
										</Table.TD>
									</Table.TR>
								);
							}

							return keys.map((key, i) => {
								return (
									<Table.TR key={`${key}Leg${i + 1}`}>
										<Table.TD>
											<Text size="-3">
												<span
													className={css`
														font-weight: 600;
														flex: 0 0 auto;
													`}
												>
													{numeral(i + 1).format('0o')} :
												</span>
												<span
													className={css`
														flex: 0 0 auto;
													`}
												>
													&nbsp;
												</span>
												<span
													className={css`
														margin-left: 5px;
														font-weight: 400;
														flex: 1 1 auto;
													`}
												>
													{legsFormat[key].join(', ')}
												</span>
											</Text>
										</Table.TD>
									</Table.TR>
								);
							});
						}

						if (QUADDIES_BET_TYPES.includes(bet_type)) {
							const legsFormat = groupBetSelectionByRaceNumberButNotName(selections);
							return Object.keys(legsFormat).map((key, i) => {
								return (
									<Table.TR key={`${key}Leg${i + 1}`}>
										<Table.TD>
											<Text size="-3">
												<span
													className={css`
														font-weight: 600;
														flex: 0 0 auto;
													`}
												>
													Leg {i + 1} (R{key}):
												</span>
												<span
													className={css`
														flex: 0 0 auto;
													`}
												>
													&nbsp;
												</span>
												<span
													className={css`
														margin-left: 5px;
														font-weight: 400;
														flex: 1 1 auto;
													`}
												>
													{legsFormat[key].join(', ')}
												</span>
											</Text>
										</Table.TD>
									</Table.TR>
								);
							});
						}
						// 1st Leg: Albury R4 #1 Place
						// 2nd Leg: Albury R5 #3 Place
						if (bet_type === 'multi') {
							return selections.sort(sortRaceByTime).map((selection, i) => {
								return (
									<Table.TR key={`${selection.competition_name}${selection.price}`}>
										<Table.TD>
											<Text size="-3">
												<span
													className={css`
														font-weight: 600;
														flex: 0 0 auto;
													`}
												>
													{formatString(
														`${positionByNumber(i + 1)} Leg: ${selection.competition_name} R${selection.race_number} #${
															selection.selection_number
														} ${selection.bet_type}`,
													)}
												</span>
											</Text>
										</Table.TD>
									</Table.TR>
								);
							});
						}

						return (
							<Table.TR key={`${name}${price}`}>
								<Table.TD>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											gap: 4,
											alignItems: 'center',
											justifyContent: 'space-between'
										}}
									>
										<Text size="-2">{name}</Text>
										<Text size="-1" align="right">
											x {number}
										</Text>
									</div>
								</Table.TD>
							</Table.TR>
						);
					})}
					<StyledMultiBetSummary__HorizontalRule />
					<Table.TR>
						<Table.TD>
							<Text size="-2">{t('Placed Bet Amount')}</Text>
						</Table.TD>
						<Table.TD>
							<Text size="-1" align="right">
								<PlotElements align="end">
									{totalStake > 0 ? <span></span> : ''}
									<CurrencyDisplay amount={totalStake} imageWidth={12} />
								</PlotElements>
							</Text>
						</Table.TD>
					</Table.TR>
					<Table.TR>
						<Table.TD>
							<Text strong size="-2">
								{t('PotentialPayout')}
							</Text>
						</Table.TD>
						<Table.TD>
							<Text strong size="-1" align="right">
								{isNaN(totalPotential) || !isFinite(totalPotential) ? (
									'TBD'
								) : (
									<CurrencyDisplay amount={totalPotential} />
								)}
							</Text>
						</Table.TD>
					</Table.TR>
				</Table>
			</StyledMultiBetSummary>
			<CustomBorder after />
		</React.Fragment>
	);
};

MultiBetSummary.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The set of bets made */
	bets: PropTypes.arrayOf(
		PropTypes.shape({
			/** The bet name */
			name: PropTypes.string.isRequired,

			/** The number of bets made in the bet */
			number: PropTypes.number.isRequired,
		}).isRequired,
	).isRequired,

	/** The total potential payout for all bets */
	totalPotential: PropTypes.number.isRequired,

	/** The total stake for all bets */
	totalStake: PropTypes.number.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};
MultiBetSummary.defaultProps = {
	className: '',
};

export default withNamespaces()(MultiBetSummary);
