import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PusherSubscribe from '../../../controllers/Pusher/PusherSubscribe';
import { updatePendingBetStatus } from '../../../../store/entities/actions/BetActions';
import { openNotification } from '../../../../store/application/applicationActions';

class PusherSubscriberUserBet extends Component {
	static propTypes = {
		/** The user ID to update */
		userId: PropTypes.number.isRequired,

		/** The bet ID to update */
		betId: PropTypes.number.isRequired
	};

	handleUpdateBet = (data) => {
		const {  response , ...bet} = data;
		// bet_id, status,
		// const statuses = ['partial', 'accepted', 'rejected'];
		this.props.updateBetStatus(bet, response, this.props.entities);

		if (bet.status.toLowerCase() === 'accepted') {
			this.props.openNotification(`Your BET [#${bet.bet_id}] has been accepted with Stake $${bet.bet_amount/100}.`, 'success', { autoClose: 3500 });
		} else if (bet.status.toLowerCase() === 'rejected') {
			this.props.openNotification(`Your BET [#${bet.bet_id}] has been rejected`, 'danger', { autoClose: 3500 });
		}
	};

	render() {
		const { userId, betId } = this.props;

		// Determine channel for all subscriptions
		const channel = `private-cache-bet-user_${betId}_${userId}`;
		// const channel = `cache-bet-user_${betId}_${userId}`;
		return (
			<Fragment>
				<PusherSubscribe key={'update'} channel={channel} event={'update'} onUpdate={this.handleUpdateBet} />
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	entities: state.entities
});

const mapDispatchToProps = (dispatch) => ({
	updateBetStatus: (bet, response, entities) => dispatch(updatePendingBetStatus(bet, response, entities)),
	openNotification: (message, type, options) => dispatch(openNotification(message, type, options))
});

export default connect(mapStateToProps, mapDispatchToProps)(PusherSubscriberUserBet);
