// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, ui } from '@tbh/ui-kit';

// Components
import { Image, Link, Tabs, Text } from '@tbh/ui-kit';
import AccountSummaryNavListItem from './AccountSummaryNavListItem/AccountSummaryNavListItem';

// Context
import { BRAND_NAME } from '../../../../../common/constants/wagering360';
import AclContext from '../../../../../contexts/AclContext';

const cssAccountSummaryNavList__Advert = css`
	label: AccountSummaryNavList__Advert;

	display: contents;
	height: 100%; // this style is necessary for IE
`;

const StyledAccountSummaryNavList__GamblingMessage = styled(Text)`
	label: AccountSummaryNavList__GamblingMessage;

	${(props) => css`
		background-color: ${ui(props).color_3};
		padding: ${spacings(props).comfortable}px ${spacings(props).cozy}px; // Override SC Text padding: 0
	`};
`;

const AccountSummaryNavList = (props) => {
	const { className, gamblingMessage, isCordova, items, onItemClick, t, ...rest } = props;

	const componentClasses = cx({
		[className]: className,
	});

	/**
	 * Handle menu item selection.
	 * @param item
	 */
	const handleItemClick = (item) => {
		onItemClick(item);
	};

	const renderSubItems = (subItems) => {
		return subItems.map((subItem) => (
			<AccountSummaryNavListItem key={subItem.id} onClick={handleItemClick} item={subItem} {...rest} />
		));
	};

	return (
		<div className={componentClasses}>
			<Tabs className={componentClasses} isColumn wrap>
				{items.map((item) => {
					return !item.items ? (
						<AccountSummaryNavListItem key={item.id} onClick={handleItemClick} item={item} {...rest} />
					) : null;
				})}
				<AclContext.Consumer key="advert">
					{(acl) =>
						acl.brandDetails && acl.brandDetails.name && acl.brandDetails.name === BRAND_NAME && !isCordova ? (
							<Link className={cssAccountSummaryNavList__Advert} href="https://promos.topbetta.com.au/mobileapp/">
								<Image
									className={css`
										width: 100%;
									`}
									src="//go.topbetta.com.au/delivery/avw.php?zoneid=11&n=a70d1bae&date=1525386907360&cb=25347086198"
									alt={t('AccountSummaryNavList__Download')}
								/>
							</Link>
						) : null
					}
				</AclContext.Consumer>
				{items.map((item) => {
					return item.items ? (
						<React.Fragment key={item.id}>
							<AccountSummaryNavListItem key={item.id} item={item} {...rest} />
							{renderSubItems(item.items)}
						</React.Fragment>
					) : null;
				})}
			</Tabs>
			<StyledAccountSummaryNavList__GamblingMessage type="primary" size="-1" align="center" strong>
				<AclContext.Consumer>
					{(acl) =>
						`${gamblingMessage ? `${gamblingMessage} ` : ''}${
							acl.gambleResponsiblyMessage ? acl.gambleResponsiblyMessage : ''
						}`
					}
				</AclContext.Consumer>
			</StyledAccountSummaryNavList__GamblingMessage>
		</div>
	);
};

AccountSummaryNavList.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Nav items */
	items: PropTypes.arrayOf(
		PropTypes.shape({
			/** Nav item id */
			id: PropTypes.string.isRequired,

			/** Nav item icon */
			icon: PropTypes.string,

			/** Nav item subitems */
			items: PropTypes.array,

			/** Nav item title */
			title: PropTypes.string,
		}),
	).isRequired,

	/** Click handler */
	onItemClick: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** Gambling message from the acl */
	gamblingMessage: PropTypes.string,

	/** If it's the cordova app or not */
	isCordova: PropTypes.bool,
};

AccountSummaryNavList.defaultProps = {
	className: '',
	gamblingMessage: '',
	isCordova: false,
};

export default withNamespaces()(AccountSummaryNavList);
