import React, { useEffect, useState } from 'react';
import { Icon, LoadingMask, Text, Ticker, spacings } from '@tbh/ui-kit';
import styled, { css } from 'react-emotion';
import CurrencyDisplay from '../../../components/features/Application/Currency/CurrencyDisplay/CurrencyDisplay';
import { legsByResultString } from '../../../common/ExoticBets';
import numeral from 'numeral';
import { countdown, decimalPlaces } from '../../../legacy/core/format';
import {
	QUADDIES_BET_TYPES,
	RACING_EXOTIC_BET_TYPES,
	SRMTypes,
	RACING_BET_PRODUCT_CODE_SP,
	RACING_BET_PRODUCT_CODE_TF,
	RACING_SAME_RACE_MULTI_TYPE
} from '../../../common/constants/Racing';
import BetNotificationEventClosed from '../../../components/features/Betting/BetNotification/BetNotificationEventClosed';
import { TOTE_NAMES_BY_PRODUCT, toteProducts } from '../../../store/entities/constants/BetConstants';
// Styled Components
const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: ${({ compact }) => spacings({ compact }).compact}px;
	overflow: auto;
	position: relative;
`;
const Button = styled.button`
	border-radius: 4px;
	border: none;
	padding: 4px 12px;
	font-size: 12px;
	cursor: pointer;
	transition: background-color 0.3s ease;

	${({ isAccept }) =>
		isAccept
			? css`
					background-color: #4caf50;
					color: #fff;

					&:hover {
						background-color: #45a049;
					}
			  `
			: css`
					background-color: #f44336;
					color: #fff;

					&:hover {
						background-color: #d32f2f;
					}
			  `}

	${({ disabled }) =>
		disabled &&
		css`
			background-color: #ddd;
			cursor: not-allowed;
			&:hover {
				background-color: #ddd;
			}
		`}
`;
const MessageContainer = styled.div`
	background: rgba(0, 0, 0, 0.4);
	width: 100%;
	height: 100%;
	position: absolute;
	border-radius: 8px;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Message = styled.div`
	background: white;
	border-radius: 4px;
	border: 1px solid black;
	padding: 4px 6px;
	width: 100%;
	max-width: 250px;
	color: #850000;
	font-size: 11px;
	text-align: center;
	cursor: pointer;

	&:hover {
		background: #dddd;
	}
`;

const BetInfo = ({ item, bet, children }) => (
	<Box>
		<Text size="-3" align="start" strong>
			R{item.race_number} - {item.competition_name}
		</Text>
		<Text size="-5" align="start" type="secondary" strong>
			{bet.bet_type == 'eachway' && 'EACHWAY'}
			{bet.bet_type != 'eachway' && item.bet_type.toUpperCase()} {bet.product_code === 'GRSF' && '(Fixed)'}{' '}
			{bet.bet_type != 'eachway' && bet.product_code !== 'GRSF'
				? TOTE_NAMES_BY_PRODUCT[bet.product_code] && TOTE_NAMES_BY_PRODUCT[bet.product_code][item.event_type]
					? `(${TOTE_NAMES_BY_PRODUCT[bet.product_code][item.event_type]})`
					: `(${bet.product_code})`
				: ''}
		</Text>
		{children}
	</Box>
);

const SelectionList = ({ selections, betType }) => {
	if (betType === 'eachway') {
		return (
			<Text size="-4" align="start" type="secondary">
				#{selections[0].selection_number} {selections[0].selection_name} 
			</Text>
		);
	}

	return selections.map(({ selection_number, selection_name }, index) => (
		<Text key={selection_number} size="-4" align="start" type="secondary">
			#{selection_number} {selection_name}
		</Text>
	));
};

const SelectionListLegs = ({ legs }) => {
	return Object.keys(legs).map((key, index) => (
		<Text size="-3" key={key}>
			<span style={{ fontWeight: 600 }}>{numeral(index + 1).format('0o')} :</span>&nbsp;
			<span style={{ fontWeight: 400 }}>{legs[key].join(', ')}</span>
		</Text>
	));
};

const SelectionListSRM = ({ selections }) => {
	return selections.map(({ selection_number, selection_name, product }, index) => (
		<Text size="-3" key={selection_name}>
			<span style={{ fontWeight: 600 }}>{SRMTypes[product]}:</span>
			<span
				className={css`
					flex: 0 0 auto;
				`}
			>
				&nbsp;
			</span>
			<span style={{ fontWeight: 400, marginLeft: 5 }}>
				#{selection_number} {selection_name}
			</span>
		</Text>
	));
};

export const Countdown = ({ betId, time, openNotification, setMessage, showTab }) => {
	// const calculateTime = (time) => new Date(time).getTime() - new Date().getTime();
	// const [sent, setSent] = useState(false);

	// useEffect(() => {
	// 	if (calculateTime(time) <= 0 && !sent) {
	// 		openNotification(`Your Partial Bet [${betId}] has expired`, 'danger');
	// 		setMessage('Your bet has expired.');
	// 		showTab();
	// 		setSent(true);
	// 	}
	// }, [time, sent, betId, openNotification, setMessage, showTab]);

	const c = countdown(time);

	return (
		<div style={{ display: 'flex', gap: '2px', border: '1px solid black', borderRadius: '8px', padding: '4px' }}>
			<Icon icon="clock" size="-2" />
			<Text size="-3" align="center" strong>
				{c}
			</Text>
		</div>
	);
};

const PedingBet = ({
	children,
	bet,
	handleNotificationPending,
	handlePartialBet,
	handleClosePartialBet,
	showTab,
	openNotification
}) => {
	const [loading, setLoading] = useState(false);
	const [awaitPartialBet, setAwaitPartialBet] = useState(false);
	const [message, setMessage] = useState('');
	const data = bet.response && bet.response[0];

	const status = (data && data.status ? data.status : bet.status).toUpperCase();

	useEffect(() => {
		if (!data) return;
		handleNotificationPending(bet.id, status);
		if (status === 'ACCEPTED') {
			setMessage('Your bet has been accepted.');
		} else if (status === 'REJECTED') {
			setTimeout(() => {
				handleClosePartialBet(bet.id, status);
			}, 1500);
			setMessage('Your bet has been rejected.');
		}
	}, [status, data, bet.id, handleNotificationPending]);

	const endTimer = !data ? true : new Date(data.end_time).getTime() - 1800 <= new Date().getTime();

	const handlePartialAction = async (accept = true) => {
		const endTimeEarly = new Date(data.end_time).getTime() - 1800;
		if (endTimeEarly <= new Date().getTime()) {
			return;
		}

		setLoading(true);
		if (accept) {
			setAwaitPartialBet(true);
			await handlePartialBet(data.betId, data.partialAmount);
			setAwaitPartialBet(false);
		} else {
			await handlePartialBet(data.betId, null, false);
		}
		setLoading(false);
	};

	return (
		<Container
			style={{
				background: status === 'PARTIAL' ? '#f2f2f2' : 'white',
				border: status === 'PARTIAL' ? '1px solid #850000' : '1px solid #dddd',
				borderRadius: '8px',
				height: 'fit-content',
				maxHeight: '240px'
			}}
		>
			{data && data.end_time && !awaitPartialBet && (
				<Ticker every={() => 1000}>
					<Countdown
						betId={data.betId}
						time={data.end_time}
						setMessage={setMessage}
						openNotification={openNotification}
						showTab={showTab}
					/>
				</Ticker>
			)}
			{message && (
				<MessageContainer>
					<Message onClick={() => handleClosePartialBet(bet.id, data.status)}>
						{message} <br /> Click to close
					</Message>
				</MessageContainer>
			)}
			{/* 
			{status == 'PROCESSING' && (
				<MessageContainer>
					<Message style={{ color: '#000' }}>
						Your bet is being processed. <br /> Please wait.
					</Message>
				</MessageContainer>
			)} */}

			<LoadingMask loading={loading} />
			<BetContainer>{children}</BetContainer>

			{status !== 'REJECTED' && status != 'ACCEPTED' && status != 'PROCESSING' && status != 'INTERCEPTED' && (
				<BetContainer>
					<Button
						style={{
							width: '60%'
						}}
						isAccept
						onClick={handlePartialAction}
						disabled={
							!(status == 'PARTIAL' || status == 'PENDING') ||
							status === 'INTERCEPTED' ||
							status === 'PROCESSING' ||
							loading ||
							endTimer
						}
					>
						Accept Partial Bet
					</Button>
					<Button
						style={{
							width: '40%'
						}}
						onClick={() => handlePartialAction(false)}
						disabled={
							status === 'INTERCEPTED' || status === 'PROCESSING' || status === 'REJECTED' || loading || endTimer
						}
					>
						Reject
					</Button>
				</BetContainer>
			)}
		</Container>
	);
};

const Box = styled.div`
	flex: 2;
	display: flex;
	flex-direction: column;
	gap: 2px;
`;

const BetContainer = styled.div`
	padding: ${({ compact }) => spacings({ compact }).compact}px;
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: ${({ tight }) => spacings({ tight }).tight}px;
`;

const StatusContainer = styled.div`
	width: fit-content;
	box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.3) 0px 0px 0px 1px;
	margin: auto;
	padding: 2px 6px;
	border-radius: 99px;
	background: #f2f2f2;
`;

const Bet = ({
	bet,
	handleNotificationPending,
	handlePartialBet,
	handleClosePartialBet,
	isActive,
	showTab,
	openNotification
}) => {
	const item = bet.bet_selections[0];
	const data = bet.response && bet.response[0];
	const legs = legsByResultString(bet.selection_string);
	const isMultiLegBetType = QUADDIES_BET_TYPES.includes(bet.bet_type);
	const isExotic = RACING_EXOTIC_BET_TYPES.includes(bet.bet_type);
	const isSRM = bet.bet_type === 'sameracemulti';
	const hasPrice = item && Number(item.fixed_odds);
	const status = (data && data.status ? data.status : bet.status).toUpperCase();
	
	const buildPRiceString = (betItem) => {
		const isExotic =
			RACING_EXOTIC_BET_TYPES.includes(betItem.bet_type) || QUADDIES_BET_TYPES.includes(betItem.bet_type);
		const isTote = !betItem.is_fixed && toteProducts.includes(betItem.product_code) && !isExotic;
		const firstSelection =
			betItem.bet_selections && betItem.bet_selections.length > 0 ? betItem.bet_selections[0] : null;
		const secondSelection =
			betItem.bet_selections && betItem.bet_selections.length > 1 ? betItem.bet_selections[1] : null;

		let productCode = '';
		let odds;
		if (betItem.bet_type === RACING_SAME_RACE_MULTI_TYPE) {
			odds = `${parseFloat(betItem.display_amount / 100).toFixed(2)}`;
			productCode = '';
		} else if (isTote || isExotic) {
			odds = isExotic ? betItem.percentage + '%' : 'TOTE';
			productCode =
				TOTE_NAMES_BY_PRODUCT[betItem.product_code] &&
				TOTE_NAMES_BY_PRODUCT[betItem.product_code][betItem.bet_selections[0].event_type]
					? TOTE_NAMES_BY_PRODUCT[betItem.product_code][betItem.bet_selections[0].event_type]
					: productCode;
		} else if (
			(betItem.product_code === RACING_BET_PRODUCT_CODE_TF || betItem.product_code === RACING_BET_PRODUCT_CODE_SP) &&
			betItem.is_fixed
		) {
			productCode =
				TOTE_NAMES_BY_PRODUCT[betItem.product_code] &&
				TOTE_NAMES_BY_PRODUCT[betItem.product_code][betItem.bet_selections[0].event_type]
					? TOTE_NAMES_BY_PRODUCT[betItem.product_code][betItem.bet_selections[0].event_type]
					: productCode;
			odds = `${productCode}`;
		} else {
			if (betItem.bet_type === 'eachway') {
				odds = `${parseFloat(firstSelection.fixed_odds).toFixed(2)}/${parseFloat(secondSelection.fixed_odds).toFixed(
					2
				)}`;
			} else if (betItem.bet_type === 'multi') {
				return decimalPlaces(
					betItem.bet_selections.reduce((acc, selection) => acc * parseFloat(selection.fixed_odds), 1),
					2
				);
			} else {
				odds = `${firstSelection.fixed_odds}`;
			}
			productCode = betItem.product_code === 'GRSF' ? 'FIXED' : betItem.product_code;
		}
		return odds;
	};

	return (
		<PedingBet
			bet={bet}
			handleNotificationPending={handleNotificationPending}
			handlePartialBet={handlePartialBet}
			handleClosePartialBet={handleClosePartialBet}
			isActive={isActive}
			showTab={showTab}
			openNotification={openNotification}
		>
			<BetInfo item={item} bet={bet}>
				{(isMultiLegBetType || isExotic) && <SelectionListLegs legs={legs} />}
				{isSRM && <SelectionListSRM selections={bet.bet_selections} />}
				{!isMultiLegBetType && !isExotic && !isSRM && (
					<SelectionList betType={bet.bet_type} selections={bet.bet_selections} />
				)}
			</BetInfo>
			<Box>
				<Text size="-3" align="center" strong>
					[ID:{bet.id}] Bet <CurrencyDisplay type="AUD" amount={bet.amount} imageWidth={11} />
					{'@' + buildPRiceString(bet)}
				</Text>
				<StatusContainer>
					<Text size="-4" align="center" type="secondary" strong>
						{status}
					</Text>
				</StatusContainer>

				{data && data.partialAmount > 0 && (
					<Text size="-4" align="center" type="secondary" strong>
						Suggested Partial Amount: <CurrencyDisplay type="AUD" amount={data.partialAmount * 100} imageWidth={11} />
					</Text>
				)}
			</Box>
		</PedingBet>
	);
};

const PendingBetContainer = ({
	isActive,
	bets,
	handleNotificationPending,
	handlePartialBet,
	handleClosePartialBet,
	showTab,
	openNotification
}) => {
	return (
		<Container
			style={{
				padding: 12
			}}
		>
			{bets.map((bet) => (
				<Bet
					key={bet.id}
					bet={bet}
					handleNotificationPending={handleNotificationPending}
					handlePartialBet={handlePartialBet}
					handleClosePartialBet={handleClosePartialBet}
					showTab={showTab}
					openNotification={openNotification}
				/>
			))}
		</Container>
	);
};

export default PendingBetContainer;
