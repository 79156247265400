import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
// import rg4js from 'raygun4js';
import { connect } from 'react-redux';
import { uniqueId } from 'lodash-es';
import { withNamespaces } from 'react-i18next';

import { sortNavigationItems } from '../../store/entities/selectors/NavigationItemsSelectors';

// Components
import { Notification } from '@tbh/ui-kit';
import ErrorBoundaryDefault from '../../components/features/ErrorBoundary/ErrorBoundaryDefault/ErrorBoundaryDefault';

// Actions
import { navigate } from '../../store/application/applicationActions';
import { getAuthenticatedUser } from '../../store/application/applicationSelectors';
import { denormalizeNavigationItems } from '../../store/entities/schemas/NavigationItemsSchema';

// Constants
import { RACING_BASE_URL } from '../../pages/Racing/RacingConstants';
import { MOBILE_MAX_WIDTH } from '../../common/constants/Breakpoints';
import { DEFAULT_BRAND_LOGOS } from '../../common/constants/wagering360';

class ErrorBoundaryContainer extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** List of available icons to use */
		brandLogos: PropTypes.object.isRequired,

		/** Navigate to the selected page */
		navigate: PropTypes.func.isRequired,

		/** Action for the dismiss notification button */
		action: PropTypes.func,

		/** Action to fire if an error occurs */
		onError: PropTypes.func,

		/** Message to display in the notification button */
		buttonText: PropTypes.string,

		/** Message to display in the Notification component */
		message: PropTypes.string,

		/** Extra classes */
		className: PropTypes.string,

		/** Children to display in the Error Boundary  */
		children: PropTypes.node,

		/** After X number of remount failures, display an alternate message */
		remountFailureCount: PropTypes.number,

		/** Alternate message to display after X number of retries */
		remountFailureTextKey: PropTypes.string,

		/** Alternate button text to display after X number of retries */
		remountFailureButtonText: PropTypes.string,

		/** Alternate function to execute after X number of retries, and the button is clicked on */
		remountFailureButtonAction: PropTypes.func,

		/** Custom fallback component to display on error */
		FallbackComponent: PropTypes.node,

		/** Whether or not the component is being viewed at mobile width or not */
		isMobileWidth: PropTypes.bool,

		/** List of navigation items to show */
		navigationItems: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				route: PropTypes.string.isRequired,
				title: PropTypes.string.isRequired,
				icon: PropTypes.string,
			}),
		),
	};

	static defaultProps = {
		action: () => {},
		onError: () => {},
		buttonText: 'Dismiss',
		message: null,
		className: '',
		children: null,
		remountFailureCount: 1,
		remountFailureTextKey: 'RemountDefault',
		remountFailureButtonText: 'OpenIntercom',
		remountFailureButtonAction: null,
		FallbackComponent: null,
		isMobileWidth: false,
		navigationItems: {},
	};

	constructor(props) {
		super(props);

		this.state = {
			key: this.generateUniqueKey(),
			hasError: false,
			errorCount: 0,
		};
	}

	/**
	 * This is the method that is activated when an error is detected underneath the error boundary
	 *
	 * @param error
	 * @param info
	 */
	componentDidCatch(error, info) {
		/**
		 * For some reason our production RayGun account doesn't appear to be receiving
		 * RayGun errors automatically when an Error Boundary is triggered
		 */
		// rg4js('send', error);
		// console.log('send',error)
		console.error(error, info);
		// this.setState({ hasError: true, errorCount: this.state.errorCount + 1 }, () => {
		// 	this.props.onError();
		// });
	}

	/**
	 * This is used to generate a unique key that will be attached to the child.
	 * When this key is changed, a remount will occur.
	 */
	generateUniqueKey = () => uniqueId(new Date().getTime());

	/**
	 * Action to fire for the custom button, or the Try Again button
	 */
	buttonAction = () => {
		this.setState({ key: this.generateUniqueKey(), hasError: false });
		this.props.action();
	};

	/**
	 * Route to the default page from a handler that passes through an event
	 */
	routeToHome = () => {
		this.routeTo();
	};

	/**
	 * Route to a different part of the application
	 *
	 * @param route
	 */
	routeTo = (route = RACING_BASE_URL) => {
		this.setState({ hasError: false }, () => this.props.navigate(route, { trigger: true }));
	};

	render() {
		const {
			t,
			children,
			buttonText,
			message,
			className,
			isMobileWidth,
			navigationItems,
			brandLogos,
			remountFailureCount,
			remountFailureTextKey,
			remountFailureButtonText,
			remountFailureButtonAction,
			FallbackComponent,
		} = this.props;

		const componentClasses = cx({
			[className]: className,
		});

		if (this.state.hasError) {
			if (FallbackComponent) {
				// If a custom fallback component is provided
				return <FallbackComponent className={componentClasses} />;
			} else if (message) {
				const remountFailure = this.state.errorCount > remountFailureCount;

				// If a message is provided display the notification
				return (
					<div className={componentClasses}>
						<Notification
							type={Notification.types.COLOUR_DANGER}
							message={
								remountFailure
									? t(`ErrorBoundaryContainer__${remountFailureTextKey}`)
									: t(`ErrorBoundaryContainer__${message}`)
							}
							buttonText={remountFailure ? remountFailureButtonText : t(buttonText)}
							buttonAction={
								remountFailure && remountFailureButtonAction
									? t(`ErrorBoundaryContainer__${remountFailureButtonAction}`)
									: this.buttonAction
							}
							strong
						/>
					</div>
				);
			}

			// Otherwise show a default error page
			return (
				<ErrorBoundaryDefault
					className={componentClasses}
					buttonAction={this.buttonAction}
					isMobileWidth={isMobileWidth}
					routeTo={this.routeTo}
					routeToHome={this.routeToHome}
					navigationItems={navigationItems}
					brandLogos={brandLogos}
				/>
			);
		}

		return React.Children.map(children, (child) => {
			// Checking if child exists is important to accept cases where it's null. e.g. [<Comp>, null]
			if (child) {
				// If we change this unique key, the component will be remounted
				const key = `${child.type.displayName}_${this.state.key}`;
				return React.cloneElement(child, { key });
			}
		});
	}
}

const mapStateToProps = (state) => {
	const isMobileWidth = window.innerWidth < MOBILE_MAX_WIDTH;

	let navigationItems = denormalizeNavigationItems(state.entities);
	const authenticatedUser = getAuthenticatedUser(state);
	const navigationList = sortNavigationItems(navigationItems, authenticatedUser);
	navigationItems = isMobileWidth ? navigationList.mobileItems : navigationList.primaryItems;

	return {
		isMobileWidth,
		navigationItems,
		brandLogos: state.acl.brandLogos || DEFAULT_BRAND_LOGOS,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		navigate: (route, opts) => {
			dispatch(navigate(route, opts));
		},
	};
};

export default withNamespaces()(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(ErrorBoundaryContainer),
);
