import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import moment from 'moment';
import { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import CountryImage from '../CountryImage/CountryImage';
import { countdown, serverDateTime } from '../../../../legacy/core/format';
import {
	StyledRacingFeature_races,
	StyledFeature_races_raceDetails,
	StyledFeature_races_raceTime,
	StyledFeature_races_timeButton,
	StyledFeature_racesTimeToGoBUtton,
	StyledMeetingAndNumber,
	StyledMeetingAndName,
	StyledFeatureIconWrapper,
	StyledFeature_races_raceTime_mobile,
	RacingICON_color
} from './FeatureRaceList.styled';
import RacingIcon from '../RacingIcon/RacingIcon';
/**
 * Change Meeting List Header Color Code ASH
 * ASH
 */

const styleFlag = css`
	width: 25px;
	height: 12px;
	margin-right: 5px;
`;

const FeatureRaceList = (props) => {
	const {
		t,
		className,
		id,
		type,
		onClickItem,
		name,
		size,
		country,
		number,
		start_datetime,
		meetingName,
		status,
		meetingId,
	} = props;

	/**
	 * We should improve how we get data to build meeting routes.
	 * Currently, we don't have enough data to correctly build a route for every meeting item.
	 */

	const handleEventClick = (e) => {
		e.preventDefault();
		onClickItem(id);

		// const url = 'racing-v3/' + type.toLowerCase() + '/' + moment(start_datetime).format().split('T')[0] + '/' + meetingName.replace(/\s/g, '').toLowerCase() + '/' + 'race-' + number + '-' + id + '-' + meetingId;
		// window.open(url, '_self');
	};

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<div className={componentClasses}>
			<StyledRacingFeature_races size={size}>
				<StyledFeature_races_raceDetails onClick={handleEventClick}>
					<StyledFeatureIconWrapper
						className={css`
							display: flex;
							flex-direction: row;
							align-items: center;
							flex-wrap: nowrap;
							min-width: fit-content;
						`}
					>
						<RacingIcon
							color={RacingICON_color}
							className={css`
								margin-left: 50px;
								margin-right: 10px;
							`}
							width={34}
							height={34}
							icon={type}
						/>
						<CountryImage
							country={country}
							className={css`
								width: 24px;
								border-radius: 4px;
								margin-bottom: 1px;
							`}
						/>
					</StyledFeatureIconWrapper>
					<div>
						<StyledMeetingAndNumber>
							{'R'}
							{number} | {meetingName}
						</StyledMeetingAndNumber>
						<StyledMeetingAndName>{name !== `Race ${number}` ? `${name}` : null}</StyledMeetingAndName>
						<StyledFeature_races_raceTime_mobile size={size}>
							{serverDateTime(moment(start_datetime)).format('MMM Do YYYY, h:mm A')}
						</StyledFeature_races_raceTime_mobile>
					</div>
				</StyledFeature_races_raceDetails>

				<StyledFeature_races_raceTime size={size}>
					{serverDateTime(moment(start_datetime)).format('MMM Do YYYY, h:mm A')}
				</StyledFeature_races_raceTime>
				<StyledFeature_races_timeButton size={size}>
					<StyledFeature_racesTimeToGoBUtton status={status}>
						{status === 'selling' ? countdown(serverDateTime(moment(start_datetime))) : status}
					</StyledFeature_racesTimeToGoBUtton>
				</StyledFeature_races_timeButton>
			</StyledRacingFeature_races>
		</div>
	);
};

FeatureRaceList.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** ID of the  item */
	id: PropTypes.any.isRequired,

	/** Handle when a meeting is clicked on */
	onClickItem: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** The scrollbar width for the browser */
	scrollbarWidth: PropTypes.number,

	/** Extra classes */
	className: PropTypes.string,

	/** The name of the race */
	name: PropTypes.string,

	/** The number of the race */
	number: PropTypes.number,

	/** The current selected meeting */
	activeMeetingItem: PropTypes.number,

	country: PropTypes.string,

	type: PropTypes.string,

	start_datetime: PropTypes.string,

	meetingName: PropTypes.string,

	meetingId: PropTypes.number,

	status: PropTypes.string,
};

FeatureRaceList.defaultProps = {
	className: null,
	activeMeetingItem: null,
	name: null,
	country: null,
	number: null,
	type: null,
	start_datetime: null,
	meetingName: null,
	meetingId: null,
	scrollbarWidth: null,
	status: '',
};

export default withNamespaces()(FeatureRaceList);
