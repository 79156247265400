import React from 'react';
import { connect } from 'react-redux';
import { buildMeetingGroupsFromGroupByType } from '../../../../pages/GRSNewRacing/RacingNewHome/racingSelectorsGRS';
import { navigateToMeeting, navigateToRaceWithType } from '../../../../store/GRSracingHome/GRSracingHomeActions';
import {
	SortMeetingGroupsByRaceTime,
	filterCountryWiseMeetingGroups,
	filterRaceTypeWiseMeetings,
} from '../../../../store/entities/selectors/MeetingSelectorsGRS';
import { scrollToTop } from '../../../../store/application/applicationActions';
import NewRaceItem, { StatusPattern } from '../RaceNav/RaceItem/NewRaceItem';
import styled, { css } from 'react-emotion';
import CountryImage from '../CountryImage/CountryImage';
import { COMPACT_RACES_AT, RACE_SELLING_STATUS, RACING_AU, RACING_GREYHOUNDS_CODE, RACING_HARNESS_CODE, RACING_THOROUGHBRED_CODE } from '../../../../common/constants/Racing';
import { ArrowIcon, Text, Ticker, brand, ui } from '@tbh/ui-kit';
import { BREAKPOINTS, STATUS_COLORS ,TODAY_RACES_COLOR} from '../../../../constants/themes';
import { Skeleton } from '../../../UI/Skeleton';
import RacingIcon from '../RacingIcon/RacingIcon';
import { countdown } from '../../../../legacy/core/format';
const Table = styled('table')`
	width: 100%;
	position: relative;
	border-collapse: separate;
	border-spacing: 0;
`;

const Thead = styled('thead')``;

const Tbody = styled('tbody')``;

const Tr = styled('tr')(
	(props) => css`
		width: 100%;
		overflow: hidden;
		position: sticky;
		top: 0;
		left: 0;
		&:not(:last-child) {
			border-bottom: 1px solid ${ui(props).color_4};
		}
	`,
);

const Th = styled('th')(
	(props) => css`
		padding: 4px;
		background: ${TODAY_RACES_COLOR.main_header_bg_color};
		color: white;
		border-bottom: 1px solid ${TODAY_RACES_COLOR.main_header_bottom_color};
		height: 45px;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		position: sticky;
		top: 0;
		left: 0;
	`,
);

const Td = styled('td')(
	(props) => css`
		background: ${TODAY_RACES_COLOR.sec_row_bg_color};

		height: 50px;
		overflow: hidden;
		justify-content: start;
		align-items: center;
		gap: 4px;
		cursor: pointer;
		font-size: calc(10px + 0.5vw);
		word-break: break-word;
		&:hover {
			background-color: ${TODAY_RACES_COLOR.main_row_hover_bg_color};
		}

		&:not(:last-child) {
			border-right: 1px solid ${TODAY_RACES_COLOR.main_header_bottom_color};
		}
	`,
);

export const StatusBox = styled(Text)(
	(props) => css`
		padding: 4px 8px;
		border-radius: 4px;
		margin-top: auto;
		${props.status === 'paying' &&
			css`
				color: ${brand(props).paying_text_color ? brand(props).paying_text_color : STATUS_COLORS.paying_text_color};
				background-color: ${brand(props).paying_bg_color
					? brand(props).paying_bg_color
					: STATUS_COLORS.paying_bg_color};
			`};
		${props.status === 'interim' &&
			css`
				background-color: ${brand(props).interim_bg_color
					? brand(props).interim_bg_color
					: STATUS_COLORS.interim_bg_color};
			`};

		${props.status === 'closed' &&
			css`
				color: ${brand(props).closed_text_color ? brand(props).closed_text_color : STATUS_COLORS.closed_text_color};
				background-color: ${brand(props).closed_bg_color
					? brand(props).closed_bg_color
					: STATUS_COLORS.closed_bg_color};
			`};
		${props.status === 'abandoned' &&
			css`
				color: ${brand(props).abandoned_text_color
					? brand(props).abandoned_text_color
					: STATUS_COLORS.abandoned_text_color};
				background-color: ${brand(props).abandoned_bg_color
					? brand(props).abandoned_bg_color
					: STATUS_COLORS.abandoned_bg_color};
			`};
		${props.status === 'deleted' &&
			css`
				color: ${brand(props).deleted_text_color ? brand(props).deleted_text_color : STATUS_COLORS.deleted_text_color};
				background-color: ${brand(props).deleted_bg_color
					? brand(props).deleted_bg_color
					: STATUS_COLORS.deleted_bg_color};
			`};
		${props.status === 'paid' &&
			css`
				color: ${brand(props).paid_text_color ? brand(props).paid_text_color : STATUS_COLORS.paid_text_color};
				background-color: ${brand(props).paid_bg_color ? brand(props).paid_bg_color : STATUS_COLORS.paid_bg_color};
			`};

		${props.status === 'selling' &&
			css`
				color: ${brand(props).selling_text_color ? brand(props).selling_text_color : STATUS_COLORS.selling_text_color};
				background-color: ${brand(props).selling_bg_color
					? brand(props).selling_bg_color
					: STATUS_COLORS.selling_bg_color};
			`};
		${props.status === 'parading' &&
			css`
				color: black;
				animation: slide 1s infinite;
				color: ${brand(props).parading_text_color
					? brand(props).parading_text_color
					: STATUS_COLORS.parading_text_color};
				background-color: ${brand(props).parading_bg_color
					? brand(props).parading_bg_color
					: STATUS_COLORS.parading_bg_color};
			`};
		${props.status === 'at_the_post' &&
			css`
				color: ${brand(props).at_the_post_text_color
					? brand(props).at_the_post_text_color
					: STATUS_COLORS.at_the_post_text_color};
				animation: slide 1s infinite;
			`};
		${props.status === 'going_behind' &&
			css`
				color: ${brand(props).going_behind_text_color
					? brand(props).going_behind_text_color
					: STATUS_COLORS.going_behind_text_color};
				animation: slide 1s infinite;
			`};
		${props.status === 'finished' &&
			css`
				color: ${brand(props).finished_text_color
					? brand(props).finished_text_color
					: STATUS_COLORS.finished_text_color};
				background-color: ${brand(props).finished_bg_color
					? brand(props).finished_bg_color
					: STATUS_COLORS.finished_bg_color};
			`};
	`,
);

export const ControllerContainer = styled('div')`
	width: fit-content;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #dddddd;
`;

export const StyledController = styled('div')(
	(props) => css`
		display: flex;
		justify-content: end;
		align-items: center;
		width: fit-content;
		z-index: 1;
		background: ${brand(props).color_2};
		border-radius: 2px;
		width: 32px;
		height: 45px;
		gap: 4px;
		border-right: 1px solid ${ui(props).color_4};
	`,
);

export const FakeItemControler = styled('div')(
	(props) => css`
		background: white;
		width: 32px;
		height: 51px;
		justify-content: start;
		align-items: center;
		gap: 4px;
		cursor: pointer;
		border-right: 1px solid ${ui(props).color_4};
		border-top: 1px solid ${ui(props).color_4};
	`,
);

const StyledArrowIcon = styled(ArrowIcon)(
	(props) => css`
		cursor: pointer;
		${props.disabled
			? css`
					color: rgba(255, 255, 255, 0.3);
					cursor: not-allowed;
			  `
			: css`
					color: rgba(255, 255, 255, 0.6);
			  `}
		&:hover {
			color: white !important;
		}
	`,
);

export const MeetingCountDown = ({ nextSellingRace }) => {
	const c = countdown(nextSellingRace);
	return (
		<Text size="-3" align="center" strong>
			{c}
		</Text>
	);
};

const MeetingsAndRacesTable = ({
	filterRaceTypeList,
	meetingGroups,
	filteredCountryList,
	selectedExotic,
	size,
	navigateToRaceWithType,
	navigateMeeting,
}) => {
	const [translateX, setTranslateX] = React.useState({});

	function renderController(title, meetingLength, maxRaceNumber) {
		const translatedMaxRight = -(maxRaceNumber - COMPACT_RACES_AT) === translateX[title];

		const translatedMaxLeft = translateX[title] === 0 || !translateX[title];

		// Create Arrow Buttons to translate RaceList
		const onClick = (direction) => {
			if (maxRaceNumber > COMPACT_RACES_AT) {
				if (direction === 'left' && !translatedMaxLeft) {
					setTranslateX({
						...translateX,
						[title]: Number(translateX[title] === undefined ? 0 : translateX[title]) + 1,
					});
				}
				if (direction === 'right' && !translatedMaxRight) {
					setTranslateX({
						...translateX,
						[title]: Number(translateX[title] === undefined ? 0 : translateX[title]) - 1,
					});
				}
			}
		};

		return (
			<ControllerContainer>
				<StyledController>
					<div onClick={() => onClick('left')}>
						<StyledArrowIcon
							disabled={translatedMaxLeft || maxRaceNumber <= COMPACT_RACES_AT}
							direction={'left'}
							size="-1"
						/>
					</div>
					<div onClick={() => onClick('right')}>
						<StyledArrowIcon
							disabled={translatedMaxRight || maxRaceNumber <= COMPACT_RACES_AT}
							direction={'right'}
							size="-1"
						/>
					</div>
				</StyledController>
				{Array.from({ length: meetingLength }).map((i) => (
					<FakeItemControler key={i} />
				))}
			</ControllerContainer>
		);
	}

	let filterMeetingGroup = filterRaceTypeWiseMeetings(
		meetingGroups,
		filterRaceTypeList.length > 0
			? filterRaceTypeList
			: [RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE]
	);

	filterMeetingGroup = filterCountryWiseMeetingGroups(filterMeetingGroup, filteredCountryList);

	filterMeetingGroup = SortMeetingGroupsByRaceTime(filterMeetingGroup);

	const handleRaceClick = (type, date, id, meeting_id) => {
		navigateToRaceWithType(type, date, id, meeting_id);
	};

	const isCompact = false;

	const isScrollable = false;

	const getTickerInterval = (status, startTime = null) => {
		if (StatusPattern[status] != RACE_SELLING_STATUS) {
			return null;
		}
		/**
		 * Date gets startTime and convert to milliseconds to calculate time to jump.
		 * If the result in millisecond is less then 5 minutes return 1 sec in milliseconds.
		 */
		if (Math.abs(new Date(startTime) - new Date()) <= 300000) {
			return 1000;
		}
		// null makes Ticker clear interval.
		return null;
	};

	const isDesktop = size.width > 640;
	const isMobile = size.width <= 640;

	const buildTable = (meetingGroup) => {
		let maxRaceCount = 0;

		if (meetingGroup.meetings.length == 0) {
			return null;
		}

		meetingGroup.meetings.forEach((meeting) => {
			if (meeting.races.length > maxRaceCount) {
				maxRaceCount = meeting.races.length;
			}
			const poll = meeting.exotic_pools || [];

			/* return only the object containing the quaddie bet type */
			const exoticPool = poll.find((product) => {
				return product.bet_type_name === selectedExotic;
			});

			const hasExoticPool =
				exoticPool !== undefined &&
				exoticPool.legs.length > 0 &&
				!exoticPool.legs.find((leg) => meeting.not_selling_races.includes(leg.race_number));

			const isExoticRace = hasExoticPool ? exoticPool.legs.map((leg) => leg.race_number) : [];
			meeting.isExoticRace = isExoticRace;
		});

		// 12 is the padding of the table
		const rowWidth = size.width - 128 - 32 - 12 * 2;
		// Divade the Row per number of Races
		const raceNumber = maxRaceCount < COMPACT_RACES_AT ? maxRaceCount : COMPACT_RACES_AT;

		const rowItemWidth = rowWidth / raceNumber;

		return (
			<div
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				<div
					style={{
						width: '100%',
						overflow: 'hidden',
						position: 'relative',
						border: '1px solid #dcdcdc',
						borderRadius: '4px',
					}}
					key={meetingGroup.title + maxRaceCount}
				>
					<Table>
						<Thead>
							<Tr
								className={css`
									display: inline-flex;
								`}
							>
								<Th
									className={css`
										z-index: 1;
										display: inline-flex;
									`}
									style={{
										width: isDesktop ? '128px' : '100%',
									}}
								>
									<RacingIcon type={meetingGroup.code} color={TODAY_RACES_COLOR.racing_icon_color}/>
									<Text type="alternate" size={isDesktop ? '-3' : '-2'} align="start" strong style={{ width: '100%' }}>
										{meetingGroup.title}
									</Text>
								</Th>
								{isDesktop &&
									Array.from({ length: maxRaceCount }, (v, i) => i + 1).map((raceNumber, i) => (
										<Th
											className={css`
												display: inline-flex;
											`}
											style={{
												width: rowItemWidth,
												transform: `translateX(${translateX[meetingGroup.title] * rowItemWidth}px)`,
												transition: 'all 0.3s ease-in-out',

												// justifyContent: i + translateX[meetingGroup.title] == COMPACT_RACES_AT - 1 ? 'flex-start' : 'center',
											}}
											key={i + `_R${raceNumber}`}
										>
											<Text type="alternate" size="-3" align="center" strong>
												R{raceNumber}
											</Text>
										</Th>
									))}
							</Tr>
						</Thead>
						<Tbody>
							{meetingGroup.meetings.map((meeting) => (
								<Tr
									key={meeting.id}
									className={css`
										display: inline-flex;
									`}
								>
									<Td
										className={css`
											z-index: 1;
											padding: 0 3px;
											display: inline-flex;
											text-center: center;
											justify-content: space-between;
										`}
										isPaid={isMobile && meeting.isPaid}
										style={{
											width: isDesktop ? '128px' : '100%',
										}}
										onClick={() => navigateMeeting(meeting)}
									>
										<div
											className={css`
												display: flex;
												flex-direction: row;
												gap: 4px;
												align-items: center;
											`}
										>
											<CountryImage
												country={meeting.country === 'INT' ? meeting.state : meeting.country}
												className={css`
													width: 24px;
													height: 24px;
													border-radius: 4px;
												`}
											/>
											<Text size="-3">
												<span>{meeting.name}</span> ({meeting.country == RACING_AU ? meeting.state : meeting.country})
											</Text>
										</div>
										<div
											className={css`
												display: flex;
												flex-direction: row;
												gap: 4px;
											`}
										>
											{meeting.status != 'selling' && isMobile && (
												<StatusBox size="-3" align="center" strong status={meeting.status}>
													{meeting.status.replaceAll('_', ' ').toUpperCase()}
												</StatusBox>
											)}
											{meeting.nextSellingRace && isMobile && (
												<Ticker every={1000}>
													<MeetingCountDown nextSellingRace={meeting.nextSellingRace} />
												</Ticker>
											)}
										</div>
									</Td>
									{isDesktop &&
										meeting.races.map((r) => (
											<Td
												className={css`
													display: inline-flex;
												`}
												style={{
													width: rowItemWidth,
													transform: `translateX(${translateX[meetingGroup.title] * rowItemWidth}px)`,
													transition: 'all 0.3s ease-in-out',
												}}
												key={r.id}
												isExoticRace={meeting.isExoticRace && meeting.isExoticRace.includes(+r.number)}
											>
												<Ticker every={getTickerInterval(r.status, r.start_date)}>
													<NewRaceItem
														showRaceLabel={false}
														id={r.id}
														type={r.type}
														date={r.start_date}
														isNextRace={r.number === meeting.next_race_number}
														meetingId={meeting.id}
														meetingName={meeting.name}
														number={+r.number}
														result_string={r.result_string}
														eventStart={r.start_date}
														race_site_link={r.race_site_link}
														fixed_odds_enabled={r.fixed_odds_enabled}
														global_tote_available={r.global_tote_available}
														hasBets={r.hasBets}
														status={r.status}
														status_display={r.race_status_display}
														isExoticRace={meeting.isExoticRace && meeting.isExoticRace.includes(+r.number)}
														// isDailyDouble={showDailyDouble && meeting.dailyDoubleRaces.includes(+r.number)}
														// isQuaddie={showQuaddies && meeting.quaddieRaces.includes(+r.number)}
														onClickItem={() => {
															handleRaceClick(r.type, r.start_date, r.id, meeting.id);
														}}
														isCompact={isCompact}
														isScrollable={isScrollable}
														size={size}
														name={r.name}
														distance={r.distance}
													/>
												</Ticker>
											</Td>
										))}
									{maxRaceCount - meeting.races.length > 0 &&
										isDesktop &&
										Array.from({ length: maxRaceCount - meeting.races.length }, (v, i) => i + 1).map(
											(raceNumber, i) => (
												<Td
													className={css`
														display: inline-flex;
													`}
													style={{
														width: rowItemWidth,
														transform: `translateX(${translateX[meetingGroup.title] * rowItemWidth}px)`,
														transition: 'all 0.3s ease-in-out',
													}}
													key={i + '_empty' + raceNumber}
												/>
											),
										)}
								</Tr>
							))}
						</Tbody>
					</Table>
				</div>
				{isDesktop && renderController(meetingGroup.title, meetingGroup.meetings.length, maxRaceCount)}
			</div>
		);
	};

	return (
		<div
			className={css`
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 24px;
				width: 100%;
				overflow: hidden;
			`}
		>
			{filterMeetingGroup.length === 0 && (
				<Text style={{ marginTop: 24 }}>No meeting or races were found for the selected filters.</Text>
			)}
			{filterMeetingGroup.map((meetingGroup) => buildTable(meetingGroup))}
		</div>
	);
};
const mapStateToProps = (state, ownProps) => {
	return {
		meetingGroups: buildMeetingGroupsFromGroupByType(state, ownProps.showOnlyMeetingGroup),
		loadingMeeting: state.grsRacingHome.loadingMeeting,
		showQuaddieRaces: state.grsRacingHome.showQuaddieRaces,
		showDailyDoubleRaces: state.grsRacingHome.showDailyDoubleRaces,
	};
};

const mapDispatchToProps = (dispatch) => ({
	navigateToRaceWithType: (selectedType, selectedDate, raceId, meetingId) => {
		scrollToTop();
		return dispatch(navigateToRaceWithType(selectedType, selectedDate, raceId, meetingId));
	},
	navigateMeeting: (meeting) =>
		dispatch(navigateToRaceWithType(meeting.type, meeting.date, meeting.nextSellingRaceId, meeting.id)),
});

const ThClassLoading = css`
	@media (max-width: ${BREAKPOINTS.laptopMin}px) {
		width: 100%;
	}
	width: 128px;
`;

export const MeetingsAndRacesTableLoading = ({ size }) => {
	const buildTable = (meetingGroup) => {
		let meetingCount = 8;
		let maxRaceCount = size.width > 640 ? 8 : 0;

		// Divade the Row per number of Races
		const rowItemWidth = 100 / maxRaceCount;

		return (
			<div
				style={{
					width: '100%',
					overflow: 'hidden',
					position: 'relative',
					border: '1px solid #dcdcdc',
				}}
			>
				<table style={{ width: '100%' }}>
					<thead>
						<Tr>
							<Th className={ThClassLoading}>
								<div
									className={css`
										display: flex;
										align-items: center;
										justify-content: center;
									`}
								>
									<Skeleton black={true} />
								</div>
							</Th>
							{Array.from({ length: maxRaceCount }, (v, i) => i + 1).map((raceNumber, i) => (
								<Th style={{ width: `calc(${rowItemWidth}% - 128px)` }} key={i}>
									<Text type="alternate" size="-3" align="center" strong>
										R{raceNumber}
									</Text>
								</Th>
							))}
						</Tr>
					</thead>
					<tbody>
						{Array.from({ length: meetingCount }, (v, i) => i + 1).map((n) => (
							<Tr key={n} style={{}}>
								<Td className={ThClassLoading}>
									<div
										className={css`
											display: flex;
											align-items: center;
											gap: 4px;
											padding: 8px;
										`}
									>
										<Skeleton style={{ width: '16px', height: '16px' }} />
										<Skeleton />
									</div>
								</Td>
								{Array.from({ length: maxRaceCount }, (v, i) => i + 1).map((raceNumber, i) => (
									<Td style={{ width: `calc(${rowItemWidth}% - 128px)`, padding: '8px' }} key={i}>
										<Skeleton style={{ width: '100%' }} />
									</Td>
								))}
							</Tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};

	return (
		<div
			className={css`
				display: flex;
				flex-direction: column;
				gap: 24px;
				width: 100%;
			`}
		>
			{buildTable()}
			{buildTable()}
		</div>
	);
};
export default connect(mapStateToProps, mapDispatchToProps)(MeetingsAndRacesTable);
