import cx from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'react-emotion';
import UpRaceSelectionDetails from './../UpRaceSelectionDetails/UpRaceSelectionDetails';
/**
 * Define all the selection statuses & racing type codes as defined in the racing constants file
 */
import {
	RACING_BET_TYPE_SP,
	RACING_NON_EXOTIC_BET_TYPE,
	RACING_TYPE_CODES,
	SELECTION_NOT_SCRATCHED_STATUS,
	SELECTION_STATUSES,
} from '../../../../../common/constants/Racing';
import { PLACE_ICON_URL, WIN_ICON_URL } from '../../../../../store/entities/constants/BetConstants';
import {
	StyledGRSNtjRaceBettingAdd,
	StyledUpPriceListItem,
	StyledUpRaceSelectionPricesList,
	StyledUpRaceSelectionsListItem,
	StyledUpRaceSelectionsListItem__Wrapper,
} from './UpRaceSelectionsListItem.styled';

const UpRaceSelectionsListItem = (props) => {
	const {
		selectionBetButtons,
		runner,
		id,
		selection_status,
		number,
		name,
		silk,
		jockey,
		barrier,
		weight,
		bettingAvailable,
		last_starts_string,
		type_code,
		displayedFlucs,
		addToSingle,
		addToMulti,
		className,
		isMobileWidth,
		betType,
		size,
		trainer,
		win_deductions,
		place_deduction,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});
	const filteredBetButtons = selectionBetButtons.filter(
		(betButton) =>
			!(betButton.bet_type == 'place' && Number.isNaN(parseFloat(betButton.price))) ||
			betButton.product_code === RACING_BET_TYPE_SP,
	);

	// console.log(id,selection_status);
	return (
		<React.Fragment>
			<StyledUpRaceSelectionsListItem id={id} className={componentClasses}>
				<StyledUpRaceSelectionsListItem__Wrapper>
					<div
						className={css`
							display: flex;
							flex-direction: row;
							flex: 0 0 62%;
							justify-content: flex-start;
							overflow: hidden;
							text-overflow: ellipsis;
							//margin-top: -20px;
						`}
					>
						<UpRaceSelectionDetails
							number={number}
							name={name}
							silk={silk}
							jockey={jockey}
							trainer={trainer}
							barrier={barrier}
							weight={weight}
							last_starts_string={last_starts_string}
							selection_status={selection_status == null ? SELECTION_NOT_SCRATCHED_STATUS : selection_status}
							type_code={type_code}
							size={size}
						/>
					</div>

					<StyledUpRaceSelectionPricesList>
						{filteredBetButtons.map((betButton) => (
							<StyledUpPriceListItem key={betButton.index}>
								{betButton.bet_type == 'place' ? (
									<span
										className={css`
											text-transform: uppercase;
											font-size: 10px;
										`}
									>
										<img src={PLACE_ICON_URL} width="20px" height="20px" />
									</span>
								) : (
									<span
										className={css`
											text-transform: uppercase;
											font-size: 10px;
										`}
									>
										<img src={WIN_ICON_URL} width="20px" height="20px" />
									</span>
								)}
								<StyledGRSNtjRaceBettingAdd
									key={`${betButton.index}_${betButton.product_id}_${betButton.product_code}_${betButton.bet_type}`}
									small={isMobileWidth ? selectionBetButtons.length > 1 : selectionBetButtons.length > 2}
									price={betButton.price}
									initialPrice={betButton.initialPrice}
									betType={betButton.bet_type}
									productId={betButton.product_id}
									selectionId={id}
									hasMulti={betButton.hasMulti}
									addToSingle={addToSingle}
									addToMulti={addToMulti}
									bettingAvailable={bettingAvailable && betButton.productAvailable}
									priceRollups={betButton.priceRollups}
									emphasisedType={
										betButton.bet_type === RACING_NON_EXOTIC_BET_TYPE &&
										(selection_status == null ? true : selection_status === SELECTION_NOT_SCRATCHED_STATUS)
											? 'background'
											: null
									}
									useWrapper={
										betButton.bet_type === RACING_NON_EXOTIC_BET_TYPE &&
										(selection_status == null ? true : selection_status === SELECTION_NOT_SCRATCHED_STATUS)
									}
									scratched={selection_status == null ? false : selection_status !== SELECTION_NOT_SCRATCHED_STATUS}
									size={size}
								/>
							</StyledUpPriceListItem>
						))}
					</StyledUpRaceSelectionPricesList>
				</StyledUpRaceSelectionsListItem__Wrapper>
			</StyledUpRaceSelectionsListItem>
		</React.Fragment>
	);
};

UpRaceSelectionsListItem.propTypes = {
	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}),

	/** Action to handle the selection being selected for a single bet */
	addToSingle: PropTypes.func.isRequired,

	/** Action to handle the selection being selected for a multi bet */
	addToMulti: PropTypes.func.isRequired,

	/** Boolean indicating if betting is available ie. race isn't closed */
	bettingAvailable: PropTypes.bool.isRequired,

	/** The currently selected bet type */
	betType: PropTypes.string.isRequired,

	/**
	 * This is a code for the type of race it is
	 * ie. T - Thoroughbred | G - Greyhounds | H - Harness
	 */
	type_code: PropTypes.oneOf(RACING_TYPE_CODES).isRequired,

	/**
	 * This will be an array of bet buttons that will be displayed for each selection
	 * The order of display will be from left to right as given by the array.
	 */
	selectionBetButtons: PropTypes.arrayOf(
		PropTypes.shape({
			/** The ID of the product */
			product_id: PropTypes.number.isRequired,
			/** The code of the product */
			product_code: PropTypes.string.isRequired,
			/** The type of bet */
			bet_type: PropTypes.string.isRequired,
			/** The odds/price for the bet */
			price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			/** If a multi bet is available for this product */
			hasMulti: PropTypes.bool,
			/** If the product is available to be bet on */
			productAvailable: PropTypes.bool,
		}),
	).isRequired,

	displayPrices: PropTypes.array,

	/** The following props are race selection details which are pretty much what the name suggests! */
	/** Selections race number */
	id: PropTypes.number.isRequired,

	/** Selections race number */
	number: PropTypes.number.isRequired,

	/** Selections name */
	name: PropTypes.string.isRequired,

	/** Whether or not the component is being viewed in small context */
	isMobileWidth: PropTypes.bool,

	/** Selections race silk */
	silk: PropTypes.string,

	/** Selections race jockey or driver */
	jockey: PropTypes.string,

	/** Selections trainer */
	trainer: PropTypes.string,

	/** Selections race barrier number */
	barrier: PropTypes.string,

	/** Selections race weight or handicap */
	weight: PropTypes.number,

	/** A string of the selections last race starts placings (including spells) */
	last_starts_string: PropTypes.string,

	/**
	 * This status is used to determine if the race selection has been scratched or late scratched
	 * and if so will render with a new class to visually convey this to the user
	 */
	selection_status: PropTypes.oneOf(SELECTION_STATUSES),

	/** Runner contains the selections form stats and last starts */
	runner: PropTypes.shape(),

	/** Extra class(es) to pass through to the component */
	className: PropTypes.string,
	/** Which fluctuations to display, or don't display if null */
	displayedFlucs: PropTypes.oneOf([null, 'apn_fluctuations', 'topbetta_fluctuations']),

	/** Win deductions in cents */
	win_deductions: PropTypes.number,

	/** Place deductions in cents */
	place_deduction: PropTypes.number,
};

UpRaceSelectionsListItem.defaultProps = {
	boxed: false,
	isMobileWidth: false,
	silk: null,
	jockey: null,
	trainer: null,
	barrier: null,
	weight: null,
	last_starts_string: null,
	apn_fluctuations: null,
	topbetta_fluctuations: null,
	selection_status: null,
	runner: null,
	fluctuations: null,
	displayedFlucs: null,
	selectionBetButtons: [
		{
			price: null,
			hasMulti: false,
			productAvailable: true,
		},
	],
	className: null,
	displayPrices: null,
	size: null,
	win_deductions: 0,
	place_deduction: 0,
};

export default UpRaceSelectionsListItem;
