import {
	RACING_BET_TYPE_MARGIN,
	RACING_BET_TYPE_EACHWAY,
	RACING_BET_TYPE_EACHWAY_SHORT,
	RACING_BET_TYPE_PLACE,
	RACING_BET_TYPE_PLACE_SHORT,
	RACING_BET_TYPE_WIN,
	RACING_ODDS_GIDS,
	RACING_BET_TYPE_WIN_SHORT,
	RACING_TOTE_NAME,
	RACING_FIXED_NAME,
	RACING_SAME_RACE_MULTI_TYPE,
	QUADDIES_BET_TYPES,
	RACING_EXOTIC_BET_TYPES,
	RACING_BET_PRODUCT_CODE_TF,
	RACING_BET_PRODUCT_CODE_SP,
} from './constants/Racing';
import {
	BET_TYPE_WIN,
	BET_TYPE_PLACE,
	BET_TYPE_SPORT,
	BET_TYPE_MULTI,
	BET_TYPE_ODDSGRID,
	BET_MULTI_LEG_NAMES,
	BET_TYPE_RACE,
	TOTE_PRICE_UNAVAILABLE,
	FIXED_PRICE_UNAVAILABLE,
	SELECTION_PRICE_NOT_APPLICABLE,
	toteProducts,
} from '../store/entities/constants/BetConstants';

import combinations from './Combinations';
import { findPrice, findProduct } from '../store/entities/selectors/ProductSelectors';

/**
 * Formats a racing bet selection into a format for the bet prompt.
 *
 * @param givenSelection
 * @param meetingName
 * @param race
 * @param type
 * @returns {Object}
 */
const formatRacingBetSelection = (givenSelection, meetingName, race, type) => {
	let prices = givenSelection.prices;
	let products = race.products;

	// Format basic information
	let selection = {
		id: givenSelection.id,
		name: givenSelection.name,
		race: meetingName + ' Race #' + race.number,
		runner: givenSelection.number,
		silk: givenSelection.silk,
		type: 'race',
	};

	// Determine products for selection
	let placePrice, placeProduct, winPrice, winProduct, productKey;
	if (type.endsWith(RACING_FIXED_NAME)) {
		selection.is_fixed = true;
		productKey = 'fixed';
	} else {
		selection.is_fixed = false;
		productKey = 'tote';
	}

	// Load place price from race products
	placePrice = prices.find((product) => findPrice(product, givenSelection, `place_${productKey}`));
	placeProduct = products.find((product) =>
		findProduct(product, givenSelection, `place_${productKey}`, BET_TYPE_PLACE, selection.is_fixed),
	);

	// Load win price from race products
	winPrice = prices.find((product) => findPrice(product, givenSelection, `win_${productKey}`));
	winProduct = products.find((product) =>
		findProduct(product, givenSelection, `win_${productKey}`, BET_TYPE_WIN, selection.is_fixed),
	);

	// Check prices if they exist
	if (prices) {
		// Use matching selection's place price, if available
		placePrice =
			prices.find((price) => {
				return price.product_id === placeProduct && price.product_code === placePrice.product_code;
			}) || placePrice;

		// Use matching selection's win price, if available
		winPrice =
			prices.find((price) => {
				return price.product_id === winProduct && price.product_code === winPrice.product_code;
			}) || winPrice;
	}

	// Determine odds and product codes
	selection.place = {
		code: placePrice.product_code,
		odds: placePrice.place_odds,
	};

	selection.win = {
		code: winPrice.product_code,
		odds: winPrice.win_odds,
	};

	return selection;
};

/**
 * Formats a sports bet selection for BetPrompt.
 *
 * @param selection
 * @param eventName
 * @param marketName
 * @returns {Object}
 */
const formatSportsBetSelection = (selection, eventName, marketName) => {
	return {
		event: eventName,
		id: selection.id,
		line: selection.line,
		market: marketName,
		name: selection.name,
		odds: selection.price,
		type: BET_TYPE_SPORT,
	};
};

/**
 * Format the odds for presentation
 *
 * @param odds
 * @returns {*}
 */
const formatOdds = (odds) => {
	// If the odds aren't a number, then just return
	if (isNaN(+odds)) {
		return odds;
	}

	odds = parseFloat(odds);
	// ;
	return odds.toFixed(2);
	// switch (odds >= 100) {
	// 	case true:
	// 		return '' + parseFloat(odds);
	// 	default:
	// 		return odds.toFixed(2);
	// }
};

/**
 * Calculate the possible payout for a bet
 *
 * @param stake
 * @param price
 * @param isBonusBet
 * @param racingBetType
 * @param placePrice
 * @returns {*}
 */
const calculatePossiblePayout = (
	stake,
	price,
	isBonusBet = false,
	racingBetType = RACING_BET_TYPE_WIN_SHORT,
	placePrice = 0,
	winPrice = 0,
	free_credit_flag = false,
) => {
	if (!stake) {
		return SELECTION_PRICE_NOT_APPLICABLE;
	}

	// Calculate the possible payout amount
	let possiblePayout = stake * price;
	if (racingBetType === RACING_BET_TYPE_PLACE_SHORT) {
		possiblePayout = stake * placePrice;
	} else if (racingBetType === RACING_BET_TYPE_EACHWAY_SHORT) {
		possiblePayout = stake * (winPrice + placePrice);
	}
	// If the bet is a bonus bet, then remove the initial stake from the payout
	if (isBonusBet && possiblePayout) {
		const amount = racingBetType === RACING_BET_TYPE_EACHWAY_SHORT ? stake * 2 : stake; // Remove double the stake for eachway
		possiblePayout -= amount * 100;
	}

	if (free_credit_flag) {
		possiblePayout = possiblePayout - stake * 100;
	}
	return possiblePayout;
};

/**
 * Format the display of the price / odds
 *
 * @param odds
 * @returns {string}
 */
const formatOddsAsPrice = (odds) => {
	// If the odds aren't a number, then return 'n/a'
	if (isNaN(+odds) || !odds) {
		return SELECTION_PRICE_NOT_APPLICABLE;
	}

	odds = parseFloat(odds);

	return `@ ${odds.toFixed(2)}`;
};

/**
 * Format the display of the price / odds
 *
 * @param odds
 * @returns {string}
 */
const formatCentsAsPrice = (odds) => {
	return formatOddsAsPrice(odds / 100);
};

/**
 * Return if the selection is an Exotic race selection
 *
 * @param selections
 * @returns {boolean}
 */
const isExotic = (selections = []) => {
	return selections.length > 1;
};

/**
 * Return if the selection is a racing selection
 *
 * @param selections
 * @returns {boolean|Number}
 */
const isRacing = (selections = []) => {
	return isExotic(selections) || (selections.length && selections[0].type === BET_TYPE_RACE);
};

/**
 * Builds the odds for the selection from the race and entity prices for the selection
 *
 * @param race
 * @param raceSelection
 * @param selection
 * @param betType
 * @param key
 * @param isFixed
 * @returns {Array}
 */
const buildOdds = (race, raceSelection, selection, betType, key, isFixed = false) => {
	const odds = [];
	const productKey = `${betType}_${key}`;

	if (raceSelection[productKey] || betType === RACING_ODDS_GIDS) {
		const products = Array.isArray(raceSelection[productKey])
			? raceSelection[productKey]
			: betType === RACING_ODDS_GIDS
			? [19]
			: [raceSelection[productKey]];

		for (let product_id of products) {
			const product =
				race.products &&
				race.products.find(
					(product) => product.bet_type === betType && product.fixed === isFixed && product.product_id === product_id,
				);

			const price =
				product &&
				raceSelection.prices &&
				raceSelection.prices.find((price) => price.product_id === product.product_id);

			const odd = product
				? {
						code: product.product_code,
						fixed: isFixed,
						odds:
							product.product_id === 19 ? price['win_odds'] : price ? price[`${betType}_odds`] : product.product_code,
						productId: product.product_id,
						type: product.product_type,
						available: !!(product.available && (!isFixed || (isFixed && price && price[`${betType}_odds`]))),
				  }
				: selection[betType];

			odds.push(odd);
		}
	}
	return odds;
};

/**
 * Finds all possible odds for tote, fixed and regular odds
 *
 * @param race
 * @param selections
 * @returns {{}}
 * Change By AR
 */
const getAllOddsForSelection = (race = {}, selections = []) => {
	// Return early if it is an exotic selection
	if (!selections.length || isExotic(selections)) {
		return {};
	}

	const selection = selections[0];

	let odds = {
		win: selection.odds,
	};

	if (isRacing(selections)) {
		odds = {
			win_tote: [],
			place_tote: [],
			margin_tote: [],
			win_fixed: [],
			place_fixed: [],
			margin_fixed: [],
			oddsgrid_fixed: [],
			oddsgrid_tote: [],
			win: selection.win,
			place: selection.place,
			margin: selection.margin,
		};

		// Backup race attached to the selection in the instance that the bet prompt is not opened from racing home
		if (!race || !race.id) {
			race = selection.race;
		}

		if (race && race.selections) {
			const raceSelection = race.selections.find((sel) => sel.id === selection.id);
			if (raceSelection) {
				odds.win_tote = buildOdds(race, raceSelection, selection, BET_TYPE_WIN, RACING_TOTE_NAME, false);
				odds.place_tote = buildOdds(race, raceSelection, selection, BET_TYPE_PLACE, RACING_TOTE_NAME, false);
				odds.margin_tote = buildOdds(race, raceSelection, selection, RACING_BET_TYPE_MARGIN, RACING_TOTE_NAME, false);
				odds.oddsgrid_tote = buildOdds(race, raceSelection, selection, BET_TYPE_ODDSGRID, RACING_TOTE_NAME, false);

				if (race.fixed_odds_enabled) {
					odds.win_fixed = buildOdds(race, raceSelection, selection, BET_TYPE_WIN, RACING_FIXED_NAME, true);
					odds.place_fixed = buildOdds(race, raceSelection, selection, BET_TYPE_PLACE, RACING_FIXED_NAME, true);
					odds.oddsgrid_fixed = buildOdds(race, raceSelection, selection, BET_TYPE_ODDSGRID, RACING_FIXED_NAME, true);
					odds.margin_fixed = buildOdds(
						race,
						raceSelection,
						selection,
						RACING_BET_TYPE_MARGIN,
						RACING_FIXED_NAME,
						true,
					);
				}
			}
		}
	}

	return odds;
};

/**
 * Calculates the total price for a leg item
 *
 * @param combinations
 * @returns {number}
 */
const calculatePriceForLegs = (combinations = []) => {
	// Determine the price for each leg based on the possible combinations
	let price = 0;
	combinations.forEach((combination) => {
		price += combination.reduce((acc, leg) => {
			// If the selection is a race
			if (leg.isRacing) {
				// If the selection is an Eachway bet
				if (leg.racingBetType === RACING_BET_TYPE_EACHWAY) {
					const placePrice = leg[RACING_BET_TYPE_PLACE].odds;
					const winPrice = leg[RACING_BET_TYPE_WIN].odds;

					return acc * (placePrice + winPrice);
				}

				return acc * leg[leg.racingBetType].odds;
			}

			// For sports selection use the price if it exists, otherwise the odds (both the same)
			return acc * (typeof leg.price === 'undefined' ? leg.odds : leg.price);
		}, 1);
	});

	return isNaN(price) ? SELECTION_PRICE_NOT_APPLICABLE : price * 100;
};

/**
 * Determine name based on leg count
 *
 * @param numberOfSelections
 * @param legCount
 * @returns {string}
 */
const generateLegName = (numberOfSelections, legCount) => {
	return legCount === numberOfSelections ? `${legCount} Leg Multi` : BET_MULTI_LEG_NAMES[legCount];
};

/**
 * Builds an individual leg item
 *
 * @param selections
 * @param legCount
 * @param legs
 * @returns {{id: *, name: string, numberOfBets, price: number, total}}
 */
const buildLegObject = (selections, legCount, legs = []) => {
	// Determine the combinations and number of selections
	const numberOfSelections = selections.length;
	const combos = combinations(selections, legCount);
	const name = generateLegName(numberOfSelections, legCount);

	// If there is an existing matching leg, keep it's stake
	const existingLeg = legs.find((leg) => leg.id === legCount);
	const legStake = existingLeg ? existingLeg.stake : 0;
	// Set the selection price as a string if there are prices unavailable
	const selectionIsFixed = selections.some(
		(selection) => selection.isRacing && selection[selection.racingBetType].fixed,
	);
	const selectionPriceUnavailable = selections.some(
		(selection) => selection.isRacing && isNaN(selection[selection.racingBetType].odds),
	);
	// Build and return the leg object
	return {
		id: legCount,
		name: name,
		numberOfBets: combos.length,
		price:
			selectionPriceUnavailable && selectionIsFixed
				? FIXED_PRICE_UNAVAILABLE
				: selectionPriceUnavailable
				? TOTE_PRICE_UNAVAILABLE
				: calculatePriceForLegs(combos),
		total: numberOfSelections,
		stake: legStake,
		amount: legStake * combos.length,
	};
};

/**
 * Builds a list of leg items
 *
 * @param selections
 * @param legs
 * @returns {Array}
 */
const buildLegs = (selections, legs = []) => {
	// removeTote
	const selectionsForLegs = selections.filter(
		(selection) =>
			selection.singleOnly !== true &&
			selection.racingBetType !== RACING_BET_TYPE_EACHWAY &&
			!toteProducts.includes(selection.product.product_code),
	).sort((a,b) => a.id - b.id);
	
	if (selections.length === 0) {
		return [];
	}

	// If selections are from the same race should not create legs

	// If there are more than one selection from the same race we should not create legs

	for (let i = 0; i < selectionsForLegs.length; i++) {
		if (toteProducts.includes(selectionsForLegs[i].product.product_code)) return [];
		for (let j = i + 1; j < selectionsForLegs.length; j++) {
			if (
				selectionsForLegs[i].race_id === selectionsForLegs[j].race_id ||
				toteProducts.includes(selectionsForLegs[j].product.product_code)
			) {
				return [];
			}
		}
	}

	// const firstSelection = selectionsForLegs[0];

	// const allSelectionsFromSameRace = selections.every((selection) => selection.race_id === firstSelection.race_id);

	// if (allSelectionsFromSameRace) {
	// 	return [];
	// }

	let numberOfSelections = selectionsForLegs.length;

	let multiLegs = [];
	if (numberOfSelections > 1) {
		// Only build the Multi leg {number of selections}
		multiLegs.push(buildLegObject(selectionsForLegs, numberOfSelections, legs));
	}
	// while (numberOfSelections > 1) {
	// 	multiLegs.push(buildLegObject(selectionsForLegs, numberOfSelections, legs));
	// 	numberOfSelections--;
	// }
	
	return multiLegs;
};

/**
 * Shape our selection data  (single bets) for submission to the bets endpoint
 *
 * @param selections
 * @param origin
 * @param boxed_flag
 * @param free_credit_flag
 */
const buildMultiSingleBets = (
	selections,
	{ origin, boxed_flag, free_credit_flag, totalBetAmount, isEachway = false },
) => {
	// Format our single selections that have bets
	if (!isEachway) {
		return selections.reduce((acc, selection) => {
			if (selection.amount > 0) {
				const betType = selection.isRacing ? selection.racingBetType : BET_TYPE_SPORT;
				if (selection.isRacing && selection.racingBetType != RACING_BET_TYPE_EACHWAY) {
					acc.push({
						origin,
						boxed_flag,
						free_credit_flag: selection.free_credit_flag ? selection.free_credit_flag : false,
						eachway: isEachway,
						selectionId: selection.id,
						bets: [
							{
								amount:
									selection.product_id === 19 ||
									selection.product_id === 20 ||
									selection.product_id === 21 ||
									selection.product_id === 22
										? selection.user_amount
										: selection.amount,
								bet_type: betType,
							},
						],
						selections: buildSelectionsForRequest([selection], true),
					});
				}
			}
			return acc;
		}, []);
	}
};

const buildEachWaySingleBets = (
	selections,
	{ origin, boxed_flag, free_credit_flag, totalBetAmount, isEachway = true },
) => {
	if (isEachway) {
		return selections.reduce((acc, selection) => {
			if (selection.isRacing && selection.amount > 0) {
				const timer = Date.now() - selection.request_at_bet_slip;
				const betType = RACING_BET_TYPE_EACHWAY;
				acc.push({
					origin,
					boxed_flag,
					free_credit_flag: selection.free_credit_flag ? selection.free_credit_flag : false,
					eachway: isEachway,
					selectionId: selection.id,
					bets: [
						{
							amount: selection.amount * 2,
							bet_type: betType,
						},
					],
					selections: [
						{
							accept_previous_bet: Math.floor(timer / 1000) <= 10,
							bet_type: RACING_BET_TYPE_WIN,
							id: selection.id,
							win_dividend: selection.win.odds,
							win_product: selection.win.productId,
						},
						{
							accept_previous_bet: Math.floor(timer / 1000) <= 10,
							bet_type: RACING_BET_TYPE_PLACE,
							id: selection.id,
							place_dividend: selection.place.odds,
							place_product: selection.place.productId,
						},
					],
				});
			}
			return acc;
		}, []);
	}
};

/**
 * Shape our leg data for submission to the bets endpoint
 *
 * @param selections
 * @param legs
 * @param origin
 * @param boxed_flag
 * @param free_credit_flag
 * @returns {{origin: *, boxed_flag: *, free_credit_flag: *, bets, selections: Array}}
 */
const buildMultiBets = (selections, legs, { origin, boxed_flag, free_credit_flag }) => {
	// Format our legs that have bets
	const multiBets = legs.reduce((acc, leg) => {
		if (leg.stake > 0) {
			acc.push({
				origin,
				boxed_flag,
				free_credit_flag: leg.free_credit_flag ? leg.free_credit_flag : false,
				amount: leg.stake,
				bet_type: BET_TYPE_MULTI,
				legs: leg.id,
			});
		}
		return acc;
	}, []);

	if (multiBets.length === 0) {
		return undefined;
	}

	return {
		origin,
		boxed_flag,
		free_credit_flag,
		bets: multiBets,
		selections: buildSelectionsForRequest(selections),
	};
};
/**
 * Shape our leg data for submission to the bets endpoint
 *
 * @param SRMs
 * @param origin
 * @param boxed_flag
 * @param free_credit_flag
 * @returns {{origin: *, boxed_flag: *, free_credit_flag: *, bets, selections: Array}}
 */
const buildSRMsBets = (SRMs, { origin, boxed_flag, free_credit_flag }) => {
	// Format our legs that have bets
	const multiBets = SRMs.reduce((acc, srm) => {
		if (srm.stake > 0) {
			acc.push({
				origin,
				boxed_flag,
				free_credit_flag: srm.free_credit_flag ? srm.free_credit_flag : false,
				bets: [
					{
						origin,
						boxed_flag,
						free_credit_flag,
						legs: srm.selections.length,
						amount: srm.stake,
						return_amount: srm.price,
						bet_type: RACING_SAME_RACE_MULTI_TYPE,
					},
				],
				selections: buildSelectionsForRequest(srm.selections),
			});
		}
		return acc;
	}, []);
	//

	return multiBets;
};
/**
 * Shape our leg data for submission to the bets endpoint
 *
 * @param exotics
 * @param origin
 * @param boxed_flag
 * @param free_credit_flag
 * @returns {{origin: *, boxed_flag: *, free_credit_flag: *, bets, selections: Array}}
 */
const buildExoticsBets = (exotics, { origin, boxed_flag, free_credit_flag }) => {
	// Format our legs that have bets
	const multiBets = exotics.reduce((acc, exotic) => {
		if (exotic.stake > 0) {
			let bet_type;
			let product;
			let boxed_flag;
			if (exotic.exoticDetails) {
				bet_type = exotic.exoticDetails.id;
				product = exotic.exoticDetails.productId;
				boxed_flag = exotic.exoticDetails.isBoxed;
			} else {
				bet_type = exotic.bet_type;
				product = exotic.product_id;
				boxed_flag = exotic.boxed_flag;
			}
			acc.push({
				origin,
				boxed_flag,
				free_credit_flag: exotic.free_credit_flag ? exotic.free_credit_flag : false,
				bets: [
					{
						amount: exotic.stake,
						bet_type,
						product,
					},
				],
				legs: [],
				SRMs: [],
				quaddies: [],
				selections: buildSelectionsForRequest(exotic.selections),
			});
		}
		return acc;
	}, []);
	//

	return multiBets;
};

/**
 * Returns the shortened version of the racing bet type
 *
 * @param racingBetType
 * @returns {*}
 */
const shortenRacingBetType = (racingBetType) => {
	if (racingBetType === RACING_BET_TYPE_EACHWAY) {
		return RACING_BET_TYPE_EACHWAY_SHORT;
	} else if (racingBetType === RACING_BET_TYPE_WIN) {
		return RACING_BET_TYPE_WIN_SHORT;
	} else if (racingBetType === RACING_BET_TYPE_PLACE || racingBetType === RACING_SAME_RACE_MULTI_TYPE) {
		return RACING_BET_TYPE_PLACE_SHORT;
	} else {
		return racingBetType;
	}
};

/**
 * Builds up a selections object for API endpoints
 *
 * @param selections
 * @param allowSingles
 * @returns {Array}
 */
const buildSelectionsForRequest = (selections, allowSingles = false) => {
	return selections.reduce((acc, selection) => {
		const timer = Date.now() - selection.request_at_bet_slip;
		/*
		 * We only really want to include Eachway bets, or single bets excluded from multi's,
		 * in this structure for bet placement and not for validation
		 */
		if (
			(selection.singleOnly || (selection.isRacing && selection.racingBetType === RACING_BET_TYPE_EACHWAY)) &&
			!allowSingles
		) {
			return acc;
		}

		if (selection.isRacing || selection.type === BET_TYPE_RACE) {
			let betTypes;
			if (selection.racingBetType === RACING_BET_TYPE_EACHWAY) {
				betTypes = [RACING_BET_TYPE_WIN, RACING_BET_TYPE_PLACE];
			} else if (
				selection.product_id === 19 ||
				selection.product_id === 20 ||
				selection.product_id === 21 ||
				selection.product_id === 22 ||
				selection.product_id === 23 ||
				selection.product_id === 21 ||
				selection.product_id === 24 ||
				selection.product_id === 29 ||
				selection.product_id === 30 ||
				selection.product_id === 31 ||
				selection.product_id === 36
			) {
				betTypes = [RACING_BET_TYPE_WIN];
			} else if (selection.product_id === 33 || selection.product_id === 34 || selection.product_id === 35) {
				betTypes = [RACING_BET_TYPE_PLACE];
			} else {
				betTypes = [selection.racingBetType];
			}
			//

			betTypes.forEach((betType) => {
				let bet_type;
				if (
					selection.product_id === 19 ||
					selection.product_id === 20 ||
					selection.product_id === 21 ||
					selection.product_id === 22
				) {
					bet_type = 'oddsgrid';
				} else if (selection.product_id === 23) {
					bet_type = 'insurebet';
				} else if (selection.product_id === 24) {
					bet_type = 'nottowin';
				} else if (selection.product_id === 29) {
					bet_type = 'trainerwin';
				} else if (selection.product_id === 30) {
					bet_type = 'cover';
				} else if (selection.product_id === 31) {
					bet_type = 'cover';
				} else {
					bet_type = betType;
				}
				if (QUADDIES_BET_TYPES.includes(selection.bet_type)) {
					acc.push({
						id: selection.id,
						bet_type,
						product: selection.product_id,
						position: selection.position,
					});
				} else {
					let betObject = {
						id: selection.id,
						bet_type,
						accept_previous_bet: Math.floor(timer / 1000) <= 10,
					};

					if (
						(selection.product && selection.product.product_code === RACING_BET_PRODUCT_CODE_TF) ||
						(selection.product && selection.product.product_code === RACING_BET_PRODUCT_CODE_SP) ||
						selection.product_id === 19 ||
						selection.product_id === 20 ||
						selection.product_id === 21 ||
						selection.product_id === 22
					) {
						betObject[`${betType}_product`] = selection.product_id;
					} else {
						betObject[`${betType}_product`] = selection[betType].productId || selection[betType].product_id;
					}

					// SP CASE
					if (selection.product_id == 15) {
						if (!Number.isNaN(Number(selection[betType].odds)))
							betObject[`${betType}_dividend`] = selection[betType].odds;
					} else {
						betObject[`${betType}_dividend`] = selection[betType].odds;
					}

					acc.push(betObject);
				}
			});
		} else {
			if (QUADDIES_BET_TYPES.includes(selection.bet_type)) {
				const price = selection.prices.find((p) => p.product_id == 16);
				acc.push({
					id: selection.id,
					bet_type: selection.bet_type,
					product: selection.product_id,
					divinded: price ? price.win_odds : null,
					position: `${selection.position}`,
				});
			} else if (RACING_EXOTIC_BET_TYPES.includes(selection.bet_type)) {
				acc.push({
					id: selection.id,
					bet_type: selection.bet_type,
					product: selection.product_id,
					position: Number(selection.position),
				});
			} else {
				acc.push({
					id: selection.id,
					product_id: selection.product_id,
					win_dividend: selection.price || selection.odds,
					accept_previous_bet: Math.floor(timer / 1000) <= 10,
				});
			}
		}

		return acc;
	}, []);
};

/**
 * Builds up a selections Combinaison object for Same Race Multi Selections
 *
 * @param selections
 * @returns {Array}
 */
const buildSameRaceMultiSelectionsCombination = (selections) => {
	// Build up the combinations with the same Race
};

export {
	formatOdds,
	formatOddsAsPrice,
	formatCentsAsPrice,
	formatRacingBetSelection,
	formatSportsBetSelection,
	calculatePossiblePayout,
	isExotic,
	isRacing,
	getAllOddsForSelection,
	generateLegName,
	buildLegObject,
	buildLegs,
	buildMultiSingleBets,
	buildMultiBets,
	buildSRMsBets,
	shortenRacingBetType,
	buildSelectionsForRequest,
	calculatePriceForLegs,
	buildEachWaySingleBets,
	buildExoticsBets,
};
