import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { navigateToRaceWithType } from '../../../../../store/GRSracingHome/GRSracingHomeActions';
import styled, { css } from 'react-emotion';
import { ArrowIcon, typography } from '@tbh/ui-kit';
import { FAMILYS,RACE_CARD_COLOR } from '../../../../../constants/themes';
import { filterMeetingByType } from '../../../../../store/entities/selectors/MeetingSelectors';
import { getMeetings } from '../../../../../pages/Racing/RacingHome/racingSelectorsGRS';
import RacingIcon from '../../RacingIcon/RacingIcon';

const MeetingName = styled('p')(
	(props) => css`
		color: white;
		font-size: 16px;
		// text-decoration: underline;
		cursor: pointer;
		margin: 0;
		${props.isMobileWidth && 'max-width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'}
	`,
);

export const DropdownContainer = styled('div')`
	position: absolute;
	top: 100%;
	margin-left: -8px;
	margin-top: 4px;
	background-color: white;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	overflow-y: auto;
	max-height: 300px;
	min-width: 240px;
	border-radius: 4px;
	border: 1px solid #dfdfdf;
	z-index: 11;
`;

const RaceDropDownContainer = styled('div')(
	(props) => css`
		display: flex;
		gap: 4px;
		height: fit-content;
		align-items: center;
		justify-content: center;
	`,
);

const RaceDropDown = styled('div')(
	(props) => css`
		display: flex;
		gap: 4px;
		border-bottom: 1px solid #e5e2e2;
		color: #fff;
		align-items: center;
		width: fit-content;
		position: relative;
		${props.isMobileWidth && 'max-width: 210px;'}
	`,
);

const RaceNumberBox = styled.div`
	width: 40px;
	height: 30px;
	background-color: ${RACE_CARD_COLOR.racenumber_box_bg_color}; // Keeping the red background
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	margin-right: 1px; // Add some space between the number and meeting name
`;

const RaceNumberText = styled.span`
	color: ${RACE_CARD_COLOR.racenumber_font_color};
	font-weight: bold;
	font-size: 14px; // Adjusted for better fit
	font-family: Arial, sans-serif;
	text-transform: uppercase;
`;

export const DropDownItem = styled('div')(
	(props) => css`
		display: flex;
		align-items: center;
		background-color: #fff;
		color: #000;
		padding: 4px;
		font-weight: 500;
		font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
		gap: 8px;
		font-size: 13px;
		cursor: pointer;
		white-space: nowrap;
		&::before {
			content: ${props.countryCode ? `" (${props.countryCode})"` : props.state ? `" (${props.state})"` : ''};
		}
		&:hover {
			background-color: #e6e6e6;
		}
	`,
);

const CountryRow = styled('div')(
	(props) => css`
		border-bottom: 1px solid #e5e2e2;
		color: #000000;
		padding: 12px;
		font-weight: 700;
		font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};

		&:not(:first-of-type) {
			border-top: 1px solid #e5e2e2;
		}
	`,
);

export const RacesDropDown = ({
	meetingId,
	meetings,
	navigateToRace,
	meetingName,
	meetingType,
	isMobileWidth,
	raceNumber,
}) => {
	const menuRef = React.useRef(null);
	const [showDropDown, setShowDropDown] = React.useState(false);

	React.useEffect(() => {
		const handleDisplayOfMenu = (event) => {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setShowDropDown(false);
			}
		};
		document.addEventListener('click', handleDisplayOfMenu, true);
		return () => {
			document.removeEventListener('click', handleDisplayOfMenu, true);
		};
	}, [menuRef]);

	const getNextRace = (meeting) => {
		let races = [...meeting.races];
		races.sort((a, b) => {
			return a.number - b.number;
		});
		let race = races.find((r) => r.number == meeting.next_race_number);
		let nextSellingRace = races.find((r) => r.status === 'selling');

		if (race && nextSellingRace && race.id != nextSellingRace.id) {
			race = nextSellingRace;
		}

		if (!race || (race && !nextSellingRace)) {
			race = races[0];
		}

		return race;
	};

	let lastCountry;
	const interRows = meetings.map((m) => {
		const countryRow =
			m.country !== lastCountry ? (
				<React.Fragment>
					<CountryRow key={m.country}>{m.country}</CountryRow>
					<DropDownItem
						key={m.id}
						onClick={() => {
							navigateToRace(m.type, m.next_race_date, getNextRace(m).id, m.id);
							setShowDropDown(false);
						}}
						countryCode={m.country === 'Australia' ? undefined : m.countryCode}
						state={m.country === 'Australia' ? m.state : undefined}
					>
						<RacingIcon color="black" type={m.type} />
						{m.name} ({m.state})
					</DropDownItem>
				</React.Fragment>
			) : (
				<DropDownItem
					key={m.id}
					onClick={() => {
						navigateToRace(m.type, m.next_race_date, getNextRace(m).id, m.id);
						setShowDropDown(false);
					}}
					countryCode={m.country === 'Australia' ? undefined : m.countryCode}
					state={m.country === 'Australia' ? m.state : undefined}
				>
					<RacingIcon color="black" type={m.type} />
					{m.name}({m.state})
				</DropDownItem>
			);
		lastCountry = m.country;
		return countryRow;
	});

	return (
		<RaceDropDownContainer>
			<RaceNumberBox>
				<RaceNumberText>R{raceNumber}</RaceNumberText>
			</RaceNumberBox>

			<RaceDropDown isMobileWidth={isMobileWidth}>
				<RacingIcon type={meetingType} color={RACE_CARD_COLOR.racing_icon_color}/>
				<MeetingName
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						setShowDropDown(!showDropDown);
					}}
					isMobileWidth={isMobileWidth}
				>
					{meetingName}
				</MeetingName>

				<ArrowIcon
					direction={showDropDown ? 'up' : 'down'}
					action={(e) => {
						e.preventDefault();
						e.stopPropagation();
						setShowDropDown(!showDropDown);
					}}
				/>
				{showDropDown && <DropdownContainer innerRef={menuRef}>{interRows}</DropdownContainer>}
			</RaceDropDown>
		</RaceDropDownContainer>
	);
};

const mapStateToProps = (state, ownProps) => {
	const meetings = getMeetings(state);

	const mettingsByType = filterMeetingByType(meetings, ownProps.meetingType).filter(
		(m) => m.id !== ownProps.meetingId || m.races.length > 0,
	);

	const mappendMettings = mettingsByType.map((m) => {
		const next_race_number = m.next_race_number === 0 ? m.races.length - 1 : m.next_race_number;
		const next_race_date =
			m.next_race_number === 0 || m.next_race_date === 'Invalid date'
				? m.races[next_race_number].start_date
				: m.next_race_date;
		return {
			type: m.type,
			name: m.name,
			country: m.country,
			countryCode: m.countryCode,
			state: m.state,
			id: m.id,
			races: m.races,
			next_race_date,
			next_race_number,
		};
	});

	mappendMettings.sort((a, b) => {
		if (a.country < b.country) {
			return -1;
		}
		if (a.country > b.country) {
			return 1;
		}
		return 0;
	});

	return {
		meetings: mappendMettings,
		meetingId: ownProps.meetingId,
		meetingType: ownProps.meetingType,
		meetingName: ownProps.meetingName,
	};
};

const mapDispatchToProps = (dispatch) => ({
	navigateToRace: (type, date, raceId, meetingId) => {
		dispatch(navigateToRaceWithType(type, date, raceId, meetingId));
		App.startSubApp('RacingNewPage');
	},
});

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(RacesDropDown));
