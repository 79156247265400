import { Checkbox } from '@tbh/ui-kit';
import styled from 'react-emotion';

export const StyledCheckBox = styled(Checkbox)`
	span {
		border: 1px solid #666666;
		height: 24px;
		width: 24px;
		:before {
			width: 8px;
			height: 16px;
			margin-left: 7px;
		}
	}
`;
