// Libraries
import { application } from '@tbh/ui-kit';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import { connect } from 'react-redux';
//import { application, brand, media } from '@tbh/ui-kit';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { SizeMe } from 'react-sizeme';
import URI from 'urijs';
import PusherSubscribe from '../../../../src/components/controllers/Pusher/PusherSubscribe';

// Actions
import { openAclManager } from '../../../store/acl/aclActions';
import {
	closeMultiBetSlip,
	getAffiliateParams,
	hideMastheadFields,
	openIframe,
	openMultiBetSlip,
	openQuickBettingSummary,
	routeTo,
	setMastheadHeight,
	setMastheadIframeHeight,
	showDeposits,
	showSidebar,
} from '../../../store/application/applicationActions';
import {
	lockScreen,
	login,
	logoutUser,
	openLoginForm,
	resetPassword,
} from '../../../store/authentication/authenticationActions';
import { isUseBonusBet } from '../../../store/betPlacement/betPlacementActions';
import { normalizeAndMergeBet } from '../../../store/entities/actions/BetActions';
import { getDenormalizedMeta } from '../../../store/entities/actions/MetaActions';
import { normalizeAndMergeUser } from '../../../store/entities/actions/UserActions';
import { closeModal } from '../../../store/modal/modalActions';
import { navigateToRegistration } from '../../../store/registration/registrationActions';
import { openThemePicker } from '../../../store/theme/themeActions';
import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';
import { isFeatureEnabled } from '../Context/contextMemoizedSelectors';

// Constants
import { MASTHEAD_AFFILIATE, MASTHEAD_DEFAULT, MASTHEAD_IFRAME } from '../../../common/constants/Application';
import { DEFAULT_BRAND_LOGOS } from '../../../common/constants/wagering360';
import { PLACE_MULTI_BET } from '../../../store/betPlacement/betPlacementReducerNames';

import { COLORS } from '../../../constants/themes';

// Components
import { Iframe, Popover } from '@tbh/ui-kit';
import AffiliateMasthead from '../../../components/features/Application/AffiliateMasthead/AffiliateMasthead';
import IframeMasthead from '../../../components/features/Application/IframeMasthead/IframeMasthead';
import Masthead from '../../../components/features/Application/Masthead/MastheadGRS';
import TimerHelpSection from '../../../components/features/Application/Masthead/TimerHelpSection/TimerHelpSection';
import {
	USER_ACCOUNT_ALL_TRANSACTIONS_URL,
	USER_ACCOUNT_TRANSACTIONS_URL,
	USER_ACCOUNT_V2_BASE_URL,
	USER_ACCOUNT_WITHDRAW_URL,
} from '../../../pages/UserAccountV2/UserAccountPageConstants';
import { getPendingBets } from '../../../store/entities/selectors/BetSelectors';
import AccountDetailsPopUp from '../UserInfoDropDown';

const StyledMastheadContainer = styled('div')`
	${(props) => css`
		position: relative;
		width: 100%;
		display: block;
		z-index: 2000;
		background-color: ${COLORS.gray[600]};
		margin: auto;
		${props.isHidden &&
			css`
				display: none;
			`};

		${props.paddingTop &&
			css`
				padding-top: ${props.paddingTop}px;
			`};
	`};
`;

const StyledMastheadContainer__IframeWrapper = styled('div')(
	(props) =>
		props.overlayIframe &&
		css`
			label: MastheadContainer__IframeWrapper;

			position: absolute;
			top: 0;
			width: 10%;
			z-index: 9999999;
		`,
);

const StyledMastheadContainer__Popover = styled(Popover)(
	(props) => css`
		label: MastheadContainer__Popover;
		top: 100%;
		//left: 0;
		width: 100%;
		margin: 0 auto;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
		border: 1px solid #3c3d44;
		z-index: 3000;
		height: 270px;
		background-color: #212429;
		${Popover.StyledPopover__Arrow} {
			display: none;
		}

		${!props.isFullWidth &&
			css`
				//max-width: ${application(props).max}px;
			`};
	`,

	// (props) =>
	// 	media(
	// 		css`
	// 			//	width: 88%;
	// 			width: 10%;
	// 		`,
	// 		1440,
	// 	)
);

class MastheadContainerGRS extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Get query string from url for the Affiliate Masthead */
		getAffiliateParams: PropTypes.func.isRequired,

		/** Navigate to the user account page */
		goToAccount: PropTypes.func.isRequired,

		/** Navigates to the account transactions page */
		goToAccountTransactions: PropTypes.func.isRequired,

		/** Navigate to Racing */
		goToHome: PropTypes.func.isRequired,

		/** Navigate to the registration page */
		goToRegistration: PropTypes.func.isRequired,

		/** Merge user's bets as it comes through pusher */
		mergeBet: PropTypes.func.isRequired,

		/** Merge user updates as they come through pusher */
		mergeUser: PropTypes.func.isRequired,

		/** Open the deposits modal */
		openDeposits: PropTypes.func.isRequired,

		/** Open the multi bet slip */
		onBetSlipClick: PropTypes.func.isRequired,

		/** Open the Join/Login modal */
		onJoinLogin: PropTypes.func.isRequired,

		/** Lock the screen and open the locked screen modal */
		onLockScreenClick: PropTypes.func.isRequired,

		/** Request user login */
		onLogin: PropTypes.func.isRequired,

		/** Request user logout */
		onLogout: PropTypes.func.isRequired,

		/** Open the sidebar */
		onMenuClick: PropTypes.func.isRequired,

		/** Reset the Masthead to it's initial state (no hidden fields) */
		onResetMasthead: PropTypes.func.isRequired,

		/** Request reset user's password */
		onResetPassword: PropTypes.func.isRequired,

		/** Open the user summary for mobile */
		//openAccountSummary: PropTypes.func.isRequired,

		/** Open the quick betting summary */
		openQuickBettingSummary: PropTypes.func.isRequired,

		/** Saves the height of the Masthead in global state */
		onMastheadHeightChange: PropTypes.func.isRequired,

		/** GA tracking */
		trackGaEvent: PropTypes.func.isRequired,

		/** Saves in redux the height of the iframe in the Masthead so the size of the TopBannerRegion can update */
		setMastheadIframeHeight: PropTypes.func.isRequired,

		/** List of available icons to use */
		brandLogos: PropTypes.object.isRequired,

		/** Whether or not there is a max-width restriction on the site */
		isFullWidth: PropTypes.bool.isRequired,

		/** Open the iframe modal */
		openIframeModal: PropTypes.func.isRequired,

		/** Close a specific open modal */
		closeModal: PropTypes.func.isRequired,

		/** The width of the scrollbar - used to offset containers */
		scrollbarWidth: PropTypes.number,

		/** User's balance */
		account_balance: PropTypes.number,

		/** Type of Masthead that should be displayed */
		mastheadType: PropTypes.oneOf([MASTHEAD_DEFAULT, MASTHEAD_AFFILIATE, MASTHEAD_IFRAME]),

		/** Affiliate name */
		affiliate_name: PropTypes.string,

		/** Affiliate text */
		affiliate_text: PropTypes.string,

		/** Affiliate username */
		affiliate_user: PropTypes.string,

		/** Amount of bets */
		count_bets: PropTypes.any,

		/** User's first name */
		first_name: PropTypes.string,

		last_name: PropTypes.string,

		/** User's free credit balance */
		free_credit_balance: PropTypes.number,

		/** If the Masthead fields need to be hidden or not (user during registration) */
		hideMastheadFields: PropTypes.bool,

		/** If it's the cordova app or not */
		isCordova: PropTypes.bool,

		/** If the bet slip is open or not */
		isMultiBetSlipOpen: PropTypes.bool,

		/** If the user is logged in or not */
		isUserLoggedIn: PropTypes.bool,

		/** If the user is verified or not */
		isUserVerified: PropTypes.bool,

		/** Display the TimerHelpSection */
		displaySessionTimer: PropTypes.bool,

		/** User's name */
		name: PropTypes.string,

		/** Opens the Acl Picker */
		onOpenAclManager: PropTypes.func,

		/** Opens the Theme Picker */
		onOpenThemePicker: PropTypes.func,

		/** User's pending bets */
		pending_bets: PropTypes.number,

		/** User's potential winnings */
		potential_winnings: PropTypes.number,

		/** If the Masthead should be visible or not */
		showMasthead: PropTypes.bool,

		/** If the Acl Manager button should be displayed or not */
		showAclButton: PropTypes.bool,

		/** If the Theme Manager button should be displayed or not */
		showThemeButton: PropTypes.bool,

		/** User's total stake */
		total_stake: PropTypes.number,

		/** User's id */
		userId: PropTypes.number,

		/** User's username */
		username: PropTypes.string,

		/** Iframe to display obove the Masthead */
		iframe: PropTypes.shape({
			/** Initial height for the iframe */
			height: PropTypes.number,
			/** Iframe src */
			src: PropTypes.string,
		}),

		/** Height of the iframe */
		iframeHeight: PropTypes.number,

		/** Meta data */
		meta: PropTypes.array,

		/** Masthead Height */
		mastheadHeight: PropTypes.number,

		street: PropTypes.string,

		dob: PropTypes.string,

		green_id_status: PropTypes.string,

		green_id_trans_id: PropTypes.string,

		userEmail: PropTypes.string,

		send_email: PropTypes.number,

		mobile: PropTypes.string,

		bailOutLimit: PropTypes.number,

		pricePumpLimit: PropTypes.number,

		goToAccountTransactionsPage: PropTypes.func,
	};

	static defaultProps = {
		account_balance: 0,
		affiliate_name: null,
		affiliate_text: null,
		affiliate_user: null,
		mastheadType: MASTHEAD_DEFAULT,
		count_bets: 0,
		userBetsCount: 0,
		first_name: null,
		last_name: null,
		free_credit_balance: 0,
		hideMastheadFields: false,
		userId: null,
		isMultiBetSlipOpen: false,
		isUserLoggedIn: false,
		name: null,
		onOpenAclManager: null,
		onOpenThemePicker: null,
		pending_bets: 0,
		potential_winnings: 0,
		showMasthead: true,
		showAclButton: false,
		showThemeButton: false,
		total_stake: 0,
		username: null,
		isCordova: false,
		isUserVerified: false,
		iframe: null,
		iframeHeight: 0,
		displaySessionTimer: true,
		meta: [],
		mastheadHeight: null,
		street: null,
		dob: null,
		green_id_status: null,
		green_id_trans_id: null,
		userEmail: null,
		send_email: null,
		mobile: null,
		cashout_limit: null,
		bailOutLimit: null,
		pricePumpLimit: 0,
		goToAccountTransactionsPage: null,
		scrollbarWidth: null,
	};

	state = {
		affiliate_name: null,
		affiliate_text: null,
		affiliate_user: null,
		error: null,
		loadingLogin: false,
		loadingResetPassword: false,
		resetPasswordError: null,
		resetPasswordSuccess: false,
		start_time: moment().format(),
		isBonusChecked: false,
		/** The state of the user summary view popover */
		showAccountSummary: false,
		showBalance: true,
	};

	/**
	 * Set the affiliate attributes with it's an affiliate header and set the start time for the timer
	 */
	componentDidMount() {
		if (this.props.mastheadType === MASTHEAD_AFFILIATE) {
			const params = this.props.getAffiliateParams();

			this.setState({
				affiliate_name: params.affiliate_name,
				affiliate_text: params.affiliate_text,
				affiliate_user: params.affiliate_user,
			});
		}
	}

	/**
	 * Sets a loading mask
	 *
	 * @param location: where in the Masthead the loading mask should be displayed
	 * @param loading: true or false
	 */
	setLoadingMask = (location, loading = false) => {
		this.setState({
			[location]: loading,
		});
	};

	/**
	 * Login the user
	 *
	 * @param username
	 * @param password
	 */
	handleLogin = (username, password) => {
		this.setLoadingMask('loadingLogin', true);

		this.props
			.onLogin(username, password)
			.then(() => {
				this.clearErrors();
				//this.props.trackGaEvent('Masthead', 'Submit', 'Login');
			})
			.catch((error) => {
				if (error.response && (error.response.status === 400 || error.response.status === 401)) {
					this.setState({
						error: error.response.data.errors,
					});
				} else {
					this.setState({
						error: error.message,
					});
				}
			})
			.finally(() => {
				this.setLoadingMask('loadingLogin', false);
			});
	};

	/**
	 * Resets the password
	 *
	 * @param email
	 */
	handleResetPassword = (email) => {
		this.setLoadingMask('loadingResetPassword', true);

		this.props
			.onResetPassword(email)
			.then((response) => {
				let error = response.data.error;

				if (response.data.success) {
					this.setState({
						resetPasswordSuccess: true,
					});
				} else {
					this.setState({
						resetPasswordSuccess: false,
						resetPasswordError: error ? error : this.props.t('MastheadContainer__PasswordError'),
					});
				}
			})
			.finally(() => {
				this.setLoadingMask('loadingResetPassword', false);
			});
	};

	/**
	 * Open the Deposits modal
	 */
	handleOpenDeposits = () => {
		this.props.openDeposits();
		//this.props.trackGaEvent('Masthead', 'Click', 'Deposit');
	};

	/**
	 * Open/Close the multi bet slip
	 */
	handleBetSlipClick = () => {
		this.props.onBetSlipClick(this.props.isMultiBetSlipOpen);
		//this.props.trackGaEvent('Masthead', 'View', 'Multi BetSlip');
	};

	/**
	 * Clear errors and track GA event when the Forgot Password is opened
	 */
	handleOpenForgotPassword = () => {
		//this.props.trackGaEvent('Masthead', 'Click', 'ResetPassword');
		this.clearErrors();
	};

	/**
	 * Show the account summary
	 */
	handleOpenAccountSummary = () => {
		//this.props.trackGaEvent('Account', 'Click', 'Account');
		this.setState({
			showAccountSummary: true,
		});
	};

	/**
	 * Close the account summary
	 */
	handleCloseAccountSummary = () => {
		this.setState({
			showAccountSummary: false,
		});
	};

	/**
	 * Navigates to the user page
	 */
	handleGoToAccount = () => {
		this.setState({
			showAccountSummary: false,
		});
		this.props.goToAccount();
	};

	/**
	 * Navigates to the account transactions page
	 */
	handleGoToAccountTransactions = (transactionPage = null) => {
		this.setState({
			showAccountSummary: false,
		});

		this.props.goToAccountTransactions(transactionPage);
	};

	/**
	 * Navigates to the account transactions bonus page
	 */
	handleGoToAccountTransactionsBonus = () => {
		this.handleGoToAccountTransactions('bonus');
	};
	/**
	 * Navigates to the account transactions bonus page
	 */
	handleGoToWithdraw = () => {
		this.setState({
			showAccountSummary: false,
		});
		this.props.goToWithdrawn();
	};

	/**
	 * Navigates to Racing home
	 */
	handleGoToHome = () => {
		if (this.props.hideMastheadFields) {
			this.props.onResetMasthead();
		}

		this.props.goToHome();
	};

	/**
	 * Navigate to account page
	 */
	handleGoToAccountTransactionsPage = () => {
		this.props.goToAccountTransactionsPage();
	};

	/**
	 * Closes the Summary View and logs the user out
	 */
	handleLogout = () => {
		this.setState({
			showAccountSummary: false,
		});
		sessionStorage.removeItem('browsertime');
		this.props.onLogout();
	};

	handleChickUseBonusBet = () => {
		if (this.state.isBonusChecked === true) {
			this.setState({
				isBonusChecked: false,
			});
		} else {
			this.setState({
				isBonusChecked: true,
			});
		}
		this.props.isUseBonusBet(this.state.isBonusChecked);
	};

	/**
	 * Clears all errors
	 */
	clearErrors = () => {
		this.setState({
			error: null,
			resetPasswordError: null,
			resetPasswordSuccess: false,
		});
	};

	/**
	 * Set the Masthead height.
	 * Used to update the top margin in the main element and the position of the main navigation
	 *
	 * @param height
	 */
	setMastheadHeight = (height) => {
		if (height !== this.props.mastheadHeight && height) {
			this.props.onMastheadHeightChange(height);
		}
	};

	/**
	 * Handle the embedded iframe posting messages to us
	 *
	 * @param event
	 */
	handleFrameMessage = (event) => {
		if (event.data) {
			if (event.data.name === 'tb_height' && this.props.iframeHeight !== event.data.height) {
				// Set the iframe height
				this.props.setMastheadIframeHeight(event.data.height);
			} else if (event.data.name === 'tb_iframe' && event.data.src) {
				// Open an iframe in a modal
				const modalConfig = event.data.modalConfig || {};
				const props = {
					src: event.data.src,
					height: event.data.height,
					onMessageReceived: this.handleFrameMessage,
				};

				this.props.openIframeModal(props, modalConfig);
			} else if (event.data.name === 'tb_iframe_close') {
				// Close the last opened modal
				this.props.closeModal('Iframe');
			} else if (event.data.name === 'tb_link' && event.data.link) {
				// Open the link sent from the iframe in a new tab
				window.open(event.data.link, '_self');
			}
		}
	};

	renderMasthead = (size) => {
		const {
			mastheadType,
			account_balance,
			count_bets,
			userBetsCount,
			first_name,
			last_name,
			goToRegistration,
			hideMastheadFields,
			userId,
			isUserLoggedIn,
			name,
			onJoinLogin,
			onMenuClick,
			openQuickBettingSummary,
			username,
			brandLogos,
			isFullWidth,
			street,
			dob,
			green_id_status,
			green_id_trans_id,
			isMultiBetSlipOpen,
			cashout_limit,
			bailOutLimit,
			pricePumpLimit,
			handleGoToAccountTransactionsPage,
			mobile,
			free_credit_balance,
			bonus_bet_enabled,
		} = this.props;

		const {
			affiliate_name,
			affiliate_text,
			affiliate_user,
			error,
			loadingLogin,
			loadingResetPassword,
			resetPasswordError,
			resetPasswordSuccess,
			showAccountSummary,
		} = this.state;

		switch (mastheadType) {
			case MASTHEAD_DEFAULT:
				return (
					<Masthead
						isFullWidth={isFullWidth}
						ate
						onMenuClick={onMenuClick}
						goToHome={this.handleGoToHome}
						handleBetVisibility={this.props.handleBetVisibility}
						goToAccountTransactionsPage={this.handleGoToAccountTransactionsPage}
						onLogin={this.handleLogin}
						goToRegistration={goToRegistration}
						onJoinLogin={onJoinLogin}
						onBetSlipClick={this.handleBetSlipClick}
						onBetsClick={openQuickBettingSummary}
						onAddFunds={this.handleOpenDeposits}
						resetPassword={this.handleResetPassword}
						openForgotPassword={this.handleOpenForgotPassword}
						toggleSummaryView={this.handleOpenAccountSummary}
						clearErrors={this.clearErrors}
						isUserLoggedIn={isUserLoggedIn}
						name={name}
						first_name={first_name}
						account_balance={account_balance}
						count_bets={count_bets}
						userBetsCount={userBetsCount}
						error={error}
						id={userId}
						resetPasswordError={resetPasswordError}
						resetPasswordSuccess={resetPasswordSuccess}
						summaryOpen={showAccountSummary}
						loadingLogin={loadingLogin}
						loadingResetPassword={loadingResetPassword}
						hideMastheadFields={hideMastheadFields}
						brandLogos={brandLogos}
						username={username}
						size={size}
						isMultiBetSlipOpen={isMultiBetSlipOpen}
						street={street}
						cashout_limit={cashout_limit}
						bailOutLimit={bailOutLimit}
						pricePumpLimit={pricePumpLimit}
						isMobile={mobile}
						toggleShowBalance={this.toggleShowBalance}
						showBalance={this.state.showBalance}
						free_credit_balance={free_credit_balance}
						bonus_bet_enabled={bonus_bet_enabled}
						depositandWithdrawalEnabled={this.props.depositandWithdrawalEnabled}
					/>
				);

			case MASTHEAD_AFFILIATE:
				return (
					<AffiliateMasthead
						isFullWidth={isFullWidth}
						account_balance={account_balance}
						affiliate_name={affiliate_name}
						affiliate_text={affiliate_text}
						affiliate_user={affiliate_user ? affiliate_user : username}
						bets={count_bets}
						onBetSlipClick={this.handleBetSlipClick}
						size={size}
					/>
				);

			case MASTHEAD_IFRAME:
				return <IframeMasthead bets={count_bets} onBetSlipClick={this.handleBetSlipClick} />;
		}
	};

	toggleShowBalance = () => {
		this.setState({ showBalance: !this.state.showBalance });
	};

	render() {
		const {
			mastheadType,
			account_balance,
			first_name,
			last_name,
			free_credit_balance,
			userId,
			isCordova,
			isUserLoggedIn,
			mergeBet,
			mergeUser,
			name,
			onLockScreenClick,
			openQuickBettingSummary,
			pending_bets,
			potential_winnings,
			showMasthead,
			showAclButton,
			showThemeButton,
			// scrollbarWidth,
			total_stake,
			username,
			isUserVerified,
			onOpenAclManager,
			onOpenThemePicker,
			iframe,
			iframeHeight,
			isFullWidth,
			displaySessionTimer,
			meta,
			street,
			dob,
			green_id_status,
			green_id_trans_id,
			userEmail,
			send_email,
			mobile,
			cashout_limit,
			bailOutLimit,
			pricePumpLimit,
			goToWithdrawal,
		} = this.props;

		const { showAccountSummary, start_time, isBonusChecked } = this.state;

		const paddingTop = iframe && iframe.overlayContent ? iframe.height : null;

		return (
			<SizeMe monitorHeight>
				{({ size }) => (
					<StyledMastheadContainer isHidden={!showMasthead} paddingTop={paddingTop}>
						<Helmet>
							{meta &&
								meta.map((item) => {
									const { tag, ...rest } = item;

									if (tag === 'title') {
										return (
											<title key="title" {...rest}>
												{item.content}
											</title>
										);
									}

									const Tag = tag ? tag : 'meta';

									return <Tag key={item.id} {...rest} />;
								})}
						</Helmet>

						{this.setMastheadHeight(size.height, size.width)}

						{userId && (
							<React.Fragment>
								<PusherSubscribe channel={`private-user_${userId}`} event="update" key="user" onUpdate={mergeUser} />
							</React.Fragment>
						)}

						{iframe && (
							<StyledMastheadContainer__IframeWrapper overlayIframe={iframe.overlayContent}>
								<Iframe
									src={iframe.src}
									height={iframeHeight ? iframeHeight : iframe.height}
									onMessageReceived={this.handleFrameMessage}
								/>
							</StyledMastheadContainer__IframeWrapper>
						)}

						{displaySessionTimer && mastheadType !== MASTHEAD_IFRAME && (
							<TimerHelpSection
								start_time={start_time}
								size={size}
								onOpenThemePicker={showThemeButton ? onOpenThemePicker : null}
								onOpenAclPicker={showAclButton ? onOpenAclManager : null}
								isFullWidth={isFullWidth}
							/>
						)}

						{this.renderMasthead(size)}

						{isUserLoggedIn && (
							<AccountDetailsPopUp
								size={size}
								first_name={first_name}
								account_balance={account_balance}
								open={showAccountSummary}
								onOutsideClick={this.handleCloseAccountSummary}
								isFullWidth={isFullWidth}
								isMobile={size.width < 768}
								toggleShowBalance={this.toggleShowBalance}
								showBalance={this.state.showBalance}
								cashout_limit={cashout_limit}
								bailOutLimit={bailOutLimit}
								pricePumpLimit={pricePumpLimit}
								goToAccount={this.handleGoToAccount}
								free_credit_balance={this.props.free_credit_balance / 100}
								goToWithdrawal={goToWithdrawal}
								goToAccountTransactions={this.handleGoToAccountTransactions}
								onAddFunds={this.handleOpenDeposits}
								onLogout={this.handleLogout}
								withdrawable_balance={this.props.withdrawable_balance / 100}
								bonus_bet_enabled={this.props.bonus_bet_enabled}
								price_bump_enabled={this.props.price_bump_enabled}
								depositsAndWithdrawalsEnabled={this.props.depositsAndWithdrawalsEnabled}
							/>
						)}
					</StyledMastheadContainer>
				)}
			</SizeMe>
		);
	}
}

const mapStateToProps = (state) => {
	const authenticatedUser = state.application.authenticatedUser;
	const masthead = state.featureToggles.features.masthead;
	// const masthead = true
	const showMasthead = masthead.enabled;
	const mastheadType = masthead.value;
	const hideMastheadFields = state.application.hideMastheadFields;

	const qsParams = URI().query(true);

	let account_balance,
		first_name,
		userId,
		name,
		pending_bets,
		potential_winnings,
		total_stake,
		username,
		last_name,
		isUserVerified,
		street,
		dob,
		green_id_status,
		send_email,
		green_id_trans_id,
		userEmail,
		cashout_limit,
		mobile,
		bailOutLimit,
		pricePumpLimit,
		withdrawable_balance,
		free_credit_balance;
	if (authenticatedUser) {
		account_balance = state.entities.users[authenticatedUser].account_balance;
		first_name = state.entities.users[authenticatedUser].first_name;
		last_name = state.entities.users[authenticatedUser].last_name;
		free_credit_balance = state.entities.users[authenticatedUser].free_credit_balance;
		userId = state.entities.users[authenticatedUser].id;
		name = state.entities.users[authenticatedUser].name;
		pending_bets = state.entities.users[authenticatedUser].pending_bets;
		potential_winnings = state.entities.users[authenticatedUser].potential_winnings;
		total_stake = state.entities.users[authenticatedUser].total_stake;
		username = state.entities.users[authenticatedUser].username;
		isUserVerified = state.entities.users[authenticatedUser].verified;
		street = state.entities.users[authenticatedUser].street;
		dob = state.entities.users[authenticatedUser].dob;
		green_id_status = state.entities.users[authenticatedUser].green_id_status;
		send_email = state.entities.users[authenticatedUser].send_email;
		green_id_trans_id = state.entities.users[authenticatedUser].green_id_trans_id;
		userEmail = state.entities.users[authenticatedUser].email;
		mobile = state.entities.users[authenticatedUser].msisdn;
		cashout_limit = state.entities.users[authenticatedUser].cashout_limit;
		bailOutLimit = state.entities.users[authenticatedUser].weekly_bet_limit;
		pricePumpLimit = state.entities.users[authenticatedUser].racing_boost_available;
		withdrawable_balance = state.entities.users[authenticatedUser].withdrawable_balance;
	}

	return {
		showMasthead,
		mastheadType,
		first_name,
		last_name,
		cashout_limit,
		bailOutLimit,
		pricePumpLimit,
		name,
		street,
		dob,
		green_id_status,
		send_email,
		green_id_trans_id,
		account_balance,
		userId,
		pending_bets,
		potential_winnings,
		total_stake,
		username,
		isUserVerified,
		hideMastheadFields,
		withdrawable_balance,
		free_credit_balance,
		brandLogos: state.acl.brandLogos || DEFAULT_BRAND_LOGOS,
		isUserLoggedIn: Boolean(authenticatedUser),
		count_bets:
			state[PLACE_MULTI_BET].selections.length +
			state[PLACE_MULTI_BET].SRMs.length +
			state[PLACE_MULTI_BET].quaddies.length +
			state[PLACE_MULTI_BET].exotics.length,
		userBetsCount: getPendingBets(state).length,
		isMultiBetSlipOpen: state.application.showMultiBetSlip,
		isCordova: state.application.isCordova,
		showThemeButton: Boolean(qsParams.show_theme_manager),
		showAclButton: Boolean(qsParams.show_acl_manager),
		iframe: state.acl.mastheadIframe,
		iframeHeight: state.application.mastheadIframeHeight,
		isFullWidth: state.featureToggles.features.fullWidth.enabled,
		displaySessionTimer: isFeatureEnabled(state, 'sessionTimer'),
		mastheadHeight: state.application.mastheadHeight,
		meta: getDenormalizedMeta(state),
		bonus_bet_enabled: state.featureToggles.features.bonusBets.enabled,
		price_bump_enabled: state.featureToggles.features.oddBoost.enabled,
		userEmail,
		mobile,
		depositsAndWithdrawalsEnabled: state.featureToggles.features.depositsAndWithdrawals.enabled,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		goToAccount: () => {
			//dispatch(trackGaEvent('AccountMenu', 'Click', 'My Account'));
			return dispatch(routeTo(USER_ACCOUNT_V2_BASE_URL));
		},
		goToAccountTransactions: (transactionPage = null) => {
			//dispatch(trackGaEvent('AccountMenu', 'Click', 'Account Transactions'));

			if (transactionPage === 'bonus') {
				return dispatch(
					routeTo(`/${USER_ACCOUNT_V2_BASE_URL}/${USER_ACCOUNT_TRANSACTIONS_URL}/${USER_ACCOUNT_ALL_TRANSACTIONS_URL}`),
				);
			}

			return dispatch(
				routeTo(`/${USER_ACCOUNT_V2_BASE_URL}/${USER_ACCOUNT_TRANSACTIONS_URL}/${USER_ACCOUNT_ALL_TRANSACTIONS_URL}`),
			);
		},

		goToHome: () => {
			return dispatch(routeTo('/welcome')); // change by @HW 21NOV2019
		},
		goToAccountTransactionsPage: () => {
			return dispatch(routeTo('/user/account-transactions/all')); // change by @HW 21NOV2019
		},
		goToWithdrawal: () => {
			//dispatch(trackGaEvent('AccountMenu', 'Click', 'Withdraw'));
			return dispatch(routeTo(`${USER_ACCOUNT_V2_BASE_URL}/withdraw`));
		},
		// redirects to the Registration page
		goToRegistration: () => {
			//dispatch(trackGaEvent('Masthead', 'Click', 'Join'));
			return dispatch(navigateToRegistration());
		},
		onLogin: (username, password) => {
			return dispatch(login(username, password));
		},
		// opens Deposits modal
		openDeposits: () => {
			return dispatch(showDeposits());
		},
		// opens Deposits modal
		onAddFunds: () => {
			return dispatch(showDeposits());
		},
		// opens Login modal
		onJoinLogin: () => {
			return dispatch(openLoginForm());
		},
		onLockScreenClick: () => {
			//dispatch(trackGaEvent('AccountMenu', 'Click', 'Lock Screen'));
			return dispatch(lockScreen());
		},
		onLogout: () => {
			//dispatch(trackGaEvent('AccountMenu', 'Click', 'Logout'));
			// dispatch(logoutUser());
			dispatch(logoutUser()).then(() => {
				return dispatch(routeTo('/welcome'), { trigger: true });
			});
		},
		goToWithdrawn: () => {
			return dispatch(routeTo(`${USER_ACCOUNT_V2_BASE_URL}/${USER_ACCOUNT_WITHDRAW_URL}`)); // change by @HW 21NOV2019
		},
		onResetPassword: (email) => {
			//dispatch(trackGaEvent('Forgot Password', 'Submit', 'Send Password'));
			return dispatch(resetPassword(email));
		},
		trackGaEvent: (category, action, label) => {
			return dispatch(trackGaEvent(category, action, label));
		},
		getAffiliateParams: () => {
			return dispatch(getAffiliateParams());
		},
		mergeBet: (bet) => {
			return dispatch(normalizeAndMergeBet(bet));
		},
		mergeUser: (user) => {
			return dispatch(normalizeAndMergeUser(user));
		},
		openQuickBettingSummary: () => {
			//dispatch(trackGaEvent('Masthead', 'Click', 'Pending Bets'));
			return dispatch(openQuickBettingSummary());
		},
		onResetMasthead: () => {
			return dispatch(hideMastheadFields(false));
		},
		onBetSlipClick: (isMultiBetSlipOpen) => {
			if (isMultiBetSlipOpen) {
				return dispatch(closeMultiBetSlip());
			} else {
				return dispatch(openMultiBetSlip());
			}
		},
		onMenuClick: () => {
			return dispatch(showSidebar());
		},
		onOpenThemePicker: () => {
			return dispatch(openThemePicker());
		},
		onOpenAclManager: () => {
			return dispatch(openAclManager());
		},
		setMastheadIframeHeight: (height) => {
			return dispatch(setMastheadIframeHeight(height));
		},
		onMastheadHeightChange: (height) => {
			return dispatch(setMastheadHeight(height));
		},
		openIframeModal: (props, modalConfig) => dispatch(openIframe(props, modalConfig)),
		closeModal: (modalKey) => dispatch(closeModal(modalKey)),
		isUseBonusBet: (isChecked) => dispatch(isUseBonusBet(isChecked)),
	};
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(MastheadContainerGRS));
