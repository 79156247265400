// MAIN COLORS
export const COLORS = {
	white: '#FFFFFF',
	gray: {
		50: '#f9fafb',
		100: '#f3f4f6',
		200: '#e5e7eb',
		300: '#d1d5db',
		400: '#9ca3af',
		500: '#6b7280',
		600: '#4b5563',
		700: '#374151',
		800: '#1f2937',
		900: '#111827',
		950: '#030712',
	},
	red: {
		300: '#BB181B',
		400: '#A5161A',
		600: '#660809'
	},
	status: {
		info: '#219EBC',
		warning: '#FB8500',
		danger: '#BB181B',
		success: '#00A216'
	}
};
export const NEXT_TO_JUMP_DEFAULT_COLORS = {
	background_color: '#006EB0',
	racing_icon_color:'white',
	font_color: '#ffffff',
	filter_background_color:'#2e2e2e'
};
export const FOOTER_DEFAULT_COLORS = {
	background_color: '#161A1D',
	font_color: COLORS.white,
	divider_color: COLORS.red[300],
	hover_text_color: COLORS.red[300]
};
export const MOBILE_NAVIGATION_FOOTER_COLORS = {
	background_color: '#850000',
	font_color: COLORS.white,
	racing_icon_color:'white'
};
export const SIDEBAR_MENU_COLORS = {
	background_color: '#006EB0',
	background_color_step0: '#004C8B',
	font_color: 'black',
	hover_text_color: '#070B19', 
	default_background_color:'#004C8B'	
};
//remining for here
export const MASTHEAD_COLORS = {
	background_color: '#212429', 
	racing_icon_color:'white',
	font_color:COLORS.white,
	hover_font_color: COLORS.red[300],
	active_bets_bg_color:'rgb(102, 8, 9)',
	active_bets_font_color:'rgb(255, 255, 255)',
	active_bets_hover_bg_color:'rgb(187, 24, 27)',
	login_button_bg_color :  'rgb(102, 102, 102)',
	login_button_font_color:'rgb(221, 221, 221)',
	login_hover_button_bg_color : 'rgb(46, 46, 46)',
	login_hover_button_font_color:'rgb(221, 221, 221)',
	forgotpass_button_hover_bg_color : '#7b0000',
	forgotpass_border_color : '#7b0000',
	cact_button_bg_color:'rgb(46, 46, 46)',
	cact_button_font_color:'rgb(255, 255, 255)',
	cact_button_hover_bg_color:'rgb(133, 0, 0)',
	cact_button_hover_font_color:'rgb(255, 255, 255)',
	balance_bonus_disply_bg_color:'#666666',
	profile_icon_color:'white',
	logout_button_color:'#c72128',
	logout_font_color:'#ffffff',
	hover_logout_button_color:'rgb(102, 102, 102)',
	nav_text_hover_color:'#c72128'

};
export const USER_ACCOUNT_COLOR = {
	menu_item_bg_color:'#850000',
	menu_item_font_color:'#ffffff',
	menu_item_hover_font_color:'#006CE8',
	menu_item_border_color:'#850000',
	//USER Setting : Marketing Preferences
	checkbox_active_color:'#850000',
	deactive_button_color:'#660809',
	deactive_font_color:'#fff',
	login_button_bg_color:'rgb(199, 33, 40)',
	login_button_font_color:'rgb(255, 255, 255)',
	login_hover_button_font_color:'rgb(46, 46, 46)',
	login_hover_button_bg_color:'rgb(102, 102, 102)'  


};
export const FETURED_RACES_COLOR = {
	racenumber_box_bg_color: COLORS.red[600],
	racenumber_font_color: COLORS.white,
	racename_font_color:COLORS.gray[500],
	meeting_font_color: 'rgb(0, 58, 145)',
	racing_icon_color: 'black'
};
export const TODAY_RACES_COLOR = {
	main_header_bg_color:'#070B19',
	main_row_hover_bg_color:'#f9f9f99c',
	main_header_bottom_color:'#dddddd',
	sec_row_bg_color:'white',
	filter_racing_icon_color: 'black',
	racing_icon_color: 'white', 
	leg_filter_bg_color:'#e5e4e2',
	button_bg_color:'#850000',
	button_font_color:'#ffffff',
	hover_button_bg_color:'#850000',
	hover_button_font_color:'#fff',
	inactive_button_bg_color:'white',
	inactive_button_font_color:'black',
	currency_active_bg_color:'#2E2E2E',
	currency_active_font_color:'#fff',
	currency_active_border_color:'#2E2E2E',
	currency_inactive_bg_color:'#fff',
	currency_inactive_hover_bg_color:'#666666',
	currency_inactive_hover_font_color:'#000',	
	currency_inactive_font_color:'#000',	
	currency_inactive_border_color:'#dddddd'
};
export const RSTIPS_COLOR = {
	hover_button_bg_color:'#660809',
	hover_button_font_color:'#ffffff',
	button_bg_color:'#660809',
	button_font_color:'#ffffff',
	filter_bg_color:'#eda0a0',
	filter_hover_color:'#eda0a0',
	runner_avtar_bg_color:'#f7aaa2',
	runner_avtar_border_color:'#eb9c9c',
	spinner_loding_color:'#f16565',
	racing_icon_color:'black',
	tips_odds_btn_bg_color: '#660809',
	tips_odds_btn_font_color: '#ffffff'
}
export const PROMOTION_COLOR = {
	hover_pro_button_bg_color:'#111111',
	hover_pro_button_font_color:'#ffffff',
	pro_button_bg_color:'#660809',
	pro_button_font_color:'#d7d7d7'	
}
export const RACE_CARD_COLOR = {
	tab_active_font_color:'#c72128 ',
	tab_active_border_color:'#c72128',
	arffilter_bg_Color :'#850000',//runner form filter flucs ,Hot stats filter
    arffilter_font_Color :'#fff',
	arffilter_hover_bg_Color:'#850000',
	arffilter_hover_font_Color:'white',
	button_bg_color:COLORS.red[600],
	button_font_color:'#fff',
	button_hover_bg_color:'#850000',
	button_hover_font_colr:'#fff',
	fav_icon_bg_color:'#ffc107',
	fav_icon_font_color:'#000000',
	samerace_multi_active_bg_color:'#850000',
	samerace_multi_active_font_color:'white',
	samerace_multi_active_hoverbg_color:'#b30000',
	racenumber_box_bg_color: '#850000',
	racenumber_font_color: 'white',
	racing_icon_color:'white'	
};
export const BETSLIP_COLOR = {
	button_bg_color:'#A5161A',
	button_font_color:'#000',
	button_hover_bg_color:'#A5161A',
	button_hover_font_color:'#FFFFFF',
	header_bet_slip_color:'#fff',
	header_bet_slip_bg_color:'#660809'	
};
export const BETRECEIPT_COLOR = {
	bet_export_header_bg:'rgb(0, 110, 176)',
	bet_export_oval_header:'rgb(0, 110, 176)',
	bet_export_sepa_header:'rgb(0, 110, 176)',
	bet_export_oval_bg:'rgb(0, 110, 176)',
	bet_export_status_bg:'rgb(22, 26, 29)',
	bet_export_status_font:'white',
	boost_text_color:COLORS.red[300]
	
};
export const BET_SUMMARY_COLOR = {
	active_tab_font_color:'#c72128',
	inactive_font_color:''	
};
// DEFAULT COLORS
export const DEFAULT_COLORS = {
	df_primary_color: '#006EB0',
	df_primary_hover_color: '#387BA5',
	df_primary_active_color: '#004C8B', 
	df_secondary_color: '#660809',
	df_secondary_hover_color: '#BB181B',
	df_secondary_active_color: '#A5161A',
	df_tertiary_color: '#161A1D',
	df_tertiary_hover_color: '#2A2E35',
	df_tertiary_active_color: '#212429',
	df_secondary_text_color:'#FFFFFF',
	df_primary_text_color:'#FFFFFF'
};

// DEFAULT STATUS COLORS
export const STATUS_COLORS = {
	selling_bg_color: '#ffffff',
	selling_text_color: '#000',
	paying_bg_color: '#32BE60',
	paying_text_color: '#000',
	abandoned_bg_color: '#C0C0C0',
	abandoned_text_color: '#000',
	paid_bg_color: '#5D97FB',
	paid_text_color: '#000',
	closed_bg_color: '#C72128',
	closed_text_color: '#000',
	interim_bg_color: '#32BE60',
	interim_text_color: '#000',
	deleted_bg_color: '#C0C0C0',
	deleted_text_color: '#000',
	at_the_post_bg_color: '#ECBE00',
	at_the_post_text_color: '#000',
	going_behind_bg_color: '#ECBE00',
	going_behind_text_color: '#000',
	false_start_bg_color: '#C72128',
	false_start_text_color: '#000',
	finished_bg_color: '#C72128',
	finished_text_color: '#000',
	protest_bg_color: '#32BE60',
	protest_text_color: '#FFFFFF',
	protest_upheld_bg_color: '#32BE60',
	protest_upheld_text_color: '#FFFFFF',	
	protest_dismissed_bg_color: '#32BE60',
	protest_dismissed_text_color: '#FFFFFF',	
	parading_bg_color: '#f27207',
	parading_text_color: '#000',
	going_down_bg_color: '#fa971c',
	going_down_text_color: '#000',
	short_delay_bg_color: '#f77f00',
	short_delay_text_color: '#000',
	long_delay_bg_color: '#f98f13',
	long_delay_text_color: '#000',
	default_status_bg_color:'#ffffff',
	default_status_text_color:'#000',
	default_status_hover_bg_color:'#a9b0be'
};

// FONT WEIGHTS
export const WEIGHTS = {
	normal: 400,
	medium: 500,
	bold: 600,
	exBold: 700,
};

// TYPE_SCALE - FONT-SIZES
export const TYPE_SCALE = {
	header1: '2.5rem',
	header2: '2rem',
	header3: '1.5rem',
	header4: '1.25rem',
	header5: '1.1rem',
	header6: '1rem',
	paragraph: '0.75rem',
	helpText: '0.625rem',
	copyrightText: '0.5rem',
};

// APP MAIN FONT FAMILY
export const FAMILYS = {
};

// SCREEN SIZES
export const BREAKPOINTS = {
	tabletMin: 550,
	laptopMin: 1100,
	desktopMin: 1500,
};

// MEDIA QUERIES
export const MEDIA_QUERIES = {
	tabletAndUp: `(min-width: ${BREAKPOINTS.tabletMin / 16}rem)`,
	laptopAndUp: `(min-width: ${BREAKPOINTS.laptopMin / 16}rem)`,
	desktopAndUp: `(min-width: ${BREAKPOINTS.desktopMin / 16}rem)`,
	tabletOnly: `
	  (min-width: ${BREAKPOINTS.tabletMin / 16}rem) and
	  (max-width: ${(BREAKPOINTS.laptopMin - 1) / 16}rem)`,
};