import { brand, Button, spacings, ui } from '@tbh/ui-kit';
import cx from 'classnames/bind';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'react-emotion';
import { FaCheck, FaHourglassHalf, FaMinus, FaMoneyBillAlt } from 'react-icons/fa';
import {
	QUADDIES_BET_TYPES,
	RACING_BET_PRODUCT_CODE_SP,
	RACING_BET_TYPE_QUINELLA,
	RACING_EXOTIC_BET_TYPES,
	SRMTypes,
} from '../../../../common/constants/Racing';
import { localDateTime } from '../../../../legacy/core/format';
import ModalConfirm from '../../../UI/ModalConfirm';
import CurrencyDisplay from '../../Application/Currency/CurrencyDisplay/CurrencyDisplay';
import TransactionsCurrency from '../../Application/Currency/CurrencyDisplay/TransactionsCurrency';
//import AccountTransactionsCell from './AccountTransactionsCell/AccountTransactionsCell';

import { Link, Table, Text } from '@tbh/ui-kit';
import { COLORS, DEFAULT_COLORS, MEDIA_QUERIES, TYPE_SCALE, WEIGHTS } from '../../../../constants/themes';
import { TOTE_NAMES_BY_PRODUCT, toteProducts } from '../../../../store/entities/constants/BetConstants';
import RacingIcon from '../../Racing/RacingIcon/RacingIcon';

const StyledAccountTransactions__Table = styled(Table)`
	label: AccountTransactions__Table;

	${(props) => css`
		margin-bottom: ${spacings(props).cozy}px;
		max-width: 100%;
	`};
`;

const StyledTransactionTableRowHeader = styled(Table.TR)(
	(props) => css`
		background-color: ${brand(props).tertiary_color
			? brand(props).tertiary_color
			: DEFAULT_COLORS.df_tertiary_color} !important;
	`,
);

const StyledAccountTransactions__TableHeader = styled(Table.TG)`
	label: AccountTransactions__TableHeader;

	${(props) => css`
		border-bottom: 2px solid ${ui(props).color_4};
	`};
`;

const StyledBetRowItem = styled(Table.TR)`
	border-bottom: 1px solid #dee2e6;
	flex-direction: column;

	&:nth-child(odd) {
		background-color: #dddddd;
	}

	@media ${MEDIA_QUERIES.tabletAndUp} {
		flex-direction: row;
	}
`;

const StyledBetDataItem = styled(Table.TD)`
	padding: 3px 6px;

	@media ${MEDIA_QUERIES.tabletAndUp} {
		padding: 6px 12px;
	}
`;

const TinyButton = styled('button')`
	background: transparent;
	border: 0;
	color: ${COLORS.gray[600]};
	font-weight: ${WEIGHTS.bold};
	font-size: ${TYPE_SCALE.paragraph};
	padding: 0;
	white-space: nowrap;
	&:hover {
		color: ${COLORS.gray[600]};
	}
`;

const BetVisibilityWrapper = styled(Link)`
	margin-top: 12px;
	margin-bottom: 12px;
`;

const BetVisibilityItem = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 6px;

	&:not(:last-of-type) {
		border-bottom: 1px solid #dee2e6;
	}
`;

const BetVisibilityItemMulti = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 6px;

	&:not(:last-of-type) {
		border-bottom: 1px solid #dee2e6;
		margin-bottom: 6px;
	}
`;

const BetVisibilityItemInfo = styled('div')`
	margin-right: auto;
`;

const Competition_Name = styled('p')`
	color: ${COLORS.gray[600]};
	font-weight: ${WEIGHTS.exBold};
	font-size: ${TYPE_SCALE.paragraph};
	margin-bottom: 0;
	display: flex;
	gap: 2px;
`;

const LegStatus = styled('span')`
	font-weight: ${WEIGHTS.medium};
	font-size: ${TYPE_SCALE.paragraph};
`;

const LegStatusIconWrapper = styled('div')`
	border-radius: 50%;
	color: ${COLORS.white};
	width: 15px;
	height: 15px;
	text-align: center;
	line-height: 1;
	margin-left: 6px;
	background-color: ${(props) => (props.status === 'winning' ? COLORS.status.success : COLORS.status.danger)};

	${'' /* svg icon center  */}
	&::before {
		content: '';
		display: block;
		margin-bottom: -1.5px;
	}
`;

const BetProductWrapper = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const BetProductInfo = styled('div')`
	width: 100%;
	margin-right: auto;
`;

const BetVisibilityItemAction = styled('div')`
	display: flex;
	align-items: center;
`;

const BetEventContentWrapper = styled(Link)`
	position: none;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-radius: 2px;
	border: 1px solid #dee2e6;
	padding: 3px;
	margin-bottom: 4px;
	margin-top: 4px;
	cursor: pointer;
`;

const BetEventContentIcon = styled('div')`
	width: 32px;
	height: 32px;
	text-align: center;
	margin: 1px 5px 1px 2px; /* top right bottom left */
	border-radius: 2px;
	background-color: ${COLORS.gray[100]};
`;

const BetEventContentInfo = styled('div')`
	text-align: left;
`;

const BetEventContentName = styled('p')`
	color: ${COLORS.gray[800]};
	font-weight: ${WEIGHTS.exBold};
	padding-bottom: 0;
	margin-bottom: 0;
	font-size: 11px;
	line-height: 1;

	@media ${MEDIA_QUERIES.tabletAndUp} {
		font-size: ${TYPE_SCALE.paragraph};
	}
`;

const BetEventContentDis = styled('span')`
	color: ${COLORS.gray[600]};
	font-weight: ${WEIGHTS.medium};
	padding-bottom: 0;
	margin-bottom: 0;
	font-size: 10px;
	display: block;

	@media ${MEDIA_QUERIES.tabletAndUp} {
		font-size: ${TYPE_SCALE.paragraph};
	}
`;

const TableDataText = styled(Text)`
	font-size: 11px;

	@media ${MEDIA_QUERIES.tabletAndUp} {
		font-size: 12px;
	}
`;

const TransactionAmountWrapper = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const StyledDateAndTimeInline = styled(StyledBetDataItem)`
	display: flex;
	justify-content: flex-start;
`;

const StyledTableDateActionHeader = styled(Table.TD)`
	display: flex;
	justify-content: flex-end;
`;

const StyledTableDateActionData = styled(StyledBetDataItem)`
	display: block;

	@media ${MEDIA_QUERIES.tabletAndUp} {
		display: flex;
		justify-content: flex-end;
	}
`;

const StyledTableDataSmallText = styled(Table.TD)`
	font-size: 11px;
	font-weight: 500;
`;

const ProbettaLabel = styled('span')`
	display: inline-block;
	font-weight: 700;
	color: #000000;
	background-color: #ffa7a9;
	padding: 4px 6px;
	border-radius: 3px;
	margin-right: 6px;
	text-transform: uppercase;
`;
const PendingLabel = styled('span')`
	display: inline-block;
	font-weight: 700;
	color: #000000;
	background-color: #fb8500;
	font-size: 9px;
	padding: 4px 6px;
	border-radius: 3px;
	margin-right: 6px;
	text-transform: uppercase;
`;

const CouponPreview = styled(Button)`
	border-radius: 2px;
	padding: 2px 4px;
	font-size: 11px;
	text-transform: capitalize;

	@media ${MEDIA_QUERIES.tabletAndUp} {
		padding: 4px 8px;
		font-size: 14px;
	}
`;

const AccountTransactions = (props) => {
	const {
		className,
		action,
		isSmallScreen,
		filteredData,
		data,
		headers,
		handleRebet,
		handleCacheOut,
		cancelWithdrawal,
		cashout_limit,
		bailOutIsEnabled,
		betVisibility,
		handleBetsListVisibility,
		NavigateAction,
		handleBetExport,
	} = props;
	const tablePaginatorClass = cx({
		[className]: className,
	});

	const handleLinkClick = (selection) => {
		NavigateAction(selection, 'bet');
	};
	const renderImage = (filtered) => {
		return (
			<BetEventContentIcon>
				{filtered.bet && filtered.bet.bet_selections[0].event_type && (
					<RacingIcon color="black" type={filtered.bet.bet_selections[0].event_type} />
				)}
			</BetEventContentIcon>
		);
	};

	const renderBetTypeByHeading = (filtered) => {
		if (filtered.bet && filtered.bet.bet_type) {
			if (filtered.bet.bet_type == 'win' || filtered.bet.bet_type == 'place') {
				return (
					<TableDataText
						strong
						className={css`
							text-transform: uppercase;
						`}
					>
						<BetEventContentWrapper action={() => handleLinkClick(filtered.bet && filtered.bet.bet_selections[0])}>
							{renderImage(filtered)}
							<BetEventContentInfo>
								<BetEventContentName>
									R{filtered.bet.bet_selections[0].race_number && filtered.bet.bet_selections[0].race_number}{' '}
									{filtered.bet.bet_selections[0].competition_name && filtered.bet.bet_selections[0].competition_name}
									{filtered.bet &&
										filtered.bet.bet_selections[0].country_code &&
										filtered.bet &&
										`(${filtered.bet.bet_selections[0].country_code})`}
								</BetEventContentName>
								<BetEventContentDis>{filtered.bet && filtered.bet.bet_selections[0].event_name}</BetEventContentDis>
							</BetEventContentInfo>
						</BetEventContentWrapper>
						<div>
							#{filtered.bet.bet_selections[0].selection_number} {filtered.bet.bet_selections[0].selection_name}
						</div>
						<div>
							{filtered.bet && filtered.bet.bet_type}{' '}
							{filtered.bet && filtered.bet.product_code === 'GRSF' && '(Fixed)'}
							{filtered.bet &&
								toteProducts.includes(filtered.bet.product_code) &&
								`(${
									TOTE_NAMES_BY_PRODUCT[filtered.bet.product_code] &&
									TOTE_NAMES_BY_PRODUCT[filtered.bet.product_code][filtered.bet.bet_selections[0].event_type]
										? TOTE_NAMES_BY_PRODUCT[filtered.bet.product_code][filtered.bet.bet_selections[0].event_type]
										: filtered.bet.product_code
								})`}
							{filtered.bet && filtered.bet.product_code === 'GB' && (
								<span
									className={css`
										color: ${COLORS.red[300]};
									`}
								>
									({filtered.bet.product_type})
								</span>
							)}{' '}
							{filtered.bet && filtered.bet.amount && <CurrencyDisplay amount={filtered.bet.amount} />}{' '}
							{filtered.bet &&
							(toteProducts.includes(filtered.bet.product_code) ||
								filtered.bet.product_code === RACING_BET_PRODUCT_CODE_SP)
								? `@ ${(TOTE_NAMES_BY_PRODUCT[filtered.bet.product_code] &&
										TOTE_NAMES_BY_PRODUCT[filtered.bet.product_code][filtered.bet.bet_selections[0].event_type]) ||
										'TBD'}`
								: ` @ $${filtered.bet.odds.toFixed(2)} `}
						</div>
					</TableDataText>
				);
			} else if (filtered.bet.bet_type === 'multi') {
				return (
					<TableDataText
						strong
						className={css`
							text-transform: uppercase;
						`}
					>
						{filtered.bet && filtered.bet.bet_type} {filtered.bet && filtered.bet.product_code === 'GRSF' && '(Fixed)'}
						{filtered.bet ? ' | ' : '-'}
						{filtered.bet && filtered.bet.amount && <CurrencyDisplay amount={filtered.bet.amount} />}
						{filtered.bet &&
							filtered.bet.odds !== 0 &&
							!(
								toteProducts.includes(filtered.bet.product_code) ||
								filtered.bet.bet_selections.some((s) => toteProducts.includes(s.product))
							) &&
							` @ $${filtered.bet.odds.toFixed(2)}`}
						{filtered.bet &&
							(toteProducts.includes(filtered.bet.product_code) ||
								filtered.bet.bet_selections.some((s) => toteProducts.includes(s.product))) &&
							'@ TBD'}
					</TableDataText>
				);
			} else if (filtered.bet.bet_type == 'eachway') {
				return (
					<TableDataText
						strong
						className={css`
							text-transform: uppercase;
						`}
					>
						<div>
							{filtered.bet && filtered.bet.bet_type}{' '}
							{filtered.bet && filtered.bet.product_code === 'GRSF' && '(Fixed)'}
							{filtered.bet &&
								toteProducts.includes(filtered.bet.product_code) &&
								`(${
									TOTE_NAMES_BY_PRODUCT[filtered.bet.product_code] &&
									TOTE_NAMES_BY_PRODUCT[filtered.bet.product_code][filtered.bet.bet_selections[0].event_type]
										? TOTE_NAMES_BY_PRODUCT[filtered.bet.product_code][filtered.bet.bet_selections[0].event_type]
										: filtered.bet.product_code
								})`}
							{filtered.bet && filtered.bet.product_code === 'GB' && (
								<span
									className={css`
										color: ${COLORS.red[300]};
									`}
								>
									({filtered.bet.product_type})
								</span>
							)}
							{filtered.bet && filtered.bet.amount && <CurrencyDisplay amount={filtered.bet.amount} />}{' '}
							{filtered.bet &&
								filtered.bet.bet_selections &&
								filtered.bet.bet_selections.length == 2 &&
								`@ $${filtered.bet.bet_selections[0].odds.toFixed(2)}/ $${filtered.bet.bet_selections[1].odds.toFixed(
									2,
								)}`}
						</div>
					</TableDataText>
				);
			} else if (RACING_EXOTIC_BET_TYPES.includes(filtered.bet.bet_type)) {
				let reversed_selection_string = filtered.bet.selection_string
					.split(' / ')
					.reverse()
					.join(' / ');
				return (
					<TableDataText
						strong
						className={css`
							text-transform: uppercase;
							cursor: ${filtered.bet.bet_type === RACING_BET_TYPE_QUINELLA ? 'pointer' : 'default'};
						`}
						action={() => filtered.bet.bet_type === RACING_BET_TYPE_QUINELLA && handleLinkClick(filtered.bet && filtered.bet.bet_selections[0])}
					>
						{`R${filtered.bet.bet_selections[0].race_number && filtered.bet.bet_selections[0].race_number} `}
						{filtered.bet.bet_selections[0].competition_name && filtered.bet.bet_selections[0].competition_name}
						{`(${filtered.bet.bet_selections[0].country_code && filtered.bet.bet_selections[0].country_code}) | `}
						{filtered.bet && filtered.bet.bet_type} {filtered.bet && filtered.bet.product_code === 'GRSF' && '(Fixed)'}
						{/* {filtered.bet && toteProducts.includes(filtered.bet.product_code) && '(Tote)'} */}
						{filtered.bet && filtered.bet.selection_string && `(${filtered.bet.selection_string})`}
						{filtered.bet &&
							filtered.bet.bet_type == RACING_BET_TYPE_QUINELLA &&
							!filtered.bet.boxed_flag &&
							`(${reversed_selection_string})`}
						{filtered.bet ? ' | ' : '-'}
						{filtered.bet && filtered.bet.amount && <CurrencyDisplay amount={filtered.bet.amount} />}
						{filtered.bet && filtered.bet.percentage !== 0 && ` @ ${filtered.bet.percentage}%`}
					</TableDataText>
				);
			} else if (QUADDIES_BET_TYPES.includes(filtered.bet.bet_type)) {
				return (
					<TableDataText
						strong
						className={css`
							text-transform: uppercase;
						`}
					>
						{`(${filtered.bet.bet_selections[0].country_code && filtered.bet.bet_selections[0].country_code}) | `}
						{filtered.bet && filtered.bet.bet_type} {filtered.bet && filtered.bet.product_code === 'GRSF' && '(Fixed)'}
						{/* {filtered.bet && toteProducts.includes(filtered.bet.product_code) && '(Tote)'} */}
						{filtered.bet && filtered.bet.selection_string && `(${filtered.bet.selection_string})`}
						{filtered.bet ? ' | ' : '-'}
						{filtered.bet && filtered.bet.amount && <CurrencyDisplay amount={filtered.bet.amount} />}
						{filtered.bet && filtered.bet.percentage !== 0 && ` @ ${filtered.bet.percentage}%`}
					</TableDataText>
				);
			} else if (filtered.bet.bet_type == 'sameracemulti') {
				return (
					<TableDataText
						strong
						className={css`
							text-transform: uppercase;
						`}
					>
						{`R${filtered.bet.bet_selections[0].race_number && filtered.bet.bet_selections[0].race_number} `}
						{filtered.bet.bet_selections[0].competition_name && filtered.bet.bet_selections[0].competition_name}
						{`(${filtered.bet.bet_selections[0].country_code && filtered.bet.bet_selections[0].country_code}) | `}
						{filtered.bet && filtered.bet.bet_type} {filtered.bet && filtered.bet.product_code === 'GRSF' && '(Fixed)'}
						{/* {filtered.bet && toteProducts.includes(filtered.bet.product_code) && '(Tote)'} */}
						{filtered.bet ? ' | ' : '-'}
						{filtered.bet && filtered.bet.amount && <CurrencyDisplay amount={filtered.bet.amount} />}
						{filtered.bet && filtered.bet.odds !== 0 && ` @ $${filtered.bet.odds}`}
					</TableDataText>
				);
			} else {
				return (
					<TableDataText
						strong
						className={css`
							text-transform: uppercase;
						`}
					>
						{filtered.bet && filtered.bet.bet_type} {filtered.bet && filtered.bet.product_code === 'GRSF' && '(Fixed)'}
						{filtered.bet && filtered.bet.product_code === 'GB' && (
							<span
								className={css`
									color: ${COLORS.red[300]};
								`}
							>
								({filtered.bet.product_type})
							</span>
						)}
						{filtered.bet ? ' | ' : '-'}
						{filtered.bet && filtered.bet.amount && <CurrencyDisplay amount={filtered.bet.amount} />}
						{filtered.bet && filtered.bet.odds !== 0 && ` @ $${filtered.bet.odds}`}
					</TableDataText>
				);
			}
		}
	};

	//const notMoreInfo = ['Eway Deposit', 'Withdrawal Cancelled', 'Withdrawal', 'Chargeback', 'Testing'];
	const notMoreInfo = filteredData
		.map((item) => item.type)
		.filter(
			(item) =>
				item !== 'Bet Entry' &&
				item !== 'Bet Win' &&
				item !== 'Bet Refund' &&
				item !== 'Bet Partial Refund' &&
				item !== 'Free bet entry',
		);

	const group_by_race_number = (data) => {
		const grouped = data.reduce((acc, item) => {
			if (acc[item.race_number]) {
				acc[item.race_number].push(item);
				return acc;
			}

			acc[item.race_number] = [item];

			return acc;
		}, {});

		return Object.values(grouped);
	};

	// 3, 1, 2 / 3, 1, 2
	const group_by_selection_result = (selection_string, data) => {
		const parts = selection_string.split(' / ');
		const newData = [...data];
		const group = parts.map((part, index) => {
			const selections = newData.filter((selection) => selection.position == index + 1);

			return selections;
		});
		return group;
	};

	let renderTransaction = null;
	if (filteredData.length) {
		renderTransaction = filteredData.map((filtered) => (
			<StyledBetRowItem key={filtered.id}>
				{isSmallScreen ? (
					<StyledDateAndTimeInline>
						<TableDataText strong>{moment(filtered.date).format('MMMM Do YYYY')}</TableDataText>
						<TableDataText
							className={css`
								margin-left: 4px;
							`}
						>
							{localDateTime(filtered.date).format('h:mm:ss a')}
						</TableDataText>
					</StyledDateAndTimeInline>
				) : (
					<StyledBetDataItem>
						<TableDataText strong>{moment(filtered.date).format('MMMM Do YYYY')}</TableDataText>
						<StyledTableDataSmallText>{localDateTime(filtered.date).format('h:mm:ss a')}</StyledTableDataSmallText>
					</StyledBetDataItem>
				)}
				<StyledBetDataItem
					className={css`
						flex: 3;
					`}
				>
					<BetProductWrapper>
						<BetProductInfo>
							{notMoreInfo.includes(filtered.type) ? (
								<React.Fragment>
									<TableDataText
										strong
										className={css`
											text-transform: uppercase;
										`}
									>
										{filtered.type}
									</TableDataText>

									<StyledTableDataSmallText>
										<span>
											{filtered.expiry_date ? (
												<div>
													<span>(TR-ID: {filtered.id})</span>
													<div>
														<span>(Expiry: {filtered.expiry_date})</span>
													</div>
												</div>
											) : (
												<span>(TR-ID: {filtered.id})</span>
											)}
										</span>
									</StyledTableDataSmallText>

									<StyledTableDataSmallText>
										{filtered.status == 'pending' && (
											<LegStatus
												className={css`
													color: ${COLORS.status.warning};
												`}
											>
												Pending
											</LegStatus>
										)}
										{filtered.status === 'approved' && (
											<LegStatus
												className={css`
													color: ${COLORS.status.success};
												`}
											>
												Approved
											</LegStatus>
										)}
										{filtered.status === 'declined' && (
											<LegStatus
												className={css`
													color: ${COLORS.status.warning};
												`}
											>
												Decline
											</LegStatus>
										)}
										{filtered.status === 'cancelled' && (
											<LegStatus
												className={css`
													color: ${COLORS.status.danger};
												`}
											>
												Cancelled
											</LegStatus>
										)}
									</StyledTableDataSmallText>
								</React.Fragment>
							) : (
								<React.Fragment>
									{renderBetTypeByHeading(filtered)}
									<StyledTableDataSmallText>
										{filtered.bet &&
											filtered.bet.bet_origin &&
											filtered.bet.bet_origin.map((item) =>
												item.id === 6 ? <ProbettaLabel key={item.id}>{item.name}</ProbettaLabel> : null,
											)}
										<span>
											{filtered.type}{' '}
											{`(Bet ID: ${filtered.bet && filtered.bet.id && filtered.bet && filtered.bet.id} / TR-ID: ${
												filtered.id
											})`}
										</span>
									</StyledTableDataSmallText>
									<StyledTableDataSmallText>
										{filtered.bet && filtered.bet.status === 'unresulted' && (
											<LegStatus
												className={css`
													color: ${COLORS.status.info};
												`}
											>
												Accepted
											</LegStatus>
										)}
									</StyledTableDataSmallText>
									<StyledTableDataSmallText>
										{filtered.bet && filtered.bet.status === 'pending' && (
											<LegStatus
												className={css`
													color: ${COLORS.status.warning};
												`}
											>
												Pending Review
											</LegStatus>
										)}
									</StyledTableDataSmallText>
									<div style={{ padding: '4px 0px' }}>
										{bailOutIsEnabled &&
											cashout_limit !== 0 &&
											filtered.bet &&
											filtered.bet.free_credit_amount === null && (
												<Button
													className={css`
														border-radius: 2px;
														background: #485158;
														color: #ffffff;
														font-size: 12px !important;
														font-weight: 600;
														border: 0;
														border-bottom: 0;

														&:hover {
															border: 0;
															border-bottom: 0;
														}
													`}
													action={handleCacheOut}
													data={filtered.bet}
													type="primary"
												>
													Bail Out
												</Button>
											)}
									</div>
								</React.Fragment>
							)}
							{/* {filtered.bet &&
								filtered.bet.bet_type && (
									<CouponPreview
										className={css`
									border-radius: 4px;
								`}
										action={() => handleSelectedCupomBet({
											...filtered.bet,
										})}
										type="primary"
									>
										Cupom preview
									</Button>
								)} */}
						</BetProductInfo>
						{!notMoreInfo.includes(filtered.type) &&
							filtered.bet &&
							filtered.bet.bet_type &&
							filtered.bet.bet_type != 'win' &&
							filtered.bet.bet_type != 'place' &&
							!(filtered.bet && filtered.bet.bet_type == RACING_BET_TYPE_QUINELLA && !filtered.bet.boxed_flag) && (
								<TinyButton onClick={() => handleBetsListVisibility(filtered.id)}>
									{betVisibility[filtered.id] ? '- Less' : '+ More'}
								</TinyButton>
							)}

						{notMoreInfo.includes(filtered.type) && filtered.status == 'pending' && (
							<Button
								className={css`
									border-radius: 4px;
								`}
								action={() => handleCancelModalOpen(true, filtered.withdrawal_id)}
								data={filtered.withdrawal_id}
								type="primary"
							>
								Cancel
							</Button>
						)}
					</BetProductWrapper>

					{betVisibility[filtered.id] && (
						<React.Fragment>
							{filtered.bet && filtered.bet.bet_type === 'multi' ? (
								<BetVisibilityWrapper>
									{filtered.bet && filtered.bet.bet_type === 'multi'
										? filtered.bet.bet_selections
												.sort((a, b) => new Date(a.date) - new Date(b.date))
												.map((item) => (
													<React.Fragment key={item.id}>
														<BetEventContentWrapper action={() => handleLinkClick(item)}>
															<BetEventContentIcon>
																<RacingIcon color="black" type={item.event_type} />
															</BetEventContentIcon>
															<BetEventContentInfo>
																<BetEventContentName>
																	R{item.race_number} {item.competition_name}{' '}
																	{item.country_code && `(${item.country_code})`}
																</BetEventContentName>
																<BetEventContentDis>{item.event_name}</BetEventContentDis>
															</BetEventContentInfo>
														</BetEventContentWrapper>
														<BetVisibilityItemMulti>
															<BetVisibilityItemInfo>
																<Competition_Name>
																	#{item.selection_number} {item.selection_name} ({item.bet_type.toUpperCase()})
																</Competition_Name>
															</BetVisibilityItemInfo>
															<BetVisibilityItemAction>
																{item.leg_status === 'winning' && (
																	<LegStatusIconWrapper
																		className={css`
																			background-color: ${COLORS.status.success};
																		`}
																	>
																		<span>
																			<FaCheck
																				className={css`
																					font-size: 12px;
																				`}
																			/>
																		</span>
																	</LegStatusIconWrapper>
																)}
																{item.leg_status === 'losing' && (
																	<LegStatusIconWrapper
																		className={css`
																			background-color: ${COLORS.status.danger};
																		`}
																	>
																		<span>
																			<FaMinus
																				className={css`
																					font-size: 12px;
																				`}
																			/>
																		</span>
																	</LegStatusIconWrapper>
																)}
																{item.leg_status === 'pending' && (
																	<LegStatusIconWrapper
																		className={css`
																			background-color: ${COLORS.status.warning};
																		`}
																	>
																		<span>
																			<FaHourglassHalf
																				className={css`
																					font-size: 12px;
																				`}
																			/>
																		</span>
																	</LegStatusIconWrapper>
																)}
																{item.leg_status === 'refunded' ||
																	(item.leg_status === 'fully-refunded' && (
																		<LegStatusIconWrapper
																			className={css`
																				background-color: ${COLORS.gray[300]};
																			`}
																		>
																			<span>
																				<FaMoneyBillAlt
																					className={css`
																						font-size: 12px;
																					`}
																				/>
																			</span>
																		</LegStatusIconWrapper>
																	))}
															</BetVisibilityItemAction>
														</BetVisibilityItemMulti>
													</React.Fragment>
												))
										: filtered.bet &&
										  filtered.bet.bet_selections[0] &&
										  !QUADDIES_BET_TYPES.includes(filtered.bet.bet_type) && (
												<BetEventContentWrapper action={() => handleLinkClick(filtered.bet.bet_selections[0])}>
													{renderImage(filtered)}
													<BetEventContentInfo>
														<BetEventContentName>
															R{filtered.bet && filtered.bet.bet_selections[0].race_number}{' '}
															{filtered.bet && filtered.bet.bet_selections[0].competition_name}{' '}
															{/* {filtered.bet && filtered.bet.bet_selections[0].country_code  */}
															{filtered.bet &&
																filtered.bet.bet_selections[0].country_code &&
																filtered.bet &&
																`(${filtered.bet.bet_selections[0].country_code})`}
														</BetEventContentName>
														<BetEventContentDis>
															{filtered.bet && filtered.bet.bet_selections[0].event_name}
														</BetEventContentDis>
													</BetEventContentInfo>
												</BetEventContentWrapper>
										  )}

									{filtered.bet &&
										QUADDIES_BET_TYPES.includes(filtered.bet.bet_type) &&
										group_by_race_number(filtered.bet.bet_selections).map((item, index) => (
											<React.Fragment key={item[0].id}>
												<BetEventContentWrapper action={() => handleLinkClick(item[0])}>
													<BetEventContentIcon>
														{item[0].event_type && <RacingIcon color="black" type={item[0].event_type} />}
													</BetEventContentIcon>
													<BetEventContentInfo>
														<BetEventContentName>
															#{item[0].race_number} {item[0].competition_name}{' '}
															{item[0].country_code && `(${item[0].country_code})`}
														</BetEventContentName>
														<BetEventContentDis>{item[0].event_name}</BetEventContentDis>
													</BetEventContentInfo>
												</BetEventContentWrapper>
												{item.map((i) => (
													<BetVisibilityItemMulti key={i.id}>
														<BetVisibilityItemInfo>
															<Competition_Name>
																{item.selection_number} . {item.selection_name}
															</Competition_Name>
														</BetVisibilityItemInfo>
														<BetVisibilityItemAction>
															{i.leg_status === 'winning' && (
																<LegStatusIconWrapper
																	className={css`
																		background-color: ${COLORS.status.success};
																	`}
																>
																	<span>
																		<FaCheck
																			className={css`
																				font-size: 12px;
																			`}
																		/>
																	</span>
																</LegStatusIconWrapper>
															)}
															{i.leg_status === 'losing' && (
																<LegStatusIconWrapper
																	className={css`
																		background-color: ${COLORS.status.danger};
																	`}
																>
																	<span>
																		<FaMinus
																			className={css`
																				font-size: 12px;
																			`}
																		/>
																	</span>
																</LegStatusIconWrapper>
															)}
															{i.leg_status === 'pending' && (
																<LegStatusIconWrapper
																	className={css`
																		background-color: ${COLORS.status.warning};
																	`}
																>
																	<span>
																		<FaHourglassHalf
																			className={css`
																				font-size: 12px;
																			`}
																		/>
																	</span>
																</LegStatusIconWrapper>
															)}
															{i.leg_status === 'refunded' && (
																<LegStatusIconWrapper
																	className={css`
																		background-color: ${COLORS.gray[300]};
																	`}
																>
																	<span>
																		<FaMoneyBillAlt
																			className={css`
																				font-size: 12px;
																			`}
																		/>
																	</span>
																</LegStatusIconWrapper>
															)}
														</BetVisibilityItemAction>
													</BetVisibilityItemMulti>
												))}
											</React.Fragment>
										))}
									{filtered.bet &&
										filtered.bet.bet_type !== 'multi' &&
										!QUADDIES_BET_TYPES.includes(filtered.bet.bet_type) &&
										filtered.bet.bet_selections.map((item) => (
											<BetVisibilityItem key={item.id}>
												<BetVisibilityItemInfo>
													<Competition_Name>
														<span style={{ width: 32, display: 'block', whiteSpace: 'nowrap' }}>
															{filtered.bet.bet_type === 'sameracemulti'
																? `${SRMTypes[item.product]}`
																: `${item.selection_number}.`}
														</span>

														{filtered.bet.bet_type === 'sameracemulti' && ` -  ${item.selection_number}. `}
														{item.selection_name}
														{filtered.bet.bet_type === 'eachway' && ` (${item.bet_type.toUpperCase()})`}
													</Competition_Name>
												</BetVisibilityItemInfo>
												<BetVisibilityItemAction>
													{item.leg_status === 'winning' && (
														<LegStatusIconWrapper
															className={css`
																background-color: ${COLORS.status.success};
															`}
														>
															<span>
																<FaCheck
																	className={css`
																		font-size: 12px;
																	`}
																/>
															</span>
														</LegStatusIconWrapper>
													)}
													{item.leg_status === 'losing' && (
														<LegStatusIconWrapper
															className={css`
																background-color: ${COLORS.status.danger};
															`}
														>
															<span>
																<FaMinus
																	className={css`
																		font-size: 12px;
																	`}
																/>
															</span>
														</LegStatusIconWrapper>
													)}
													{item.leg_status === 'pending' && (
														<LegStatusIconWrapper
															className={css`
																background-color: ${COLORS.status.warning};
															`}
														>
															<span>
																<FaHourglassHalf
																	className={css`
																		font-size: 12px;
																	`}
																/>
															</span>
														</LegStatusIconWrapper>
													)}
													{item.leg_status === 'refunded' && (
														<LegStatusIconWrapper
															className={css`
																background-color: ${COLORS.gray[300]};
															`}
														>
															<span>
																<FaMoneyBillAlt
																	className={css`
																		font-size: 12px;
																	`}
																/>
															</span>
														</LegStatusIconWrapper>
													)}
												</BetVisibilityItemAction>
											</BetVisibilityItem>
										))}
								</BetVisibilityWrapper>
							) : (
								<BetVisibilityWrapper>
									{filtered.bet && filtered.bet.bet_type === 'multi'
										? filtered.bet.bet_selections.map((item) => (
												<React.Fragment key={item.id}>
													<BetEventContentWrapper
														action={() => handleLinkClick(filtered.bet && filtered.bet.bet_selections[0])}
													>
														<BetEventContentIcon>
															<RacingIcon color="black" type={item.event_type} />
														</BetEventContentIcon>
														<BetEventContentInfo>
															<BetEventContentName>
																#{item.race_number} {item.competition_name}{' '}
																{item.country_code && `(${item.country_code})`}
															</BetEventContentName>
															<BetEventContentDis>{item.event_name}</BetEventContentDis>
														</BetEventContentInfo>
													</BetEventContentWrapper>
													<BetVisibilityItemMulti>
														<BetVisibilityItemInfo>
															<Competition_Name>
																{item.selection_number}. {item.selection_name}
															</Competition_Name>
														</BetVisibilityItemInfo>
														<BetVisibilityItemAction>
															{item.leg_status === 'winning' && (
																<LegStatusIconWrapper
																	className={css`
																		background-color: ${COLORS.status.success};
																	`}
																>
																	<span>
																		<FaCheck
																			className={css`
																				font-size: 12px;
																			`}
																		/>
																	</span>
																</LegStatusIconWrapper>
															)}
															{item.leg_status === 'losing' && (
																<LegStatusIconWrapper
																	className={css`
																		background-color: ${COLORS.status.danger};
																	`}
																>
																	<span>
																		<FaMinus
																			className={css`
																				font-size: 12px;
																			`}
																		/>
																	</span>
																</LegStatusIconWrapper>
															)}
															{item.leg_status === 'pending' && (
																<LegStatusIconWrapper
																	className={css`
																		background-color: ${COLORS.status.warning};
																	`}
																>
																	<span>
																		<FaHourglassHalf
																			className={css`
																				font-size: 12px;
																			`}
																		/>
																	</span>
																</LegStatusIconWrapper>
															)}
															{item.leg_status === 'refunded' ||
																(item.leg_status === 'fully-refunded' && (
																	<LegStatusIconWrapper
																		className={css`
																			background-color: ${COLORS.gray[300]};
																		`}
																	>
																		<span>
																			<FaMoneyBillAlt
																				className={css`
																					font-size: 12px;
																				`}
																			/>
																		</span>
																	</LegStatusIconWrapper>
																))}
														</BetVisibilityItemAction>
													</BetVisibilityItemMulti>
												</React.Fragment>
										  ))
										: filtered.bet &&
										  filtered.bet.bet_selections[0] &&
										  !QUADDIES_BET_TYPES.includes(filtered.bet.bet_type) &&
										  !RACING_EXOTIC_BET_TYPES.includes(filtered.bet.bet_type) && (
												<BetEventContentWrapper action={() => handleLinkClick(filtered.bet.bet_selections[0])}>
													{renderImage(filtered)}
													<BetEventContentInfo>
														<BetEventContentName>
															R{filtered.bet && filtered.bet.bet_selections[0].race_number}{' '}
															{filtered.bet && filtered.bet.bet_selections[0].competition_name}{' '}
															{/* {filtered.bet && filtered.bet.bet_selections[0].country_code  */}
															{filtered.bet &&
																filtered.bet.bet_selections[0].country_code &&
																filtered.bet &&
																`(${filtered.bet.bet_selections[0].country_code})`}
														</BetEventContentName>
														<BetEventContentDis>
															{filtered.bet && filtered.bet.bet_selections[0].event_name}
														</BetEventContentDis>
													</BetEventContentInfo>
												</BetEventContentWrapper>
										  )}

									{filtered.bet &&
										QUADDIES_BET_TYPES.includes(filtered.bet.bet_type) &&
										group_by_race_number(filtered.bet.bet_selections).map((item, index) => (
											<React.Fragment key={item[0].id}>
												<BetEventContentWrapper action={() => handleLinkClick(item[0])}>
													<BetEventContentIcon>
														{item[0].event_type && <RacingIcon color="black" type={item[0].event_type} />}
													</BetEventContentIcon>
													<BetEventContentInfo>
														<BetEventContentName>
															#{item[0].race_number} {item[0].competition_name}{' '}
															{item[0].country_code && `(${item[0].country_code})`}
														</BetEventContentName>
														<BetEventContentDis>{item[0].event_name}</BetEventContentDis>
													</BetEventContentInfo>
												</BetEventContentWrapper>
												{item.map((i) => (
													<BetVisibilityItemMulti key={i.id}>
														<BetVisibilityItemInfo>
															<Competition_Name>
																{i.selection_number}. {i.selection_name}
															</Competition_Name>
														</BetVisibilityItemInfo>
														<BetVisibilityItemAction>
															{i.leg_status === 'winning' && (
																<LegStatusIconWrapper
																	className={css`
																		background-color: ${COLORS.status.success};
																	`}
																>
																	<span>
																		<FaCheck
																			className={css`
																				font-size: 12px;
																			`}
																		/>
																	</span>
																</LegStatusIconWrapper>
															)}
															{i.leg_status === 'losing' && (
																<LegStatusIconWrapper
																	className={css`
																		background-color: ${COLORS.status.danger};
																	`}
																>
																	<span>
																		<FaMinus
																			className={css`
																				font-size: 12px;
																			`}
																		/>
																	</span>
																</LegStatusIconWrapper>
															)}
															{i.leg_status === 'pending' && (
																<LegStatusIconWrapper
																	className={css`
																		background-color: ${COLORS.status.warning};
																	`}
																>
																	<span>
																		<FaHourglassHalf
																			className={css`
																				font-size: 12px;
																			`}
																		/>
																	</span>
																</LegStatusIconWrapper>
															)}
															{i.leg_status === 'refunded' && (
																<LegStatusIconWrapper
																	className={css`
																		background-color: ${COLORS.gray[300]};
																	`}
																>
																	<span>
																		<FaMoneyBillAlt
																			className={css`
																				font-size: 12px;
																			`}
																		/>
																	</span>
																</LegStatusIconWrapper>
															)}
														</BetVisibilityItemAction>
													</BetVisibilityItemMulti>
												))}
											</React.Fragment>
										))}

									{filtered.bet &&
										RACING_EXOTIC_BET_TYPES.includes(filtered.bet.bet_type) &&
										!(filtered.bet && filtered.bet.bet_type == RACING_BET_TYPE_QUINELLA) &&
										!filtered.bet.boxed_flag &&
										group_by_selection_result(filtered.bet.selection_string, filtered.bet.bet_selections).map(
											(item, index) => (
												<React.Fragment key={item[0].id}>
													{index == 0 ? (
														<BetEventContentWrapper action={() => handleLinkClick(item)}>
															<BetEventContentIcon>
																{item[0].event_type && <RacingIcon color="black" type={item[0].event_type} />}
															</BetEventContentIcon>
															<BetEventContentInfo>
																<BetEventContentName>
																	#{item[0].race_number} {item[0].competition_name}{' '}
																	{item[0].country_code && `(${item[0].country_code})`}
																</BetEventContentName>
																<BetEventContentDis>{item[0].event_name}</BetEventContentDis>
															</BetEventContentInfo>
														</BetEventContentWrapper>
													) : (
														<div
															style={{
																borderWidth: 2,
																borderBottomStyle: 'solid',
																borderColor: COLORS.gray[600],
																// borderColor: index % 2 === 0 ? COLORS.gray[100] : COLORS.gray[400],
																height: 2,
																width: '100%',
															}}
														/>
													)}
													{item.map((i, c) => (
														<BetVisibilityItemMulti
															key={i.id}
															style={
																c == item.length - 1
																	? {
																			borderBottom: 'none',
																			marginBottom: 0,
																	  }
																	: {}
															}
														>
															<BetVisibilityItemInfo>
																<Competition_Name>
																	{i.selection_number}. {i.selection_name}
																</Competition_Name>
															</BetVisibilityItemInfo>
															{
																<BetVisibilityItemAction>
																	{i.leg_status === 'winning' && (
																		<LegStatusIconWrapper
																			className={css`
																				background-color: ${COLORS.status.success};
																			`}
																		>
																			<span>
																				<FaCheck
																					className={css`
																						font-size: 12px;
																					`}
																				/>
																			</span>
																		</LegStatusIconWrapper>
																	)}
																	{i.leg_status === 'losing' && (
																		<LegStatusIconWrapper
																			className={css`
																				background-color: ${COLORS.status.danger};
																			`}
																		>
																			<span>
																				<FaMinus
																					className={css`
																						font-size: 12px;
																					`}
																				/>
																			</span>
																		</LegStatusIconWrapper>
																	)}
																	{i.leg_status === 'pending' && (
																		<LegStatusIconWrapper
																			className={css`
																				background-color: ${COLORS.status.warning};
																			`}
																		>
																			<span>
																				<FaHourglassHalf
																					className={css`
																						font-size: 12px;
																					`}
																				/>
																			</span>
																		</LegStatusIconWrapper>
																	)}
																	{i.leg_status === 'refunded' && (
																		<LegStatusIconWrapper
																			className={css`
																				background-color: ${COLORS.gray[300]};
																			`}
																		>
																			<span>
																				<FaMoneyBillAlt
																					className={css`
																						font-size: 12px;
																					`}
																				/>
																			</span>
																		</LegStatusIconWrapper>
																	)}
																</BetVisibilityItemAction>
															}
														</BetVisibilityItemMulti>
													))}
												</React.Fragment>
											),
										)}

									{filtered.bet &&
										RACING_EXOTIC_BET_TYPES.includes(filtered.bet.bet_type) &&
										filtered.bet.boxed_flag &&
										filtered.bet.bet_selections.map((item, index) => (
											<React.Fragment key={item.id}>
												{index == 0 ? (
													<BetEventContentWrapper action={() => handleLinkClick(item)}>
														<BetEventContentIcon>
															{item.event_type && <RacingIcon color="black" type={item.event_type} />}
														</BetEventContentIcon>
														<BetEventContentInfo>
															<BetEventContentName>
																#{item.race_number} {item.competition_name}{' '}
																{item.country_code && `(${item.country_code})`}
															</BetEventContentName>
															<BetEventContentDis>{item.event_name}</BetEventContentDis>
														</BetEventContentInfo>
													</BetEventContentWrapper>
												) : null}

												<BetVisibilityItemMulti key={item.id}>
													<BetVisibilityItemInfo>
														<Competition_Name>
															{item.selection_number}. {item.selection_name}
														</Competition_Name>
													</BetVisibilityItemInfo>
													{
														<BetVisibilityItemAction>
															{item.leg_status === 'winning' && (
																<LegStatusIconWrapper
																	className={css`
																		background-color: ${COLORS.status.success};
																	`}
																>
																	<span>
																		<FaCheck
																			className={css`
																				font-size: 12px;
																			`}
																		/>
																	</span>
																</LegStatusIconWrapper>
															)}
															{item.leg_status === 'losing' && (
																<LegStatusIconWrapper
																	className={css`
																		background-color: ${COLORS.status.danger};
																	`}
																>
																	<span>
																		<FaMinus
																			className={css`
																				font-size: 12px;
																			`}
																		/>
																	</span>
																</LegStatusIconWrapper>
															)}
															{item.leg_status === 'pending' && (
																<LegStatusIconWrapper
																	className={css`
																		background-color: ${COLORS.status.warning};
																	`}
																>
																	<span>
																		<FaHourglassHalf
																			className={css`
																				font-size: 12px;
																			`}
																		/>
																	</span>
																</LegStatusIconWrapper>
															)}
															{item.leg_status === 'refunded' && (
																<LegStatusIconWrapper
																	className={css`
																		background-color: ${COLORS.gray[300]};
																	`}
																>
																	<span>
																		<FaMoneyBillAlt
																			className={css`
																				font-size: 12px;
																			`}
																		/>
																	</span>
																</LegStatusIconWrapper>
															)}
														</BetVisibilityItemAction>
													}
												</BetVisibilityItemMulti>
											</React.Fragment>
										))}

									{filtered.bet &&
										filtered.bet.bet_type !== 'multi' &&
										!QUADDIES_BET_TYPES.includes(filtered.bet.bet_type) &&
										!RACING_EXOTIC_BET_TYPES.includes(filtered.bet.bet_type) &&
										filtered.bet.bet_selections.map((item) => (
											<BetVisibilityItem key={item.id}>
												<BetVisibilityItemInfo>
													<Competition_Name>
														<span style={{ width: 32, display: 'block', whiteSpace: 'nowrap' }}>
															{filtered.bet.bet_type === 'sameracemulti'
																? `${SRMTypes[item.product]}`
																: `${item.selection_number}.`}
														</span>

														{filtered.bet.bet_type === 'sameracemulti' && ` -  ${item.selection_number}. `}
														{item.selection_name}
														{filtered.bet.bet_type === 'eachway' && ` (${item.bet_type.toUpperCase()})`}
													</Competition_Name>
												</BetVisibilityItemInfo>
												{
													<BetVisibilityItemAction>
														{item.leg_status === 'winning' && (
															<LegStatusIconWrapper
																className={css`
																	background-color: ${COLORS.status.success};
																`}
															>
																<span>
																	<FaCheck
																		className={css`
																			font-size: 12px;
																		`}
																	/>
																</span>
															</LegStatusIconWrapper>
														)}
														{item.leg_status === 'losing' && (
															<LegStatusIconWrapper
																className={css`
																	background-color: ${COLORS.status.danger};
																`}
															>
																<span>
																	<FaMinus
																		className={css`
																			font-size: 12px;
																		`}
																	/>
																</span>
															</LegStatusIconWrapper>
														)}
														{item.leg_status === 'pending' && (
															<LegStatusIconWrapper
																className={css`
																	background-color: ${COLORS.status.warning};
																`}
															>
																<span>
																	<FaHourglassHalf
																		className={css`
																			font-size: 12px;
																		`}
																	/>
																</span>
															</LegStatusIconWrapper>
														)}
														{item.leg_status === 'refunded' && (
															<LegStatusIconWrapper
																className={css`
																	background-color: ${COLORS.gray[300]};
																`}
															>
																<span>
																	<FaMoneyBillAlt
																		className={css`
																			font-size: 12px;
																		`}
																	/>
																</span>
															</LegStatusIconWrapper>
														)}
													</BetVisibilityItemAction>
												}
											</BetVisibilityItem>
										))}
								</BetVisibilityWrapper>
							)}
						</React.Fragment>
					)}
				</StyledBetDataItem>
				<StyledTableDateActionData>
					{isSmallScreen ? (
						<TransactionAmountWrapper>
							{filtered.type === 'Bet Win' && <TableDataText strong>Won Amount</TableDataText>}
							{filtered.type === 'Bet Entry' && <TableDataText strong>Amount</TableDataText>}
							{filtered.type === 'Bet Refund' && <TableDataText strong>Refund Amount</TableDataText>}
							{filtered.type === 'Eway Deposit' && <TableDataText strong>Deposit Amount</TableDataText>}
							<TableDataText strong>
								<TransactionsCurrency
									isMinAmount={filtered.amount < 0}
									amount={filtered.amount}
									type={filtered.bet && filtered.bet.free_credit_amount ? 'bonusCurrency' : 'currency'}
								/>
							</TableDataText>
						</TransactionAmountWrapper>
					) : (
						<TableDataText strong>
							<TransactionsCurrency
								isMinAmount={filtered.amount < 0}
								amount={filtered.amount}
								type={filtered.bet && filtered.bet.free_credit_amount ? 'bonusCurrency' : 'currency'}
							/>
						</TableDataText>
					)}
				</StyledTableDateActionData>
				<StyledTableDateActionData>
					{isSmallScreen ? (
						<TransactionAmountWrapper>
							<TableDataText strong>Balance</TableDataText>
							<TableDataText strong>
								<CurrencyDisplay amount={filtered.running_balance} />
							</TableDataText>
						</TransactionAmountWrapper>
					) : (
						<TableDataText strong>
							<CurrencyDisplay amount={filtered.running_balance} />
						</TableDataText>
					)}
				</StyledTableDateActionData>
			</StyledBetRowItem>
		));
	}

	const [isCancelModalOpen, setIsCancelModalOpen] = useState({
		isOpen: false,
		id: null,
	});
	const handleCancelModalOpen = (state, id) => {
		setIsCancelModalOpen({ isOpen: state, id: id });
	};

	const handleSelectedCupomBet = (bet) => {
		if (!bet) {
			handleBetExport(null);
			return;
		}

		if (
			bet.bet_type === 'win' ||
			bet.bet_type === 'place' ||
			bet.bet_type === 'eachway' ||
			bet.bet_type === 'sameracemulti' ||
			bet.bet_type === 'multi' ||
			QUADDIES_BET_TYPES.includes(bet.bet_type)
		) {
			const data = {
				id: bet.id,
				status: bet.status,
				type: bet.bet_type,
				date: serverDateTime(bet.requested_at).format('DD MMM YYYY HH:mm'),
				stake: bet.amount,
				odds: bet.odds,
				return: bet.odds * bet.amount,
				flexiAmount: bet.percentage,
				selections: bet.bet_selections.map((s) => ({
					selection_number: s.selection_number,
					selection_name: s.selection_name,
					product: s.product,
					meeting_name: s.competition_name,
					race_number: s.race_number,
					meeting_id: s.meeting_id,
					type: s.bet_type,
					race_type: s.event_type,
					tab_number: s.tab_number,
				})),
			};
			handleBetExport(data);
		} else {
			handleBetExport(null);
		}
	};

	return (
		<div className={tablePaginatorClass}>
			<ModalConfirm
				open={isCancelModalOpen.isOpen}
				setOpen={() => handleCancelModalOpen(false, null)}
				onConfirm={() => {
					cancelWithdrawal(isCancelModalOpen.id);
					handleCancelModalOpen(false, null);
				}}
				title="Cancel Withdrawal"
				text="Are you sure you want to cancel this withdrawal?"
			/>
			<StyledAccountTransactions__Table striped={!isSmallScreen}>
				{isSmallScreen ? (
					<StyledTransactionTableRowHeader
						className={css`
							border-top-left-radius: 3px;
							border-top-right-radius: 4px;
							padding-top: 3px;
							padding-bottom: 3px;
						`}
					>
						<Table.TD>
							<Text
								strong
								className={css`
									font-size: 14px;
									color: white;
								`}
							>
								Account Transactions
							</Text>
						</Table.TD>
					</StyledTransactionTableRowHeader>
				) : (
					<StyledTransactionTableRowHeader
						className={css`
							border-top-left-radius: 3px;
							border-top-right-radius: 4px;
							padding-top: 6px;
							padding-bottom: 6px;
						`}
					>
						<Table.TD>
							<Text
								strong
								className={css`
									font-size: 14px;
									color: white;
								`}
							>
								Date
							</Text>
						</Table.TD>
						<Table.TD
							className={css`
								flex: 3;
							`}
						>
							<Text
								strong
								className={css`
									font-size: 14px;
									color: white;
								`}
							>
								Description
							</Text>
						</Table.TD>
						<StyledTableDateActionHeader>
							<Text
								strong
								className={css`
									font-size: 14px;
									color: white;
								`}
							>
								Amount
							</Text>
						</StyledTableDateActionHeader>
						<StyledTableDateActionHeader>
							<Text
								strong
								className={css`
									font-size: 14px;
									color: white;
								`}
							>
								Balance
							</Text>
						</StyledTableDateActionHeader>
					</StyledTransactionTableRowHeader>
				)}

				{renderTransaction}
			</StyledAccountTransactions__Table>
		</div>
	);
};

AccountTransactions.propTypes = {
	/** Action to pass down to the child cell */
	action: PropTypes.func,

	/** Action handler for initialising a rebet */
	handleRebet: PropTypes.func,

	/** Extra classes */
	className: PropTypes.string,

	/** The initial data collection */
	data: PropTypes.array,

	/** If we are rendering on a small screen */
	isSmallScreen: PropTypes.bool,

	/** Headers for the data */
	headers: PropTypes.array,

	/** The data after filtering or splicing has been applied */
	filteredData: PropTypes.arrayOf(PropTypes.shape),

	/** The current page */
	page: PropTypes.number,

	/** The size of the filteredData per page */
	size: PropTypes.number,

	/** Action handler for pending withdraw cancel */
	cancelWithdrawal: PropTypes.func,
};

AccountTransactions.defaultProps = {
	action: () => {},
	changeTablePage: () => {},
	handleRebet: () => {},
	cancelWithdrawal: () => {},
	handleCacheOut: () => {},
	className: null,
	isSmallScreen: false,
	data: [],
	filteredData: [],
	headers: [],
	page: 1,
	size: 10,
};

export default AccountTransactions;
