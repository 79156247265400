import React from 'react';
import styled from 'react-emotion';
import { StyledSection } from '../Components';
import ChangePasswordSection from './Sections/ChangePassword';
import DeactivateAccountSection from './Sections/DeactivateAccount';
import NotificationSection from './Sections/NotificationSection';
import PersonalDetailsSection from './Sections/PersonalDetails';
import PreferencesSection from './Sections/Preferences';
import { StatementSection } from './Sections/Statement';
import { withNamespaces } from 'react-i18next';
import { createErrorBoundary } from '../../../ErrorBoundary/ErrorBoundaryActions';
import { connect } from 'react-redux';
import { LoadingMask } from '@tbh/ui-kit';
import { USER_ACCOUNT_COLOR } from '../../../../constants/themes';

export const StyledSectionSubTitle = styled.h3`
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 8px;
    color: #000;
`;

export const StyledSectionSubScriptionVia = styled.h3`
    font-size: 16px;
    font-weight: bold;
    margin-left: 30px;
    margin-right: 10px;
    color: #000;
`;

export const StyledCheckboxInput = styled.input`
    margin-right: 6px;
    min-height: 16px;
    min-width: 16px;
    width: 16px;
    height: 16px;
    appearance: none;
    border: 1px solid #000;
    border-radius: 2px;
    outline: none;
    transition: background-color 0.3s, border-color 0.3s;
    background-color: #fff;
    cursor: pointer;

    &:checked {
        background-color:  ${USER_ACCOUNT_COLOR.checkbox_active_color};;
        border: none;
    }
`;

export const StyledCheckboxInputGroup = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px 15px;
`;

export const StyledCheckboxDiv = styled.label`
    display: inline-flex;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
    border-radius: 4px;
    margin: 0;
    span {
        font-size: 12px;
        margin: 0;
        color: #000;
    }

    input:checked + span::before {
        content: "✓";
        left: 2.5px;
        top: 0px;
        font-size: 12px;
        display: block;
        text-align: center;
        color: #fff;
        position: absolute;
    }
`;

const SettingsContainer = ({
    address,
    country,
    email,
    dob,
    first_name,
    last_name,
    postcode,
    state,
    suburb,
    username,
    street,
    user,
    isLoading,
    teamname,
    t
}) => {
    return (
        <StyledSection>
            <LoadingMask loading={isLoading} />
            <NotificationSection />
            {/* {user.rns_user_name && (
                <BlackBookNotificationSection />
            )} */}

            <ChangePasswordSection t={t} />

            <PersonalDetailsSection
                address={address}
                country={country}
                email={email}
                dob={dob}
                first_name={first_name}
                last_name={last_name}
                postcode={postcode}
                state={state}
                suburb={suburb}
                username={username}
                street={street}
            />

            <PreferencesSection />

            {/* <DefaultBetAmountsContainer /> */}

            <StatementSection name={teamname}/>

            <DeactivateAccountSection />
        </StyledSection>
    );
};

const mapStateToProps = (state) => {
    const authenticatedUser = state.application.authenticatedUser;
    const user = state.entities.users[authenticatedUser] || {};

    return {
        isLoading: state.application.isBodyRegionLoading,
        teamname: state.acl.brandDetails.teamname,
        // Account Profile
        address: user.street,
        country: user.country,
        dob: user.dob,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        postcode: user.postcode,
        state: user.state,
        suburb: user.city,
        username: user.username,
        street: user.street,
        user: user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withNamespaces()(
    createErrorBoundary(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(SettingsContainer),
    ),
);
