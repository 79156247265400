import React from 'react';
import { BounceLoader } from 'react-spinners';
import { RSTIPS_COLOR } from '../../../constants/themes';

const Spinner = () => {
    return (
        <BounceLoader
            loading={true}
            size={60}
            color={RSTIPS_COLOR.spinner_loding_color}
        />
    );
};

export default Spinner;