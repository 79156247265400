// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings } from '@tbh/ui-kit';

// Components
import { Button, Checkbox, Input, Link, PasswordInput, Text } from '@tbh/ui-kit';

// Context
import AclContext from '../../../../../contexts/AclContext';

import { EVENT_KEY_ENTER, ROCKET_CHAT_LINK } from '../../../../../common/constants/Application';
import CreateAccount from '../CreateAccount/CreateAccount';
import { USER_ACCOUNT_COLOR } from '../../../../../constants/themes';

const StyledLogin__KeepLoggedIn = styled('div')(
	(props) => css`
		label: Login__KeepLoggedInAndButtons;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);
const StyledLogin__ButtonGroup = styled('div')((props) => `
	display: flex;
	flex-direction: column;
	gap: ${spacings(props).cozy}px;
`);
const StyledLoginContainer__CreateAccount = styled(CreateAccount)(
	(props) => css`
		label: LoginContainer__CreateAccount;

		// padding: ${spacings(props).comfortable}px ${spacings(props).cozy}px;
	`,
);
class Login extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Action to login the user */
		login: PropTypes.func.isRequired,

		/** Action to show the forgot password form */
		forgotPassword: PropTypes.func.isRequired,

		/** Hide the form title */
		hideTitle: PropTypes.bool,

		/** Action to launch Intercom */
		launchChat: PropTypes.func,

		/** If the login action is loading */
		loadingLogin: PropTypes.bool,

		/** If there was an error for the username */
		username_error: PropTypes.string,

		/** If there was an error for the password */
		password_error: PropTypes.string,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		hideTitle: false,
		launchChat: null,
		loadingLogin: false,
		username_error: null,
		password_error: null,
		className: null,
	};

	state = {
		username: '',
		password: '',
		username_valid: false,
		password_valid: false,
		keep_logged_in: false,
	};

	/**
	 * Handle changes in the inputs
	 *
	 * @param name
	 * @param value
	 * @param valid
	 */
	handleChange = (name, value, valid) => {
		this.setState({
			[name]: value,
			[`${name}_valid`]: valid,
		});
	};

	onKeyDown = (event) => {
		if (event.key === EVENT_KEY_ENTER) {
			this.login();
		}
	};

	login = () => {
		this.props.login(this.state.username, this.state.password, this.state.keep_logged_in);
	};

	handleCheckbox = () => {
		this.setState({
			keep_logged_in: !this.state.keep_logged_in,
		});
	};

	forgotPassword = () => {
		this.props.forgotPassword('Login Register', 'Click', 'Forgot Password');
	};

	render() {
		const { className, hideTitle, launchChat, loadingLogin, password_error, username_error, t } = this.props;
	
		const { username, password, keep_logged_in } = this.state;
	
		return (
			<form
				className={cx({ [className]: className })}
				onSubmit={(e) => {
					e.preventDefault(); // Prevents the form from doing a full page reload
					this.login(); // Call login on form submit
				}}
			>
				<Input
					name="username"
					value={username}
					onChange={this.handleChange}
					label={t('Login__UsernameOrEmail')}
					error={username_error}
					margin="cozy"
					autoCorrect="off"
					autoCapitalize="none"
				/>
	
				<PasswordInput
					name="password"
					value={password}
					onChange={this.handleChange}
					label={t('Password')}
					error={password_error}
					margin="cozy"
				/>
	
				<StyledLogin__KeepLoggedIn>
					<Checkbox
						name="keep_logged_in"
						value="keep_logged_in"
						label={t('Login__KeepLoggedIn')}
						labelSize="-2"
						action={this.handleCheckbox}
						checked={keep_logged_in}
						inline
					/>
				</StyledLogin__KeepLoggedIn>
	
				<StyledLogin__ButtonGroup center>
					<Button
						action={this.login}
						loading={loadingLogin}
						bold
						flex
						className={css`
							-webkit-border-radius: 4px;
							-moz-border-radius: 4px;
							border-radius: 4px;
							background-color: ${USER_ACCOUNT_COLOR.login_button_bg_color};
							color: ${USER_ACCOUNT_COLOR.login_button_font_color};
							padding: 8px;
							&:hover {
								color: ${USER_ACCOUNT_COLOR.login_hover_button_font_color};
								background: ${USER_ACCOUNT_COLOR.login_hover_button_bg_color};								
							}
						`}
						type="submit"  // Ensure the button submits the form
					>
						{t('Login')}
					</Button>
	
					<StyledLoginContainer__CreateAccount createAccount={this.props.goToRegistration} />
				</StyledLogin__ButtonGroup>
	
				<AclContext.Consumer>
					{(acl) =>
						acl.helpLink ? (
							<Text align="center">
								<Link href={acl.helpLink.link} size="-2" target="blank" underline>
									{acl.helpLink.text}
								</Link>
							</Text>
						) : launchChat ? (
							<Text align="center">
								<Link href={ROCKET_CHAT_LINK} size="-2" target="blank" underline>
									{t('Login__Chat')}
								</Link>
							</Text>
						) : null
					}
				</AclContext.Consumer>
			</form>
		);
	}
	
}

export default withNamespaces()(Login);
