// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';
import { css } from 'react-emotion';

// Components
import { FlexText, Link, Input, InputGroup, Notification, Text, PlotElements } from '@tbh/ui-kit';
import CurrencyInput from '../../Application/Currency/CurrencyInput/CurrencyInput';
import CurrencyDisplay from '../../Application/Currency/CurrencyDisplay/CurrencyDisplay';

// Styling
import {
	StyledDepositForm__CurrencyKeypad,
	StyledDepositForm__NewCard,
	StyledDepositForm__Section,
	StyledDepositForm__SelectArea,
	StyledDepositForm__Notification,
	StyledDepositForm__TermsAcceptance,
	StyledDepositForm__Actions,
	StyledDepositForm__TermsCheckbox,
	StyledClearButton,
	StyledDepositButton,
} from './DepositForm.styled';


const DepositForm = (props) => {
	
	const [hasDecimalInput, setHasDecimalInput] = React.useState(false);


	const {
		t,
		user,
		depositTermsAccepted,
		handleDepositTermsCheckbox,
		className,
		loadingCards,
		isQuickDeposit,
		customButton,
		activeKeypadButton,
		depositAmount,
		buttonValues,
		creditCards,
		creditCardId,
		isDepositEnabled,
		handleCancelDeposit,
		handleDeposit,
		handleFormChange,
		handleLastButtonClicked,
		newCard,
		onAddCard,
		onDeleteCard,
		onDepositAmountChange,
		onDepositKeypadChange,
		onClearDepositChange,
		onCardChange,
		onShowExistingCard,
		onOpenVerifyInfo,
		showVerificationMessage,
		isPromoCodeValid,
		verifyCreditCardsOnly,

		// Inputs for the credit card form
		creditCardName,
		creditCardNumber,
		creditCardExpiry,
		creditCardSecurityCode,
		shouldVerifyCard,
		isSaveCard,
		promoCode,
		handleShouldShowVerification,
		handleShouldShowSaveCard,
		// CC Verify

		onStartCardVerification,
		onVerifyCardSubmit,
		loadingCardVerification,
		verificationAttemptsLeft,
		verificationFailed,
		skipCardVerification,
		verificationStep,
		verifyAmount,
		verifyAmountIsValid,
		brandName,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	/**
	 * Change to amount via keypad
	 */
	const handleButtonClick = (event, value) => {
		onDepositKeypadChange(value);
	};

	const handleClearButtonClick = () => {
		onClearDepositChange();
	};

	/**
	 * Calls parent to save new amount
	 */
	const handleInputChange = (name, value) => {		
		const hasDecimal = String(value/100).includes('.');
		
		setHasDecimalInput(hasDecimal);
		
		const amount = parseInt(value) || 0;
		onDepositAmountChange(amount);

		
	};
	const buttonDisabledState = depositAmount < 1000 || !isDepositEnabled || hasDecimalInput;
	
	

	/**
	 * Whether user has been blocked
	 * @return {boolean}
	 */
	const isUserBlocked = () => {
		return verificationStep === 'blocked';
	};

	/**
	 * Build the credit card content, either rendering a new credit card form or the credit card select form
	 *
	 * @returns {*}
	 */
	const buildCreditCardContent = () => {
		if (newCard || !creditCardId) {
			return (
				<StyledDepositForm__NewCard
					onChange={handleFormChange}
					shouldVerifyCard={shouldVerifyCard}
					isSaveCard={isSaveCard}
					creditCardName={creditCardName}
					creditCardNumber={creditCardNumber}
					creditCardExpiry={creditCardExpiry}
					creditCardSecurityCode={creditCardSecurityCode}
					depositAmount={depositAmount.toString()}
					handleShouldShowVerification={handleShouldShowVerification}
					handleShouldShowSaveCard={handleShouldShowSaveCard}
					onShowExistingCard={onShowExistingCard}
					onOpenVerifyInfo={onOpenVerifyInfo}
					brandName={brandName}
				/>
			);
		}

		return (
			<StyledDepositForm__SelectArea
				onCardChange={onCardChange}
				onChange={handleFormChange}
				creditCardId={creditCardId}
				creditCards={creditCards}
				creditCardSecurityCode={creditCardSecurityCode}
				loadingCardVerification={loadingCardVerification}
				onStartCardVerification={onStartCardVerification}
				onVerifyCardSubmit={onVerifyCardSubmit}
				verificationAttemptsLeft={verificationAttemptsLeft}
				verificationFailed={verificationFailed}
				skipCardVerification={skipCardVerification}
				verificationStep={verificationStep}
				verifyAmount={verifyAmount}
				verifyAmountIsValid={verifyAmountIsValid}
				onOpenVerifyInfo={onOpenVerifyInfo}
				verifyCreditCardsOnly={verifyCreditCardsOnly}
			/>
		);
	};

	const isCardVerified = creditCardId && creditCards[creditCardId] && creditCards[creditCardId].verified;
	return (
		<div className={componentClasses}>
			<StyledDepositForm__Section>
				{!verifyCreditCardsOnly && (
					<React.Fragment>
						<Text
							paragraph
							strong
							className={css`
								font-size: 15px;
							`}
						>
							{t('DepositAmount')}
						</Text>
						<StyledDepositForm__CurrencyKeypad
							activeButton={activeKeypadButton}
							buttonAction={handleButtonClick}
							buttonValues={[1000, 2000, 5000, 10000, 25000, 50000]}
							customButton={
								customButton
									? customButton
									: {
										action: handleLastButtonClicked,
										type: activeKeypadButton === 'last' ? 'primary' : 'ghost',
										label: t('Last'),
									}
							}
						/>
						<StyledClearButton action={handleClearButtonClick}>{'Clear Amounts'}</StyledClearButton>
						{!isQuickDeposit ? (
							<InputGroup>
								<CurrencyInput
									label={t('DepositForm__Deposit')}
									value={depositAmount.toString()}
									name="depositAmount"
									onChange={handleInputChange}
									horizontalMargin="cozy"
									// placeholder={t('DepositForm__Minimum')}
									margin="cozy"
									// Add error message if deposit amount is less than $10 
									error={
										hasDecimalInput
										? 'Please enter a valid amount in whole dollars (without cents)!'
										: depositAmount < 1000
											? 'Deposit amount must be at least $10'
											: null
									}
								/>


								<Input
									label={t('PromoCode')}
									name="promoCode"
									value={promoCode}
									valid={promoCode ? isPromoCodeValid : null}
									error={promoCode && isPromoCodeValid === false ? 'Not eligible promo!' : null}
									onChange={handleFormChange}
									margin="cozy"
								/>
							</InputGroup>
						) : (
							<CurrencyInput
								label={t('DepositForm__Deposit')}
								placeholder={t('DepositForm__Minimum')}
								value={depositAmount.toString()}
								name="depositAmount"
								onChange={handleInputChange}
								margin="cozy"
								// Add error message if deposit amount is less than $10 
								error={
									hasDecimalInput
									? 'Please enter a valid amount in whole dollars (without cents)!'
									: depositAmount < 1000
										? 'Deposit amount must be at least $10'
										: null
								}
							/>
						)}
					</React.Fragment>
				)}
				{!newCard && Object.keys(creditCards).length > 0 && !isUserBlocked() && (
					<FlexText>
						<Text strong>{t('ChooseCreditCard')}</Text>
						{!verifyCreditCardsOnly && !isQuickDeposit && (verificationStep === 'verify-card' || isCardVerified) ? (
							<Text>
								<Link action={onAddCard} size="-1" underline>
									{t('Add')}
								</Link>{' '}
								|&nbsp;
								<Link action={onDeleteCard} size="-1" underline>
									{t('Delete')}
								</Link>
							</Text>
						) : null}
					</FlexText>
				)}
			</StyledDepositForm__Section>

			{!loadingCards &&
				(isUserBlocked() ? (
					<Notification strong type="danger">
						<Text size="-2" strong>
							{t('AccountBlockedTemporarily')}
						</Text>
						<Text size="-2" paragraph>
							{t('DepositForm__ExceededVerification')}
						</Text>
					</Notification>
				) : !verifyCreditCardsOnly || Object.keys(creditCards).length > 0 ? (
					buildCreditCardContent()
				) : null)}

			{showVerificationMessage && (
				<StyledDepositForm__Notification type="success">
					<Text size="-2" align="center">
						{t('DepositForm__VerifiedMessage')}
					</Text>
				</StyledDepositForm__Notification>
			)}
			{user && user.country === 'United Kingdom' && (
				<StyledDepositForm__TermsAcceptance>
					<StyledDepositForm__TermsCheckbox
						inline
						name="accept_deposit_terms"
						action={handleDepositTermsCheckbox}
						data={depositTermsAccepted}
						checked={depositTermsAccepted}
					/>
					<Text size="-1" tag="span">
						{`${t('Accept')} `}
						<Link type="secondary" target="blank" href="https://help.topbetta.com.au/knowledge-base/terms-conditions/">
							{t('termsAndConditions')}
						</Link>
					</Text>
				</StyledDepositForm__TermsAcceptance>
			)}

			{!verifyCreditCardsOnly && (isCardVerified || verificationStep === 'verify-card') ? (
				<StyledDepositForm__Actions center>
					<StyledDepositButton
						action={handleDeposit}
						disabled={buttonDisabledState}
						type="primary"
						flex>
						<PlotElements>
							<span>{t('Deposit')}&nbsp;</span>
							<CurrencyDisplay amount={depositAmount} />
						</PlotElements>
					</StyledDepositButton>
					{handleCancelDeposit && (
						<StyledDepositButton action={handleCancelDeposit} type="danger" flex>
							{t('Cancel')}
						</StyledDepositButton>
					)}
				</StyledDepositForm__Actions>
			) : null}
		</div>
	);
};

DepositForm.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The current authenticated user */
	user: PropTypes.shape({
		/** The country the user lives in */
		country: PropTypes.string.isRequired,
	}).isRequired,

	/** Whether or not the deposit terms have been accepted. Should be automatically accepted for non-UK users */
	depositTermsAccepted: PropTypes.bool.isRequired,

	/** Action when the deposit terms checkbox is clicked on */
	handleDepositTermsCheckbox: PropTypes.func.isRequired,

	/** Hides New/Existing card forms */
	loadingCards: PropTypes.bool,

	/** Whether is quick deposit and full deposit functionality */
	isQuickDeposit: PropTypes.bool,

	/** Deposit button clicked */
	handleDeposit: PropTypes.func.isRequired,

	/** Deposit amount changed */
	onDepositAmountChange: PropTypes.func.isRequired,

	/** Deposit keypad button clicked */
	onDepositKeypadChange: PropTypes.func.isRequired,

	/** Credit Card details or security code input changed */
	handleFormChange: PropTypes.func.isRequired,

	/** Cancel deposit button clicked */
	handleCancelDeposit: PropTypes.func,

	/** The keypad button to mark as active */
	activeKeypadButton: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

	/** Opens the Credit Card Form to add a new credit card */
	onAddCard: PropTypes.func,

	/** Deletes the credit card selected */
	onDeleteCard: PropTypes.func,

	/** Shows the Credit Card Select Form */
	onShowExistingCard: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),

	/** Opens the Verify Credit Card screen */
	onOpenVerifyInfo: PropTypes.func,

	/** Credit Card select changed */
	onCardChange: PropTypes.func,

	/** Deposit Amount Last button clicked */
	handleLastButtonClicked: PropTypes.func,

	/** Extra classes */
	className: PropTypes.string,

	/** The deposit amount */
	depositAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

	/** Deposit values for the buttons */
	buttonValues: PropTypes.array,

	/**
	 * An object containing the properties for the Currency Entry Keypad custom button.
	 * The object item contains it's action, type & label
	 */
	customButton: PropTypes.shape({
		action: PropTypes.func,
		type: PropTypes.string,
		label: PropTypes.string,
	}),

	/** List of credit cards for the user */
	creditCards: PropTypes.object,

	/** ID of the selected credit card */
	creditCardId: PropTypes.number,

	/** Enable the Deposit CTA button */
	isDepositEnabled: PropTypes.bool,

	/** Action to initiate card verification */
	onStartCardVerification: PropTypes.func,

	/** Action to dispatch card verification */
	onVerifyCardSubmit: PropTypes.func,

	/** To render loading mask on button */
	loadingCardVerification: PropTypes.bool,

	/** Number of attempts left for card verification */
	verificationAttemptsLeft: PropTypes.number,

	/** Set true after first failed attempt */
	verificationFailed: PropTypes.bool,

	/** Func to render cvv input again */
	skipCardVerification: PropTypes.func,

	/** Controls which view to render in verification process */
	verificationStep: PropTypes.oneOf(['verify-card', 'verify-amount', 'blocked']),

	/** Verification code field */
	verifyAmount: PropTypes.string,

	/** If the verification code is valid or not */
	verifyAmountIsValid: PropTypes.bool,

	/** Whether to display the New Card form or not */
	newCard: PropTypes.bool,

	/** Displays the message that the credit card was verified */
	showVerificationMessage: PropTypes.bool,

	/** The credit card name */
	creditCardName: PropTypes.string,

	/** The credit card number */
	creditCardNumber: PropTypes.string,

	/** The credit card expiry date */
	creditCardExpiry: PropTypes.string,

	/** The credit card CVV */
	creditCardSecurityCode: PropTypes.string,

	/** Promo code for the deposit */
	promoCode: PropTypes.string,

	/** Whether the is valid  */
	isPromoCodeValid: PropTypes.bool,

	/** If only the credit card verification should be displayed or not */
	verifyCreditCardsOnly: PropTypes.bool,

	/** Brand name */
	brandName: PropTypes.string,
};

DepositForm.defaultProps = {
	loadingCards: false,
	isQuickDeposit: false,
	className: '',
	activeKeypadButton: null,
	depositAmount: null,
	buttonValues: [1000, 2500, 5000, 10000, 25000, 50000],
	customButton: {},
	creditCards: {},
	creditCardId: null,
	isDepositEnabled: false,
	loadingCardVerification: false,
	verificationAttemptsLeft: null,
	verificationFailed: null,
	skipCardVerification: undefined,
	verificationStep: 'verify-card',
	verifyAmount: '',
	verifyAmountIsValid: false,
	newCard: false,
	showVerificationMessage: false,
	onAddCard: undefined,
	onDeleteCard: undefined,
	onCardChange: () => { },
	handleLastButtonClicked: () => { },
	onVerifyCardSubmit: undefined,
	onStartCardVerification: undefined,
	onOpenVerifyInfo: undefined,
	onShowExistingCard: undefined,
	shouldVerifyCard: false,
	isSaveCard: false,
	creditCardName: '',
	creditCardNumber: '',
	creditCardExpiry: '',
	creditCardSecurityCode: '',
	promoCode: null,
	isPromoCodeValid: null,
	handleCancelDeposit: undefined,
	verifyCreditCardsOnly: false,
	brandName: null,
};

export default withNamespaces()(DepositForm);
