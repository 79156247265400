import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';

import { Text } from '@tbh/ui-kit';
import UpRaceSelectionsList from '../UpRaceSelectionsList/UpRaceSelectionsList';

import {
	RACING_BET_TYPE_WIN,
	RACING_BET_TYPE_MARGIN,
	RACING_BET_PRODUCT_CODE_FIXED,
	RACING_BET_TYPE_SP,
} from '../../../../common/constants/Racing';
import { FONT_SIZE_12 } from '../../../../store/application/applicationConstants';
import { StyledRaceCard_goToRace } from './UpRaceCard.styled';

class UpRaceCard extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
			height: PropTypes.number,
		}),

		raceId: PropTypes.number.isRequired,

		race: PropTypes.shape({
			id: PropTypes.number.isRequired,
			products: PropTypes.array,
			status: PropTypes.string,
			fixed_odds_enabled: PropTypes.bool,
			number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			name: PropTypes.string,
			distance: PropTypes.string,
			start_date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
			exotic_bets_allowed: PropTypes.bool,
			selection: PropTypes.array,
			type: PropTypes.string,
			country: PropTypes.string,
			meeting_name: PropTypes.string,
		}).isRequired,

		/** Array of objects that contain all the selection details */
		selections: PropTypes.arrayOf(
			PropTypes.shape({
				/**
				 * Selections will contain all the selection details but besides 'id' they are not needed to be defined here.
				 * They are defined in the RaceSelectionsListItem
				 */
				id: PropTypes.number.isRequired,

				/** The list of prices for the selection */
				prices: PropTypes.arrayOf(
					PropTypes.shape({
						id: PropTypes.number, //@HW
						/** The current price for a win bet with this product */
						win_odds: PropTypes.number,

						/** The current price for a place bet */
						place_odds: PropTypes.number,

						/** The current price for a margin bet */
						margin_odds: PropTypes.number,

						/** The code for this product with this product */
						product_code: PropTypes.string.isRequired,

						/** The product id (NOTE: this is not a unique id between products) */
						product_id: PropTypes.number.isRequired,
					}),
				),
			}),
		).isRequired,

		/** Action to build the list of selection bet buttons */
		buildSelectionBetButtons: PropTypes.func.isRequired,

		/** The currently authenticated user */
		user: PropTypes.shape({
			/** The number of boosts available to the user */
			racing_boost_available: PropTypes.number,
		}),

		/** Single bets place in this race */
		bets: PropTypes.array,

		/** Whether or not to render the pusher updater */
		includePusher: PropTypes.bool,

		/** Handle when a selection is clicked on to make a bet */
		onClickSingle: PropTypes.func,

		/** Handle when a selection is added to multi */
		onClickMulti: PropTypes.func,

		/** The selected bet type ( win/place, trifecta, etc) */
		selectedBetType: PropTypes.string,

		/** Product choices to be displayed on small screens */
		winPlaceProductChoices: PropTypes.array,

		/** Extra classes */
		className: PropTypes.string,

		/** Fluctuations key to be rendered. False values hides Flucs column */
		fluctuationsKey: PropTypes.string,

		/** Whether betting should be disabled or not */
		disableBetting: PropTypes.bool,

		/** Action to perform when the race item is clicked on */
		goToRace: PropTypes.func.isRequired,

		/** Passed via ownProps from parent, needed for bet placement */
		meetingId: PropTypes.number.isRequired,

		/** Passed via ownProps from parent, needed for bet placement */
		meetingName: PropTypes.string,

		/** Win/Place to build betting buttons */
		winPlaceProducts: PropTypes.arrayOf(
			PropTypes.shape({
				fixed: PropTypes.bool,
				bet_type: PropTypes.string,
			}),
		),

		selectionType: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		user: null,
		includePusher: true,
		bets: [],
		meetingName: 'n/a',
		fluctuationsKey: null,
		winPlaceProducts: [],
		winPlaceProductChoices: [],
		disableBetting: false,
		selectedBetType: '',
		boxed: false,
		selectedProduct: '',

		onClickSingle: () => {},
		onClickMulti: () => {},
		navigateToRace: () => {},
		size: null,
		selectionType: '',
	};

	constructor(props) {
		super(props);

		this.state = {
			// Show my bets list if 2 bets or less or if betting not available
			showMyBets: props.bets.length < 3 || !this.isBettingAvailable(props.race),

			// Race details for prop comparisons
			raceId: props.race.id,
		};
	}

	/**
	 * Assess race status and determine whether is available
	 * race.status === RACE_SELLING_STATUS
	 * @param race
	 * @return {boolean}
	 */
	isBettingAvailable = (race) => {
		return !this.props.disableBetting;
	};

	/**
	 * Sort the products so that Fixed is first, and by bet type
	 * Change To the win place order a - win place, b - place place
	 * Ex: change to the win win must change to the order a.ordinal - b.ordinal
	 */
	sortProducts = (a, b) => {
		// Sort by the ordinal key first
		const order = a.ordinal - a.ordinal;
		if (order === 0) {
			// Then sort by fixed, if the ordinal is the same
			const isFixed = b.fixed - a.fixed;
			// If they are both fixed, sort by bet_type
			if (isFixed === 0) {
				// Margin and Win bet types should come first, so we must set Margin to be Win
				// otherwise Place will come before it
				const betTypeA = a.bet_type === RACING_BET_TYPE_MARGIN ? RACING_BET_TYPE_WIN : a.bet_type;
				const betTypeB = b.bet_type === RACING_BET_TYPE_MARGIN ? RACING_BET_TYPE_WIN : b.bet_type;
				return betTypeA < betTypeB;
			}
			return isFixed;
		}
		return order;
	};

	/**
	 * Selects which products to build betting button on selections list.
	 * @return {*}
	 */
	getShowingProducts = () => {
		const { winPlaceProducts } = this.props;

		return winPlaceProducts
			.filter(
				({ bet_type, product_code }) =>
					(bet_type === 'win' && product_code === RACING_BET_PRODUCT_CODE_FIXED) ||
					bet_type === 'place' ||
					(winPlaceProducts.length == 1 && product_code === RACING_BET_TYPE_SP),
			)
			.sort(this.sortProducts);
	};

	render() {
		const {
			t,
			race,
			selectedBetType,
			onClickSingle,
			onClickMulti,
			buildSelectionBetButtons,
			fluctuationsKey,
			winPlaceProducts,
			className,
			size,
			meetingId,
			meetingName,
			goToRace,
			favorite_selection,
			selections,
			selectionType,
		} = this.props;

		const componentClasses = cx({
			[className]: className,
		});
		// const isMobileWidth = size.width < MOBILE_MAX_WIDTH;

		const displayedBetProducts = this.getShowingProducts();
		const isBettingAvailable = this.isBettingAvailable(race);
		//const winPlaceProductsNJ = this.buildWinPlaceProductsGRS(race);

		const handleClick = (e) => {
			e.preventDefault();
			//goToRace(race.id);
			const url =
				'racing-v3/' +
				race.type.toLowerCase() +
				'/' +
				moment(race.start_date)
					.format()
					.split('T')[0] +
				'/' +
				meetingName.replace(/\s/g, '').toLowerCase() +
				'/' +
				'race-' +
				race.number +
				'-' +
				race.id +
				'-' +
				meetingId;
			window.open(url, '_self');
		};

		let uniqDisplayedBetProducts = [...new Set(displayedBetProducts)];

		return (
			<div className={componentClasses}>
				{selectionType == 'favorite_selection' ? (
					<Text
						className={css`
							color: #c72128;
							margin-top: 6px;
							text-transform: uppercase;
							padding-left: 10px;
							font-size: ${FONT_SIZE_12};
						`}
					>
						Favourite Selections
					</Text>
				) : (
					<Text
						className={css`
							color: #c72128;
							margin-top: 6px;
							text-transform: uppercase;
							padding-left: 10px;
							font-size: ${FONT_SIZE_12};
						`}
					>
						Top Selections
					</Text>
				)}

				<UpRaceSelectionsList
					size={size}
					clickSingle={onClickSingle}
					clickMulti={onClickMulti}
					selections={selections}
					displayedBetProducts={uniqDisplayedBetProducts}
					// Bet type filtering, checkboxes control
					raceId={race.id}
					meetingName={meetingName}
					betType={selectedBetType}
					// Betting availability based of Race status
					bettingAvailable={isBettingAvailable}
					// Decides whether and which fluctuations should be rendered. Null for none
					displayedFlucs={fluctuationsKey}
					winPlaceProducts={winPlaceProducts}
					//isMobileWidth={isMobileWidth}
					buildSelectionBetButtons={buildSelectionBetButtons}
					selectionType={selectionType}
				/>

				<StyledRaceCard_goToRace action={handleClick} type="secondary">
					{t('NextToJumpRaceDetails__View')}
				</StyledRaceCard_goToRace>
			</div>
		);
	}
}

export default withNamespaces()(UpRaceCard);
