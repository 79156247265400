import { Checkbox, PasswordInput, Text } from '@tbh/ui-kit';
import React from 'react';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { createErrorBoundary } from '../../containers/ErrorBoundary/ErrorBoundaryActions';
import { connect } from 'react-redux';
import { PageComponentContainer } from '../../components/features/Application/PageComponentContainer/PageComponentContainer';
import { StyledSectionButton } from '../../containers/UserAccountV2/ContentUserAccount/Components';
import { routeTo } from '../../store/application/applicationActions';
import { trackGaEvent } from '../../store/trackingPixels/trackingActions';
import { deactivateAccount } from '../../store/userProfile/userProfileActions';
import Modal from '../../components/controllers/Modal/Modal';
import { PrimaryButton } from '../../components/UI/Buttons/Buttons';
import { StyledVerificationModalContent } from '../../containers/UserAccountV2/ContentUserAccount/Verifications/VerificationsContainer';
import { ClipLoader } from 'react-spinners';
import { USER_ACCOUNT_V2_BASE_URL } from '../../pages/UserAccountV2/UserAccountPageConstants';



const Paragraph = styled(Text)`
    padding: 0px 10px;
    margin-bottom: 20px;
`;

const Container = styled('div')`
    width: 100%;
    max-width: 520px;
    padding: 10px 12px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: #e8e8e8;
    margin: auto;
    margin-bottom: 20px;
`;

const ConfirmPassword = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #d9d9d9;
    margin-top: 20px;
    padding: 12px 0px;
`;

const BoldBottomLink = styled('span')`
    display: inline-block;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        color: #BB181B;
        text-decoration: underline;
    }
`;


const DeactivatedAccountPage = ({
    t,
    user,

    toWelcome,
    deactivateAccount,
    goToSettings,
    goToResponsibleGambling,
    brandDetails
}) => {

    const [form, setForm] = React.useState({
        checked: false,
        password: '',
    });

    const [errors, setErrors] = React.useState({
        password: '',
    });

    const [deactivated, setDeactivated] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const changeForm = (name, value) => {
        setForm({
            ...form,
            [name]: value,
        });
    };


    const OnDeactivateAccount = async () => {
        setLoading(true);

        try {
            const data = await deactivateAccount(form.password);
            setDeactivated(true);
        } catch (error) {
            setErrors({
                password: error
            });
        }

        setLoading(false);
    };


    if (!user && !deactivated) {
        toWelcome();
        return null;
    }

    const modalConfig = {
        mobileWidth: true,
        noBorder: true,
        preventBackdropClose: true,
        hideClose: true,
        title: 'Deactivated Account',
    };



    // If not logged redirect to welcome page
    return (
        <PageComponentContainer style={{ padding: '22px', background:'#fff' }}>
            <Modal
                open={deactivated}
                onClose={() => toWelcome()}
                config={modalConfig}
                componentKey="failed-succeed-verification"
            >
                <StyledVerificationModalContent>
                    <Text size="1" align="center">
                        Your account has been deactivated!
                    </Text>

                    <PrimaryButton onClick={() => toWelcome()}>Go to Home</PrimaryButton>
                </StyledVerificationModalContent>
            </Modal>

            <Text strong size="1" className={'mb-4'} >Deactivating Your {brandDetails.teamname} Account.</Text>

            <Text strong size="0" className={'mb-2'}>
                1. Your Account
            </Text>
            <Paragraph size="-2">
                Before deactivating your account online, please ensure that your account is fully verified and you have withdrawn your account balance. Also you agree not to open any further accounts while your account is deactivated. You may re-activate your account at any time by contacting our Customer Support team on {brandDetails.phone}
            </Paragraph>
            <Text strong size="0" className={'mb-2'}>
                2. Pending Bets and your Account Balance
            </Text>
            <Paragraph size="-2">
                Any pending bets you have on your account will remain active. And any potential winnings will be transferred to your nominated account once the bets have resulted. Any pending withdrawals will also be actioned.
            </Paragraph>
            <Text strong size="0" className={'mb-2'}>
                3. Emails and Alerts
            </Text>
            <Paragraph size="-2">
                While your account is inactive, you will not receive emails and other alerts from {brandDetails.teamname}
            </Paragraph>
            <Paragraph size="-2">
                Contact the {brandDetails.teamname} Customer Support team on {brandDetails.phone} if you are unable to deactivate your account.
            </Paragraph>
            <Paragraph size="-2">
                If you only wish to change your marketing preferences (including unsubscribing) please click <BoldBottomLink onClick={goToSettings}>here.</BoldBottomLink>
            </Paragraph>
            <Paragraph size="-2">
                If you wish to close your account because you can no longer gamble safely, please click <BoldBottomLink onClick={goToResponsibleGambling}>here.</BoldBottomLink>
            </Paragraph>


            <Container>
                <Checkbox checked={form.checked} label="I agree to the conditions outlined"
                    action={() => changeForm('checked', !form.checked)}
                    className={css`div{font-size: 12px!important;}`}
                />
                {form.checked && (
                    <ConfirmPassword>
                        <Text size="-4">Confirm account closure with password</Text>
                        <PasswordInput
                            className={css`border: 1px solid #d9d9d9;background-color: white;padding:8px;border-radius: 4px;margin-top:4px;`}
                            name="password"
                            value={form.password}
                            onChange={(name, value) => changeForm(name, value)}
                            label={t('Password')}
                            error={errors.password}
                            margin="cozy"
                        />
                        <StyledSectionButton style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => OnDeactivateAccount()}>
                            {loading ? <ClipLoader color="#fff" size={20} loading={loading} /> : 'Deactivate Account'}

                        </ StyledSectionButton>
                    </ConfirmPassword>

                )}

            </Container>
        </PageComponentContainer>
    );
};

const mapStateToProps = state => ({
    user: state.entities.users[state.application.authenticatedUser],
    brandDetails: state.acl.brandDetails

});

const mapDispatchToProps = dispatch => ({
    toWelcome: () => dispatch(routeTo('/')),
    deactivateAccount: (password) => dispatch(deactivateAccount(password)),
    goToSettings: () => {
        dispatch(trackGaEvent('AccountMenu', 'Click', 'Settings'));
        return dispatch(routeTo(`${USER_ACCOUNT_V2_BASE_URL}/settings`));
    },
    goToResponsibleGambling: () => {
        dispatch(trackGaEvent('AccountMenu', 'Click', 'Withdraw'));
        return dispatch(routeTo(`${USER_ACCOUNT_V2_BASE_URL}/responsible-gambling`));
    },
});

export default withNamespaces()(
    createErrorBoundary(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(DeactivatedAccountPage),
    ),
);