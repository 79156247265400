// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';

import { Text } from '@tbh/ui-kit';

import { BRAND_NAME } from '../../../../../common/constants/wagering360';

const FooterNote = (props) => {
	const { className, t, brandName } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<div className={componentClasses}>
			<Text type="alternate" align="justify" size="-3" paragraph>
				{t('FooterNote__One', { brandName })}
			</Text>
			{brandName === BRAND_NAME && (
				<Text type="alternate" align="justify" size="-3">
					{t('FooterNote__Two', { brandName })}
				</Text>
			)}
		</div>
	);
};

FooterNote.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The business brand name */
	brandName: PropTypes.string.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

FooterNote.defaultProps = {
	className: '',
};

export default withNamespaces()(FooterNote);
