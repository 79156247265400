import { HeaderBar } from '@tbh/ui-kit';
import cx from 'classnames/bind';
import React from 'react';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { StyledNJRaceHeaderBar__Metting__NameLoading } from './../../Carousel/UpCarouselItem/UpCarouselItem.styled';

const StyledRaceHeaderBar = styled(HeaderBar)`
	${(props) => css`
		min-height: 35px;
		border-radius: 4px 4px 0 0;
		background-color: #1a1a1a;
		width: 100%;
		max-width: 100%;
		margin: 0;
		padding: 0;
	`};
`;

const StyledLoadingShimmer = styled(StyledNJRaceHeaderBar__Metting__NameLoading)`
	${(props) => css`
		background: #2a2a2a;
		animation: loadingShimmer 1s infinite;
		@keyframes loadingShimmer {
			0% {
				opacity: 0.6;
			}
			50% {
				opacity: 0.8;
			}
			100% {
				opacity: 0.6;
			}
		}
	`}
`;

const RaceHeaderBarLoading = (props) => {
	const { className } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<StyledRaceHeaderBar fluid noPadding className={componentClasses} type="primary" margin={false}>
			<div
				style={{
					background: '#1a1a1a',
					padding: '12px',
					position: 'relative',
					width: '100%',
					boxSizing: 'border-box',
				}}
			>
				{/* First Row */}
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						marginBottom: '10px',
						width: '100%',
					}}
				>
					<div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
						<StyledLoadingShimmer
							width="35px"
							height="28px"
							style={{
								borderRadius: '4px',
								background: '#2f1e1e',
							}}
						/>
						<StyledLoadingShimmer
							width="110px"
							height="20px"
							style={{
								borderRadius: '4px',
								background: '#2a2a2a',
							}}
						/>
					</div>
					<div style={{ display: 'flex', gap: '8px' }}>
						{/* <StyledLoadingShimmer 
                            width="80px" 
                            height="24px" 
                            style={{ 
                                borderRadius: '4px',
                                background: '#2a2a2a'
                            }} 
                        /> */}
						<StyledLoadingShimmer
							width="60px"
							height="24px"
							style={{
								borderRadius: '4px',
								background: '#2a2a2a',
							}}
						/>
					</div>
				</div>

				{/* Second Row - Title */}
				<div style={{ marginBottom: '10px', width: '50%' }}>
					<StyledLoadingShimmer
						width="100%"
						height="22px"
						style={{
							borderRadius: '4px',
							background: '#2a2a2a',
						}}
					/>
				</div>

				{/* Third Row - Race Details */}
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: '10px',
						width: '35%',
					}}
				>
					<StyledLoadingShimmer
						width="70%"
						height="16px"
						style={{
							borderRadius: '4px',
							background: '#2a2a2a',
						}}
					/>
					<StyledLoadingShimmer
						width="20%"
						height="16px"
						style={{
							borderRadius: '4px',
							background: '#2a2a2a',
						}}
					/>
				</div>

				{/* Fourth Row - Race Time */}
				<div style={{ width: '100%' }}>
					<StyledLoadingShimmer
						width="33%"
						height="16px"
						style={{
							borderRadius: '4px',
							background: '#2a2a2a',
						}}
					/>
				</div>
			</div>
		</StyledRaceHeaderBar>
	);
};

export default withNamespaces()(RaceHeaderBarLoading);
