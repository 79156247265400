import { Icon, Panel, Text, spacings } from '@tbh/ui-kit';
import cx from 'classnames/bind';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { buildRaceLegSequence } from '../../../../common/BuildRaceLegSequence';
import { MOBILE_MAX_WIDTH } from '../../../../common/constants/Breakpoints';
import { GOAT_BET_TYPE_WIN, GOAT_PRODUCT_TYPE_BOOST } from '../../../../common/constants/GoatProducts';
import {
	QUADDIES_BET_TYPES,
	RACE_CLOSED_STATUS,
	RACE_FINISHED_STATUS,
	RACE_SELLING_STATUS,
	RACING_BET_PRODUCT_CODE_SP,
	RACING_BET_PRODUCT_CODE_TF,
	RACING_BET_TYPE_DAILY_DOUBLE,
	RACING_BET_TYPE_EACHWAY,
	RACING_BET_TYPE_EXACTA,
	RACING_BET_TYPE_FIRST_FOUR,
	RACING_BET_TYPE_MARGIN,
	RACING_BET_TYPE_NOTTO_WIN_BET,
	RACING_BET_TYPE_PLACE,
	RACING_BET_TYPE_QUADDIE,
	RACING_BET_TYPE_QUINELLA,
	RACING_BET_TYPE_RUNNING_DOUBLE,
	RACING_BET_TYPE_RUNNING_TREBLE,
	RACING_BET_TYPE_STRAIGHT_EIGHT,
	RACING_BET_TYPE_TRAINERT_4_BET,
	RACING_BET_TYPE_TRAINER_WIN_BET,
	RACING_BET_TYPE_TRIFECTA,
	RACING_BET_TYPE_WIN,
	RACING_EXOTIC_BET_TYPES,
	RACING_ISSURE_BET,
	RACING_MORE_MARKETS_TYPE,
	RACING_MULTIPLES_TYPE,
	RACING_NON_EXOTIC_BET_TYPE,
	RACING_ODD_GRID_BET_TYPE,
	RACING_SAME_RACE_MULTI_TYPE,
	RACING_TYPES_LOOKUP,
	SELECTION_NOT_SCRATCHED_STATUS,
	SRMTypes
} from '../../../../common/constants/Racing';
import { SelectQuaddieType } from '../../../../common/SelectQuaddieBet';
import { BREAKPOINTS } from '../../../../constants/themes';
import MyBetsIcon from '../../../../containers/Application/MyBets/MyBetsIcon/MyBetsIcon';
import {
	getNotScratchedSelections,
	getSelectionsFromTips
} from '../../../../pages/Racing/RacingHome/racingSelectorsGRS';
import { isBetPlacedForProduct } from '../../../../store/entities/actions/BetActions';
import { LoadingSpanner } from '../../Application/LoadingSpinner/LoadingSpinner';
import PusherSubscriberMeetingChannel from '../PusherSubscribers/PusherSubscriberMeetingChannel';
import PusherSubscriberRaceChannel from '../PusherSubscribers/PusherSubscriberRaceChannel';
import RaceProductLegend from '../RaceProductLegend/RaceProductLegend';
import RaceSelectionsList from '../RaceSelectionsList/RaceSelectionsList';
import RaceSelectionTips from '../RaceSelectionTips/RaceSelectionTips';
import * as RCS from './RaceCard.styled';
import { toteProducts } from '../../../../store/entities/constants/BetConstants';

export const StyledRaceCard = styled('div')`
	${(props) =>
		`
		width:100%;
		border-radius: ${spacings(props).tight}px;
		margin-top: 10px;
		border: 1px solid #666666;
		padding: ${spacings(props).compact}px
	`}
`;

const StyledRaceResultsTitle = styled('div')`
	${(props) => css`
		label: RaceResultsTitle;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		text-transform: uppercase;
		color: #000000;
		margin: 40px 0px;
		padding: ${spacings(props).compact}px;
	`}
`;

class NewRaceCard extends Component {
	/**
	 * Check if a certain bet with a matching type or product, etc, has been placed
	 *
	 * @param key
	 * @param value
	 * @param props
	 * @returns {*}
	 */
	static isBetPlaced = (key, value, props) => {
		const { bets, race } = props;

		return isBetPlacedForProduct(key, value, bets, race.products);
	};

	/**
	 * Return whether or not a Margin bet has been placed
	 *
	 * @param props
	 * @returns {*}
	 */
	static isMarginBetPlaced = (props) => {
		return NewRaceCard.isBetPlaced('bet_type', RACING_BET_TYPE_MARGIN, props);
	};

	/**
	 * Return whether or not a Bump bet has been placed
	 *
	 * @param props
	 * @returns {*}
	 */
	static isBumpBetPlaced = (props) => {
		return NewRaceCard.isBetPlaced('product_type', GOAT_PRODUCT_TYPE_BOOST, props);
	};

	/**
	 * Check that Early Speed is available by seeing if the first selection has an early speed position
	 *
	 * @param selections
	 * @returns {Array|number|boolean}
	 */
	static isEarlySpeedAvailable = (selections = []) => {
		return selections && selections.length && parseInt(selections[0].early_speed_position) >= 0;
	};

	/**
	 * Check that Settling Position is available by checking the length of the first selections settling speed positions
	 *
	 * @param selections
	 * @returns {Array|number|*|boolean}
	 */
	static isSettlingPositionAvailable = (selections = []) => {
		return (
			selections &&
			selections.length > 0 &&
			selections[0].settling_speed_positions &&
			selections[0].settling_speed_positions.length > 0
		);
	};

	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
			height: PropTypes.number
		}).isRequired,

		/** De-normalized race and details */
		race: PropTypes.shape({
			id: PropTypes.number.isRequired,
			status: PropTypes.string,
			fixed_odds_enabled: PropTypes.bool,
			number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			name: PropTypes.string,
			distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			start_date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
			exotic_bets_allowed: PropTypes.bool,
			selections: PropTypes.array,
			win_pool_total: PropTypes.number,
			place_pool_total: PropTypes.number,
			products: PropTypes.array,
			tips: PropTypes.array,
			displayed_results: PropTypes.array,
			results: PropTypes.array
		}).isRequired,

		/** Action to build the list of selection bet buttons */
		buildSelectionBetButtons: PropTypes.func.isRequired,

		/** The currently authenticated user */
		user: PropTypes.shape({
			/** The number of boosts available to the user */
			racing_boost_available: PropTypes.number
		}),

		/** Single bets place in this race */
		bets: PropTypes.array,

		/** Whether or not to render the pusher updater */
		includePusher: PropTypes.bool,

		/** Trigger to clear selected checkboxes state */
		clearSelections: PropTypes.bool,

		/** Function to clear selected checkboxes state */
		handleClearSelections: PropTypes.func,

		/** Display the bet type filters */
		displayBetTypeFilters: PropTypes.bool,

		/** Whether betting should be disabled or not */
		disableBetting: PropTypes.bool,

		/** Display exotic bet types */
		disableExotics: PropTypes.bool,

		/** Display derivative bet types */
		disableDerivatives: PropTypes.bool,

		/** Display speedmap tabs */
		disableSpeedmaps: PropTypes.bool,

		/** Computed results to render */
		displayedResults: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
		displayedExoticResults: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

		/** Loading mask status to cover race card section */
		loadingRace: PropTypes.bool,

		/** Fluctuations key to be rendered. False values hides Flucs column */
		fluctuationsKey: PropTypes.string,

		/** Flag to render Forms button or not. Based of 'runner', under Selections. */
		shouldRenderFormsButton: PropTypes.bool,

		/** Handle when a selection is clicked on to make a bet */
		onClickSingle: PropTypes.func,

		/** Handle when an exotic selection is clicked on */
		onClickExotic: PropTypes.func,

		ShowBetProduct: PropTypes.bool,

		/** Handle when a selection is added to multi */
		onClickMulti: PropTypes.func,

		/** Handle when a derivative selection is clicked on */
		onDerivativeSelectionClick: PropTypes.func,

		/** Handle navigation to a race id */
		navigateToRace: PropTypes.func,

		/** The selected bet type ( win/place, trifecta, etc) */
		selectedBetType: PropTypes.string,

		/** If the exotic is boxed or not */
		boxed: PropTypes.bool,

		/** The selected bet product */
		selectedProduct: PropTypes.string,

		/** Function to calculate the margin length from the race */
		getGoatMarginButtLength: PropTypes.func,

		/** Handle when the bet type is changed */
		onBetTypeChange: PropTypes.func,

		/** Tracks bet type selection */
		trackGaMarketType: PropTypes.func,

		/** Tracking all form toggle */
		trackGaAllForm: PropTypes.func,

		/** Tracks single runner form toggle */
		trackGaRunnerForm: PropTypes.func,

		/** Tracks goat product being enabled */
		trackGaGoatType: PropTypes.func,

		/** Tracks when speedmaps are toggled */
		trackGaSpeedmaps: PropTypes.func,

		/** Tracks when speedmaps are toggled */
		trackGRSHotStats: PropTypes.func,

		/** Tracks when BetBuilder are toggled */
		trackBetBuilder: PropTypes.func,

		onclickBetBuilder: PropTypes.func,

		/** Win/Place to build betting buttons */
		winPlaceProducts: PropTypes.arrayOf(
			PropTypes.shape({
				fixed: PropTypes.bool,
				bet_type: PropTypes.string
			})
		),

		/** Product choices to be displayed on small screens */
		winPlaceProductChoices: PropTypes.array,

		/** Extra classes */
		className: PropTypes.string,

		/** Passed via ownProps from parent */
		meetingId: PropTypes.number.isRequired,

		betBuilderMeeting: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			state: PropTypes.string,
			country: PropTypes.string,
			type: PropTypes.string.isRequired,
			start_date: PropTypes.string.isRequired,
			races: PropTypes.array
		}),

		/** Navigate to the bet builder page */
		onGoToBetBulder: PropTypes.func.isRequired,

		meetingCountry: PropTypes.string,

		raceDistance: PropTypes.string,

		oddsGridPrice: PropTypes.array,

		trackComputerTipsType: PropTypes.func,

		dailyBailOut: PropTypes.number
	};

	static defaultProps = {
		className: '',
		user: null,
		displayBetTypeFilters: true,
		disableExotics: false,
		disableDerivatives: false,
		disableSpeedmaps: false,
		includePusher: true,
		bets: [],
		loadingRace: false,
		meetingName: 'n/a',
		fluctuationsKey: null,
		displayedResults: [],
		displayedExoticResults: [],
		winPlaceProducts: [],
		oddsGridPrice: [],
		winPlaceProductChoices: [],
		disableBetting: false,
		shouldRenderFormsButton: true,
		selectedBetType: '',
		boxed: false,
		selectedProduct: '',
		clearSelections: false,
		handleClearSelections: () => {},
		getGoatMarginButtLength: () => {},
		onBetTypeChange: () => {},
		trackGaMarketType: () => {},
		trackGaAllForm: () => {},
		trackGaRunnerForm: () => {},
		trackGaGoatType: () => {},
		trackGaSpeedmaps: () => {},
		onClickExotic: () => {},
		onClickSingle: () => {},
		onClickMulti: () => {},
		navigateToRace: () => {},
		onDerivativeSelectionClick: () => {},
		trackGRSHotStats: () => {},
		ShowBetProduct: false,
		trackBetBuilder: () => {},
		onclickBetBuilder: () => {},
		betBuilderMeeting: null,
		meetingCountry: '',
		raceDistance: null,
		trackComputerTipsType: () => {},
		dailyBailOut: 0
	};

	constructor(props) {
		super(props);

		this.state = {
			expandFormVisibility: false,
			// Initial result to display
			activeResultTab: this.initialActiveResults(props.race),

			// Holds the status of the 'all form' open button
			allFormOpen: false,

			//hot selections open button
			hotStatsOpen: false,

			BetBuilderOpened: false,
			// Show my bets list if 2 bets or less or if betting not available
			showMyBets: props.bets.length < 3 || !this.isBettingAvailable(props.race),

			// The special GOAT product that has been selected
			selectedGoatProduct: null,

			// before click price bump showing ald fixed win price
			selectWinFixedOldPrice: [],

			// The informatics section and screen in view
			informatics: {
				speedmapsOpen: false,
				earlySpeedOpen: false,
				settlingPositionOpen: false,
				computerTipsOpen: false
			},

			// Race details for prop comparisons
			raceId: props.race.id,
			raceStatus: props.race.status,
			toteChecked: true,
			betProductId: null,
			flucsCheck: window.innerWidth >= BREAKPOINTS.laptopMin
		};
		this.handleChange = this.handleChange.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			nextProps.race.id !== prevState.raceId ||
			(nextProps.race.status !== prevState.raceStatus && nextProps.race.status === RACE_CLOSED_STATUS) ||
			(prevState.selectedGoatProduct === GOAT_PRODUCT_TYPE_BOOST &&
				(NewRaceCard.isBumpBetPlaced(nextProps) || (nextProps.user && nextProps.user.racing_boost_available <= 0))) ||
			(prevState.selectedGoatProduct === RACING_BET_TYPE_MARGIN &&
				(NewRaceCard.isMarginBetPlaced(nextProps) || (nextProps.user && nextProps.user.racing_butt_available <= 0)))
		) {
			return {
				allFormOpen: false,
				hotStatsOpen: false,
				BetBuilderOpened: false,
				selectedGoatProduct: null,
				raceId: nextProps.race.id,
				raceStatus: nextProps.race.status,
				informatics: {
					speedmapsOpen: false,
					earlySpeedOpen: false,
					settlingPositionOpen: false,
					computerTipsOpen: false
				}
			};
		}

		return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.race.id !== this.props.race.id) {
			this.setState({
				activeResultTab: this.initialActiveResults(this.props.race)
			});
		}
	}

	getFirstProductWithResult(race) {
		let products = race.products || [];
		let results = race.results || [];
		let exoticResults = race.displayed_exotic_results || [];
		return products.find((product) => {
			if (
				results.find((result) => result.product_id === product.product_id) ||
				exoticResults.find((result) => result.product_id === product.product_id)
			) {
				return true;
			}
			return false;
		});
	}

	/**
	 * Determine which set of race results to display by default
	 * @param race
	 * @returns {string}
	 */
	initialActiveResults = (race) => {
		let activeResultTab = '';
		// If there are (legacy) results to display, grab the Product ID of the results that would be displayed
		// by default and set them as the initial result to set to display for the results list
		if (race.displayed_results && race.displayed_results.length) {
			const defaultResultsProductId = race.displayed_results[0].product_id;
			const defaultResultsProduct = race.products.find((product) => product.product_id === defaultResultsProductId);
			if (defaultResultsProduct) {
				activeResultTab = defaultResultsProduct.product_name;
			}
		}

		if (!activeResultTab) {
			// Loop through the products until you find a result for that product
			let product = this.getFirstProductWithResult(race);
			activeResultTab = product && product.product_name;
		}

		return activeResultTab;
	};

	/**
	 * Add a selection id to the list of opened form items
	 */
	handleAllFormToggle = () => {
		this.setState((prevState) => {
			// When switching to true send GA event.
			if (!prevState.allFormOpen) {
				this.props.trackGaAllForm();
			}
			return {
				allFormOpen: !prevState.allFormOpen,
				hotStatsOpen: false,
				BetBuilderOpened: false,
				informatics: {
					speedmapsOpen: false,
					earlySpeedOpen: false,
					settlingPositionOpen: false,
					computerTipsOpen: false
				}
			};
		});
		this.setState((prevState) => ({
			expandFormVisibility: !prevState.expandFormVisibility
		}));
	};

	/**
	 * Toggle the hot stats section open and closed
	 */
	handleHotStatsToggle = (state) => {
		this.setState((prevState) => {
			// When switching to true send GA event.
			// if (!prevState.informatics.speedmapsOpen) {
			// 	this.props.trackGRSHotStats('Hotstats');
			// }
			return {
				allFormOpen: false,
				hotStatsOpen: state,
				BetBuilderOpened: false,
				informatics: {
					speedmapsOpen: false,
					earlySpeedOpen: false,
					settlingPositionOpen: false,
					computerTipsOpen: false
				}
			};
		});
	};

	handleComputerizedTipToggle = (tabClicked) => {
		//this.props.trackGaSpeedmaps(tabClicked);
		this.setState((prevState) => {
			// When switching to true send GA event.
			// if (!prevState.informatics.computerTipsOpen) {
			//   this.props.trackGaSpeedmaps(tabClicked);
			// }

			return {
				allFormOpen: false,
				hotStatsOpen: false,
				BetBuilderOpened: false,
				informatics: {
					speedmapsOpen: false,
					earlySpeedOpen: false,
					settlingPositionOpen: false,
					computerTipsOpen: !prevState.informatics.computerTipsOpen
				}
			};
		});
	};

	goToBetBuilderPage = () => {
		const { meetingId, race } = this.props;
		this.props.onGoToBetBulder(this.props.meetingId);
	};

	/**
	 * Toggle the speedmaps section open and closed
	 */
	handleSpeedmapsToggle = () => {
		this.setState((prevState) => {
			// When switching to true send GA event.
			if (!prevState.informatics.speedmapsOpen) {
				this.props.trackGaSpeedmaps('Speedmap');
			}
			return {
				allFormOpen: false,
				hotStatsOpen: false,
				BetBuilderOpened: false,
				informatics: {
					speedmapsOpen: !prevState.informatics.speedmapsOpen,
					earlySpeedOpen: !prevState.informatics.speedmapsOpen,
					settlingPositionOpen: false,
					computerTipsOpen: false
				}
			};
		});
	};

	/**
	 * Toggle the informatics section
	 * @param tabClicked
	 */
	handleInformaticsToggle = (tabClicked) => {
		this.props.trackGaSpeedmaps(tabClicked);
		this.setState({
			informatics: {
				speedmapsOpen: true,
				earlySpeedOpen: tabClicked !== 'settlingPosition',
				settlingPositionOpen: tabClicked === 'settlingPosition',
				computerTipsOpen: false
			}
		});
	};

	/**
	 * Assess race status and determine whether is available
	 * @param race
	 * @return {boolean}
	 */
	isBettingAvailable = (race) => {
		return race.status === RACE_SELLING_STATUS && !this.props.disableBetting;
	};

	/**
	 * Return how many checkboxes to display for an exotic bet
	 *
	 * @returns {*}
	 */
	getCheckboxes = () => {
		let { selectedBetType, boxed } = this.props;

		if (boxed) {
			return { 1: true };
		}

		switch (selectedBetType) {
			case RACING_BET_TYPE_QUINELLA:
				return { 1: true, 2: true }; // add two checkbox
			//return { 1: true };
			case RACING_MULTIPLES_TYPE:
				return { 1: true, 2: true }; // add two checkbox
			case RACING_BET_TYPE_QUADDIE:
				return { 1: true };
			case RACING_BET_TYPE_DAILY_DOUBLE:
				return { 1: true };
			case RACING_BET_TYPE_RUNNING_DOUBLE:
				return { 1: true };
			case RACING_BET_TYPE_RUNNING_TREBLE:
				return { 1: true };
			case RACING_BET_TYPE_EXACTA:
				return { 1: true, 2: true };
			case RACING_BET_TYPE_TRIFECTA:
				return { 1: true, 2: true, 3: true };
			case RACING_BET_TYPE_FIRST_FOUR:
				return { 1: true, 2: true, 3: true, 4: true };
			case RACING_BET_TYPE_STRAIGHT_EIGHT:
				return { 1: true, 2: true, 3: true, 4: true, 5: true, 6: true, 7: true, 8: true };
		}
	};

	/**
	 * Sort the products so that Fixed is first, and by bet type
	 * Change To the win place order a - win place, b - place place
	 * Ex: change to the win win must change to the order a.ordinal - b.ordinal
	 */
	sortProducts = (a, b) => {
		// Sort by the ordinal key first
		const order = a.ordinal - a.ordinal;
		if (order === 0) {
			// Then sort by fixed, if the ordinal is the same
			const isFixed = b.fixed - a.fixed;
			// If they are both fixed, sort by bet_type
			if (isFixed === 0) {
				// Margin and Win bet types should come first, so we must set Margin to be Win
				// otherwise Place will come before it
				const betTypeA = a.bet_type === RACING_BET_TYPE_MARGIN ? RACING_BET_TYPE_WIN : a.bet_type;
				const betTypeB = b.bet_type === RACING_BET_TYPE_MARGIN ? RACING_BET_TYPE_WIN : b.bet_type;
				return betTypeA < betTypeB;
			}
			return isFixed;
		}
		return order;
	};

	/**
	 * Get the products shown for pools. This will be the same as `getShowingProducts` unless the selected product is an exotic
	 */
	getShowingPoolProducts = () => {
		const { selectedBetType } = this.props;
		const { selectedGoatProduct } = this.state;
		let exoticProduct;
		// If the bet type isn't win or place, it means that its an exotic. Find the bet product on that exotic. NOTE: we are making the assumption there is only one bet product for each exotic type. if we ever support more, then this is going to have to change...
		if (selectedBetType !== 'winplace' && !selectedGoatProduct) {
			exoticProduct =
				this.props.race.products &&
				this.props.race.products.find((product) => {
					const { bet_type } = product;
					return bet_type === selectedBetType;
				});
			if (exoticProduct) {
				return [exoticProduct];
			}
			return [];
		}

		return this.getShowingProducts();
	};

	handleChange(toteChecked) {
		this.setState({ toteChecked });
	}

	getShowingProducts = () => {
		const { selectedProduct, selectedBetType, winPlaceProducts, winPlaceProductChoices, size, race } = this.props;
		const { fixed_odds_enabled } = race;
		const { selectedGoatProduct, toteChecked } = this.state;
		const isMobileWidth = size.width < MOBILE_MAX_WIDTH + 120;
		const chosenProduct = winPlaceProductChoices.find((choice) => choice.betType === selectedProduct) || {};

		let newWinPlaceProducts = [...winPlaceProducts];

		if (selectedGoatProduct === RACING_BET_TYPE_MARGIN) {
			newWinPlaceProducts = this.filterMarginProducts(newWinPlaceProducts);
		} else {
			newWinPlaceProducts = newWinPlaceProducts.filter((product) => product.bet_type !== RACING_BET_TYPE_MARGIN);
		}

		if (selectedGoatProduct === GOAT_PRODUCT_TYPE_BOOST) {
			return this.filterBoostProducts(newWinPlaceProducts);
		} else {
			newWinPlaceProducts = newWinPlaceProducts.filter(
				(product) => product.product_type !== GOAT_PRODUCT_TYPE_BOOST && product.product_code !== GOAT_BET_TYPE_WIN
			);
		}

		if (!selectedBetType) {
			return [];
		}

		const totes = this.getTotes(race.totes_json, newWinPlaceProducts);

		switch (selectedBetType) {
			case 'winplace':
				return this.handleWinPlace(toteChecked, newWinPlaceProducts, totes, isMobileWidth);
			case RACING_BET_TYPE_EACHWAY:
				return this.handleEachWay(newWinPlaceProducts);
			case RACING_ODD_GRID_BET_TYPE:
				return this.handleOddGrid(newWinPlaceProducts, selectedGoatProduct, chosenProduct);
			case RACING_BET_TYPE_NOTTO_WIN_BET:
			case RACING_ISSURE_BET:
			case RACING_MORE_MARKETS_TYPE:
				return this.handleOtherBetTypes(newWinPlaceProducts, chosenProduct, 'cover');
			case RACING_BET_TYPE_TRAINERT_4_BET:
			case RACING_BET_TYPE_TRAINER_WIN_BET:
				return this.handleOtherBetTypes(newWinPlaceProducts, chosenProduct, selectedBetType);
			case RACING_SAME_RACE_MULTI_TYPE:
				return this.handleSameRaceMulti(newWinPlaceProducts);
			default:
				return this.handleExoticBetTypes(
					selectedBetType,
					newWinPlaceProducts,
					totes,
					isMobileWidth,
					chosenProduct,
					selectedGoatProduct
				);
		}
	};

	filterMarginProducts = (products) => {
		const marginProducts = products.filter((product) => product.bet_type === RACING_BET_TYPE_MARGIN);
		marginProducts.forEach((marginProduct) => {
			products = products.filter(
				(product) =>
					product.bet_type !== RACING_BET_TYPE_PLACE ||
					!(
						product.bet_type === RACING_BET_TYPE_PLACE &&
						product.product_code === marginProduct.product_code &&
						product.fixed === marginProduct.fixed
					)
			);
		});
		return products;
	};

	filterBoostProducts = (products) => {
		return products.filter(
			(product) =>
				product.product_type == GOAT_PRODUCT_TYPE_BOOST &&
				product.bet_type === RACING_BET_TYPE_WIN &&
				product.product_code === GOAT_BET_TYPE_WIN &&
				product.fixed
		);
	};

	getTotes = (totes_json, products) => {
		let totes = { win: 18, place: 18 };
		if (totes_json && totes_json.length !== 0) {
			const win_tote = products.find(
				(product) => product.product_id == totes_json[0].product_id && product.bet_type == RACING_BET_TYPE_WIN
			);
			if (win_tote) {
				totes.win = win_tote.product_id;
			}
			if (totes_json.length > 1) {
				const place_tote = products.find(
					(product) => product.product_id == totes_json[1].product_id && product.bet_type == RACING_BET_TYPE_PLACE
				);
				if (place_tote) {
					totes.place = place_tote.product_id;
				}
			}
		}
		return totes;
	};

	handleWinPlace = (toteChecked, products, totes, isMobileWidth) => {
		if (toteChecked) {
			products = this.filterToteCheckedProducts(products, totes);
		} else {
			const hasWinPlace = products.find((product) => product.product_id === 16 && product.fixed);

			if (!hasWinPlace) {
				products = products.filter((product) => product.product_code === RACING_BET_PRODUCT_CODE_SP);
			} else {
				products = products.filter(
					(product) =>
						product.product_id === 16 &&
						(product.bet_type === RACING_BET_TYPE_WIN || product.bet_type === RACING_BET_TYPE_PLACE)
					// || product.product_code === RACING_BET_PRODUCT_CODE_SP || product.product_code === RACING_BET_PRODUCT_CODE_TF,
				);
			}
		}
		products = this.sortWinPlaceProducts(products, totes);
		products = this.limitProductsForMobile(products, toteChecked, isMobileWidth);

		return products;
	};

	filterToteCheckedProducts = (products, totes) => {
		const hasWinPlace = products.find((product) => product.product_id === 16 && product.fixed);

		return products.filter(
			(product) =>
				(product.product_id == totes.win && product.bet_type == RACING_BET_TYPE_WIN) ||
				(product.product_id == totes.place && product.bet_type == RACING_BET_TYPE_PLACE) ||
				product.product_id == 16 ||
				product.product_code === RACING_BET_PRODUCT_CODE_TF ||
				(!hasWinPlace && product.product_code === RACING_BET_PRODUCT_CODE_SP)
		);
	};

	sortWinPlaceProducts = (products, totes) => {
		const typeOrder = { win: 1, place: 2 };
		return products.sort((a, b) => {
			if (a.product_code === RACING_BET_PRODUCT_CODE_SP) {
				return -1;
			}
			if (b.product_code === RACING_BET_PRODUCT_CODE_SP) {
				return 1;
			}
			if (a.product_id === 16 && b.product_id === 16) {
				return typeOrder[a.bet_type] - typeOrder[b.bet_type];
			}
			if (a.product_id === 16 || b.product_id === 16) {
				return a.product_id === 16 ? -1 : 1;
			}
			if (a.product_id === totes.place && b.product_id === totes.win) {
				return typeOrder[a.bet_type] - typeOrder[b.bet_type];
			}
			if (a.product_id === totes.win && b.product_id === totes.place) {
				return typeOrder[a.bet_type] - typeOrder[b.bet_type];
			}
			return 0;
		});
	};

	limitProductsForMobile = (products, toteChecked, isMobileWidth) => {
		if (toteChecked && isMobileWidth) {
			if (products.length > 2) {
				products = products.filter(
					(product) =>
						product.product_code !== RACING_BET_PRODUCT_CODE_TF && product.product_code !== RACING_BET_PRODUCT_CODE_SP
				);

				products = products.length > 2 ? products.slice(2, 4) : products;
			}
		}
		return products;
	};

	handleEachWay = (products) => {
		products = products.filter(
			(product) =>
				product.product_type !== GOAT_PRODUCT_TYPE_BOOST &&
				product.product_id === 16 &&
				product.bet_type === RACING_BET_TYPE_WIN
		);
		products[0] = {
			...products[0],
			product_name: 'Win-Place',
			product_code: 'EW',
			product_id: -1,
			bet_type: RACING_BET_TYPE_EACHWAY,
			product_type: RACING_BET_TYPE_EACHWAY
		};
		return products;
	};

	handleOddGrid = (products, selectedGoatProduct, chosenProduct) => {
		products = products.filter((product) => {
			if (selectedGoatProduct === RACING_BET_TYPE_MARGIN && chosenProduct.betType === RACING_BET_TYPE_WIN) {
				return product.bet_type === RACING_BET_TYPE_MARGIN || product.bet_type !== chosenProduct.betType;
			}
			return product.bet_type === chosenProduct.betType || product.bet_type === 'oddsgrid';
		});
		return products.sort((a, b) => (a.product_code.toLowerCase() < b.product_code.toLowerCase() ? 1 : -1));
	};

	handleOtherBetTypes = (products, chosenProduct, betType) => {
		return products.filter((product) => product.bet_type === chosenProduct.betType || product.bet_type === betType);
	};

	handleSameRaceMulti = (products) => {
		products = products.filter((product) => Object.keys(SRMTypes).includes(product.product_code));
		const types = ['SRMWin', 'SRMTop2', 'SRMTop3', 'SRMTop4'];
		return types.map((type) => products.find((product) => product.product_code === type)).filter(Boolean);
	};

	handleExoticBetTypes = (selectedBetType, products, totes, isMobileWidth, chosenProduct, selectedGoatProduct) => {
		if (selectedBetType !== RACING_NON_EXOTIC_BET_TYPE) {
			let winPrice = products.find((product) => product.bet_type === RACING_BET_TYPE_WIN && product.product_id === 16);
			if (!winPrice) {
				winPrice = products.find(
					(product) => product.product_id == totes.win && product.bet_type == RACING_BET_TYPE_WIN
				);
			}
			return winPrice ? [winPrice] : [];
		}
		products.sort(this.sortProducts);
		if (isMobileWidth && chosenProduct.betType) {
			products = products.filter((product) => {
				if (selectedGoatProduct === RACING_BET_TYPE_MARGIN && chosenProduct.betType === RACING_BET_TYPE_WIN) {
					return product.bet_type === RACING_BET_TYPE_MARGIN || product.bet_type === chosenProduct.betType;
				}
				return product.bet_type === chosenProduct.betType;
			});
		}
		return products;
	};

	/**
	 * get old win prices with selections
	 * @returns
	 */
	getSelectionsOldWinFixedPriceWhenPriceBump = (selections) => {
		let newSelections = [];
		selections.map((sel, index) => {
			newSelections.push({
				index,
				id: sel.id,
				name: sel.name,
				selection_status: sel.selection_status,
				prices: sel.prices.length > 0 && sel.prices.find((price) => price.product_id === 16) // product_code: "GRSF"
			});
		});
		return newSelections;
	};

	/**
	 * Change the tab when clicking on a goat product
	 */
	onSelectGoatProduct = (data) => {
		this.setState((prevState) => {
			if (data === GOAT_PRODUCT_TYPE_BOOST && prevState.selectedGoatProduct !== GOAT_PRODUCT_TYPE_BOOST) {
				this.props.trackGaGoatType('GOAT Price Bump');
			} else if (data === RACING_BET_TYPE_MARGIN && prevState.selectedGoatProduct !== RACING_BET_TYPE_MARGIN) {
				this.props.trackGaGoatType('GOAT Margin Butt');
			}

			const runningSelections = getNotScratchedSelections(this.props.race.selections);
			return {
				selectedGoatProduct: data === prevState.selectedGoatProduct ? null : data,
				selectWinFixedOldPrice:
					data === prevState.selectedGoatProduct
						? null
						: this.getSelectionsOldWinFixedPriceWhenPriceBump(runningSelections)
			};
		});
	};

	/**
	 * Change the selected bet type and reset the selected goat product
	 *
	 * @param selectedBetType
	 * @param boxed
	 * @param selectedProduct
	 */
	handleBetTypeChange = (selectedBetType, boxed, selectedProduct) => {
		this.onSelectGoatProduct(null);
		this.props.onBetTypeChange(selectedBetType, boxed, selectedProduct);
	};

	/**
	 * Toggles the 'My Bets' section
	 */
	toggleShowMyBets = () => {
		this.setState({
			showMyBets: !this.state.showMyBets
		});
	};

	formatSettlingPositions = (selections) => {
		let settlingSelections = [];

		// Split settling_speed_positions into row and position and add it to the selection
		selections.map((selection) => {
			if (!selection.settling_speed_positions || !selection.settling_speed_positions.length) {
				return selection;
			}

			const settlingPositions = selection.settling_speed_positions;

			// Add a selection in the new array for each settling position.
			// That will create clones for selections that have multiple positions so they can be easily rendered.
			return settlingPositions.map((settlingPosition) => {
				settlingSelections.push({
					...selection,
					row: settlingPosition.substr(settlingPosition.length - 1),
					position: settlingPosition.substring(0, settlingPosition.length - 1)
				});
			});
		});

		return settlingSelections;
	};

	changeResultTab = (product) => {
		this.setState({
			activeResultTab: product
		});
	};

	/**
	 * Group by relavent field
	 * @param array
	 */
	groupBy = (data, key) => {
		return data.reduce(function(acc, item) {
			(acc[item[key]] = acc[item[key]] || []).push(item);
			return acc;
		}, {});
	};

	/**
	 * get all types
	 * @param array searchArray
	 */
	getTipsTypes = (searchArray) => {
		const { race } = this.props;
		const computerTipsGroup = this.groupBy(searchArray, 'tips_type');
		//  console.log(computerTipsGroup);
		const tips = Object.keys(computerTipsGroup).map((group, key) => {
			return group;
		});
		return tips;
	};

	/**
	 * selections array with computer tips
	 * @param array selections
	 */
	getSelectionsComputerTips = (selections) => {
		const tipSelections = [];
		const race_rankingArray = [];

		const selection = selections.map((sel) => {
			if (sel.race_ranking !== null) {
				Object.entries(sel.race_ranking).forEach(([key, value]) => {
					return race_rankingArray.push({
						id: sel.id,
						name: sel.name,
						tab: sel.number,
						tips_type: key,
						points: Number(value),
						barrier: sel.barrier,
						silk: sel.silk,
						selection_status: sel.selection_status,
						prices: [sel.prices.find((price) => price.product_id === 16)] // product_code: "GRSF"
					});
				});
			}
		});
		if (selection) {
			tipSelections.push(race_rankingArray);
		}

		return tipSelections;
	};

	/**
	 * Find the races product given the name. If no name is supplied, then it should try and pick the first product that has a result
	 */

	findRaceProductByName = (name) => {
		const { race } = this.props;
		if (!name) {
			return this.getFirstProductWithResult(this.props.race);
		}
		return race.products.find((product) => product.product_name === name);
	};

	// Flucs | Tote | HotStack
	handleSwitch = (name, state) => {
		switch (name) {
			case 'Flucs':
				this.setState({
					flucsCheck: state
				});
				break;
			case 'Tote':
				this.handleChange(state);
				break;
			case 'HotStats':
				this.handleHotStatsToggle(state);
				break;
			default:
				break;
		}
	};

	displayTote() {
		const { winPlaceProducts } = this.props;
		if (winPlaceProducts.length == 0) {
			return false;
		}
		let products = winPlaceProducts.filter(
			(product) => product.bet_type === RACING_BET_TYPE_WIN || product.bet_type === RACING_BET_TYPE_PLACE
		);
		const totes_json = this.props.race.totes_json;

		let totes = {
			win: 18,
			place: 18
		};

		if (totes_json && totes_json.length != 0) {
			const win_tote = winPlaceProducts.find(
				(product) => product.product_id == totes_json[0].product_id && product.bet_type == RACING_BET_TYPE_WIN
			);
			if (win_tote) {
				totes.win = win_tote.product_id;
			}
			if (totes_json.length > 1) {
				const place_tote = winPlaceProducts.find(
					(product) => product.product_id == totes_json[1].product_id && product.bet_type == RACING_BET_TYPE_PLACE
				);
				if (place_tote) {
					totes.place = place_tote.product_id;
				}
			}
		}

		products = products.filter((product) => {
			return product.product_id == totes.win || product.product_id == totes.place;
		});
		return products.length != 0;
	}

	render() {
		const {
			t,
			race,
			user,
			includePusher,
			loadingRace,
			bets,
			selectedProduct,
			selectedBetType,
			boxed,
			winPlaceProductChoices,
			fluctuationsKey,
			disableExotics,
			disableDerivatives,
			disableSpeedmaps,
			displayedResults,
			displayedExoticResults,
			shouldRenderFormsButton,
			trackGaRunnerForm,
			onClickSingle,
			onClickMulti,
			onClickExotic,
			clearSelections,
			handleClearSelections,
			onDerivativeSelectionClick,
			buildSelectionBetButtons,
			getGoatMarginButtLength,
			winPlaceProducts,
			className,
			size,
			ShowBetProduct,
			meetingCountry,
			raceDistance,
			handlecacheOut,
			cashout_limit,
			betBuilderMeeting,
			onGoToBetBulder,
			trackComputerTipsType,
			handleAddToBackBook,
			backBookResponse,
			dailyBailOut,
			bailOutIsEnabled,
			handleAddSameRaceMulti
		} = this.props;
		// selectionsSameRaceMulti
		const {
			allFormOpen,
			selectedGoatProduct,
			selectWinFixedOldPrice,
			informatics,
			activeResultTab,
			hotStatsOpen
		} = this.state;
		const displayedBetProducts = this.getShowingProducts();
		const displayedPoolProducts = this.getShowingPoolProducts();
		const isMobileWidth = size.width < MOBILE_MAX_WIDTH + 120;
		const componentClasses = cx({
			[className]: className
		});

		const activeResultProduct = this.findRaceProductByName(activeResultTab);

		const isBettingAvailable = this.isBettingAvailable(race);

		const goatLength = selectedGoatProduct === RACING_BET_TYPE_MARGIN ? getGoatMarginButtLength(race) : null;
		const isDerivativesAvailable =
			!disableDerivatives && race.derivatives_enabled && race.derivative_markets && race.derivative_markets.length > 0;

		const runningSelections = getNotScratchedSelections(race.selections, false);
		const tipSelections = getSelectionsFromTips(runningSelections, race.tips || []);

		const displaySpeedmaps =
			!disableSpeedmaps &&
			(NewRaceCard.isEarlySpeedAvailable(runningSelections) ||
				NewRaceCard.isSettlingPositionAvailable(runningSelections));

		// We dont want to use the TabsGroup for mobile

		const winPool =
			// Show only the Pool Total when GT is provided
			!race.global_tote_available ||
			selectedBetType !== RACING_NON_EXOTIC_BET_TYPE ||
			// On mobile, show only when tote products are showing
			(isMobileWidth && selectedProduct !== RACING_BET_TYPE_WIN)
				? null
				: race.win_pool_total / 100;

		const placePool =
			!race.global_tote_available ||
			selectedBetType !== RACING_NON_EXOTIC_BET_TYPE ||
			(isMobileWidth && selectedProduct !== RACING_BET_TYPE_PLACE)
				? null
				: race.place_pool_total / 100;

		const handleNavigation = (raceId, race_id) => {
			/* Prevent navigation if already on race page */
			if (raceId === race_id) {
				return null;
			}

			return this.props.navigateToLeg(raceId);
		};

		const awating_result = () => {
			if (race.status === RACE_CLOSED_STATUS || race.status === RACE_FINISHED_STATUS) {
				return true;
			}

			return false;
		};
		const unscratched_selections =
			race.selections &&
			race.selections.filter((selections) => selections.selection_status === SELECTION_NOT_SCRATCHED_STATUS);
		const isExotic = RACING_EXOTIC_BET_TYPES.includes(selectedBetType);
		const raceType = race.type;
		const products = race.products ? race.products : [];

		return (
			<React.Fragment>
				<RCS.StyledOptions>
					<RCS.StyledOption active={allFormOpen} onClick={() => this.handleAllFormToggle()}>
						{allFormOpen ? `${t('RaceCard__Form')}` : t('RaceCard__Form')}
					</RCS.StyledOption>
					{raceType === 'T' && selectedBetType !== RACING_ODD_GRID_BET_TYPE && (
						<RCS.StyledOption active={hotStatsOpen} onClick={() => this.handleSwitch('HotStats', !hotStatsOpen)}>
							Hot Stats
						</RCS.StyledOption>
					)}
					{selectedBetType !== RACING_ODD_GRID_BET_TYPE && (
						<RCS.StyledOption
							active={this.state.flucsCheck}
							onClick={() => this.handleSwitch('Flucs', !this.state.flucsCheck)}
						>
							Flucs
						</RCS.StyledOption>
					)}
					{selectedBetType === RACING_NON_EXOTIC_BET_TYPE && this.displayTote() && (
						<RCS.StyledOption
							active={this.state.toteChecked}
							onClick={() => this.handleChange(!this.state.toteChecked)}
						>
							Tote
						</RCS.StyledOption>
					)}
				</RCS.StyledOptions>
				<StyledRaceCard className={componentClasses}>
					<PusherSubscriberRaceChannel raceId={race.id} />
					<PusherSubscriberMeetingChannel meetingId={this.props.meetingId} />
					{/* //  add new loading spinner by @HW 26Sep2019	 */}
					<LoadingSpanner style={{ display: loadingRace ? 'block' : 'none' }} />
					{/* {loadingRace && <LoadingMask loading={loadingRace} displayAtTop={true} />} */}
					{bets.length > 0 && (
						<RCS.StyledRaceCard__MyBetsListHeader
							toggleCollapsibleContent={this.toggleShowMyBets}
							isCollapsed={!this.state.showMyBets}
							label={`${
								this.state.showMyBets
									? t('RaceCard__HideMyBets').toUpperCase()
									: t('RaceCard__ShowMyBets').toUpperCase()
							} ( ${bets.length} )`}
							type="secondary"
							headerBarOptions={{ nodeLeft: <MyBetsIcon size="1" push="1" /> }}
						>
							<RCS.StyledRaceCard__MyBetsList
								activeBets={bets}
								raceStatus={race.status}
								handlecacheOut={handlecacheOut}
								cashout_limit={cashout_limit}
								dailyBailOutLimit={dailyBailOut}
								bailOutIsEnabled={bailOutIsEnabled}
							/>
						</RCS.StyledRaceCard__MyBetsListHeader>
					)}

					<RCS.StyledRaceCard__Results size={size}>
						{activeResultProduct && displayedResults[activeResultProduct.product_id] && (
							<RCS.StyledRaceCard__ResultsWinPlace
								key={activeResultProduct.id}
								size={size}
								results={displayedResults[activeResultProduct.product_id]}
							/>
						)}
						{activeResultProduct && !disableExotics && displayedExoticResults[activeResultProduct.product_id] && (
							<RCS.StyledRaceCard__ResultsExotic
								size={size}
								results={displayedExoticResults[activeResultProduct.product_id]}
							/>
						)}
					</RCS.StyledRaceCard__Results>

					{awating_result() ? (
						<StyledRaceResultsTitle>
							<Icon icon={RACING_TYPES_LOOKUP[race.type]} size="3" push="1" />
							<Text size="1">{t("And they're off. Awaiting race results")}</Text>
						</StyledRaceResultsTitle>
					) : null}

					{allFormOpen && race.comment && (
						<Panel>
							{tipSelections.length > 0 && (
								<RaceSelectionTips
									size={size}
									handleClickSingle={onClickSingle}
									handleClickMulti={onClickMulti}
									selections={tipSelections}
									raceProducts={products}
									isBettingAvailable={isBettingAvailable}
									buildSelectionBetButtons={buildSelectionBetButtons}
								/>
							)}
						</Panel>
					)}

					{isBettingAvailable && (
						<RCS.RaceCardMenu>
							{QUADDIES_BET_TYPES.includes(selectedBetType) && this.props.selectedQuaddiesRaces.length > 0 ? (
								<RCS.LegButtonGroup>
									{this.props.selectedQuaddiesRaces.map((raceId, index) => (
										<RCS.StyledLegButton
											key={raceId}
											onClick={() => handleNavigation(raceId, this.props.race.id)}
											active={raceId === this.props.race.id}
											verified={
												this.props.quaddiesLegsSelections[raceId] &&
												this.props.quaddiesLegsSelections[raceId].length > 0
											}
										>
											{t('Leg')} {index + 1}
										</RCS.StyledLegButton>
									))}
								</RCS.LegButtonGroup>
							) : (
								<RCS.LegButtonGroup>
									{buildRaceLegSequence(
										this.props.races,
										this.props.race.id,
										SelectQuaddieType(selectedBetType, this.props.race),
										products.find((p) => p.bet_type == selectedBetType)
									).map((raceId, index) => (
										<RCS.StyledLegButton
											key={raceId}
											onClick={() => handleNavigation(raceId, this.props.race.id)}
											active={raceId === this.props.race.id}
											verified={
												this.props.quaddiesLegsSelections[index] && this.props.quaddiesLegsSelections[index].length > 0
											}
										>
											{t('Leg')} {index + 1}
										</RCS.StyledLegButton>
									))}
								</RCS.LegButtonGroup>
							)}
						</RCS.RaceCardMenu>
					)}

					<RaceSelectionsList
						onMenuClick={this.props.onMenuClick}
						handleBetTypeChange={this.handleBetTypeChange}
						isBoxedSelected={boxed}
						activeProduct={selectedProduct}
						handleHotStatsToggle={this.handleHotStatsToggle}
						handleAllFormToggle={this.handleAllFormToggle}
						shouldRenderFormsButton={shouldRenderFormsButton || displaySpeedmaps}
						handleAddSameRaceMultiBetSlip={handleAddSameRaceMulti}
						currentSameRaceMultiPrice={this.props.currentSameRaceMultiPrice}
						selectionsSameRaceMulti={this.props.selectionsSameRaceMulti}
						handleSameRaceMultiSelections={this.props.handleSameRaceMultiSelections}
						toteChanged={this.handleChange}
						toteChecked={this.state.toteChecked}
						size={size}
						clickSingle={onClickSingle}
						clickMulti={onClickMulti}
						clickExotic={onClickExotic}
						selections={race.selections}
						hotSelection={race.hot_selection && race.hot_selection.length > 0 ? race.hot_selection[0].id : null} // hot_selection
						displayedBetProducts={displayedBetProducts}
						displayedPoolProducts={displayedPoolProducts}
						// Some work was done on this concern on tracking issue's branch.
						allFormOpen={allFormOpen}
						// Bet type filtering, checkboxes control
						raceId={race.id}
						raceNumber={race.number}
						raceName={race.name}
						raceType={race.type}
						displayedCheckboxes={this.getCheckboxes()}
						clearSelections={clearSelections}
						handleClearSelections={handleClearSelections}
						betType={selectedBetType}
						boxed={boxed}
						// Betting availability based of Race status
						bettingAvailable={isBettingAvailable}
						// Decides whether and which fluctuations should be rendered. Null for none
						displayedFlucs={fluctuationsKey}
						trackOpenRunnerForm={trackGaRunnerForm}
						// Betting availability based of Race status
						goatLength={goatLength}
						isMobileWidth={isMobileWidth}
						buildSelectionBetButtons={buildSelectionBetButtons}
						winPool={winPool}
						placePool={placePool}
						hotStatsOpen={hotStatsOpen}
						ShowBetProduct={ShowBetProduct}
						Type={race.type}
						StartDate={race.start_date}
						MettingName={race.meeting_name}
						Test={race.selections}
						speedmapOpen={informatics.speedmapsOpen}
						meetingCountry={meetingCountry}
						raceDistance={raceDistance}
						user={user}
						enabled_cache_out={race.cash_out_enabled}
						handleAddToBackBook={handleAddToBackBook}
						backBookResponse={backBookResponse}
						selectedGoatProduct={selectedGoatProduct}
						showSelectWinFixedOldPrice={selectWinFixedOldPrice} // show old price when click price bump
						handleQuaddieSelections={this.props.handleQuaddieSelections}
						quaddiesLegsSelections={this.props.quaddiesLegsSelections}
						placeQuaddieBet={this.props.placeQuaddieBet}
						exoticLeg={this.props.exoticLeg}
						cleanQuaddies={this.props.cleanQuaddies}
						flucsCheck={this.state.flucsCheck}
					/>

					{/* <RaceCardCombinationTable /> */}
					{isDerivativesAvailable ? (
						<RCS.StyledRaceCard__DerivativeSelectionsList
							raceId={race.id}
							handleDerivativeSelectionClick={onDerivativeSelectionClick}
							derivativeMarkets={race.derivative_markets}
							bettingAvailable={isBettingAvailable}
							size={size}
						/>
					) : null}

					<RaceProductLegend products={race.products} hideExotics={disableExotics} />
				</StyledRaceCard>
				{unscratched_selections && unscratched_selections.length >= 5 && unscratched_selections.length <= 7 && (
					<div style={{ display: 'flex', justifyContent: 'flex-end', padding: '4px', width: '100%' }}>
						<Text size="-2" align="end" type="light" style={{ textDecoration: 'underline' }}>
							2 Place Dividends
						</Text>
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default withNamespaces()(NewRaceCard);
