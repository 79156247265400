import React, { useState, useEffect } from 'react';
import styled, { css } from 'react-emotion';
import { brand, typography } from '@tbh/ui-kit';
import { StyledPageComponentContainer } from '../../components/features/Application/PageComponentContainer/PageComponentContainer.styled';
import { FAMILYS, MEDIA_QUERIES, DEFAULT_COLORS, PROMOTION_COLOR } from '../../constants/themes';
import PromotionsList, { LoadingPromotionsList } from '../../components/features/PromotionsList/PromotionsList';
import axios from 'axios';
import { connect, useDispatch, useSelector } from 'react-redux';
import { fetchPromotions } from '../../store/promotionsPage/promotionsPageActions';
import { navigateToRacingHome } from '../../store/GRSracingHome/GRSracingHomeActions';
import { serverDateTime } from '../../legacy/core/format';
import { SizeMe } from 'react-sizeme';

const PromotionCard = styled('div')`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const PromotionCardHeader = styled('div')(
	(props) => css`
		background-color: ${brand(props).tertiary_color ? brand(props).tertiary_color : DEFAULT_COLORS.df_tertiary_color};
		color: #ffffff;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		padding: 12px 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	`
);

const PromotionCardHeaderLeft = styled('div')`
	display: block;
`;

const PromotionCardTitle = styled('h4')(
	(props) => css`
		color: #ffffff;
		font-weight: 600;
		font-size: 24px;
		text-transform: uppercase;
		margin-bottom: 4px;
		font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
	`
);

const PromotionCardBody = styled('div')`
	border: 1px solid #f0eeed;
	padding: 12px 24px;
	background-color: white;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
`;

const PromotionButton = styled('button')(
	(props) => css`
		background: none;
		color: #111111;
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		text-decoration: none;
		border: 0;
		padding: 0.375rem 0.75rem;
		font-weight: 500;
		font-size: 12px;
		line-height: 1.5;
		font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
		cursor: pointer;

		@media ${MEDIA_QUERIES.laptopAndUp} {
			font-size: 15px;
		}
	`
);

const PromotionButtonPrimary = styled(PromotionButton)`
	color: ${(props) =>
		props.isActive ? PROMOTION_COLOR.hover_pro_button_font_color : PROMOTION_COLOR.pro_button_font_color};
	background-color: ${(props) =>
		props.isActive ? PROMOTION_COLOR.pro_button_bg_color : PROMOTION_COLOR.hover_pro_button_bg_color};
	border-radius: 3px;
	transition: background-color 0.2s linear, color 0.2s linear;

	&:hover {
		background-color: ${PROMOTION_COLOR.pro_button_bg_color};
		color: #ffffff;
	}

	&:focus {
		background-color: ${PROMOTION_COLOR.pro_button_bg_color};
		color: #ffffff;
		outline: none;
		box-shadow: none;
	}

	&:active {
		background-color: ${PROMOTION_COLOR.pro_button_bg_color};
		color: #ffffff;
		outline: none;
		box-shadow: none;
	}

	&:not(:last-of-type) {
		margin-right: 4px;
	}
`;

const Promotions = ({ SportsIsEnabled }) => {
	const dispatch = useDispatch();
	const { promotions, isLoading, error } = useSelector((state) => state.promotionsPage);
	const [activeCategory, setActiveCategory] = useState('all');
	console.log('promotions', promotions);
	useEffect(() => {
		fetchPromotionsData(activeCategory);
	}, [activeCategory, dispatch]);

	const fetchPromotionsData = (type) => {
		dispatch(fetchPromotions(type));
	};

	const navigateToTodaysRacing = () => {
		dispatch(navigateToRacingHome(serverDateTime(moment())));
	};

	const handleCategoryClick = (type) => {
		setActiveCategory(type);
		fetchPromotionsData(type);
	};

	return (
		<SizeMe>
			{({ size }) => (
				<StyledPageComponentContainer>
					<PromotionCard>
						<PromotionCardHeader>
							<PromotionCardHeaderLeft>
								<PromotionCardTitle>Promotions</PromotionCardTitle>
								<PromotionButtonPrimary isActive={activeCategory === 'all'} onClick={() => handleCategoryClick('all')}>
									View All
								</PromotionButtonPrimary>
								<PromotionButtonPrimary
									isActive={activeCategory === 'racing'}
									onClick={() => handleCategoryClick('racing')}
								>
									Racing
								</PromotionButtonPrimary>
								{SportsIsEnabled && (
									<PromotionButtonPrimary
										isActive={activeCategory === 'sport'}
										onClick={() => handleCategoryClick('sport')}
									>
										Sports
									</PromotionButtonPrimary>
								)}
								<PromotionButtonPrimary
									isActive={activeCategory === 'features'}
									onClick={() => handleCategoryClick('features')}
								>
									Features
								</PromotionButtonPrimary>
							</PromotionCardHeaderLeft>
						</PromotionCardHeader>
						<PromotionCardBody>
							{isLoading && <LoadingPromotionsList />}

							{error && (
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Error: {error}</div>
							)}

							{!isLoading && !error && (
								<PromotionsList promotions={promotions} navigateToTodaysRacing={navigateToTodaysRacing} size={size} />
							)}
						</PromotionCardBody>
					</PromotionCard>
				</StyledPageComponentContainer>
			)}
		</SizeMe>
	);
};

const mapStateToProps = (state) => ({
	SportsIsEnabled: state.featureToggles.features.Sports ? state.featureToggles.features.Sports.enabled : false
});

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Promotions);
// state.featureToggles.features.Sports ? state.featureToggles.features.Sports.enabled : false
