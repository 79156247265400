// Libraries
import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';
// Components
import { ButtonGroup } from '@tbh/ui-kit';
import { StyledMenuButtonGroup } from './TopNavigation.styled';
import { connect } from 'react-redux';
import { navigateToRacingHome } from '../../../../store/GRSracingHome/GRSracingHomeActions';
import { routeTo } from '../../../../store/application/applicationActions';
import { BETBUILDER_BASE_URL } from '../../../../pages/BetBuilder/BetBuilderConstants';
import { RS_TIPS_BASE_URL, RS_TIPS_SUB_APP } from '../../../../pages/RSTips/RSTipsConstants';
import { PROMOTIONS_BASE_URL, PROMOTIONS_SUB_APP } from '../../../../pages/Promotions/PromotionsPageConstants';
import { TertiaryButton } from '../../../UI/Buttons';
import { serverDateTime } from '../../../../legacy/core/format';
import moment from 'moment';
import RacingIcon from '../../Racing/RacingIcon/RacingIcon';
import { MASTHEAD_COLORS } from '../../../../constants/themes';

const MenuIconWrapper = styled.img`
	width: 20px;
	height: 20px;
	object-fit: contain;
`;

class TopNavigationMasthead extends Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		size: PropTypes.shape({
			width: PropTypes.number
		}).isRequired,
		className: PropTypes.string,
		nextNtjRaceMeetingId: PropTypes.number,
		sports: PropTypes.object,
		meetingType: PropTypes.string,
		handleBetBulder: PropTypes.func,
		handleFeature: PropTypes.func,
		handleSports: PropTypes.func,
		handleRacing: PropTypes.func,
		handlePromotions: PropTypes.func,
		handleRSTips: PropTypes.func,
		SportsIsEnabled: PropTypes.bool
	};

	static defaultProps = {
		className: '',
		nextNtjRaceMeetingId: null,
		meetingType: undefined,
		handleFeature: () => undefined,
		handleBetBulder: () => undefined,
		handleSports: () => undefined,
		handleRacing: () => undefined,
		handlePromotions: () => undefined,
		handleRSTips: () => undefined,
		sports: {}
	};

	render() {
		const {
			size,
			className,
			disabledButtons,
			handleRacing,
			handleBetBulder,
			handleFeature,
			handleSports,
			handlePromotions,
			handleRSTips,
			SportsIsEnabled
		} = this.props;

		const componentClasses = cx({
			[className]: className
		});

		const cssStyledTopNavigation__ButtonExpanded =
			size.width < 500
				? css`
						display: none;
						text-transform: uppercase;
				  `
				: css`
						display: block;
						text-transform: uppercase;
				  `;

		const TopMenuButtons = {
			RacingNewPage: {
				action: handleRacing,
				title: "Today's Racing",
				icon: (
					<RacingIcon
						color={MASTHEAD_COLORS.racing_icon_color}
						classname={css`
							position: relative;
							top: 6px;
							margin-top: -12px;
						`}
					/>
				)
			},
			Promotions: {
				action: handlePromotions,
				title: 'Promotions',
				icon: <i className="fa fa-tag fa-2x" aria-hidden="true" />
			},
			RSTips: {
				action: handleRSTips,
				title: 'Tips',
				icon: (
					<MenuIconWrapper
						src={'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/rns-logo.png'}
						alt=""
					/>
				)
			},
			Sports: {
				action: handleSports,
				title: 'Sports',
				isComingSoon: false,
				icon: <i className="fa fa-futbol-o fa-2x" aria-hidden="true" />
			}
		};

		const listTopMenuButtons = Object.keys(TopMenuButtons)
			.filter((key) => {
				if (key === 'Sports' && !SportsIsEnabled) {
					return false;
				}
				return true;
			})
			.map((key) => {
				return {
					...TopMenuButtons[key],
					type: key
				};
			});

		return (
			<StyledMenuButtonGroup className={componentClasses} left spacing={ButtonGroup.spacings.SPACING_TIGHT} size={size}>
				{listTopMenuButtons.map((menuItem) => (
					<TertiaryButton
						style={{ padding: 0, width: 120, marginLeft: 'auto' }}
						key={menuItem.type}
						disable={disabledButtons.includes(menuItem.type)}
						size="small"
						mediaSize={size}
						onClick={menuItem.action}
						active={menuItem.type == this.props.selectedPageKey}
					>
						{menuItem.icon}
						<div
							className={cssStyledTopNavigation__ButtonExpanded}
							style={{
								marginTop: '4px'
							}}
						>
							{menuItem.title}
						</div>
						{menuItem.isComingSoon && (
							<span
								style={{
									marginLeft: 4,
									fontSize: '9px'
								}}
							>
								(Coming Soon)
							</span>
						)}
					</TertiaryButton>
				))}
			</StyledMenuButtonGroup>
		);
	}
}

const mapStateToProps = (state) => {
	const selectedPage = state.application.selectedPage;

	const disabledButtons = ['FeatureRacing', 'Sports', 'Promotions', 'RSTips'];
	return {
		sports: state.entities.sports,
		disabledButtons: disabledButtons,
		selectedPageKey: selectedPage,
		SportsIsEnabled: state.featureToggles.features.Sports ? state.featureToggles.features.Sports.enabled : false
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		//fetchSports: () => dispatch(fetchAllSports()),
		handleRacing: () => {
			dispatch(navigateToRacingHome(serverDateTime(moment())));
		},
		handleBetBulder: () => {
			dispatch(routeTo(BETBUILDER_BASE_URL));
		},
		handleRSTips: () => {
			dispatch(routeTo(RS_TIPS_BASE_URL));
			App.startSubApp(RS_TIPS_SUB_APP);
		},
		handlePromotions: () => {
			dispatch(routeTo(PROMOTIONS_BASE_URL));
			App.startSubApp(PROMOTIONS_SUB_APP);
		}
	};
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(TopNavigationMasthead));
