// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames/bind';

// Components
import DepositTypeFilter from '../../components/features/UserAccount/DepositTypeFilter/DepositTypeFilter';
import ModalHeader from '../../components/features/Application/ModalHeader/ModalHeader';
import ModalFooter from '../../components/features/Application/ModalFooter/ModalFooter';
import BrandContactPhone from '../../components/ui-components/BrandContactPhone/BrandContactPhone';
import DepositContainer from './DepositContainer/DepositContainer';
import BankEFTDepositContainer from './BankEFTDepositContainer';
import BPayDepositContainer from './BPayDepositContainer';
import PoliDepositContainer from './PoliDepositContainer';

// Functions/Constants
import {
	DEPOSIT_TYPE_BANK,
	DEPOSIT_TYPE_BPAY,
	DEPOSIT_TYPE_CARD,
	DEPOSIT_TYPE_POLI,
	DEPOSIT_TYPES_NAMES,
} from './DepositConstants';
import { DEPOSIT_DEFAULT, DEPOSIT_USER_ACCOUNT } from '../../store/deposits/depositsReducerNames';
import { trackGaEvent } from '../../store/trackingPixels/trackingActions';
import { closeModal } from '../../store/modal/modalActions';
import { routeTo } from '../../store/application/applicationActions';
import LastStep from '../../components/features/Authentication/Registration/LastStep';
class DepositsPromptContainer extends Component {
	static propTypes = {
		/** Func to track tab changes **/
		track: PropTypes.func.isRequired,

		/** Extra classes */
		className: PropTypes.string,

		/** Callback when the modal is closed */
		closeModal: PropTypes.func,

		/** Flag to control tabs state **/
		disableTabs: PropTypes.bool,
		BPayIsEnabled: PropTypes.bool,
		isBankDepositEnabled: PropTypes.bool,

		/** If the container is going to be rendered in a page (main content) or not */
		renderOnPage: PropTypes.bool,

		/** If the receipt is being displayed or not **/
		showingReceipt: PropTypes.bool,

		/** If only the credit card verification should be displayed or not */
		verifyCreditCardsOnly: PropTypes.bool,
	};

	static defaultProps = {
		className: '',
		closeModal: undefined,
		disableTabs: false,
		BPayIsEnabled: false,
		isBankDepositEnabled: false,
		renderOnPage: false,
		showingReceipt: false,
		verifyCreditCardsOnly: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			// Holds current selected tab
			selectedType: DEPOSIT_TYPE_CARD,
			showingReceipt: props.showingReceipt,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.showingReceipt !== prevState.showingReceipt) {
			return {
				showingReceipt: nextProps.showingReceipt,
			};
		}

		return null;
	}

	/**
	 * Change tab and track event.
	 * @param type
	 */
	handleTypeChange = (type) => {
		this.props.track(type);
		this.setState({
			selectedType: type,
		});
	};

	// Containers to be selected and presented in render method.
	renderDepositContainer = () => {
		const { closeModal, toWelcome, renderOnPage, verifyCreditCardsOnly } = this.props;

		if (verifyCreditCardsOnly) {
			return <DepositContainer trackingCategory="Repeat Deposit" verifyCreditCardsOnly />;
		}

		switch (this.state.selectedType) {
			case DEPOSIT_TYPE_CARD:
				return (
					<LastStep
					trackingCategory="Repeat Deposit"
					onClose={renderOnPage ? toWelcome : closeModal}
					renderOnPage={renderOnPage}
				/>
				);
			case DEPOSIT_TYPE_POLI:
				return <PoliDepositContainer onCancel={renderOnPage ? toWelcome : closeModal} renderOnPage={renderOnPage} />;
			case DEPOSIT_TYPE_BPAY:
				return <BPayDepositContainer onClose={renderOnPage ? toWelcome : closeModal} renderOnPage={renderOnPage} />;
			case DEPOSIT_TYPE_BANK:
				return <BankEFTDepositContainer onButtonClick={renderOnPage ? toWelcome : closeModal} renderOnPage={renderOnPage} />;
		}
	};

	render() {
		const { className, closeModal, disableTabs,BPayIsEnabled,isBankDepositEnabled, renderOnPage, verifyCreditCardsOnly } = this.props;
		const { selectedType, showingReceipt } = this.state;

		const containerClasses = cx({
			[className]: className,
		});

		return (
			<div className={containerClasses}>
				{!renderOnPage && (
					<ModalHeader
						title={showingReceipt ? 'DepositReceipt' : 'MakeDeposit'}
						onClose={closeModal}
						aside={<BrandContactPhone />}
					/>
				)}

				{!showingReceipt && !verifyCreditCardsOnly ? (
					<DepositTypeFilter
						action={this.handleTypeChange}
						depositType={selectedType}
						buttonSize="medium"
						disableTabs={disableTabs}
						BPayIsEnabled={BPayIsEnabled}
						isBankDepositEnabled={isBankDepositEnabled}
					/>
				) : null}

				{this.renderDepositContainer()}

				{!renderOnPage && <ModalFooter />}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const sliceOfState = ownProps.renderOnPage ? state[DEPOSIT_USER_ACCOUNT] : state[DEPOSIT_DEFAULT];

	const { depositLoading, verificationLoading, verificationAttemptsLeft } = sliceOfState;
	// Disable tabs when waiting for promises
	return {
		disableTabs: depositLoading || verificationLoading || verificationAttemptsLeft === 0,
		showingReceipt: sliceOfState.lastDepositId && !sliceOfState.depositLoading,
		BPayIsEnabled: state.featureToggles.features.BPayDeposit ? state.featureToggles.features.BPayDeposit.enabled : false,
		isBankDepositEnabled: state.featureToggles.features.bankDeposit ? state.featureToggles.features.bankDeposit.enabled : false,
	};
};

const mapDispatchToProps = (dispatch) => ({
	toWelcome: () => dispatch(routeTo('/')),
	track: (type) => dispatch(trackGaEvent('DepositMenu', 'Click', DEPOSIT_TYPES_NAMES[type])),
	closeModal: () => dispatch(closeModal('DepositsPromptContainer')),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(DepositsPromptContainer);
