import { createSelector } from 'reselect';

// Schema functions
import { denormalizeMeetings, denormalizeMeetingsOnly } from '../../../store/entities/schemas/MeetingSchema';
import { denormalizeNextToJumpRaces } from '../../../store/entities/schemas/NextToJumpRaceSchema';
import { denormalizeNextToJumpRaceSelections } from '../../../store/entities/schemas/NextToJumpRaceSelectionSchema';
import { denormalizeSports } from '../../../store/entities/schemas/SportSchema';
import {
	filterFourNextRacesToJump,
	sortRacesToJumpByStartTime,
} from '../../../store/entities/selectors/NextToJumpRaceSelectors';

// Constants
import { GOAT_PRODUCT_TYPE_BOOST } from '../../../common/constants/GoatProducts';
import {
	RACE_SELLING_STATUS,
	RACING_AU,
	RACING_BET_TYPE_EACHWAY,
	RACING_BET_TYPE_MARGIN,
	RACING_BET_TYPE_PLACE,
	RACING_BET_TYPE_SP,
	RACING_BET_TYPE_TF,
	RACING_BET_TYPE_WIN,
	RACING_EXOTIC_BET_TYPE_ORDER_LOOKUP,
	RACING_EXOTIC_BET_TYPES,
	RACING_FR,
	RACING_GREYHOUNDS_CODE,
	RACING_GROUP_TITLE_GREYHOUNDS,
	RACING_GROUP_TITLE_HARNESS,
	RACING_GROUP_TITLE_INTERNATIONAL,
	RACING_GROUP_TITLE_LOCAL,
	RACING_GROUP_TITLE_THOROUGHBRED,
	RACING_HARNESS_CODE,
	RACING_HK,
	RACING_MARKET_NAME,
	RACING_NZ,
	RACING_SAME_RACE_MULTI_TYPE,
	RACING_SGP,
	RACING_THOROUGHBRED_CODE,
	RACING_TYPES_LOOKUP,
	RACING_UK,
	SELECTION_NOT_SCRATCHED_STATUS,
	SELECTION_SCRATCHED_STATUS,
	SRMTypes,
} from '../../../common/constants/Racing';
import { backmarkerKey, leaderKey, midfieldKey, offpaceKey } from '../../../common/constants/SpeedmapConstants';

import { fetchFixedPriceRollup, fetchRollTablePrice } from '../../../store/application/applicationSelectors';
import { PLACE_MULTI_BET } from '../../../store/betPlacement/betPlacementReducerNames';
import { getFeaturedRacesFromSports } from '../../../store/entities/actions/SportsActions';
import {
	BET_TYPE_MULTI,
	PRODUCT_TYPE_STANDARD,
	TOTE_NAMES_BY_PRODUCT,
	toteProducts,
} from '../../../store/entities/constants/BetConstants';
import { denormalizeBets } from '../../../store/entities/schemas/BetSchema';
import { denormalizeRaces } from '../../../store/entities/schemas/RaceSchema';
import { getBettingEntities } from '../../../store/entities/schemas/relationships/BettingRelationships';
import { getRacingEntities } from '../../../store/entities/schemas/relationships/RacingRelationships';
import { getSportsEntities } from '../../../store/entities/schemas/relationships/SportsRelationships';
import {
	compareMeetingsByStatusAndGrade,
	containsCountryCodes,
	doesNotContainCountryCodes,
	isGallops,
	isGreyhounds,
	isHarness,
} from '../../../store/entities/selectors/MeetingSelectors';
import {
	createPriceForSelectedProduct,
	getExoticProducts,
	getNonExoticProductChoices,
	getOddsGridPrice,
	getWinPlaceProducts,
} from '../../../store/entities/selectors/ProductSelectors';
import { selectFlucsKey } from '../../../store/entities/selectors/RaceSelectors';
import { buildExoticResults, buildResults } from '../../../store/entities/selectors/RacingResultSelectors';

/**
 * Memoized function to build next to jump races data.
 * @params state
 */
const getNextToJumpRaces = createSelector(
	(state) => state.entities.nextToJumpRaces,
	(nextToJumpRaces) => {
		return sortRacesToJumpByStartTime(denormalizeNextToJumpRaces({ nextToJumpRaces }));
	},
);

/**
 * Get the NTJ race that is selling
 *
 * @param entities
 * @param ntjRaces
 */
const getNextToJumpSellingRace = (entities, ntjRaces) => {
	return ntjRaces.find((race) => {
		const ntjRace = getRaceByID(entities, race.id);
		return ntjRace && ntjRace.status === RACE_SELLING_STATUS;
	});
};

/**
 * Build next to jump races data with selections
 */
const getNextToJumpRaceSelections = createSelector(
	(state) => state.entities.nextToJumpRaceSelections,
	(nextToJumpRaceSelections) => {
		return sortRacesToJumpByStartTime(denormalizeNextToJumpRaceSelections({ nextToJumpRaceSelections }));
	},
);

/**
 * Filter next to jump races selection data
 */
const getNextFourFilteredRacesToJump = createSelector(getNextToJumpRaceSelections, (ntjRaces) =>
	filterFourNextRacesToJump(ntjRaces),
);

const getMeetingsAndRacesWithBets = createSelector(
	(state) => getBettingEntities(state.entities),
	(bettingEntities) => {
		// Grab look up table of meetings and races with bets.
		return searchRacesWithBets(denormalizeBets(bettingEntities));
	},
);

/**
 * Memoized selector to get meetings list
 * @params state
 */
const getMeetings = createSelector(
	(state) => state.grsRacingHome.showingMeetings,
	(state) => getRacingEntities(state.entities),
	getMeetingsAndRacesWithBets,
	(showingMeetings, racingEntities, meetingsAndRacesWithBets) => {
		let meetings = denormalizeMeetings(racingEntities, showingMeetings);
		for (const meeting of meetings) {
			meeting.races.sort((a, b) => a.number - b.number);
			let meetingsStatues = {};
			let nextSellingRace = null;
			const not_selling_races = [];

			for (let race of meeting.races) {
				if (!meetingsStatues[race.status]) {
					meetingsStatues[race.status] = 0;
				}

				meetingsStatues[race.status] += 1;

				if (race.status != 'selling') {
					not_selling_races.push(race.number);
				}

				if (race.status == 'selling' && !nextSellingRace) {
					nextSellingRace = race;
				}
			}
			meeting.not_selling_races = not_selling_races;
			meeting.status = 'selling';
			if (!nextSellingRace) {
				let max = 0;
				for (let status in meetingsStatues) {
					if (meetingsStatues[status] > max) {
						max = meetingsStatues[status];
						meeting.status = status;
					}
				}
			}
			meeting.nextSellingRace = nextSellingRace ? nextSellingRace.start_date : null;
			meeting.nextSellingRaceNumber = nextSellingRace ? nextSellingRace.number : null;
			meeting.nextSellingRaceId = nextSellingRace ? nextSellingRace.id : null;
		}

		return signMeetingsAndRacesWithBets(meetings, meetingsAndRacesWithBets);
	},
);

/**
 * Memoized selector to group meetings in the different categories.
 * @params state
 */
const buildMeetingGroups = createSelector(
	getMeetings,
	(state) => state.featureToggles.features.groupMeetingsByCountry,
	(state) => state.featureToggles.features.meetingGroups,
	(meetings, groupMeetingsByCountry, customMeetingGroups) => {
		return groupMeetings(meetings, groupMeetingsByCountry, customMeetingGroups);
	},
);

/**
 * Memoized selector to group meetings in the different categories and filter them by a provided value
 * @params state
 */
const buildMeetingGroupsFromGroup = createSelector(
	buildMeetingGroups,
	(state, meetingGroupFilter) => meetingGroupFilter,
	(meetingGroups, meetingGroupFilter) => {
		if (meetingGroupFilter) {
			return meetingGroups.filter((group) => group.groupFilterId.includes(meetingGroupFilter || ''));
		}

		return meetingGroups;
	},
);

/*
 * +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 *
 */

/**
 * Memoized selector to group meetings in the different categories.
 * @HW 16Dec2019
 * @param meetingType (Thoroughbred , harness and greyhounds)
 * @params state
 */
const buildMeetingGroupsByType = createSelector(
	getMeetings,
	(state) => state.featureToggles.features.groupMeetingsByCountry,
	(state) => state.featureToggles.features.meetingGroups,
	(state) => state.grsRacingHome.selectedType,
	(meetings, groupMeetingsByCountry, customMeetingGroups, selectedType) => {
		return groupMeetingsbyGRS(meetings, groupMeetingsByCountry, customMeetingGroups, selectedType);
	},
);

/**
 * Memoized selector to group meetings in the different categories and filter them by a provided value
 * Thoroughbred , Harness and greyhounds
 * @Hw 16Dec2019
 */
const buildMeetingGroupsFromGroupByType = createSelector(
	buildMeetingGroupsByType,
	(state, meetingGroupFilter) => meetingGroupFilter,
	(meetingGroups, meetingGroupFilter) => {
		if (meetingGroupFilter) {
			return meetingGroups.filter((group) => group.groupFilterId.includes(meetingGroupFilter || ''));
		}

		//console.log(meetingGroups)
		return meetingGroups;
	},
);

// ===================THOROUGHBRED ==========================

/**
 * Memoized selector to group meetings in the different categories.
 * @HW Thoroughbred meeting group 21Nov2019
 * @params state
 */
const buildGallopsMeetingGroups = createSelector(
	getMeetings,
	(state) => state.featureToggles.features.groupMeetingsByCountry,
	(state) => state.featureToggles.features.meetingGroups,
	(meetings, groupMeetingsByCountry, customMeetingGroups) => {
		return groupMeetings_GALLOPS(meetings, groupMeetingsByCountry, customMeetingGroups);
	},
);

/**
 * Memoized selector to group meetings in the different categories and filter them by a provided value
 * @HW Thoroughbred meeting group 21Nov2019
 * @params state
 */
const buildGallopsMeetingGroupsFromGroup = createSelector(
	buildGallopsMeetingGroups,
	(state, meetingGroupFilter) => meetingGroupFilter,
	(meetingGroups, meetingGroupFilter) => {
		if (meetingGroupFilter) {
			return meetingGroups.filter((group) => group.groupFilterId.includes(meetingGroupFilter || ''));
		}

		return meetingGroups;
	},
);

// ===================HARNESS ==========================
const buildHarnessMeetingGroups = createSelector(
	getMeetings,
	(state) => state.featureToggles.features.groupMeetingsByCountry,
	(state) => state.featureToggles.features.meetingGroups,
	(meetings, groupMeetingsByCountry, customMeetingGroups) => {
		return groupMeetings_Harness(meetings, groupMeetingsByCountry, customMeetingGroups);
	},
);
/**
 * Memoized selector to group meetings in the different categories and filter them by a provided value
 * @HW Harness meeting group 21Nov2019
 * @params state
 */
const buildHarnessMeetingGroupsFromGroup = createSelector(
	buildHarnessMeetingGroups,
	(state, meetingGroupFilter) => meetingGroupFilter,
	(meetingGroups, meetingGroupFilter) => {
		if (meetingGroupFilter) {
			return meetingGroups.filter((group) => group.groupFilterId.includes(meetingGroupFilter || ''));
		}

		return meetingGroups;
	},
);

// ===================GREYHOUNDS ==========================

const buildGreyhoundsMeetingGroups = createSelector(
	getMeetings,
	(state) => state.featureToggles.features.groupMeetingsByCountry,
	(state) => state.featureToggles.features.meetingGroups,
	(meetings, groupMeetingsByCountry, customMeetingGroups) => {
		return groupMeetings_greyhounds(meetings, groupMeetingsByCountry, customMeetingGroups);
	},
);

const buildGreyhoundsMeetingGroupsFromGroup = createSelector(
	buildGreyhoundsMeetingGroups,
	(state, meetingGroupFilter) => meetingGroupFilter,
	(meetingGroups, meetingGroupFilter) => {
		if (meetingGroupFilter) {
			return meetingGroups.filter((group) => group.groupFilterId.includes(meetingGroupFilter || ''));
		}

		return meetingGroups;
	},
);
/*
 * ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 */

/**
 * Memoized selector to feed data to Meetings lists.
 * This memoized selector takes care only of changes to meeting entities.
 *
 * @params state
 */
const getMeetingsOnly = createSelector(
	(state) => state.grsRacingHome.showingMeetings,
	(state) => state.entities.meetings,
	getMeetingsAndRacesWithBets,
	(showingMeetings, meetingEntities, meetingsAndRacesWithBets) => {
		let meetings = denormalizeMeetingsOnly({ meetings: meetingEntities }, showingMeetings);
		return signMeetingsAndRacesWithBets(meetings, meetingsAndRacesWithBets);
	},
);

/**
 * Memoized selector to feed data to Meetings lists ignoring changes to races.
 * Aiming to dim the number of re-renders from changes to Race entities,
 * this selector was created to watch changes to meetings only,
 *  // @HW 21Nov2019
 * @params state
 */
const buildMeetingsListGroups = createSelector(
	getMeetings,
	(state) => state.featureToggles.features.groupMeetingsByCountry,
	(state) => state.featureToggles.features.meetingGroups,

	(meetings, groupMeetingsByCountry, customMeetingGroups) => {
		return groupMeetings_GALLOPS(meetings, groupMeetingsByCountry, customMeetingGroups);
	},
);

/**
 * Memoized selector to feed data to Meetings lists ignoring changes to races.
 * Aiming to dim the number of re-renders from changes to Race entities,
 * this selector was created to watch changes to meetings only,
 * @HW 21Nov2019
 * @params state
 */
const buildMeetingsListGroupsByGRS = createSelector(
	getMeetings,
	(state) => state.featureToggles.features.groupMeetingsByCountry,
	(state) => state.featureToggles.features.meetingGroups,
	(state) => state.grsRacingHome.selectedType,

	(meetings, groupMeetingsByCountry, customMeetingGroups, selectedType) => {
		return groupMeetingsbyGRS(meetings, groupMeetingsByCountry, customMeetingGroups, selectedType);
		//return groupMeetings_GRS(meetings, groupMeetingsByCountry, customMeetingGroups, selectedType);
	},
);

//////////////////////////////////////////////////////////////////////////////////////

/// navigation meeting
/*
 * gallops
 */

const buildNavGallopsMeetingsListGroupsByGRS = createSelector(
	getMeetings,
	(state) => state.featureToggles.features.groupMeetingsByCountry,
	(state) => state.featureToggles.features.meetingGroups,

	(meetings, groupMeetingsByCountry, customMeetingGroups) => {
		return navigationGallopsGroupMeetingsbyGRS(meetings, groupMeetingsByCountry, customMeetingGroups);
	},
);

/*
 * Harness
 */

const buildNavHarnessMeetingsListGroupsByGRS = createSelector(
	getMeetings,
	(state) => state.featureToggles.features.groupMeetingsByCountry,
	(state) => state.featureToggles.features.meetingGroups,

	(meetings, groupMeetingsByCountry, customMeetingGroups) => {
		return navigationHarnessGroupMeetingsbyGRS(meetings, groupMeetingsByCountry, customMeetingGroups);
	},
);

/*
 * Greyhounds
 */

const buildNavGreyhoundsMeetingsListGroupsByGRS = createSelector(
	getMeetings,
	(state) => state.featureToggles.features.groupMeetingsByCountry,
	(state) => state.featureToggles.features.meetingGroups,

	(meetings, groupMeetingsByCountry, customMeetingGroups) => {
		return navigationGrehoundsGroupMeetingsbyGRS(meetings, groupMeetingsByCountry, customMeetingGroups);
	},
);

/////////////////////////////////////////////////////////////////////////////////////

/**
 * Harness Meeting List
 * @HW 21Nov2019
 */
const buildMeetingsListGroups_Harness = createSelector(
	getMeetings,
	(state) => state.featureToggles.features.groupMeetingsByCountry,
	(state) => state.featureToggles.features.meetingGroups,

	(meetings, groupMeetingsByCountry, customMeetingGroups) => {
		return groupMeetings_Harness(meetings, groupMeetingsByCountry, customMeetingGroups);
	},
);

const buildGreyhoundsMeetingsListGroups = createSelector(
	getMeetings,
	(state) => state.featureToggles.features.groupMeetingsByCountry,
	(state) => state.featureToggles.features.meetingGroups,

	(meetings, groupMeetingsByCountry, customMeetingGroups) => {
		return groupMeetings_greyhounds(meetings, groupMeetingsByCountry, customMeetingGroups);
	},
);

/**
 * Assess state and return selected meeting.
 * @params state
 */
const getSelectedMeeting = createSelector(
	(state) => state.grsRacingHome.selectedMeeting,
	(state) => getRacingEntities(state.entities),
	(state) => getBettingEntities(state.entities),
	(selectedMeeting, racingEntities, bettingEntities) => {
		// grab lookup table of races with bets.
		let racesWithBets = searchRacesWithBets(denormalizeBets(bettingEntities), selectedMeeting);
		// denormalize like the input were multiple meetings but given needed id.
		// logic similar to getMeetings but applied to a single meeting.
		if (!selectedMeeting) {
			return null;
		}

		let meetings = denormalizeMeetings(racingEntities, [selectedMeeting]);
		//If no meeting is found return error.
		if (!meetings || !meetings[0]) {
			console.error('No meeting has been found!');
			return null;
		}

		let meeting = signMeetingsAndRacesWithBets(meetings, racesWithBets)[0];
		// Sort races by number
		if (Array.isArray(meeting.races)) {
			meeting.races.sort((raceA, raceB) => +raceA.number - +raceB.number);
		}
		return meeting;
	},
);

/**
 * Assess state and return  bet builder selected meeting.
 * @params state
 * @HW 09JUNE2020
 */
const getBetbuilderSelectedMeeting = createSelector(
	(state) =>
		state.grsRacingHome.selectedBuilderMeeting
			? state.grsRacingHome.selectedBuilderMeeting
			: state.grsRacingHome.selectedMeeting,
	(state) => getRacingEntities(state.entities),
	(state) => getBettingEntities(state.entities),
	(selectedBuilderMeeting, racingEntities, bettingEntities) => {
		// grab lookup table of races with bets.
		let racesWithBets = searchRacesWithBets(denormalizeBets(bettingEntities), selectedBuilderMeeting);

		// denormalize like the input were multiple meetings but given needed id.
		// logic similar to getMeetings but applied to a single meeting.
		let meetings = denormalizeMeetings(racingEntities, [selectedBuilderMeeting]);

		//If no meeting is found return error.
		if (!meetings || !meetings[0]) {
			console.error('No meeting has been found!');
			return null;
		}

		let meeting = signMeetingsAndRacesWithBets(meetings, racesWithBets)[0];

		// Sort races by number
		if (Array.isArray(meeting.races)) {
			meeting.races.sort((raceA, raceB) => +raceA.number - +raceB.number);
		}
		return meeting;
	},
);

/**
 * Return an array of selected meetings
 *
 * @param state
 * @param selectedMeetingId
 * @returns {Array}
 */
const getDenormalizedMeeting = (state, selectedMeetingId) => {
	return denormalizeMeetings(getRacingEntities(state.entities), [selectedMeetingId]);
};

/**
 * The slice of state where the raceId is stored for tournaments
 * @param state
 */
const tournamentsRaceId = (state) => state.activeTournament.raceDetails.raceFilter;

/**
 * The slice of state where the raceId is stored for GRS racing home
 * @param state
 */
const racingHomeRaceId = (state) => state.grsRacingHome.selectedRace;

/**
 * The slice of state where the meeting type is stored for GRS racing home
 * @HW 15Dec2019
 * @param state
 */
const racingHomeMeetingType = (state) => state.grsRacingHome.selectedType;

/**
 * The slice of state where the selected race is stored against the bet prompt selection
 * @param state
 * @returns {null}
 */
const betPromptRaceId = (state) => (state.betPrompt.selections.length ? state.betPrompt.selections[0].race_id : null);

/**
 * Assess state and return selected race. Optionally provide it the function to use to retrieve the race ID from state.
 * If you provide it with a different slice to get the Race ID from, you must also make sure you pass it into
 * any sister selectors, eg: getFlucsKey(state, tournamentsRaceId)
 *
 * Usage: getSelectedRace(state, tournamentsRaceId)
 *
 * @param state
 * @param Function
 */
const getSelectedRace = createSelector(
	(state, getRaceIdSlice = racingHomeRaceId) => getRaceIdSlice(state),
	(state, getRaceIdSlice = racingHomeRaceId) => getRacingEntities(state.entities),
	(selectedRace, entities) => getRaceByID(entities, selectedRace, true),
);

/**
 * Function to sort a race, on a specified key, with a comparator function
 *
 * @param race
 * @param key
 * @param comparator
 * @returns {*}
 */
const sortRaceKey = (race, key, comparator) => {
	if (Array.isArray(race[key]) && race[key].length > 0) {
		race[key].sort(comparator);
	}

	return race;
};

/**
 * Sort various race keys
 *
 * @param race
 * @returns {*}
 */
const sortRaceItems = (race) => {
	//Sort results to ascending order by position.
	race = sortRaceKey(race, 'displayed_results', sortResultsByPosition);
	race = sortRaceKey(race, 'displayed_exotic_results', sortExoticResultByBetTypeOrder);

	// If Greyhounds
	if (race.type && race.type.toUpperCase() === RACING_GREYHOUNDS_CODE) {
		race.selections = sortGreyhoundsByBarrier(race.selections);
	} else {
		race = sortRaceKey(race, 'selections', sortResultsByNumber);
	}

	return race;
};

/**
 * Assess state and return selected race, sorting fields as required
 *
 * @params state
 */
const getRaceByID = (entities, selectedRace, sort = true) => {
	if (selectedRace) {
		let race = denormalizeRaces(entities, [selectedRace])[0];

		if (race) {
			// Sort predefined keys on a race
			if (sort) {
				race = sortRaceItems(race);
			}

			return race;
		}
	}

	return null;
};

/**
 * Return a denormalized list of featured races from the sports entities
 */
const getFeaturedRaces = createSelector(
	(state) => getSportsEntities(state.entities),
	(entities) => {
		const denormalizedSports = denormalizeSports(entities);
		return getFeaturedRacesFromSports(denormalizedSports);
	},
);

/**
 * Decides which flucs field should be rendered.
 *
 * We should render flucs under following conditions:
 *     1) We have data to present;
 *     2) The relating product is available;
 *     3) Depending on time to jump, we present flucs from different source.
 *
 * @return {string}
 */
const getFlucsKey = createSelector(getSelectedRace, (race) => selectFlucsKey(race));

/**
 * Build displayable results for selected race
 * @params state
 */
const buildWinPlaceResults = createSelector(getSelectedRace, (race) => {
	return buildResults(race.results, race.products);
});

/**
 * Build displayable exotic results for selected race
 * @params state
 */
const buildRacingExoticResults = createSelector(getSelectedRace, (race) => {
	return buildExoticResults(race.exotic_results, race.products);
});

/**
 * Get only the selections that aren't scratched
 * @param selections
 * @returns {*[]}
 */
const getNotScratchedSelections = (selections = []) => {
	return selections.filter((selection) => selection.selection_status === SELECTION_NOT_SCRATCHED_STATUS);
};

/**
 * Get only the selections that are included as tips
 * @param selections
 * @param tips
 * @returns {*|Array}
 */
const getSelectionsFromTips = (selections = [], tips = []) => {
	return tips.reduce((tipSelections, tipNumber) => {
		const selection = selections.find((sel) => sel.number === tipNumber);
		if (selection) {
			tipSelections.push(selection);
		}

		return tipSelections;
	}, []);
};

/**
 * Get the number of selections that aren't scratched
 * @param selections
 * @returns {number}
 */
const getNumberOfNotScratchedSelections = (selections = []) => getNotScratchedSelections(selections).length;

/**
 * Filter standard 'win' and 'place' products.
 * @params state
 */
const buildWinPlaceProducts = createSelector(getSelectedRace, (race) => {
	if (!race || !Array.isArray(race.products)) {
		return [];
	}

	// Get the available win & place products
	const winPlaceProducts = getWinPlaceProducts(race.products);

	// Get the number of non scratched selections
	const numOfSelections = getNumberOfNotScratchedSelections(race.selections);

	// If there are less than 5 selection remove the place products
	if (numOfSelections < 5) {
		return winPlaceProducts.filter((product) => product.bet_type !== RACING_BET_TYPE_PLACE);
	} else {
		return winPlaceProducts;
	}
});

const buildOddsGridPrice = createSelector(getSelectedRace, (race) => {
	if (!Array.isArray(race.selections)) {
		return [];
	}
	const OddsGridPrice = getOddsGridPrice(race.selections);
	return OddsGridPrice;
});

/**
 * Filter standard 'win' & place products
 * @params state
 */
const GRSBuildWinPlaceProducts = createSelector(getSelectedRace, (race) => {
	if (!Array.isArray(race.products)) {
		return [];
	}

	// Get the available win & place products
	const winPlaceProducts = getWinPlaceProducts(race.products);

	// Get the number of non scratched selections
	const numOfSelections = getNumberOfNotScratchedSelections(race.selection);

	// If there are less than 3 selection remove the place products
	if (numOfSelections < 3) {
		return winPlaceProducts.filter((product) => product.bet_type !== RACING_BET_TYPE_PLACE);
	} else {
		return winPlaceProducts;
	}
});

/**
 * Filter standard 'win' and 'place' products.
 * @params state
 */
const buildWinPlaceTournamentProducts = createSelector(buildWinPlaceProducts, (products) => {
	if (!Array.isArray(products)) {
		return [];
	}

	// Filter our special goat products that are not enabled for tournaments, and disable the add to multi button
	return products
		.filter((product) => product.bet_type !== RACING_BET_TYPE_MARGIN && product.product_type === PRODUCT_TYPE_STANDARD)
		.map((product) => {
			product.multi_available = false;
			return product;
		});
});

const shouldRenderFormsButton = createSelector(getSelectedRace, (race) => {
	if (!race || !race.selections || race.selections.length === 0) {
		return false;
	}

	// Look for any selection that has 'runner' field.
	return !!race.selections.find((selection) => !!selection.runner);
});

/**
 * Filter exotic products.
 * @params state
 */
const buildExoticProducts = createSelector(getSelectedRace, (race) => {
	if (!Array.isArray(race.products)) {
		return [];
	}

	return getExoticProducts(race.products);
});

/**
 * Builds win/place product choices to be rendered bet type filter for mobile.
 * @params state
 */
const buildMobileWinPlaceChoices = createSelector(buildWinPlaceProducts, (winPlaceProducts) => {
	if (!Array.isArray(winPlaceProducts)) {
		return [];
	}
	return getNonExoticProductChoices(winPlaceProducts);
});

/**
 * Build list of bets for given race id.
 */
const buildBetsForRace = createSelector(
	(state) => getBettingEntities(state.entities),
	(state) => state.grsRacingHome.selectedRace,
	(state) => state.betPrompt.selections,
	(entities, selectedRace, selections) => {
		return getSingleBetsForRace(denormalizeBets(entities), selectedRace, selections);
	},
);

/**
 * The win/place result should be sorted by positions 1 to 4
 * @param runnerA
 * @param runnerB
 */
const sortResultsByPosition = (runnerA, runnerB) => runnerA.position - runnerB.position;

/**
 * The runner number will be sorted
 * @param runnerA
 * @param runnerB
 */
const sortResultsByNumber = (runnerA, runnerB) => runnerA.number - runnerB.number;

/**
 * Sorts selections for Greyhound races.
 *
 * A scratched selection may have a 'not scratched' one to fill the vacant barrier.
 * Selections without a matching 'not scratched' one is sorted as not scratched.
 *
 * When there are only 8 runners: scratched runners should be left at the barrier they were assigned.
 * When there are more than 8 runners: should fill barriers with not scratched selections and leave scratched ones last sorted by barrier number.
 *
 * If a runner doesn't have a barrier, move it last, but still above the scratched selections
 *
 * @param selections
 * @return {Array.<*>}
 */
export const sortGreyhoundsByBarrier = (selections = []) => {
	const scratchings = selections.filter((selection) => {
		return selection.selection_status === SELECTION_SCRATCHED_STATUS && selection.barrier && selection.barrier <= 8;
	});

	// Find scratchings to be slotted in place
	const staticScratchings = scratchings.reduce((acc, scratching) => {
		const barrierMatches = selections.filter((selection) => selection.barrier === scratching.barrier);
		if (barrierMatches.length === 1) {
			acc.push(scratching);
		}

		return acc;
	}, []);

	return selections.sort((a, b) => {
		const aNumber = parseInt(a.number);
		const bNumber = parseInt(b.number);

		if (!a.selection_status || !b.selection_status) {
			return 0;
		}

		let aStatus = a.selection_status.toLowerCase();
		let bStatus = b.selection_status.toLowerCase();

		// For sorting, set the status to not scratched so it fits in properly
		if (staticScratchings.find((staticScratching) => staticScratching.id === a.id)) {
			aStatus = SELECTION_NOT_SCRATCHED_STATUS;
		}
		if (staticScratchings.find((staticScratching) => staticScratching.id === b.id)) {
			bStatus = SELECTION_NOT_SCRATCHED_STATUS;
		}

		// If the first and second statuses of the greyhounds are different, rank the scratched one lower.
		const aNotScratched = aStatus === SELECTION_NOT_SCRATCHED_STATUS;
		if (aNotScratched !== (bStatus === SELECTION_NOT_SCRATCHED_STATUS)) {
			return aNotScratched ? -1 : 1;
		}

		// Sort so that those without barriers are at the bottom, but above scratched selections
		if (b.barrier === 0) {
			// Return -1
			return b.barrier - a.barrier || aNumber - bNumber;
		}

		if (a.barrier === 0) {
			// Return 1
			return b.barrier - a.barrier || aNumber - bNumber;
		}

		// If the first greyhound is scratched, both must be scratched due to the logic in the 'aNotScratched' statement.
		// As such, sort by number instead.
		// If both greyhounds are not scratched, sort by number.
		const higherNumber = Math.max(aNumber, bNumber);
		if (!aNotScratched || higherNumber <= 8) {
			return aNumber - bNumber;
		}

		// Sort by barrier
		return a.barrier - b.barrier;
	});
};

/**
 * The exotics must follow the order expressed by the constant `RACING_EXOTIC_BET_TYPE_ORDER_LOOKUP`
 * @param typeA
 * @param typeB
 */
const sortExoticResultByBetTypeOrder = (typeA, typeB) =>
	RACING_EXOTIC_BET_TYPE_ORDER_LOOKUP[typeA.bet_type] - RACING_EXOTIC_BET_TYPE_ORDER_LOOKUP[typeB.bet_type];

/**
 * Passes extra options down to our filter action
 *
 * @param filterAction
 * @param options
 * @returns {function(...[*]): *}
 */
const customFilterCreator = (filterAction, ...options) => {
	return (...rest) => filterAction(...options, ...rest);
};

/**
 * This will sort our meeting groups so that default items are last and higher ordinals are first
 *
 * @param a
 * @param b
 * @returns {number|*}
 */
const sortMeetingGroups = (a, b) => {
	if (a.default) {
		return 1;
	} else if (b.default) {
		return -1;
	}

	if (!a.ordinal) {
		return 1;
	} else if (!b.ordinal) {
		return -1;
	}

	return b.ordinal - a.ordinal || a.title.localeCompare(b.title);

	//return a.default - b.default || b.ordinal - a.ordinal || a.title.localeCompare(b.title);
};

/**
 * Builds array of meetings groups by selected type using the following fields:
 * title       name for the meeting group when rendering;
 *      meetings    array of meeting entities.
 * @HW 16Dec2019 additionaly pass the selected type
 * @param {*} meetings
 * @param {*} groupMeetingsByCountry
 * @param {*} customMeetingGroups
 * @param {*} selectedType
 */
const groupMeetingsbyGRS = (meetings = [], groupMeetingsByCountry, customMeetingGroups, selectedType) => {
	const meetingGroups = [];

	// Setup the meeting groups config with the default
	const meetingGroupsConfig = {
		[RACING_THOROUGHBRED_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_THOROUGHBRED,
				code: RACING_THOROUGHBRED_CODE,
				filter: isGallops,
				default: true,
			},
		},
		[RACING_GREYHOUNDS_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_GREYHOUNDS,
				code: RACING_GREYHOUNDS_CODE,
				filter: isGreyhounds,
				default: true,
			},
		},
		[RACING_HARNESS_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_HARNESS,
				code: RACING_HARNESS_CODE,
				filter: isHarness,
				default: true,
			},
		},
	};
	/**
	 * AR Change if you need filter country code
	 * any country code remove inside this arrey it is goes to INTERNATIONAL races.
	 */
	// If the deprecated feature toggle 'groupMeetingsByCountry' is enabled then sort by AU/NZ & International
	if (groupMeetingsByCountry && groupMeetingsByCountry.enabled) {
		// const countryCodes = [RACING_AU, RACING_NZ, RACING_SGP, RACING_HK];
		const countryCodes = [RACING_AU];

		Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
			config.local = {
				title: `${RACING_GROUP_TITLE_LOCAL} ${config.default.title}`,
				code: config.default.code,
				filter: customFilterCreator(containsCountryCodes, raceTypeCode, countryCodes),
				ordinal: 1,
				identifier: 'state',
				default: false,
			};

			config.default.title = `${RACING_GROUP_TITLE_INTERNATIONAL} ${config.default.title}`;
			config.default.code = raceTypeCode;
			config.default.filter = customFilterCreator(doesNotContainCountryCodes, raceTypeCode, countryCodes);
			config.default.identifier = 'state';
		});
	}

	// Otherwise use the custom groups if supplied
	else if (customMeetingGroups && customMeetingGroups.enabled) {
		const customMeetingGroupsConfig = customMeetingGroups.value;
		const customCountryCodes = [];

		// Loop through the custom meeting groups and then add the group to eah race type
		customMeetingGroupsConfig.map(function(customMeetingGroup) {
			Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
				if (customMeetingGroup.countryCodes && customMeetingGroup.countryCodes.length > 0) {
					config[customMeetingGroup.title] = {
						title: `${customMeetingGroup.title} ${RACING_TYPES_LOOKUP[raceTypeCode]}`,
						code: raceTypeCode,
						filter: customFilterCreator(containsCountryCodes, raceTypeCode, customMeetingGroup.countryCodes),
						ordinal: customMeetingGroup.ordinal,
						identifier: customMeetingGroup.meetingGroupIdentifier,
						default: false,
					};
				} else {
					// If there are no countryCodes in the config then add the title & identifier to the default group if they exist
					if (customMeetingGroup.title) {
						config.default.title = `${customMeetingGroup.title} ${RACING_TYPES_LOOKUP[raceTypeCode]}`;
						config.default.code = raceTypeCode;
					}
					if (customMeetingGroup.meetingGroupIdentifier) {
						config.default.identifier = customMeetingGroup.meetingGroupIdentifier;
					}
				}
			});

			// Setup the custom country codes to use later on the default meetings group
			if (customMeetingGroup.countryCodes) {
				customCountryCodes.push(...customMeetingGroup.countryCodes);
			}
		});

		// Add the default group filter
		Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
			config.default.filter = customFilterCreator(doesNotContainCountryCodes, raceTypeCode, customCountryCodes);
		});
	}

	switch (selectedType) {
		case 't': {
			// Add the meetings to the filtering rules and sort by ordinals
			const meetingFilteringRules =
				// Add the gallop meetings
				Object.values(meetingGroupsConfig[RACING_THOROUGHBRED_CODE]).sort(sortMeetingGroups);
			// Apply our filtering rules and the status/grade sorting to the meetings
			meetingFilteringRules.forEach((group) => {
				meetingGroups.push({
					title: group.title,
					code: group.code,
					groupFilterId: group.default ? 'default' : group.title,
					identifier: group.identifier,
					meetings: meetings.filter(group.filter).sort(compareMeetingsByStatusAndGrade),
				});
			});

			return meetingGroups;
		}
		case 'h': {
			// Add the meetings to the filtering rules and sort by ordinals
			const meetingFilteringRules =
				// Add the harness meetings
				Object.values(meetingGroupsConfig[RACING_HARNESS_CODE]).sort(sortMeetingGroups);

			// Apply our filtering rules and the status/grade sorting to the meetings
			meetingFilteringRules.forEach((group) => {
				meetingGroups.push({
					title: group.title,
					code: group.code,
					groupFilterId: group.default ? 'default' : group.title,
					identifier: group.identifier,
					meetings: meetings.filter(group.filter).sort(compareMeetingsByStatusAndGrade),
				});
			});

			return meetingGroups;
		}
		case 'g': {
			// Add the meetings to the filtering rules and sort by ordinals
			// get greyhounds meeting
			const meetingFilteringRules = Object.values(meetingGroupsConfig[RACING_GREYHOUNDS_CODE]).sort(sortMeetingGroups);

			// Apply our filtering rules and the status/grade sorting to the meetings
			meetingFilteringRules.forEach((group) => {
				meetingGroups.push({
					title: group.title,
					code: group.code,
					groupFilterId: group.default ? 'default' : group.title,
					identifier: group.identifier,
					meetings: meetings.filter(group.filter).sort(compareMeetingsByStatusAndGrade),
				});
			});
			return meetingGroups;
		}
	}

	// Add the meetings to the filtering rules and sort by ordinals
	// show all the  type of meetings
	const meetingFilteringRules =
		// Add the gallop meetings
		Object.values(meetingGroupsConfig[RACING_THOROUGHBRED_CODE])
			.sort(sortMeetingGroups)
			// Add the greyhound meetings
			.concat(Object.values(meetingGroupsConfig[RACING_GREYHOUNDS_CODE]).sort(sortMeetingGroups))
			// Add the harness meetings
			.concat(Object.values(meetingGroupsConfig[RACING_HARNESS_CODE]).sort(sortMeetingGroups));

	// Add the meetings to the filtering rules and sort by ordinals
	//   const meetingFilteringRules =
	//     // Add the gallop meetings
	//     Object.values(meetingGroupsConfig[RACING_THOROUGHBRED_CODE])
	//       .sort(sortMeetingGroups);

	// Apply our filtering rules and the status/grade sorting to the meetings
	meetingFilteringRules.forEach((group) => {
		meetingGroups.push({
			title: group.title,
			code: group.code,
			groupFilterId: group.default ? 'default' : group.title,
			identifier: group.identifier,
			meetings: meetings.filter(group.filter).sort(compareMeetingsByStatusAndGrade),
		});
	});
	return meetingGroups;
};

///////////////////////////////////////////////////////////////////////////////////////////
//gallops meeting for navigation

/**
 * Builds array of meetings groups by selected type using the following fields:
 * title       name for the meeting group when rendering;
 *      meetings    array of meeting entities.
 * @HW 16Dec2019 additionaly pass the selected type
 * @param {*} meetings
 * @param {*} groupMeetingsByCountry
 * @param {*} customMeetingGroups
 */
const navigationGallopsGroupMeetingsbyGRS = (meetings = [], groupMeetingsByCountry, customMeetingGroups) => {
	const meetingGroups = [];

	// Setup the meeting groups config with the default
	const meetingGroupsConfig = {
		[RACING_THOROUGHBRED_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_THOROUGHBRED,
				filter: isGallops,
				default: true,
			},
		},
		[RACING_GREYHOUNDS_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_GREYHOUNDS,
				filter: isGreyhounds,
				default: true,
			},
		},
		[RACING_HARNESS_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_HARNESS,
				filter: isHarness,
				default: true,
			},
		},
	};
	/**
	 * AR Change if you need filter country code
	 * any country code remove inside this arrey it is goes to INTERNATIONAL races.
	 */
	// If the deprecated feature toggle 'groupMeetingsByCountry' is enabled then sort by AU/NZ & International
	if (groupMeetingsByCountry && groupMeetingsByCountry.enabled) {
		const countryCodes = [RACING_AU, RACING_NZ, RACING_FR, RACING_SGP, RACING_HK, RACING_UK];

		Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
			config.local = {
				title: `${RACING_GROUP_TITLE_LOCAL} ${config.default.title}`,
				filter: customFilterCreator(containsCountryCodes, raceTypeCode, countryCodes),
				ordinal: 1,
				identifier: 'state',
				default: false,
			};

			config.default.title = `${RACING_GROUP_TITLE_INTERNATIONAL} ${config.default.title}`;
			config.default.filter = customFilterCreator(doesNotContainCountryCodes, raceTypeCode, countryCodes);
			config.default.identifier = 'state';
		});
	}

	// Otherwise use the custom groups if supplied
	else if (customMeetingGroups && customMeetingGroups.enabled) {
		const customMeetingGroupsConfig = customMeetingGroups.value;
		const customCountryCodes = [];

		// Loop through the custom meeting groups and then add the group to eah race type
		customMeetingGroupsConfig.map(function(customMeetingGroup) {
			Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
				if (customMeetingGroup.countryCodes && customMeetingGroup.countryCodes.length > 0) {
					config[customMeetingGroup.title] = {
						title: `${customMeetingGroup.title} ${RACING_TYPES_LOOKUP[raceTypeCode]}`,
						filter: customFilterCreator(containsCountryCodes, raceTypeCode, customMeetingGroup.countryCodes),
						ordinal: customMeetingGroup.ordinal,
						identifier: customMeetingGroup.meetingGroupIdentifier,
						default: false,
					};
				} else {
					// If there are no countryCodes in the config then add the title & identifier to the default group if they exist
					if (customMeetingGroup.title) {
						config.default.title = `${customMeetingGroup.title} ${RACING_TYPES_LOOKUP[raceTypeCode]}`;
					}
					if (customMeetingGroup.meetingGroupIdentifier) {
						config.default.identifier = customMeetingGroup.meetingGroupIdentifier;
					}
				}
			});

			// Setup the custom country codes to use later on the default meetings group
			if (customMeetingGroup.countryCodes) {
				customCountryCodes.push(...customMeetingGroup.countryCodes);
			}
		});

		// Add the default group filter
		Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
			config.default.filter = customFilterCreator(doesNotContainCountryCodes, raceTypeCode, customCountryCodes);
		});
	}

	// Add the meetings to the filtering rules and sort by ordinals
	const meetingFilteringRules =
		// Add the gallop meetings
		Object.values(meetingGroupsConfig[RACING_THOROUGHBRED_CODE]).sort(sortMeetingGroups);
	// Apply our filtering rules and the status/grade sorting to the meetings
	meetingFilteringRules.forEach((group) => {
		meetingGroups.push({
			title: group.title,
			groupFilterId: group.default ? 'default' : group.title,
			identifier: group.identifier,
			meetings: meetings.filter(group.filter).sort(compareMeetingsByStatusAndGrade),
		});
	});
	return meetingGroups;
};

////////////////////////////////////////////////////////////////////////////////
// harness meeting for navigaion
/**
 * Builds array of meetings groups by selected type using the following fields:
 * title       name for the meeting group when rendering;
 *      meetings    array of meeting entities.
 * @HW 16Dec2019 additionaly pass the selected type
 * @param {*} meetings
 * @param {*} groupMeetingsByCountry
 * @param {*} customMeetingGroups
 *
 */
const navigationHarnessGroupMeetingsbyGRS = (meetings = [], groupMeetingsByCountry, customMeetingGroups) => {
	const meetingGroups = [];

	// Setup the meeting groups config with the default
	const meetingGroupsConfig = {
		[RACING_THOROUGHBRED_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_THOROUGHBRED,
				filter: isGallops,
				default: true,
			},
		},
		[RACING_GREYHOUNDS_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_GREYHOUNDS,
				filter: isGreyhounds,
				default: true,
			},
		},
		[RACING_HARNESS_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_HARNESS,
				filter: isHarness,
				default: true,
			},
		},
	};
	/**
	 * AR Change if you need filter country code
	 * any country code remove inside this arrey it is goes to INTERNATIONAL races.
	 */
	// If the deprecated feature toggle 'groupMeetingsByCountry' is enabled then sort by AU/NZ & International
	if (groupMeetingsByCountry && groupMeetingsByCountry.enabled) {
		const countryCodes = [RACING_AU, RACING_NZ, RACING_FR, RACING_SGP, RACING_HK, RACING_UK];

		Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
			config.local = {
				title: `${RACING_GROUP_TITLE_LOCAL} ${config.default.title}`,
				filter: customFilterCreator(containsCountryCodes, raceTypeCode, countryCodes),
				ordinal: 1,
				identifier: 'state',
				default: false,
			};

			config.default.title = `${RACING_GROUP_TITLE_INTERNATIONAL} ${config.default.title}`;
			config.default.filter = customFilterCreator(doesNotContainCountryCodes, raceTypeCode, countryCodes);
			config.default.identifier = 'state';
		});
	}

	// Otherwise use the custom groups if supplied
	else if (customMeetingGroups && customMeetingGroups.enabled) {
		const customMeetingGroupsConfig = customMeetingGroups.value;
		const customCountryCodes = [];

		// Loop through the custom meeting groups and then add the group to eah race type
		customMeetingGroupsConfig.map(function(customMeetingGroup) {
			Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
				if (customMeetingGroup.countryCodes && customMeetingGroup.countryCodes.length > 0) {
					config[customMeetingGroup.title] = {
						title: `${customMeetingGroup.title} ${RACING_TYPES_LOOKUP[raceTypeCode]}`,
						filter: customFilterCreator(containsCountryCodes, raceTypeCode, customMeetingGroup.countryCodes),
						ordinal: customMeetingGroup.ordinal,
						identifier: customMeetingGroup.meetingGroupIdentifier,
						default: false,
					};
				} else {
					// If there are no countryCodes in the config then add the title & identifier to the default group if they exist
					if (customMeetingGroup.title) {
						config.default.title = `${customMeetingGroup.title} ${RACING_TYPES_LOOKUP[raceTypeCode]}`;
					}
					if (customMeetingGroup.meetingGroupIdentifier) {
						config.default.identifier = customMeetingGroup.meetingGroupIdentifier;
					}
				}
			});

			// Setup the custom country codes to use later on the default meetings group
			if (customMeetingGroup.countryCodes) {
				customCountryCodes.push(...customMeetingGroup.countryCodes);
			}
		});

		// Add the default group filter
		Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
			config.default.filter = customFilterCreator(doesNotContainCountryCodes, raceTypeCode, customCountryCodes);
		});
	}

	// Add the meetings to the filtering rules and sort by ordinals
	const meetingFilteringRules =
		// Add the gallop meetings
		Object.values(meetingGroupsConfig[RACING_HARNESS_CODE]).sort(sortMeetingGroups);
	// Apply our filtering rules and the status/grade sorting to the meetings
	meetingFilteringRules.forEach((group) => {
		meetingGroups.push({
			title: group.title,
			groupFilterId: group.default ? 'default' : group.title,
			identifier: group.identifier,
			meetings: meetings.filter(group.filter).sort(compareMeetingsByStatusAndGrade),
		});
	});
	return meetingGroups;
};

////////////////////////////////////////////////////////////////////////////////////////

// greyhounds meeting for navigaion
/**
 * Builds array of meetings groups by selected type using the following fields:
 * title       name for the meeting group when rendering;
 *      meetings    array of meeting entities.
 * @HW 16Dec2019 additionaly pass the selected type
 * @param {*} meetings
 * @param {*} groupMeetingsByCountry
 * @param {*} customMeetingGroups
 *
 */
const navigationGrehoundsGroupMeetingsbyGRS = (meetings = [], groupMeetingsByCountry, customMeetingGroups) => {
	const meetingGroups = [];

	// Setup the meeting groups config with the default
	const meetingGroupsConfig = {
		[RACING_THOROUGHBRED_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_THOROUGHBRED,
				filter: isGallops,
				default: true,
			},
		},
		[RACING_GREYHOUNDS_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_GREYHOUNDS,
				filter: isGreyhounds,
				default: true,
			},
		},
		[RACING_HARNESS_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_HARNESS,
				filter: isHarness,
				default: true,
			},
		},
	};
	/**
	 * AR Change if you need filter country code
	 * any country code remove inside this arrey it is goes to INTERNATIONAL races.
	 */
	// If the deprecated feature toggle 'groupMeetingsByCountry' is enabled then sort by AU/NZ & International
	if (groupMeetingsByCountry && groupMeetingsByCountry.enabled) {
		const countryCodes = [RACING_AU, RACING_NZ, RACING_FR, RACING_SGP, RACING_HK, RACING_UK];

		Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
			config.local = {
				title: `${RACING_GROUP_TITLE_LOCAL} ${config.default.title}`,
				filter: customFilterCreator(containsCountryCodes, raceTypeCode, countryCodes),
				ordinal: 1,
				identifier: 'state',
				default: false,
			};

			config.default.title = `${RACING_GROUP_TITLE_INTERNATIONAL} ${config.default.title}`;
			config.default.filter = customFilterCreator(doesNotContainCountryCodes, raceTypeCode, countryCodes);
			config.default.identifier = 'state';
		});
	}

	// Otherwise use the custom groups if supplied
	else if (customMeetingGroups && customMeetingGroups.enabled) {
		const customMeetingGroupsConfig = customMeetingGroups.value;
		const customCountryCodes = [];

		// Loop through the custom meeting groups and then add the group to eah race type
		customMeetingGroupsConfig.map(function(customMeetingGroup) {
			Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
				if (customMeetingGroup.countryCodes && customMeetingGroup.countryCodes.length > 0) {
					config[customMeetingGroup.title] = {
						title: `${customMeetingGroup.title} ${RACING_TYPES_LOOKUP[raceTypeCode]}`,
						filter: customFilterCreator(containsCountryCodes, raceTypeCode, customMeetingGroup.countryCodes),
						ordinal: customMeetingGroup.ordinal,
						identifier: customMeetingGroup.meetingGroupIdentifier,
						default: false,
					};
				} else {
					// If there are no countryCodes in the config then add the title & identifier to the default group if they exist
					if (customMeetingGroup.title) {
						config.default.title = `${customMeetingGroup.title} ${RACING_TYPES_LOOKUP[raceTypeCode]}`;
					}
					if (customMeetingGroup.meetingGroupIdentifier) {
						config.default.identifier = customMeetingGroup.meetingGroupIdentifier;
					}
				}
			});

			// Setup the custom country codes to use later on the default meetings group
			if (customMeetingGroup.countryCodes) {
				customCountryCodes.push(...customMeetingGroup.countryCodes);
			}
		});

		// Add the default group filter
		Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
			config.default.filter = customFilterCreator(doesNotContainCountryCodes, raceTypeCode, customCountryCodes);
		});
	}

	// Add the meetings to the filtering rules and sort by ordinals
	const meetingFilteringRules =
		// Add the gallop meetings
		Object.values(meetingGroupsConfig[RACING_GREYHOUNDS_CODE]).sort(sortMeetingGroups);
	// Apply our filtering rules and the status/grade sorting to the meetings
	meetingFilteringRules.forEach((group) => {
		meetingGroups.push({
			title: group.title,
			groupFilterId: group.default ? 'default' : group.title,
			identifier: group.identifier,
			meetings: meetings.filter(group.filter).sort(compareMeetingsByStatusAndGrade),
		});
	});
	return meetingGroups;
};
////////////////////////////////////////////////////////////////////////////////////////

/**
 * Builds array of meetings groups using the following fields:
 *      title       name for the meeting group when rendering;
 *      meetings    array of meeting entities.
 *
 * @HW 21Nov2019
 *
 * @param meetings
 * @param groupMeetingsByCountry
 * @return {Array}
 */
const groupMeetings_GRS = (meetings = [], groupMeetingsByCountry, customMeetingGroups, RACE_TYPE) => {
	const meetingGroups = [];

	// Setup the meeting groups config with the default
	const meetingGroupsConfig = {
		[RACING_THOROUGHBRED_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_THOROUGHBRED,
				filter: isGallops,
				default: true,
			},
		},
		[RACING_GREYHOUNDS_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_GREYHOUNDS,
				filter: isGreyhounds,
				default: true,
			},
		},
		[RACING_HARNESS_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_HARNESS,
				filter: isHarness,
				default: true,
			},
		},
	};

	// If the deprecated feature toggle 'groupMeetingsByCountry' is enabled then sort by AU/NZ & International
	if (groupMeetingsByCountry && groupMeetingsByCountry.enabled) {
		const countryCodes = [RACING_AU, RACING_NZ];

		Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
			config.local = {
				title: `${RACING_GROUP_TITLE_LOCAL} ${config.default.title}`,
				filter: customFilterCreator(containsCountryCodes, raceTypeCode, countryCodes),
				ordinal: 1,
				identifier: 'state',
				default: false,
			};

			config.default.title = `${RACING_GROUP_TITLE_INTERNATIONAL} ${config.default.title}`;
			config.default.filter = customFilterCreator(doesNotContainCountryCodes, raceTypeCode, countryCodes);
			config.default.identifier = 'state';
		});
	}

	// Otherwise use the custom groups if supplied
	else if (customMeetingGroups && customMeetingGroups.enabled) {
		const customMeetingGroupsConfig = customMeetingGroups.value;
		const customCountryCodes = [];

		// Loop through the custom meeting groups and then add the group to eah race type
		customMeetingGroupsConfig.map(function(customMeetingGroup) {
			Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
				if (customMeetingGroup.countryCodes && customMeetingGroup.countryCodes.length > 0) {
					config[customMeetingGroup.title] = {
						title: `${customMeetingGroup.title} ${RACING_TYPES_LOOKUP[raceTypeCode]}`,
						filter: customFilterCreator(containsCountryCodes, raceTypeCode, customMeetingGroup.countryCodes),
						ordinal: customMeetingGroup.ordinal,
						identifier: customMeetingGroup.meetingGroupIdentifier,
						default: false,
					};
				} else {
					// If there are no countryCodes in the config then add the title & identifier to the default group if they exist
					if (customMeetingGroup.title) {
						config.default.title = `${customMeetingGroup.title} ${RACING_TYPES_LOOKUP[raceTypeCode]}`;
					}
					if (customMeetingGroup.meetingGroupIdentifier) {
						config.default.identifier = customMeetingGroup.meetingGroupIdentifier;
					}
				}
			});

			// Setup the custom country codes to use later on the default meetings group
			if (customMeetingGroup.countryCodes) {
				customCountryCodes.push(...customMeetingGroup.countryCodes);
			}
		});

		// Add the default group filter
		Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
			config.default.filter = customFilterCreator(doesNotContainCountryCodes, raceTypeCode, customCountryCodes);
		});
	}

	// Add the meetings to the filtering rules and sort by ordinals
	const meetingFilteringRules =
		// Add the gallop meetings
		Object.values(meetingGroupsConfig[RACING_THOROUGHBRED_CODE])
			.sort(sortMeetingGroups)
			// Add the greyhound meetings
			.concat(Object.values(meetingGroupsConfig[RACING_GREYHOUNDS_CODE]).sort(sortMeetingGroups))
			// Add the harness meetings
			.concat(Object.values(meetingGroupsConfig[RACING_HARNESS_CODE]).sort(sortMeetingGroups));

	// Apply our filtering rules and the status/grade sorting to the meetings
	meetingFilteringRules.forEach((group) => {
		meetingGroups.push({
			title: group.title,
			groupFilterId: group.default ? 'default' : group.title,
			identifier: group.identifier,
			meetings: meetings.filter(group.filter).sort(compareMeetingsByStatusAndGrade),
		});
	});

	return meetingGroups;
};
/**
 * Builds array of meetings groups using the following fields:
 *      title       name for the meeting group when rendering;
 *      meetings    array of meeting entities.
 *
 * @param meetings
 * @param groupMeetingsByCountry
 * @return {Array}
 */
const groupMeetings = (meetings = [], groupMeetingsByCountry, customMeetingGroups) => {
	const meetingGroups = [];

	// Setup the meeting groups config with the default
	const meetingGroupsConfig = {
		[RACING_THOROUGHBRED_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_THOROUGHBRED,
				filter: isGallops,
				default: true,
			},
		},
		[RACING_GREYHOUNDS_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_GREYHOUNDS,
				filter: isGreyhounds,
				default: true,
			},
		},
		[RACING_HARNESS_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_HARNESS,
				filter: isHarness,
				default: true,
			},
		},
	};

	// If the deprecated feature toggle 'groupMeetingsByCountry' is enabled then sort by AU/NZ & International
	if (groupMeetingsByCountry && groupMeetingsByCountry.enabled) {
		const countryCodes = [RACING_AU, RACING_NZ];

		Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
			config.local = {
				title: `${RACING_GROUP_TITLE_LOCAL} ${config.default.title}`,
				filter: customFilterCreator(containsCountryCodes, raceTypeCode, countryCodes),
				ordinal: 1,
				identifier: 'state',
				default: false,
			};

			config.default.title = `${RACING_GROUP_TITLE_INTERNATIONAL} ${config.default.title}`;
			config.default.filter = customFilterCreator(doesNotContainCountryCodes, raceTypeCode, countryCodes);
			config.default.identifier = 'state';
		});
	}

	// Otherwise use the custom groups if supplied
	else if (customMeetingGroups && customMeetingGroups.enabled) {
		const customMeetingGroupsConfig = customMeetingGroups.value;
		const customCountryCodes = [];

		// Loop through the custom meeting groups and then add the group to eah race type
		customMeetingGroupsConfig.map(function(customMeetingGroup) {
			Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
				if (customMeetingGroup.countryCodes && customMeetingGroup.countryCodes.length > 0) {
					config[customMeetingGroup.title] = {
						title: `${customMeetingGroup.title} ${RACING_TYPES_LOOKUP[raceTypeCode]}`,
						filter: customFilterCreator(containsCountryCodes, raceTypeCode, customMeetingGroup.countryCodes),
						ordinal: customMeetingGroup.ordinal,
						identifier: customMeetingGroup.meetingGroupIdentifier,
						default: false,
					};
				} else {
					// If there are no countryCodes in the config then add the title & identifier to the default group if they exist
					if (customMeetingGroup.title) {
						config.default.title = `${customMeetingGroup.title} ${RACING_TYPES_LOOKUP[raceTypeCode]}`;
					}
					if (customMeetingGroup.meetingGroupIdentifier) {
						config.default.identifier = customMeetingGroup.meetingGroupIdentifier;
					}
				}
			});

			// Setup the custom country codes to use later on the default meetings group
			if (customMeetingGroup.countryCodes) {
				customCountryCodes.push(...customMeetingGroup.countryCodes);
			}
		});

		// Add the default group filter
		Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
			config.default.filter = customFilterCreator(doesNotContainCountryCodes, raceTypeCode, customCountryCodes);
		});
	}

	// Add the meetings to the filtering rules and sort by ordinals
	const meetingFilteringRules =
		// Add the gallop meetings
		Object.values(meetingGroupsConfig[RACING_THOROUGHBRED_CODE])
			.sort(sortMeetingGroups)
			// Add the greyhound meetings
			.concat(Object.values(meetingGroupsConfig[RACING_GREYHOUNDS_CODE]).sort(sortMeetingGroups))
			// Add the harness meetings
			.concat(Object.values(meetingGroupsConfig[RACING_HARNESS_CODE]).sort(sortMeetingGroups));

	// Apply our filtering rules and the status/grade sorting to the meetings
	meetingFilteringRules.forEach((group) => {
		meetingGroups.push({
			title: group.title,
			groupFilterId: group.default ? 'default' : group.title,
			identifier: group.identifier,
			meetings: meetings.filter(group.filter).sort(compareMeetingsByStatusAndGrade),
		});
	});

	return meetingGroups;
};
/***
 * ========================================================================================================
 */
/**
 * Builds array of meetings groups using the following fields:
 *      title       name for the meeting group when rendering;
 *      meetings    array of meeting entities.
 *  @HW 21Nov2019
 * @param meetings
 * @param groupMeetingsByCountry
 * @return {Array}
 */
const groupMeetings_GALLOPS = (meetings = [], groupMeetingsByCountry, customMeetingGroups) => {
	const meetingGroups = [];

	// Setup the meeting groups config with the default
	const meetingGroupsConfig = {
		[RACING_THOROUGHBRED_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_THOROUGHBRED,
				filter: isGallops,
				default: true,
			},
		},
		[RACING_GREYHOUNDS_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_GREYHOUNDS,
				filter: isGreyhounds,
				default: true,
			},
		},
		[RACING_HARNESS_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_HARNESS,
				filter: isHarness,
				default: true,
			},
		},
	};

	// If the deprecated feature toggle 'groupMeetingsByCountry' is enabled then sort by AU/NZ & International
	if (groupMeetingsByCountry && groupMeetingsByCountry.enabled) {
		const countryCodes = [RACING_AU, RACING_NZ];

		Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
			config.local = {
				title: `${RACING_GROUP_TITLE_LOCAL} ${config.default.title}`,
				filter: customFilterCreator(containsCountryCodes, raceTypeCode, countryCodes),
				ordinal: 1,
				identifier: 'state',
				default: false,
			};

			config.default.title = `${RACING_GROUP_TITLE_INTERNATIONAL} ${config.default.title}`;
			config.default.filter = customFilterCreator(doesNotContainCountryCodes, raceTypeCode, countryCodes);
			config.default.identifier = 'state';
		});
	}

	// Otherwise use the custom groups if supplied
	else if (customMeetingGroups && customMeetingGroups.enabled) {
		const customMeetingGroupsConfig = customMeetingGroups.value;
		const customCountryCodes = [];

		// Loop through the custom meeting groups and then add the group to eah race type
		customMeetingGroupsConfig.map(function(customMeetingGroup) {
			Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
				if (customMeetingGroup.countryCodes && customMeetingGroup.countryCodes.length > 0) {
					config[customMeetingGroup.title] = {
						title: `${customMeetingGroup.title} ${RACING_TYPES_LOOKUP[raceTypeCode]}`,
						filter: customFilterCreator(containsCountryCodes, raceTypeCode, customMeetingGroup.countryCodes),
						ordinal: customMeetingGroup.ordinal,
						identifier: customMeetingGroup.meetingGroupIdentifier,
						default: false,
					};
				} else {
					// If there are no countryCodes in the config then add the title & identifier to the default group if they exist
					if (customMeetingGroup.title) {
						config.default.title = `${customMeetingGroup.title} ${RACING_TYPES_LOOKUP[raceTypeCode]}`;
					}
					if (customMeetingGroup.meetingGroupIdentifier) {
						config.default.identifier = customMeetingGroup.meetingGroupIdentifier;
					}
				}
			});

			// Setup the custom country codes to use later on the default meetings group
			if (customMeetingGroup.countryCodes) {
				customCountryCodes.push(...customMeetingGroup.countryCodes);
			}
		});

		// Add the default group filter
		Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
			config.default.filter = customFilterCreator(doesNotContainCountryCodes, raceTypeCode, customCountryCodes);
		});
	}

	// Add the meetings to the filtering rules and sort by ordinals
	const meetingFilteringRules =
		// Add the gallop meetings
		Object.values(meetingGroupsConfig[RACING_THOROUGHBRED_CODE]).sort(sortMeetingGroups);
	// Add the greyhound meetings
	//.concat(Object.values(meetingGroupsConfig[RACING_GREYHOUNDS_CODE]).sort(sortMeetingGroups))
	// Add the harness meetings
	//.concat(Object.values(meetingGroupsConfig[RACING_HARNESS_CODE]).sort(sortMeetingGroups));

	// Apply our filtering rules and the status/grade sorting to the meetings
	meetingFilteringRules.forEach((group) => {
		meetingGroups.push({
			title: group.title,
			groupFilterId: group.default ? 'default' : group.title,
			identifier: group.identifier,
			meetings: meetings.filter(group.filter).sort(compareMeetingsByStatusAndGrade),
		});
	});

	return meetingGroups;
};
/**
 * Builds array of meetings groups using the following fields:
 *      title       name for the meeting group when rendering;
 *      meetings    array of meeting entities.
 * @HW 21Nov2019 harness meetings
 * @param meetings
 * @param groupMeetingsByCountry
 * @return {Array}
 */
const groupMeetings_Harness = (meetings = [], groupMeetingsByCountry, customMeetingGroups) => {
	const meetingGroups = [];

	// Setup the meeting groups config with the default
	const meetingGroupsConfig = {
		[RACING_THOROUGHBRED_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_THOROUGHBRED,
				filter: isGallops,
				default: true,
			},
		},
		[RACING_GREYHOUNDS_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_GREYHOUNDS,
				filter: isGreyhounds,
				default: true,
			},
		},
		[RACING_HARNESS_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_HARNESS,
				filter: isHarness,
				default: true,
			},
		},
	};

	// If the deprecated feature toggle 'groupMeetingsByCountry' is enabled then sort by AU/NZ & International
	if (groupMeetingsByCountry && groupMeetingsByCountry.enabled) {
		const countryCodes = [RACING_AU, RACING_NZ];

		Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
			config.local = {
				title: `${RACING_GROUP_TITLE_LOCAL} ${config.default.title}`,
				filter: customFilterCreator(containsCountryCodes, raceTypeCode, countryCodes),
				ordinal: 1,
				identifier: 'state',
				default: false,
			};

			config.default.title = `${RACING_GROUP_TITLE_INTERNATIONAL} ${config.default.title}`;
			config.default.filter = customFilterCreator(doesNotContainCountryCodes, raceTypeCode, countryCodes);
			config.default.identifier = 'state';
		});
	}

	// Otherwise use the custom groups if supplied
	else if (customMeetingGroups && customMeetingGroups.enabled) {
		const customMeetingGroupsConfig = customMeetingGroups.value;
		const customCountryCodes = [];

		// Loop through the custom meeting groups and then add the group to eah race type
		customMeetingGroupsConfig.map(function(customMeetingGroup) {
			Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
				if (customMeetingGroup.countryCodes && customMeetingGroup.countryCodes.length > 0) {
					config[customMeetingGroup.title] = {
						title: `${customMeetingGroup.title} ${RACING_TYPES_LOOKUP[raceTypeCode]}`,
						filter: customFilterCreator(containsCountryCodes, raceTypeCode, customMeetingGroup.countryCodes),
						ordinal: customMeetingGroup.ordinal,
						identifier: customMeetingGroup.meetingGroupIdentifier,
						default: false,
					};
				} else {
					// If there are no countryCodes in the config then add the title & identifier to the default group if they exist
					if (customMeetingGroup.title) {
						config.default.title = `${customMeetingGroup.title} ${RACING_TYPES_LOOKUP[raceTypeCode]}`;
					}
					if (customMeetingGroup.meetingGroupIdentifier) {
						config.default.identifier = customMeetingGroup.meetingGroupIdentifier;
					}
				}
			});

			// Setup the custom country codes to use later on the default meetings group
			if (customMeetingGroup.countryCodes) {
				customCountryCodes.push(...customMeetingGroup.countryCodes);
			}
		});

		// Add the default group filter
		Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
			config.default.filter = customFilterCreator(doesNotContainCountryCodes, raceTypeCode, customCountryCodes);
		});
	}

	// Add the meetings to the filtering rules and sort by ordinals
	const meetingFilteringRules =
		// Add the harness meetings
		Object.values(meetingGroupsConfig[RACING_HARNESS_CODE]).sort(sortMeetingGroups);

	// Apply our filtering rules and the status/grade sorting to the meetings
	meetingFilteringRules.forEach((group) => {
		meetingGroups.push({
			title: group.title,
			groupFilterId: group.default ? 'default' : group.title,
			identifier: group.identifier,
			meetings: meetings.filter(group.filter).sort(compareMeetingsByStatusAndGrade),
		});
	});

	return meetingGroups;
};

/**
 * Builds array of meetings groups using the following fields:
 *      title       name for the meeting group when rendering;
 *      meetings    array of meeting entities.
 * @HW 21Nov2019  greyhounds meetings
 * @param meetings
 * @param groupMeetingsByCountry
 * @return {Array}
 */
const groupMeetings_greyhounds = (meetings = [], groupMeetingsByCountry, customMeetingGroups) => {
	const meetingGroups = [];

	// Setup the meeting groups config with the default
	const meetingGroupsConfig = {
		[RACING_THOROUGHBRED_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_THOROUGHBRED,
				filter: isGallops,
				default: true,
			},
		},
		[RACING_GREYHOUNDS_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_GREYHOUNDS,
				filter: isGreyhounds,
				default: true,
			},
		},
		[RACING_HARNESS_CODE]: {
			default: {
				title: RACING_GROUP_TITLE_HARNESS,
				filter: isHarness,
				default: true,
			},
		},
	};

	// If the deprecated feature toggle 'groupMeetingsByCountry' is enabled then sort by AU/NZ & International
	if (groupMeetingsByCountry && groupMeetingsByCountry.enabled) {
		const countryCodes = [RACING_AU, RACING_NZ];

		Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
			config.local = {
				title: `${RACING_GROUP_TITLE_LOCAL} ${config.default.title}`,
				filter: customFilterCreator(containsCountryCodes, raceTypeCode, countryCodes),
				ordinal: 1,
				identifier: 'state',
				default: false,
			};

			config.default.title = `${RACING_GROUP_TITLE_INTERNATIONAL} ${config.default.title}`;
			config.default.filter = customFilterCreator(doesNotContainCountryCodes, raceTypeCode, countryCodes);
			config.default.identifier = 'state';
		});
	}

	// Otherwise use the custom groups if supplied
	else if (customMeetingGroups && customMeetingGroups.enabled) {
		const customMeetingGroupsConfig = customMeetingGroups.value;
		const customCountryCodes = [];

		// Loop through the custom meeting groups and then add the group to eah race type
		customMeetingGroupsConfig.map(function(customMeetingGroup) {
			Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
				if (customMeetingGroup.countryCodes && customMeetingGroup.countryCodes.length > 0) {
					config[customMeetingGroup.title] = {
						title: `${customMeetingGroup.title} ${RACING_TYPES_LOOKUP[raceTypeCode]}`,
						filter: customFilterCreator(containsCountryCodes, raceTypeCode, customMeetingGroup.countryCodes),
						ordinal: customMeetingGroup.ordinal,
						identifier: customMeetingGroup.meetingGroupIdentifier,
						default: false,
					};
				} else {
					// If there are no countryCodes in the config then add the title & identifier to the default group if they exist
					if (customMeetingGroup.title) {
						config.default.title = `${customMeetingGroup.title} ${RACING_TYPES_LOOKUP[raceTypeCode]}`;
					}
					if (customMeetingGroup.meetingGroupIdentifier) {
						config.default.identifier = customMeetingGroup.meetingGroupIdentifier;
					}
				}
			});

			// Setup the custom country codes to use later on the default meetings group
			if (customMeetingGroup.countryCodes) {
				customCountryCodes.push(...customMeetingGroup.countryCodes);
			}
		});

		// Add the default group filter
		Object.entries(meetingGroupsConfig).forEach(([raceTypeCode, config]) => {
			config.default.filter = customFilterCreator(doesNotContainCountryCodes, raceTypeCode, customCountryCodes);
		});
	}

	// Add the meetings to the filtering rules and sort by ordinals
	const meetingFilteringRules =
		// Add the greyhounds meetings
		Object.values(meetingGroupsConfig[RACING_GREYHOUNDS_CODE]).sort(sortMeetingGroups);

	// Apply our filtering rules and the status/grade sorting to the meetings
	meetingFilteringRules.forEach((group) => {
		meetingGroups.push({
			title: group.title,
			groupFilterId: group.default ? 'default' : group.title,
			identifier: group.identifier,
			meetings: meetings.filter(group.filter).sort(compareMeetingsByStatusAndGrade),
		});
	});

	return meetingGroups;
};

/**
 *  ======================================================================================================
 */

/**
 * Create Look up object with ids for each race with active bets.
 * 'event_id' represent race
 * 'competition_id' represents meeting
 *
 * If meetingId is passed, only such meeting and its races will be flagged.
 * Disabled bet icon for cacheout status
 *
 * @param bets
 * @param meetingId
 * @return {*}
 */
const searchRacesWithBets = (bets = [], meetingId) => {
	return bets.reduce((acc, bet) => {
		if (!Array.isArray(bet.bet_selections) || bet.status === 'cashout') {
			return acc;
		}

		bet.bet_selections.forEach((selection) => {
			// Avoids creating 'undefined' key:
			if (!selection.competition_id || !selection.event_id) {
				return;
			}

			// If selection is not valid
			if (!isValidActiveRacingSelection(selection, bet)) {
				return;
			}

			// If meetingId exist but selection does not belong to meeting
			if (meetingId && selection.competition_id !== meetingId) {
				return;
			}

			//If meeting still doesn't exist, create.
			if (!acc[selection.competition_id]) {
				acc[selection.competition_id] = {};
			}
			//If race still doesn't exist, create.
			if (!acc[selection.competition_id][selection.event_id]) {
				acc[selection.competition_id][selection.event_id] = true;
			}
		});

		// console.log(acc)
		return acc;
	}, {});
};

/**
 * Receives denormalized bets and build list with all active bets for given race.
 *
 * @param bets
 * @param raceId
 * @param selections
 * @returns {*|Array}
 */
const getSingleBetsForRace = (bets = [], raceId, selections = []) => {
	// Backup race attached to the selection in the instance that the bet prompt is not opened from racing home
	if (!raceId && selections && selections.length) {
		const selection = selections[0];
		raceId = selection.race_id || (selection.race && selection.race.id);
	}
	return bets.reduce((acc, bet) => {
		if (!Array.isArray(bet.bet_selections)) {
			return acc;
		}

		bet.bet_selections.find((selection) => {
			// Avoid creating 'undefined' key:
			if (!selection.event_id) {
				return false;
			}

			// If selection is not valid
			if (!isValidActiveRacingSelection(selection, bet)) {
				return false;
			}

			if (selection.event_id === raceId) {
				acc.push(bet);
				return true;
			}
		});

		return acc;
	}, []);
};

/**
 * Checks whether a selection is of racing market and is not from multi bet.
 * @param selection
 * @param bet
 * @return {boolean}
 */
const isValidActiveRacingSelection = (selection, bet) => {
	return selection.market_name === RACING_MARKET_NAME && bet.bet_type !== BET_TYPE_MULTI;
};

/**
 * Given a lookup table with every meeting and race with bets.
 * Traverse meetings adding flag 'hasBets' to those meetings and races
 * that have bets.
 *
 * @param meetings
 * @param meetingsAndRacesWithBets
 * @return {Array}
 */
const signMeetingsAndRacesWithBets = (meetings = [], meetingsAndRacesWithBets = {}) => {
	// If no bet found.
	if (Object.keys(meetingsAndRacesWithBets).length === 0) {
		return meetings;
	}
	return meetings.map((meeting) => {
		// If meeting has no race with bets.
		if (!meetingsAndRacesWithBets[meeting.id]) {
			return meeting;
		}

		let races = signRacesWithBets(meeting.races, meetingsAndRacesWithBets[meeting.id]);
		return {
			...meeting,
			hasBets: true,
			races,
		};
	});
};

/**
 * Create new race objects adding field hasBets.
 * @param races
 * @param {object} racesWithBets {raceId: true [, {raceId: true} ...] }
 * @return {Array} new array with races
 */
const signRacesWithBets = (races = [], racesWithBets = {}) => {
	return races.map((race) => {
		// If race has no bets
		if (!racesWithBets[race.id]) {
			return race;
		}

		return {
			...race,
			hasBets: true,
		};
	});
};

/**
 * Calculate the Margin Butt Length from the products
 *
 * @param race
 * @returns {string}
 */
const getGoatMarginButtLength = (race) => {
	const marginButtProducts = race.products.filter((product) => product.bet_type === RACING_BET_TYPE_MARGIN);
	if (marginButtProducts && marginButtProducts.length) {
		// One length per race applicable to all runners at a given time
		return marginButtProducts[0].margin;
	}

	return '';
};

/**
 * Determine the runners position percent based on their position and the total number of runners
 *
 * @param position
 * @param selectionsLength
 * @returns {number}
 */
const determinePositionPercent = (position, selectionsLength = 10) => {
	if (position === 0 || position === null || !selectionsLength) {
		return 0;
	}

	// Position's start at 1, but we want them to start from 0
	return 1 - (position - 1) / selectionsLength;
};

/**
 * Calculate the speed colour of a horses position
 *
 * @param position
 * @param selectionsLength
 * @returns {string}
 */
const determineSpeedColour = (position, selectionsLength = 10) => {
	const positionPercent = determinePositionPercent(position, selectionsLength);
	if (positionPercent >= 1) {
		return leaderKey;
	} else if (positionPercent >= 0.7) {
		return offpaceKey;
	} else if (positionPercent >= 0.4) {
		return midfieldKey;
	}

	return backmarkerKey;
};

/**
 * Separate settlingPositions by row
 *
 * @param selections
 * @returns {*[]}
 */
const getSettlingRows = (selections) => {
	// Create new array with all unique row letters so we can map the rows
	return [...new Set(selections.map((selection) => selection.row))].sort();
};

/**
 * Get the largest position number, that is the backing position
 *
 * @param selections
 * @param key
 * @returns {number}
 */
const getBackingPosition = (selections, key = 'early_speed_position') => {
	// This will be used to calculate all positions
	return Math.max(...new Set(selections.map((selection) => selection[key])));
};

/**
 * Grab the fixed product from a race, otherwise grab the tote product
 *
 * @param raceProducts
 * @param selectionPrices
 * @param betType
 * @param isBettingAvailable
 * @returns {*}
 */
const getBetProduct = (
	raceProducts = [],
	selectionPrices = [],
	betType = RACING_BET_TYPE_WIN,
	isBettingAvailable = true,
) => {
	let betProduct;

	if (isBettingAvailable && raceProducts && raceProducts.length) {
		betProduct = raceProducts.find((product) => product.available && product.fixed && product.bet_type === betType);

		if (
			!betProduct ||
			// Check that a price exists for the product
			!selectionPrices.some(
				(price) =>
					price.product_code === betProduct.product_code &&
					price.product_id === betProduct.product_id &&
					price[`${betType}_odds`],
			)
		) {
			betProduct = raceProducts.find((product) => product.available && !product.fixed && product.bet_type === betType);
		}
	}

	return betProduct;
};
const getBackingPositionForComputerTips = (selections, key = 'points') => {
	// This will be used to calculate all positions

	return Math.max(...new Set(selections.map((selection) => selection[key])));
};
/**
 * Builds an array of bet buttons that will be displayed for each selection
 *
 * @param prices
 * @param displayedBetProducts
 * @param betType
 * @returns {function(*): Array}
 */
const buildSelectionBetButtons = (prices, displayedBetProducts, betType, raceType) => (dispatch) => {
	let betButtons = [];
	let priceAvailable;
	const displayMultiButton = !RACING_EXOTIC_BET_TYPES.includes(betType);
	displayedBetProducts.forEach((product, index) => {
		if (!product) {
			return;
		}

		//get the price for the current product
		let price = null;
		let liability = null;
		let priceRollups = 0;

		if (prices) {
			let priceObject = createPriceForSelectedProduct(product, prices);
			// A price may not exist even if the prices array exists
			if (priceObject) {
				// check the products bet type to determine which price to return @AR
				if (
					product.product_id === 19 ||
					product.product_id === 20 ||
					product.product_id === 21 ||
					product.product_id === 22 ||
					product.product_id === 23 ||
					product.product_id === 24 ||
					product.product_id === 25 ||
					product.product_id === 26 ||
					product.product_id === 27 ||
					product.product_id === 28 ||
					product.product_id === 29 ||
					product.product_id === 30 ||
					product.product_id === 31 ||
					product.product_id === 36
				) {
					price = priceObject['win_odds'];
				} else if (product.product_id === 33 || product.product_id === 34 || product.product_id === 35) {
					price = priceObject['place_odds'];
				} else {
					price = priceObject[`${product.bet_type}_odds`];
				}

				priceAvailable = true;
				liability = priceObject.liability;
			}
		}

		let initialPrice = price;
		if (!price) {
			price = product.product_code;
			initialPrice = price;
			// If there is no price and product is fixed disable betting.
			if (
				product.product_type === RACING_BET_TYPE_EACHWAY ||
				toteProducts.includes(product.product_code) ||
				product.product_code === RACING_BET_TYPE_TF
			) {
				priceAvailable = true;
			} else {
				priceAvailable = !product.fixed;
			}

			if (product.product_code === RACING_BET_TYPE_SP) {
				price = 'SP';
			} else if (
				product.bet_type == RACING_SAME_RACE_MULTI_TYPE ) {
					price = SRMTypes[product.product_code];
			} else if (
				raceType &&
				toteProducts.includes(product.product_code) &&
				TOTE_NAMES_BY_PRODUCT[product.product_code]
			) {
				price = TOTE_NAMES_BY_PRODUCT[product.product_code][raceType];
			} else {
				price = product.product_code;
			}
		} else if (product.product_type === GOAT_PRODUCT_TYPE_BOOST) {
			// Get the number of rolls to perform
			priceRollups = dispatch(fetchRollTablePrice(true, price)).rolls;
			// Set the boosted price Ashan
			price = dispatch(fetchFixedPriceRollup(true, price));
		}
		// add the button to the selections buttons array
		betButtons.push({
			index,
			price,
			initialPrice,
			priceRollups,
			liability,
			product_id: product.product_id,
			product_code: product.product_code,
			product_type: product.product_type,
			bet_type: product.bet_type,

			// Availability of multi bet is based on product
			hasMulti: true,

			// if the product is not available then disable the bet button
			productAvailable: product.available && price && priceAvailable,
		});
	});

	return betButtons;
};

const getSelectionsOnMultiBetByBet = (state, race_id) => {
	const selections = state[PLACE_MULTI_BET].selections;
	return selections.filter((selection) => selection.race_id === race_id);
	// return selections.filter((selection) => selection.bet_type === betType);
};

export {
	betPromptRaceId,
	buildBetsForRace,
	buildExoticProducts, //@HW 21Nov2019
	buildGallopsMeetingGroups, //@HW 21Nov2019
	buildGallopsMeetingGroupsFromGroup, //@HW 21Nov2019
	buildGreyhoundsMeetingGroups, //@HW 21Nov2019
	buildGreyhoundsMeetingGroupsFromGroup, //@HW 21Nov2019
	buildGreyhoundsMeetingsListGroups, //@HW 21Nov2019
	buildHarnessMeetingGroups, //@HW 21Nov2019
	buildHarnessMeetingGroupsFromGroup,
	buildMeetingGroups,
	buildMeetingGroupsByType,
	buildMeetingGroupsFromGroup,
	buildMeetingGroupsFromGroupByType,
	buildMeetingsListGroups, //@HW 21Nov2019
	buildMeetingsListGroups_Harness,
	buildMeetingsListGroupsByGRS,
	buildMobileWinPlaceChoices, //@HW 09JUNE2020
	buildNavGallopsMeetingsListGroupsByGRS, //@HW 25Agu2020
	buildNavGreyhoundsMeetingsListGroupsByGRS,
	buildNavHarnessMeetingsListGroupsByGRS,
	buildOddsGridPrice,
	buildRacingExoticResults,
	buildSelectionBetButtons,
	buildWinPlaceProducts,
	buildWinPlaceResults,
	buildWinPlaceTournamentProducts,
	determinePositionPercent,
	determineSpeedColour,
	getBackingPosition,
	getBackingPositionForComputerTips,
	getBetbuilderSelectedMeeting,
	getBetProduct,
	getDenormalizedMeeting,
	getFeaturedRaces,
	getFlucsKey,
	getGoatMarginButtLength,
	getMeetings,
	getNextFourFilteredRacesToJump,
	getNextToJumpRaces,
	getNextToJumpRaceSelections,
	getNextToJumpSellingRace,
	getNotScratchedSelections,
	getNumberOfNotScratchedSelections,
	getRaceByID,
	getSelectedMeeting,
	getSelectedRace,
	getSelectionsFromTips,
	getSelectionsOnMultiBetByBet,
	getSettlingRows,
	getSingleBetsForRace,
	groupMeetings, //@HW 21Nov2019
	GRSBuildWinPlaceProducts,
	racingHomeRaceId,
	searchRacesWithBets,
	shouldRenderFormsButton,
	signMeetingsAndRacesWithBets,
	signRacesWithBets,
	sortMeetingGroups,
	tournamentsRaceId,
};
