import React from 'react';
import { Column, Container, StyledSection, StyledSectionButton, StyledSectionTitle } from '../../Components';
import { setDefaultUserSettings } from '../../../../../store/defaultBetAmounts/defaultBetAmountsActions';
import { withNamespaces } from 'react-i18next';
import { createErrorBoundary } from '../../../../ErrorBoundary/ErrorBoundaryActions';
import { connect } from 'react-redux';
import { routeTo } from '../../../../../store/application/applicationActions';
import { DEACTIVATE_ACCOUNT } from '../../../../../pages/DeactivatedAccount/DeactivatedAccountConstans';
import { Text } from '@tbh/ui-kit';
import { USER_ACCOUNT_COLOR } from '../../../../../constants/themes';

const DeactivateAccountSection = ({ user, goToDeactivateAccount, brandDetails }) => {
	return (
		<div>
			<Text size="1">Deactivate My Account</Text>
			<Container>
				<Text size="-2">
					Click here if you wish to close your account. Any request to re-open your account should be sent to{' '}
					{brandDetails.email}
				</Text>

				<Column justifyContent="flex-start" alignItems="flex-start">
					<StyledSectionButton color={USER_ACCOUNT_COLOR.deactive_button_color} fontColor={USER_ACCOUNT_COLOR.deactive_font_color} onClick={() => goToDeactivateAccount()}>
						Deactivate Account
					</StyledSectionButton>
				</Column>
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => {
	const authenticatedUser = state.application.authenticatedUser;
	const user = state.entities.users[authenticatedUser] || {};

	const settings = user.settings || {};

	return {
		user,
		seetings: settings,
		deactivate: settings.deactivate || false,
		brandDetails: state.acl.brandDetails,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		goToDeactivateAccount: () => {
			dispatch(routeTo(`/${DEACTIVATE_ACCOUNT}`));
		},
		updateUserSetting: (user, setting) => {
			dispatch(setDefaultUserSettings(user, setting));
		},
	};
};
export default withNamespaces()(
	createErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(DeactivateAccountSection)),
);
