import React from 'react';
import styled, { css } from 'react-emotion';
import moment from 'moment';
import { Text, typography } from '@tbh/ui-kit';
import { FAMILYS } from '../../../constants/themes';
import TopSelectionListView from './TopSelectionListView';
import { withNamespaces } from 'react-i18next';
import { createErrorBoundary } from '../../../containers/ErrorBoundary/ErrorBoundaryActions';
import { connect } from 'react-redux';
import { navigateToNextToJumpRaceWithType, navigateToRaceWithType } from '../../../store/GRSracingHome/GRSracingHomeActions';
const ExpertTipsListWrapper = styled('div')`
	padding-bottom: 10px;

	&:not(:last-of-type) {
		border-bottom: 1px solid #e3e3e3;
		margin-bottom: 12px;
	}
`;

const ExpertTipsListHeader = styled('div')`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
`;

const ExpertTipsRaceLogo = styled('img')`
	width: 38px;
	height: 38px;
	object-fit: cover;
	margin-right: 10px;
`;

const ExpertTipsRaceTitle = styled('h3')(
	(props) => css`
		font-weight: 500;
		font-size: 17px;
		color: #000000;
		text-transform: capitalize;
		font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
		margin-top: 0;
	`,
);

const ExpertTipsListSubHeader = styled('div')`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 6px;
`;

const ExpertTipsListMeetingName = styled('a')(
	(props) => css`
		font-weight: 700;
		font-size: 15px;
		color: #000000;
		margin-top: 0;
		margin-bottom: 0;
		font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
		text-decoration: none;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	`,
);

const ExpertTipsListMeetingJumpTime = styled('h6')(
	(props) => css`
		font-weight: 700;
		font-size: 15px;
		color: #000000;
		margin-bottom: 0;
		font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
	`,
);

const ExpertTipsCommentInfo = styled('div')`
	margin-bottom: 10px;
`;

const ExpertTipsCommentText = styled(Text)`
	font-weight: 500;
	font-size: 13px;
	color: #4d4d4d;
	text-align: justify;
`;

const EmptyResultWrapper = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	border-radius: 8px;
`;

const EmptyResultMessage = styled('h4')(
	(props) => css`
		font-weight: 600;
		font-size: 18px;
		color: #161515;
		margin-bottom: 0;
		font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
	`,
);

const ExpertTipsList = ({ tipsList, raceType, eventContext, navigateToRaceWithType }) => {
	let renderTipsList = null;
	if (tipsList.length > 0) {
		renderTipsList = tipsList.map((item, index) => (
			<ExpertTipsListWrapper key={index}>
				<ExpertTipsListHeader>
					<ExpertTipsRaceLogo src={item.comment_source_logo} alt="Logo" />
					<ExpertTipsRaceTitle>{item.comment_source}</ExpertTipsRaceTitle>
				</ExpertTipsListHeader>
				<ExpertTipsListSubHeader>
					<ExpertTipsListMeetingName
						onClick={() =>
							navigateToRaceWithType(
								item.type,
								item.start_datetime,
								item.id,
								item.meeting_id,
								item.meeting_name,
								item.number,
							)
						}
					>
						R{item.number} - {item.meeting_name}
					</ExpertTipsListMeetingName>
					<ExpertTipsListMeetingJumpTime>
						Jump Time: {moment(item.start_datetime).format('LT')}
					</ExpertTipsListMeetingJumpTime>
				</ExpertTipsListSubHeader>
				<ExpertTipsCommentInfo>
					<ExpertTipsCommentText>{item.comment}</ExpertTipsCommentText>
				</ExpertTipsCommentInfo>
				<TopSelectionListView
					eventContext={eventContext}
					race={item}
					raceId={item.id}
					raceType={raceType}
					meetingId={item.meeting_id}
					selections={item.top_selections}
					products={item.products}
				/>
			</ExpertTipsListWrapper>
		));
	} else {
		renderTipsList = (
			<EmptyResultWrapper>
				<EmptyResultMessage>{"Sorry, we couldn't find any results."}</EmptyResultMessage>
			</EmptyResultWrapper>
		);
	}
	return <React.Fragment>{renderTipsList}</React.Fragment>;
};

const mapStateToProps = (state, ownProps) => {
	return {};
};
const mapDispatchToProps = (dispatch) => ({
	navigateToRaceWithType: (type, date, raceId, meetingId) => {
		dispatch(navigateToRaceWithType(type, date, raceId, meetingId));
		App.startSubApp('RacingNewPage');
	},
});

export default withNamespaces()(createErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(ExpertTipsList)));
