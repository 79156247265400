/**
 * NOTE: It is not recommended to use this file directly, and to instead use one of the following:
 * multiBetPlacementActions.js
 * singleBetPlacementActions.js
 * Your own factory that utilizes createNamedActions()
 */
import { batchActions } from 'redux-batched-actions';

import { post } from '../../common/Ajax';
import { createAction } from '../../common/actions/actionHelpers';

import {
	BET_PLACEMENT_RESET,
	BET_PLACEMENT_CLEAR_BETS,
	BET_PLACEMENT_ADD_BET,
	BET_PLACEMENT_ADD_TOURNAMENT_BET,
	BET_PLACEMENT_SET_BETS,
	BET_PLACEMENT_SET_LEGS,
	BET_PLACEMENT_ADD_LEG,
	BET_PLACEMENT_REMOVE_LEG,
	BET_PLACEMENT_REPLACE_LEG,
	BET_PLACEMENT_SET_SELECTIONS,
	BET_PLACEMENT_ADD_TOURNAMENT_SELECTION,
	BET_PLACEMENT_ADD_SELECTION,
	BET_PLACEMENT_REMOVE_SELECTION,
	BET_PLACEMENT_REPLACE_SELECTION,
	BET_PLACEMENT_SET_ERROR,
	BET_PLACEMENT_UPDATE,
	USE_BONUS_BET,
	BET_PLACEMENT_SET_SRM,
	BET_PLACEMENT_REPLACE_SRM,
	BET_PLACEMENT_REMOVE_SRM,
	BET_PLACEMENT_ADD_SRM,
	BET_PLACEMENT_SET_LOADING,
	BET_PLACEMENT_CHANGE_SELECTION,
	BET_PLACEMENT_QUADDIES_REPLACE,
	BET_PLACEMENT_QUADDIES_ADD,
	BET_PLACEMENT_QUADDIES_REMOVE,
	BET_PLACEMENT_EXOTICS_REMOVE,
	BET_PLACEMENT_EXOTICS_REPLACE,
	BET_PLACEMENT_EXOTICS_ADD,
	BET_PLACEMENT_ADD_RACE,
	BET_PLACEMENT_REMOVE_RACE,
	BET_PLACEMENT_CLEAR_RACES,
	BET_PLACEMENT_REPLACE_RACE,
	BETSLIP_TAB,
} from './betPlacementActionTypes';

import {
	DEFAULT_SPORT_ORIGIN,
	DEFAULT_RACING_ORIGIN,
	BET_ENDPOINT_STANDARD,
	BET_ENDPOINT_TOURNAMENTS,
	TAB_BETSLIP,
	TAB_PENDING_BETS,
} from './betPlacementConstants';
import { PLACE_SINGLE_BET, PLACE_MULTI_BET } from './betPlacementReducerNames';
import {
	QUADDIES_BET_TYPES,
	RACING_BET_TYPE_EACHWAY,
	RACING_BET_TYPE_MARGIN,
	RACING_BET_TYPE_PLACE,
	RACING_BET_TYPE_TITLES,
	RACING_BET_TYPE_WIN,
	RACING_EXOTIC_BET_TYPES,
	RACING_ODDS_GIDS,
	RACING_SAME_RACE_MULTI_TYPE,
	RACING_SAME_RACE_MULTI_TYPE_TITLE,
} from '../../common/constants/Racing';
import {
	BET_TYPE_RACE,
	BET_TYPE_DERIVATIVE,
	BET_TYPE_MULTI,
	PRODUCT_TYPE_BOOST,
	PRICE_DIVIDEND,
	toteProducts,
	TOTE_NAMES_BY_PRODUCT,
} from '../entities/constants/BetConstants';
import {
	BET_CANCELLED_STATUS,
	BET_FULL_REFUND_STATUS,
	BET_REJECTED_STATUS,
	BET_PENDING_STATUS,
	BET_PROCESSING_STATUS,
} from '../../common/constants/Bets';

import {
	exoticValidation,
	normalizeAndMergeBets,
	normalizeAndMergeTournamentBets,
} from '../entities/actions/BetActions';
import { updateEntities } from '../../common/actions/actionHelpers';
import { setBetPromptErrors, updateBetPromptDetails } from '../betPrompt/betPromptActions';
import { normalizeSportSelections } from '../../store/entities/schemas/SportSelectionSchema';
import { getGoatMarginButtLength, getRaceByID } from '../../pages/Racing/RacingHome/racingSelectorsGRS';
import { buildLegs, generateLegName } from '../../common/BetPlacement';
import { buildRacingPrices } from '../betPrompt/betPromptActionHelpers';
import { getDerivativeSelectionsInPrompt } from '../../containers/Betting/bettingMemoizedSelectors';
import { getFixedPriceRollup } from '../application/applicationSelectors';
import { fetchAuthenticatedUser } from '../authentication/authenticationActions';
import { trackGaTransaction, trackGtmEvent } from '../trackingPixels/trackingActions';
import { getAuthenticatedUser } from '../application/applicationSelectors';
import { buildPriceUpdates, updatePrices } from '../entities/actions/PriceActions';

import { centsAsDollars } from '../../legacy/core/format';

/**
 * Adds a bet to the payload's bets array
 *
 * @param reducerName
 * @param bet
 * @return {Object}
 */
export const addBet = (reducerName, bet = {}) => {
	const payloadBet = Array.isArray(bet) ? bet : [bet];
	return createAction(BET_PLACEMENT_ADD_BET, payloadBet, { reducerName });
};

export const isUseBonusBet = (isChecked) => {
	const reducerName = 'placeMultiBet';
	return createAction(USE_BONUS_BET, isChecked, { reducerName });
};

/**
 * Adds a tournament bet to the payload's bets array
 * @param reducerName
 * @param bet
 * @return {Object}
 */
export const addTournamentBet = (reducerName, bet = {}) => {
	return createAction(BET_PLACEMENT_ADD_TOURNAMENT_BET, bet, { reducerName });
};

/**
 * Replaces the bets slice with a new set
 * @param reducerName
 * @param bets
 * @returns {Object}
 */
export const setBets = (reducerName, bets = []) => {
	const payloadBet = Array.isArray(bets) ? bets : [bets];
	return createAction(BET_PLACEMENT_SET_BETS, payloadBet, { reducerName });
};

/**
 * Clears the list of placed bets
 *
 * @param reducerName
 * @returns {Object}
 */
export const clearBets = (reducerName) => {
	return createAction(BET_PLACEMENT_CLEAR_BETS, null, { reducerName });
};

/**
 * Adds an exotic bet to the payload's bets array
 * @param reducerName
 * @param bet_type
 * @param amount
 * @param product
 * @return {Object}
 */
export const addExoticBet = (reducerName, bet_type, amount, product) => {
	return addBet(reducerName, {
		bet_type,
		amount,
		product,
	});
};

/**
 * Adds a multi bet to the payload's bets array
 * @param reducerName
 * @param amount
 * @param legs
 * @return {Object}
 */
export const addMultiBet = (reducerName, amount, legs) => {
	return addBet(reducerName, {
		bet_type: 'multi',
		amount,
		legs,
	});
};

/**
 * Adds a sport bet to the payload's bets array
 * @param reducerName
 * @param amount
 * @param ticket
 * @return {Object}
 */
export const addSportBet = (reducerName, amount, ticket) => {
	if (ticket) {
		return addTournamentBet(reducerName, {
			bet_type: 'sport',
			amount,
		});
	}

	return addBet(reducerName, {
		bet_type: 'sport',
		amount,
	});
};

/**
 * Adds a single bet to the payload's bets array
 * @param reducerName
 * @param bet_type
 * @param amount
 * @param ticket
 * @return {Object}
 */
export const addSingleBet = (reducerName, bet_type, amount, ticket) => {
	if (ticket) {
		return addTournamentBet(reducerName, {
			bet_type,
			amount,
		});
	}

	return addBet(reducerName, {
		bet_type,
		amount,
	});
};

/**
 * Removes structured selection the payload's leg array
 * @param reducerName
 * @param leg
 * @return {Object}
 */
export const removeLeg = (reducerName, leg) => {
	return createAction(BET_PLACEMENT_REMOVE_LEG, leg, { reducerName });
};

/**
 * Replaces structured leg the payload's leg array
 * @param reducerName
 * @param leg
 * @return {Object}
 */
export const replaceLeg = (reducerName, leg = {}) => {
	return createAction(BET_PLACEMENT_REPLACE_LEG, leg, { reducerName });
};

/**
 * Adds a structured leg the payload's leg array
 * @param reducerName
 * @param leg
 * @return {Object}
 */
export const addLeg = (reducerName, leg = {}) => {
	const legItems = Array.isArray(leg) ? leg : [leg];
	return createAction(BET_PLACEMENT_ADD_LEG, legItems, { reducerName });
};

/**
 * Replaces the legs slice with a new set
 * @param reducerName
 * @param legs
 * @returns {Object}
 */
export const setLegs = (reducerName, legs = []) => {
	return createAction(BET_PLACEMENT_SET_LEGS, legs, { reducerName });
};

/**
 * Removes structured selection the payload's selection array
 * @param reducerName
 * @param selection
 * @return {Object}
 */
export const removeSelection = (reducerName, selection) => {
	return createAction(BET_PLACEMENT_REMOVE_SELECTION, selection, { reducerName });
};

/**
 * Replaces structured selection the payload's selection array
 * @param reducerName
 * @param selection
 * @return {Object}
 */
export const replaceSelection = (reducerName, selection = {}) => {
	return createAction(BET_PLACEMENT_REPLACE_SELECTION, selection, { reducerName });
};
/**
 * Replaces structured selection the payload's selection array
 * @param reducerName
 * @param data
 * @return {Object}
 */
export const changeSelection = (reducerName, data = {}) => {
	return createAction(BET_PLACEMENT_CHANGE_SELECTION, data, { reducerName });
};

/* Functions for adding selections */

/**
 * Replaces the selections slice with a new set
 * @param reducerName
 * @param selections
 * @returns {Object}
 */
export const setSelections = (reducerName, selections = []) => {
	const betSelections = Array.isArray(selections) ? selections : [selections];
	return createAction(BET_PLACEMENT_SET_SELECTIONS, betSelections, { reducerName });
};

/**
 * Adds a structured selection the payload's selection array
 * @param reducerName
 * @param selection
 * @param ticket
 * @return {Object}
 */
export const addSelection = (reducerName, selection = {}, ticket = null) => {
	if (ticket) {
		return addTournamentSelection(reducerName, selection);
	}
	const betSelection = Array.isArray(selection) ? selection : [selection];
	return createAction(BET_PLACEMENT_ADD_SELECTION, betSelection, {
		reducerName,
	});
};

/**
 * Adds a tournament selection the payload's selection array
 * @param reducerName
 * @param selection
 * @return {Object}
 */
export const addTournamentSelection = (reducerName, selection = {}) => {
	return createAction(BET_PLACEMENT_ADD_TOURNAMENT_SELECTION, selection, { reducerName });
};

/**
 * Adds an exotic selection to the payload's selection array
 * @param reducerName
 * @param id
 * @param bet_type
 * @param product
 * @param position
 * @return {Object}
 */
export const addExoticSelection = (reducerName, id, bet_type, product, position) => {
	return addSelection(reducerName, {
		id,
		bet_type,
		product,
		position,
	});
};

/**
 * Adds a fixed selection to the payload's selection array
 * @param reducerName
 * @param id
 * @param bet_type
 * @param type
 * @param productId
 * @param dividend
 * @param ticket
 * @param margin
 * @return {Object}
 */
export const addFixedSelection = (reducerName, id, bet_type, type, productId, dividend, ticket, margin) => {
	return addSelection(
		reducerName,
		{
			id,
			bet_type,
			margin,
			[`${type}_dividend`]: dividend,
			[`${type}_product`]: productId,
		},
		ticket,
	);
};

/**
 * Adds a fixed selection to the payload's selection array for tournaments.
 * Differs for eachway were we don't add a separate selection like a regular bet.
 * @param reducerName
 * @param id
 * @param bet_type
 * @param products
 * @param dividends
 * @param ticket
 * @returns {Object}
 */
export const addTournamentFixedSelection = (reducerName, id, bet_type, products, dividends, ticket) => {
	return addSelection(
		reducerName,
		{
			id,
			bet_type,
			...products,
			...dividends,
		},
		ticket,
	);
};

/**
 * Adds a sport selection to the payload's selection array
 * @param reducerName
 * @param id
 * @param win_dividend
 * @param product_id
 * @return {Object}
 */
export const addSportSelection = (reducerName, id, win_dividend, product_id) => {
	return addSelection(reducerName, {
		id,
		win_dividend,
		product_id,
	});
};

/**
 * Adds a tote selection to the payload's selection array
 * @param reducerName
 * @param id
 * @param bet_type
 * @param productId
 * @param ticket
 * @param margin
 * @return {Object}
 */
export const addToteSelection = (reducerName, id, bet_type, productId, ticket, margin) => {
	return addSelection(
		reducerName,
		{
			id,
			bet_type,
			margin,
			[`${bet_type}_product`]: productId,
		},
		ticket,
	);
};

/**
 * Adds a tote selection to the payload's selection array for tournaments.
 * Differs for eachway were we don't add a separate selection like a regular bet.
 * @param reducerName
 * @param id
 * @param bet_type
 * @param products
 * @param ticket
 * @returns {Object}
 */
export const addTournamentToteSelection = (reducerName, id, bet_type, products, ticket) => {
	return addSelection(
		reducerName,
		{
			id,
			bet_type,
			...products,
		},
		ticket,
	);
};

/* Setter functions */

/**
 * Generic update function to set the payload
 * @param reducerName
 * @param payload
 * @return {Object}
 */
export const update = (reducerName, payload) => createAction(BET_PLACEMENT_UPDATE, payload, { reducerName });

/**
 * Sets the boxed_flag property on the payload
 * @param reducerName
 * @param boxed_flag
 * @return {Object}
 */
export const setBoxedFlag = (reducerName, boxed_flag) => update(reducerName, { boxed_flag });

/**
 * Sets the isLoading property on the payload
 * @param reducerName
 * @param isLoading
 * @return {Object}
 */
export const setLoading = (reducerName, isLoading) => update(reducerName, { isLoading });

/**
 * Sets the origin property on the payload
 * @param reducerName
 * @param origin
 * @return {Object}
 */
export const setOrigin = (reducerName, origin) => update(reducerName, { origin });

/**
 * Sets the ticket_id on the payload. Also used to determine if it is a tournament bet.
 * @param reducerName
 * @param ticket_id
 * @return {Object}
 */
export const setTicket = (reducerName, ticket_id) => update(reducerName, { ticket_id });

/**
 * Sets the bonus bet property on the payload
 * @param reducerName
 * @param free_credit_flag
 * @return {Object}
 */
export const useBonusBet = (reducerName, free_credit_flag) => update(reducerName, { free_credit_flag });

/**
 * Resets to the initialState, with setting of some optional keys
 * @param reducerName
 * @param boxed_flag
 * @param free_credit_flag
 * @param origin
 * @return {Object}
 */
export const reset = (reducerName, boxed_flag = false, free_credit_flag = false, origin = 0) => {
	return createAction(
		BET_PLACEMENT_RESET,
		{
			boxed_flag,
			free_credit_flag,
			origin,
		},
		{ reducerName },
	);
};

/**
 * Update the error message in the store
 * @param reducerName
 * @param errorMessage
 * @return {Object}
 */
export const setError = (reducerName, errorMessage) => {
	//console.log(errorMessage);
	return createAction(BET_PLACEMENT_SET_ERROR, errorMessage, { reducerName });
};

// functions

/**
 * Check to see if single bet is valid
 * Resolve immediately as we have no single validation
 * @return {Promise}
 */
export const validateSingle = Promise.resolve(true);

/**
 * Validates a multi bet on the server side
 * @return {Promise}
 */

export const validateMulti = async (params) => await post('bets/multi/validate', { selections: params });
/**
 * Validates a multi bet on the server side
 * @return {Promise}
 */

export const validateSameRaceMulti = async (params) => {
	try {
		const { data } = await post('bets/sameracemulti/validate', { selections: params });
		return { ...data, status: 200 };
	} catch (error) {
		let errorMessage = '';

		if (error.response && error.response.data && error.response.data.errors) {
			if (Array.isArray(error.response.data.errors)) {
				for (let i = 0; i < error.response.data.errors.length; i++) {
					if (typeof error.response.data.errors[i] === 'object') {
						for (let key in error.response.data.errors[i]) {
							if (error.response.data.errors[i][key] && Array.isArray(error.response.data.errors[i][key])) {
								errorMessage += error.response.data.errors[i][key].join(' ') + '\n';
							}
						}
					} else {
						errorMessage += error.response.data.errors[i] + '\n';
					}
				}
			} else {
				errorMessage = error.response.data.errors;
			}
		} else {
			errorMessage = error.message;
		}

		if (errorMessage == '') {
			errorMessage = 'There was an error validating your bet. Please try again later.';
		}

		let status = error.response ? error.response.status : 500;

		return { errorMessage, status };
	}
};

/**
 * Validates bet selections from the store, or an optional selections list
 * Is a wrapper for the validateMulti function
 * @param reducerName
 * @param selections
 * @return {Promise}
 */
export const validateSelections = (reducerName, selections) => (dispatch, getState) => {
	const state = getState();
	const params = Array.isArray(selections) ? selections : state[reducerName].selections;
	return validateMulti(params);
};

/**
 * Check if the current selections are valid for the bet type
 * Is run client side as there is no server side validation for exotics
 * @param reducerName
 * @param betType
 * @param selections
 * @param boxed
 * @return {Promise}
 */
export const validateExotic = (reducerName, betType, selections, boxed) => (dispatch, getState) => {
	const state = getState();
	const exoticSelections = Array.isArray(selections) ? selections : state[reducerName].selections;
	const exoticBoxed = boxed !== undefined ? boxed : state[reducerName].boxed_flag;

	return exoticValidation(betType, exoticSelections, exoticBoxed);
};

/**
 * Place a bet, using what is in state
 * @param reducerName
 * @param betPlacement
 * @return {Promise}
 */
export const placeBet = (reducerName, betPlacement) => async (dispatch, getState) => {
	const state = getState();
	const betState = betPlacement || state[reducerName];

	try {
		const endpoint = betState.ticket_id ? BET_ENDPOINT_TOURNAMENTS : BET_ENDPOINT_STANDARD;
		const response = await post(endpoint, betState);
		if (response.status === 200 || response.statusText === 'OK') {
			/*for (const bet of response.data.data) {
				const name = bet.bet_type === 'multi' ? 'BetSlipMultis' : 'BetSlip';
				const user = getAuthenticatedUser(getState());

				const id = `${bet.id}-${user.id}`;
				const transactionData = {
					revenue: centsAsDollars(bet.amount),
				};
				const itemsData = [
					{
						name: name,
						sku: `${name}-${bet.id}`,
						category: bet.bet_type === 'sport' ? 'Single' : bet.bet_type === 'multi' ? 'Multi' : bet.bet_type,
						price: centsAsDollars(bet.amount),
					},
				];

				dispatch(trackGaTransaction(id, transactionData, itemsData));
			}*/

			/**
			 * Normalize and merge the bet entities into the correct slice
			 */
			if (betState.ticket_id) {
				normalizeAndMergeTournamentBets(response.data.data)(dispatch);
			} else {
				//dispatch(trackGtmEvent('successfulBetPlacement'));
				dispatch(normalizeAndMergeBets(response.data.data));

				// Update the user account balance
				//dispatch(fetchAuthenticatedUser());
			}

			return Promise.resolve(response);
		} else {
			if (!response.data.errors) {
				return Promise.reject('Unknown Error has occurred');
			}
			let errors = Array.isArray(response.data.errors)
				? response.data.errors.map((e) => e + ', ')
				: response.data.errors;
			// Return bet response
			return Promise.reject(response.data.errors);
		}
	} catch (error) {
		document.Sentry && document.Sentry.captureException(error);
		return Promise.reject(error);
	}
};

export const handleBetPlacementErrors = (errors) => (dispatch) => {
	const betPromptErrors = Array.isArray(errors) ? errors : errors;
	dispatch(
		batchActions([
			setBetPromptErrors(betPromptErrors),
			updateBetPromptDetails({
				isLoading: false,
			}),
		]),
	);
};

/**
 * Place a single sport bet
 * @param reducerName
 * @param stake
 * @param useBonusBets
 * @param selections
 * @param origin
 * @param ticket
 */
export const placeSportBet = (
	reducerName,
	stake,
	useBonusBets,
	selections = null,
	origin = DEFAULT_SPORT_ORIGIN,
	ticket = null,
) => (dispatch, getState) => {
	const state = getState();
	const betSelections = selections || state.betPrompt.selections;
	const betSelection = betSelections[0];

	// Create bet service and load it up with relevant information
	dispatch(
		batchActions([
			addSportBet(reducerName, stake, ticket),
			addSportSelection(reducerName, betSelection.id, betSelection.odds, betSelection.product_id),
			setOrigin(reducerName, origin),
			setTicket(reducerName, ticket),
			useBonusBet(reducerName, useBonusBets),
			updateBetPromptDetails({
				isLoading: true,
			}),
		]),
	);

	return dispatch(placeBet(reducerName))
		.then((response) => {
			const { data } = response.data;

			dispatch(
				updateBetPromptDetails({
					title: 'Bet Receipt',
					betsPlaced: data.map((bet) => bet.id),
				}),
			);
		})
		.catch((response) => {
			if (response.response) {
				const { data } = response.response;
				const { errors, selections } = data;

				if (selections) {
					// Update the odds to ensure that they are correct
					const isBumped = betSelection.product_type === PRODUCT_TYPE_BOOST;
					betSelection.odds = isBumped ? getFixedPriceRollup(state, false, selections[0].price) : selections[0].price;

					// If the price is bumped and there are price changed errors then we need to update
					// the error message to have the bumped price, and not the base price
					if (isBumped && errors.length) {
						const priceChangedErrorIndex = errors.findIndex((error) => error.includes('Odds have changed'));
						if (priceChangedErrorIndex >= 0) {
							const regex = /\d.+$/;
							errors[priceChangedErrorIndex] = errors[priceChangedErrorIndex].replace(regex, betSelection.odds);
						}
					}

					dispatch(
						batchActions([
							dispatch(updateEntities(normalizeSportSelections(selections).entities)),
							// TODO: This is kind of hacky
							dispatch(updateBetPromptDetails({ selections: [betSelection] })),
						]),
					);
				}

				dispatch(handleBetPlacementErrors(errors));
			} else {
				dispatch(handleBetPlacementErrors(response));
			}
			document.Sentry && document.Sentry.captureException(response);
		})
		.finally(() => {
			// Clear the single bet slice if it was a single bet
			if (reducerName === PLACE_SINGLE_BET) {
				dispatch(reset(reducerName));
			}

			dispatch(
				updateBetPromptDetails({
					isLoading: false,
				}),
			);
		});
};

/**
 * Racing bet start point. Builds BetService instance, fill up with single or exotic bet
 * and handle bet placements and results.
 *
 * @param reducerName
 * @param betSelectionType
 * @param stake
 * @param useBonusBets
 * @param betType
 * @param exoticDetails
 * @param selections
 * @param origin
 * @param ticket
 */
export const placeRacingBet = (
	reducerName,
	betSelectionType,
	stake,
	useBonusBets,
	betType,
	exoticDetails = {},
	selections = null,
	origin = DEFAULT_RACING_ORIGIN,
	ticket = null,
) => (dispatch, getState) => {
	const state = getState();
	let betSelections = selections || state.betPrompt.selections;
	if (state.betPrompt.selectionType === BET_TYPE_DERIVATIVE) {
		// Derivative selections
		betSelections = getDerivativeSelectionsInPrompt(state);
	}

	// Create BetService for racing
	dispatch(
		batchActions([
			setOrigin(reducerName, origin),
			setTicket(reducerName, ticket),
			useBonusBet(reducerName, useBonusBets),
			updateBetPromptDetails({
				isLoading: true,
			}),
		]),
	);

	// If 'exoticDetails' has reasonable data, it's exotic bet
	if (exoticDetails.id) {
		// fill bet service with exotic data
		const type = exoticDetails.id;
		const { isBoxed, productId } = exoticDetails;

		dispatch(batchActions([addExoticBet(reducerName, type, stake, productId), setBoxedFlag(reducerName, isBoxed)]));

		// Add each selection to the bet service
		betSelections.forEach((sel) => {
			const position = isBoxed ? 0 : sel.position;
			dispatch(addExoticSelection(reducerName, sel.id, type, productId, position));
		});
	} else {
		dispatch(addSingleBet(reducerName, betSelectionType, stake, ticket));

		// Eachway will make two bets.
		const types =
			betType === RACING_BET_TYPE_EACHWAY ? [RACING_BET_TYPE_WIN, RACING_BET_TYPE_PLACE, RACING_ODDS_GIDS] : [betType];
		const betSelection = betSelections[0];

		if (ticket) {
			// Eachway bets don't add a separate selection, but instead list both products/dividends for the one selection
			let selectionProducts = {};
			let selectionDividends = {};

			// Tournament bets can be fixed, even if fixed odds are not enabled for the race
			let isFixed = false;
			types.forEach((type) => {
				selectionProducts[`${type}_product`] = betSelection[type].productId;
				selectionDividends[`${type}_dividend`] = betSelection[type].odds;
				isFixed = betSelection[type].fixed;
			});

			/**
			 * Tournaments expect selection data to be at the root level, and in a selections array
			 * AR Change Odds Change to Divident
			 */
			if (isFixed) {
				dispatch(
					batchActions([
						addTournamentFixedSelection(
							reducerName,
							betSelection.id,
							betType,
							selectionProducts,
							selectionDividends,
							ticket,
						),
						setSelections(reducerName, {
							id: betSelection.id,
							bet_type: betType,
							...selectionProducts,
							...selectionDividends,
						}),
					]),
				);
			} else {
				dispatch(
					batchActions([
						addTournamentToteSelection(reducerName, betSelection.id, betType, selectionProducts, ticket),
						setSelections(reducerName, { id: betSelection.id, bet_type: betType, ...selectionProducts }),
					]),
				);
			}
		} else {
			let marginLength;
			if (betType === RACING_BET_TYPE_MARGIN) {
				const race = getRaceByID(state.entities, betSelection.race_id, false);
				marginLength = getGoatMarginButtLength(race);
			}

			// Add each selection to the bet service

			types.forEach((type) => {
				if ((betSelection[type] && betSelection[type].fixed) || betSelection.fixed || betSelection.product_id === 19) {
					dispatch(
						addFixedSelection(
							reducerName,
							betSelection.id,
							betSelectionType === BET_TYPE_RACE || betSelectionType === RACING_BET_TYPE_EACHWAY
								? type
								: betSelectionType,
							type,
							betSelection.product_id === 19
								? betSelection['win'].productId
								: betSelection[type]
								? betSelection[type].productId
								: null,
							betSelection.product_id === 19
								? betSelection['win'].odds
								: betSelection[type]
								? betSelection[type].odds
								: betSelection.price,
							ticket,
							marginLength,
						),
					);
				} else {
					dispatch(
						addToteSelection(
							reducerName,
							betSelection.id,
							type,
							betSelectionType === RACING_ODDS_GIDS ? betSelection['win'].productId : betSelection[type].productId,
							ticket,
							marginLength,
						),
					);
				}
			});
		}
	}

	return dispatch(placeBet(reducerName))
		.then((response) => {
			const { data } = response.data;

			/**
			 * The received bet may have some data to the handled/presented e.g:
			 *     > bet was referred, confirmed, rejected and reason/comment.
			 * Currently this data has been handled by BetPromptContainer.
			 */
			dispatch(
				updateBetPromptDetails({
					title: 'Bet Receipt',
					betsPlaced: data.map((bet) => bet.id),
				}),
			);
		})
		.catch((response) => {
			document.Sentry && document.Sentry.captureException(response);
			if (response.response) {
				const { data } = response.response;
				const { errors } = data;
				dispatch(handleBetPlacementErrors(errors));
			} else {
				dispatch(handleBetPlacementErrors(response));
			}
		})
		.finally(() => {
			// Clear the single bet slice if it was a single bet
			if (reducerName === PLACE_SINGLE_BET) {
				dispatch(reset(reducerName));
			}

			dispatch(
				updateBetPromptDetails({
					isLoading: false,
				}),
			);
		});
};

/**
 * Place a multi bet
 * @param reducerName
 * @param betParams
 * @param useBonusBets
 */
export const placeMultiBet = (reducerName, betParams, useBonusBets = false) => (dispatch) => {
	// Create bet service and load it up with relevant information
	dispatch(
		batchActions([setOrigin(reducerName, 3), useBonusBet(reducerName, useBonusBets), setLoading(reducerName, true)]),
	);
	return dispatch(placeBet(reducerName, betParams))
		.then((response) => {
			const { data } = response.data;
			dispatch(handleMultiBetsPlaced(reducerName, data));
			// Update the user account balance
			{
				/*dispatch(fetchAuthenticatedUser())*/
			}
		})
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			dispatch(handleMultiBetResponse(reducerName, error.response));
		})
		.finally(() => dispatch(setLoading(reducerName, false)));
};

export const handleMultiBetsPlaced = (reducerName, data) => (dispatch, getState) => {
	const state = getState();
	const { selections } = state[reducerName];
	// Build formatted collection of bets
	const bets = data.filter((bet) => !!bet && !bet.error);
	const errors = data.filter((bet) => bet.error);

	let summaries = [];
	let unitSummaries = [];
	let rejected = [];

	dispatch(
		setError(
			reducerName,
			errors.map((e) => e.error),
		),
	);
	if (bets.length == 0) {
		return;
	}

	bets.forEach((bet) => {
		const betSelections = bet.bet_selections.length;

		// Find existing summary of same cardinality, unless it's a single bet
		let summaryExisting = summaries.find((summary) => summary.receipt === bet.id);
		let unitSummaryExisting = null;
		// Add the original entity selection into the bet_selection key
		bet.bet_selections.forEach((betSelection) => {
			betSelection.entitySelection = selections.find((sel) => sel.id === betSelection.selection_id);
		});

		/**
		 * If summary of same cardinality found, modify object
		 */
		if (summaryExisting) {
			summaryExisting = modifyExistingBetSummary(summaryExisting, bet);
		}
		if (unitSummaryExisting) {
			unitSummaryExisting = modifyExistingBetSummary(unitSummaryExisting, bet);
		}
		/**
		 * If we need to add a new summary, or unit summary
		 */
		if (!summaryExisting || !unitSummaryExisting) {
			const length = betSelections;
			const generatedName = generateLegName(length, betSelections);
			const isRefunded = bet.status === BET_FULL_REFUND_STATUS;
			const isRejected = bet.status === BET_REJECTED_STATUS || bet.status === BET_CANCELLED_STATUS;
			const isProcessing = bet.status === BET_PROCESSING_STATUS;
			const isTote =
				toteProducts.includes(bet.product_code) || bet.bet_selections.some((s) => toteProducts.includes(s.product));
			const isExotic = RACING_EXOTIC_BET_TYPES.includes(bet.bet_type) || QUADDIES_BET_TYPES.includes(bet.bet_type);
			// bet_type
			let odds;
			if (isTote) {
				odds = 0;
			} else if (bet.bet_type === 'eachway') {
				odds =
					bet.bet_selections.reduce((acc, s) => {
						return acc + parseFloat(s.fixed_odds);
					}, 0) / 2;
			} else if (bet.bet_type == 'sameracemulti') {
				odds = (bet.display_amount / 100).toFixed(2);
			} else {
				odds = bet.bet_selections
					.reduce((acc, s) => {
						return acc * parseFloat(s.fixed_odds);
					}, 1)
					.toFixed(2);
			}

			const summaryShape = {
				pending: bet.status === BET_PENDING_STATUS ? [bet] : [],
				rejected: isRejected ? [bet] : [],
				refunded: isRefunded ? [bet] : [],
				approved: bet.status !== BET_PENDING_STATUS && !isRejected && !isRefunded && !isProcessing ? [bet] : [],
				processing: isProcessing ? [bet] : [],
				quantity: betSelections,
				number: 1,
				receipt: bet.id,
				product_code: bet.product_code,
				price: odds,
				amount: bet.amount,
				isBonus: bet.free_credit_amount > 0,
				bet_type: bet.bet_type,
				selections: bet.bet_selections,
				selection_string: bet.selection_string,
				isTote: isTote && !isExotic,
				isExotic,
				percentage: bet.percentage,
			};
			// Generate and add summary to collection if it doesn't already exist
			if (!summaryExisting) {
				let name = '';
				let subName = '';
				let info = '';
				if (bet.bet_type === RACING_SAME_RACE_MULTI_TYPE) {
					subName = `Race ${bet.bet_selections[0].race_number} - ${bet.bet_selections[0].competition_name}`;
					name = RACING_SAME_RACE_MULTI_TYPE_TITLE;
				} else if (isExotic) {
					if (QUADDIES_BET_TYPES.includes(bet.bet_type)) {
						subName = `${bet.bet_selections[0].competition_name}`;
					} else {
						subName = `Race ${bet.bet_selections[0].race_number} - ${bet.bet_selections[0].competition_name}`;
					}
					name = RACING_BET_TYPE_TITLES[bet.bet_type];
				} else if (bet.bet_type === BET_TYPE_MULTI) {
					name = generatedName;
				} else if (isTote) {
					info = `R${bet.bet_selections[0].race_number} ${bet.bet_selections[0].competition_name}`;
					name = `#${bet.bet_selections[0].selection_number} ${bet.bet_selections[0].selection_name}`;
					subName =
						TOTE_NAMES_BY_PRODUCT[bet.product_code][bet.bet_selections[0].event_type] +
						' ' +
						bet.bet_type.toUpperCase();
				} else {
					info = `R${bet.bet_selections[0].race_number} ${bet.bet_selections[0].competition_name}`;
					name = `#${bet.bet_selections[0].selection_number} ${bet.bet_selections[0].selection_name}`;
					subName = 'FIXED ' + bet.bet_type.toUpperCase();
				}

				summaries.push({
					...summaryShape,
					name: name,
					subName,
					info,
				});
			}

			// Generate and add the unit summary to the collection if it doesn't already exist
			if (!unitSummaryExisting) {
				let name = '';
				let subName = '';
				let info = '';
				if (bet.bet_type === RACING_SAME_RACE_MULTI_TYPE) {
					subName = `R${bet.bet_selections[0].race_number} ${bet.bet_selections[0].competition_name}`;
					name = RACING_SAME_RACE_MULTI_TYPE_TITLE;
				} else if (isExotic) {
					if (QUADDIES_BET_TYPES.includes(bet.bet_type)) {
						info = `${bet.bet_selections[0].competition_name}`;
					} else {
						info = `R${bet.bet_selections[0].race_number} ${bet.bet_selections[0].competition_name}`;
					}
					name = RACING_BET_TYPE_TITLES[bet.bet_type];
				} else if (bet.bet_type === BET_TYPE_MULTI) {
					name = generatedName;
				} else if (isTote) {
					info = `R${bet.bet_selections[0].race_number} ${bet.bet_selections[0].competition_name}`;
					name = `#${bet.bet_selections[0].selection_number} ${bet.bet_selections[0].selection_name}`;
					subName =
						TOTE_NAMES_BY_PRODUCT[bet.product_code][bet.bet_selections[0].event_type] +
						' ' +
						bet.bet_type.toUpperCase();
				} else {
					info = `R${bet.bet_selections[0].race_number} ${bet.bet_selections[0].competition_name}`;
					name = `#${bet.bet_selections[0].selection_number} ${bet.bet_selections[0].selection_name}`;
					subName = 'FIXED ' + bet.bet_type.toUpperCase();
				}
				unitSummaries.push({ ...summaryShape, name: name, subName, info });
			}
		}
	});
	// Loop though the UnitSummaries to calculate total potential/stake;
	let totalPotential = 0;
	let totalStake = 0;
	unitSummaries = unitSummaries.map((unitSummary) => {
		const isTote = unitSummary.isTote;
		const isExotic = unitSummary.isExotic;
		const approvedNumber = unitSummary.number - unitSummary.rejected.length - unitSummary.refunded.length;
		const unitStake = unitSummary.amount;
		let unitPotential = approvedNumber == 0 ? 0 : Number(unitSummary.price) * (unitSummary.amount / approvedNumber) || 0;
		// const unitPotential = unitSummary.price * (unitSummary.amount / approvedNumber) || 0;
		totalStake += unitSummary.rejected.length || unitSummary.refunded.length ? 0 : unitStake;
		if (unitPotential && totalPotential !== PRICE_DIVIDEND && !isTote && totalPotential !== 'TBD') {
			totalPotential += unitPotential;

			if (unitSummary.isBonus) {
				totalPotential -= unitSummary.amount;
				unitPotential -= unitSummary.amount;
			}

		} else {
			if (isTote || `${totalPotential}`.includes('TOTE')) {
				totalPotential = 'TBD';
			} else {
				totalPotential = PRICE_DIVIDEND;
			}
		}

		if (unitSummary.refunded.length > 0 || unitSummary.rejected.length > 0) {
			totalPotential = 0;
		}

		if (isNaN(totalPotential)) totalPotential = 'n/a';

		return {
			...unitSummary,
			totalStake: unitStake,
			totalPotential: !isTote && !isExotic ? unitPotential : totalPotential
		};
	});

	// if there is pending bets, we need switch the tab to pending
	if (summaries.length > 0 && summaries[0].pending.length > 0) dispatch(handleBetslipTab(TAB_PENDING_BETS));

	dispatch(
		setBets(reducerName, {
			bets,
			totalPotential,
			totalStake,
			summaries,
			unitSummaries,
		}),
	);
};

/**
 * Check the error message to see if the odds or price has changed
 * Odds = Pusher
 * Price = Server side validation
 *
 * @param error
 * @returns {boolean}
 */
const hasPriceChangedError = (error) => error.substr(0, 4) === 'Odds' || error.substr(0, 5) === 'Price';

/**
 * Error handler for when multi bet validation or placement fails
 *
 * @param reducerName
 * @param response
 */
export const handleMultiBetResponse = (reducerName, response) => (dispatch, getState) => {
	// If any selections are invalid
	if (response && response.data) {
		// Ensure we maintain consistency in the selections list that exists in the bet slip at this time
		const state = getState();
		const { selections } = state[reducerName];

		let errorList = [];
		if (response.data.selections || response.data.selection) {
			let errorList = response.data.errors || [response.data.error];
			if (errorList && !Array.isArray(errorList) && typeof errorList === 'object') {
				errorList = Object.values(errorList);
			}

			const selectionsList = response.data.selections || [response.data.selection];

			// Loop through all the selections
			const newSelections = selections.map((selection) => {
				// Don't exclude the selection from multi bets if it was only a price change for a single selection
				selection.singleOnly = !(errorList.length === 1 && hasPriceChangedError(errorList[0]));

				// If there is an invalid selection
				const index = selectionsList.findIndex((responseSelection) => responseSelection.id === selection.id);
				if (index > -1) {
					const error = errorList[index];
					selection.warning = error;

					// If the error is an odds change message, display it as an overlay on the selection
					// otherwise display it as a notification warning for the selection
					selection.invalid = !hasPriceChangedError(error);
					selection.invalidFatal = error.substr(0, 17) === 'Betting is closed';

					// Always ensure the price is up-to-date
					if (selection.isRacing) {
						const { racingBetType } = selection;

						// Grab the new prices, and the old price ID's we are using
						const newSelection = {
							...selectionsList[index],
							...selection.prices,
							win: { bet_type: RACING_BET_TYPE_WIN, product_id: selection.win.productId, ...selection.win },
							place: { bet_type: RACING_BET_TYPE_PLACE, product_id: selection.place.productId, ...selection.place },
						};
						// Check if the selected racing bet type is fixed for the current product
						let isFixed = false;
						// if (racingBetType === RACING_BET_TYPE_EACHWAY) {
						// 	isFixed = selection[RACING_BET_TYPE_WIN].fixed || selection[RACING_BET_TYPE_PLACE].fixed;
						// } else {
						// 	isFixed = selection[racingBetType].fixed;
						// }

						if (racingBetType === RACING_BET_TYPE_EACHWAY) {
							isFixed = selection[RACING_BET_TYPE_WIN].fixed || selection[RACING_BET_TYPE_PLACE].fixed;
						} else if (racingBetType === RACING_ODDS_GIDS) {
							isFixed = selection[RACING_BET_TYPE_WIN].fixed || selection[RACING_BET_TYPE_PLACE].fixed;
						} else if (racingBetType === RACING_SAME_RACE_MULTI_TYPE) {
							isFixed = selection[RACING_BET_TYPE_PLACE].fixed;
						} else {
							isFixed = selection[racingBetType].fixed;
						}

						// TODO: Enable this code, however we're releasing at 4pm on a Friday afternoon, so I am limiting the scope of the change (TBB-6090)
						// Grab the products for the selection
						// const selectedProducts = {};
						// RACING_BASE_BET_TYPES.forEach((racingBetType) => {
						// 	selection[racingBetType].product_id = selection[racingBetType].productId;
						// 	selectedProducts[racingBetType] = selection[racingBetType];
						// });

						// Rebuild the prices for the selection
						const { place, win, prices, oldPrices } = buildRacingPrices(
							newSelection,
							isFixed,
							racingBetType,
							// selectedProducts,
							newSelection[racingBetType],
						);

						// Set the new prices
						selection = {
							...selection,
							place,
							win,
							prices,
							oldPrices,
						};
					} else {
						// Sports prices
						selection.oldPrice = selection.price;
						selection.price = selectionsList[index].price;
					}
				} else {
					selection.invalid = false;
					selection.invalidFatal = false;

					// Clear any previous warnings, unless they were about Odds
					if (selection.warning && !hasPriceChangedError(selection.warning)) {
						selection.warning = '';
					}
				}

				return selection;
			});

			dispatch(updatePrices(buildPriceUpdates(selectionsList)));
			dispatch(setSelections(reducerName, newSelections));
			dispatch(rebuildLegs());
		} else if (response.data.errors || response.data.error) {
			errorList = response.data.errors || [response.data.error];
			if (errorList && !Array.isArray(errorList) && typeof errorList === 'object') {
				errorList = Object.values(errorList);
			}
		} else {
			// Since there were no errors we need to clear any errors that may be left over
			const newSelections = selections.map((selection) => {
				selection.invalid = false;
				selection.invalidFatal = false;

				// Clear any previous warnings, unless they were about Odds
				if (selection.warning && !hasPriceChangedError(selection.warning)) {
					selection.warning = '';
				}

				return selection;
			});

			dispatch(setSelections(reducerName, newSelections));
			dispatch(rebuildLegs());
		}

		if (errorList.length == 0 && response.status == 500) errorList = ['An error occurred while placing the bet.'];
		dispatch(setError(reducerName, errorList));
	}
};

/**
 * Recalculates legs and updates the store
 *
 * @param reducerName
 * @private
 */
export const rebuildLegs = (reducerName = PLACE_MULTI_BET) => (dispatch, getState) => {
	const state = getState()[reducerName];
	const { legs, selections } = state;
	dispatch(setLegs(reducerName, buildLegs(selections, legs)));
};

/**
 * Modify the summary item builds from multi and single bets.
 * NOTE: This function MUTATES the passed in betSummary directly.
 *
 * @param betSummary
 * @param bet
 * @returns {{}}
 */
export const modifyExistingBetSummary = (betSummary = {}, bet = {}) => {
	betSummary.number++;
	betSummary.receipt = null;
	betSummary.price += bet.odds;

	// Append the bet to the list of rejected, pending or approved in the summary
	if (bet.status === BET_PENDING_STATUS) {
		const betSummaryPending = Array.isArray(betSummary.pending) ? [...betSummary.pending] : [];
		if (!betSummaryPending.some((pendingBetSummary) => pendingBetSummary.id === bet.id)) {
			betSummaryPending.push(bet);
		}
		betSummary.pending = betSummaryPending;
	} else if (bet.status === BET_FULL_REFUND_STATUS) {
		const betSummaryRefunded = Array.isArray(betSummary.refunded) ? [...betSummary.refunded] : [];
		if (!betSummaryRefunded.some((refundedBetSummary) => refundedBetSummary.id === bet.id)) {
			betSummaryRefunded.push(bet);
		}
		betSummary.refunded = betSummaryRefunded;
	} else if (bet.status === BET_REJECTED_STATUS || bet.status === BET_CANCELLED_STATUS) {
		const betSummaryRejected = Array.isArray(betSummary.rejected) ? [...betSummary.rejected] : [];
		if (!betSummaryRejected.some((rejectedBetSummary) => rejectedBetSummary.id === bet.id)) {
			betSummaryRejected.push(bet);
		}
		betSummary.rejected = betSummaryRejected;
	} else {
		const betSummaryApproved = Array.isArray(betSummary.approved) ? [...betSummary.approved] : [];
		if (!betSummaryApproved.some((approvedBetSummary) => approvedBetSummary.id === bet.id)) {
			betSummaryApproved.push(bet);
		}
		betSummary.approved = betSummaryApproved;
	}

	if (
		bet.status !== BET_REJECTED_STATUS &&
		bet.status !== BET_CANCELLED_STATUS &&
		bet.status !== BET_FULL_REFUND_STATUS
	) {
		betSummary.amount += bet.amount;
	}

	return betSummary;
};

export const setSRM = (reducerName = PLACE_MULTI_BET, srm = []) => {
	return createAction(BET_PLACEMENT_SET_SRM, srm, { reducerName });
};

/**
 * Adds a structured SRM the payload's SRM array
 * @param reducerName
 * @param srm
 * @return {Object}
 */
export const addSRM = (reducerName = PLACE_MULTI_BET, srm = {}) => {
	const srmItems = Array.isArray(srm) ? srm : [srm];
	return createAction(BET_PLACEMENT_ADD_SRM, srmItems, { reducerName });
};

/**
 * Removes structured srm the payload's srm array
 * @param reducerName
 * @param srm
 * @return {Object}
 */
export const removeSRM = (reducerName = PLACE_MULTI_BET, srm) => {
	return createAction(BET_PLACEMENT_REMOVE_SRM, srm, { reducerName });
};

/**
 * Replaces structured srm the payload's srm array
 * @param reducerName
 * @param srm
 * @return {Object}
 */
export const replaceSRM = (reducerName = PLACE_MULTI_BET, srm = {}) => {
	return createAction(BET_PLACEMENT_REPLACE_SRM, srm, { reducerName });
};

export const setBetLoading = (reducerName = PLACE_MULTI_BET, loading = false) => {
	return createAction(BET_PLACEMENT_SET_LOADING, loading, { reducerName });
};

export const replaceQuaddies = (reducerName = PLACE_MULTI_BET, quaddie = {}) => {
	return createAction(BET_PLACEMENT_QUADDIES_REPLACE, quaddie, { reducerName });
};

export const addQuaddies = (reducerName = PLACE_MULTI_BET, quaddie = {}) => {
	return createAction(BET_PLACEMENT_QUADDIES_ADD, quaddie, { reducerName });
};

export const removeQuaddies = (reducerName = PLACE_MULTI_BET, quaddie = {}) => {
	return createAction(BET_PLACEMENT_QUADDIES_REMOVE, quaddie, { reducerName });
};
export const removeExotics = (reducerName = PLACE_MULTI_BET, exotic = {}) => {
	return createAction(BET_PLACEMENT_EXOTICS_REMOVE, exotic, { reducerName });
};
export const replaceExotics = (reducerName = PLACE_MULTI_BET, exotic = {}) => {
	return createAction(BET_PLACEMENT_EXOTICS_REPLACE, exotic, { reducerName });
};
export const addExotics = (reducerName = PLACE_MULTI_BET, exotic = {}) => {
	return createAction(BET_PLACEMENT_EXOTICS_ADD, exotic, { reducerName });
};

/**
 * Add a selection to the multi bet
 * @param reducerName
 * @param race : {id, status}
 */
export const addRace = (reducerName = PLACE_MULTI_BET, race) => (dispatch, getState) => {
	const state = getState();
	if (state[PLACE_MULTI_BET].races.find(({ id }) => id == race.id)) {
		return;
	}
	dispatch(createAction(BET_PLACEMENT_ADD_RACE, race, { reducerName }));
};

export const removeRace = (reducerName = PLACE_MULTI_BET, race_id) => (dispatch, getState) => {
	const state = getState();
	for (let i = 0; i < state[PLACE_MULTI_BET].selections.length; i++) {
		if (state[PLACE_MULTI_BET].selections[i].race_id == race_id) {
			return;
		}
	}

	for (let i = 0; i < state[PLACE_MULTI_BET].SRMs.length; i++) {
		if (state[PLACE_MULTI_BET].SRMs[i].race_id == race_id) {
			return;
		}
	}

	for (let i = 0; i < state[PLACE_MULTI_BET].SRMs.length; i++) {
		if (state[PLACE_MULTI_BET].exotics[i].race_id == race_id) {
			return;
		}
	}

	for (let i = 0; i < state[PLACE_MULTI_BET].quaddies.length; i++) {
		for (let j = 0; j < state[PLACE_MULTI_BET].quaddies[i].races.length; j++) {
			if (state[PLACE_MULTI_BET].quaddies[i].races[j].id == race_id) {
				return;
			}
		}
	}

	dispatch(createAction(BET_PLACEMENT_REMOVE_RACE, race_id, { reducerName }));
};

export const replaceRace = (reducerName = PLACE_MULTI_BET, race) => (dispatch, getState) => {
	const state = getState();
	if (!state[PLACE_MULTI_BET].races.find(({ id }) => id == race.id)) {
		return;
	}
	dispatch(createAction(BET_PLACEMENT_REPLACE_RACE, race, { reducerName }));
};

export const clearRaces = (reducerName = PLACE_MULTI_BET) => {
	return createAction(BET_PLACEMENT_CLEAR_RACES, race_id, { reducerName });
};

//INTERCEPETED
//PARTIAL

export const cancelPartialBet = async ({ betId }) => async (dispatch, getState) => {
	try {
		const data = await post('/partial-bet', {
			bet_id: betId,
			action: 'rejected',
		});
		return data;
	} catch (e) {
		document.Sentry && document.Sentry.captureException(e);
		if (e.response && e.response.data && e.response.data.errors) {
			dispatch(setError(PLACE_MULTI_BET, e.response.data.errors));
			return e.response.data.errors;
		}

		dispatch(setError(PLACE_MULTI_BET, ['Error  in partial bet']));
		return null;
	}
};

export const partialBet = async ({ amount, betId, flexi }) => async (dispatch, getState) => {
	try {
		let body = {
			bet_id: betId,
			amount: amount,
			action: 'accepted',
		};

		if (flexi) body.flexi = flexi;

		const data = await post('/partial-bet', body);
		return data;
	} catch (e) {
		document.Sentry && document.Sentry.captureException(e);
		if (e.response && e.response.data && e.response.data.errors) {
			dispatch(setError(PLACE_MULTI_BET, e.response.data.errors));

			return e.response.data.errors;
		}

		dispatch(setError(PLACE_MULTI_BET, ['Error  in partial bet']));
		return 'Error  in partial bet';
	}
};

export const handleBetslipTab = (tag = TAB_BETSLIP, reducerName = PLACE_MULTI_BET) => (dispatch) => {
	dispatch(createAction(BETSLIP_TAB, tag, { reducerName }));
};
