// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { css } from 'react-emotion';

// Components
import LoggedUserElements from './LoggedUserElements/LoggedUserElements';
import { Text } from '@tbh/ui-kit';
import { PrimaryButton } from '../../../UI/Buttons';
import {MASTHEAD_COLORS } from '../../../../constants/themes';

// Styles
import StyledMasthead, {
	StyledMasthead__KeepGoing,
	StyledMasthead__Wrapper,
	StyledMasthead__Logo,
	StyledMasthead__LogoImage,
	StyledMasthead__JoinLoginElements,
	StyledMasterhead__Hambuger__Menu,
} from './Masthead.styled';

// Constants
import { SMALL_DESKTOP_MAX_WIDTH, TABLET_MAX_WIDTH } from '../../../../common/constants/Breakpoints';
import TopNavigationMasthead from '../TopNavigation/TopNavigationMasthead';
import { PendingBetCount } from '../../../../containers/Betting/MultiBetContainer/PendingBetCount';

class Masthead extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The size of the component */
		size: PropTypes.shape({
			/** How wide the component is */
			width: PropTypes.number,
		}).isRequired,

		/** When the menu icon is clicked on */
		onMenuClick: PropTypes.func.isRequired,

		/** Action for navigating to the home page */
		goToHome: PropTypes.func.isRequired,

		/** Action to login */
		onLogin: PropTypes.func.isRequired,

		/** Action to go to the registration page */
		goToRegistration: PropTypes.func.isRequired,

		/** Action for login/register */
		onJoinLogin: PropTypes.func.isRequired,

		/** Action for toggling the bet slip */
		onBetSlipClick: PropTypes.func.isRequired,

		/** Action for opening the pending bets modal */
		onBetsClick: PropTypes.func.isRequired,

		/** Action for the add funds button */
		onAddFunds: PropTypes.func.isRequired,

		/** Action when a user wants to reset their password */
		resetPassword: PropTypes.func.isRequired,

		/** Action to open the forgot password form */
		openForgotPassword: PropTypes.func.isRequired,

		/** Action to toggle the account summary view */
		toggleSummaryView: PropTypes.func.isRequired,

		/** Action to clear any error messages that exist */
		clearErrors: PropTypes.func.isRequired,

		/** List of available icons to use */
		brandLogos: PropTypes.object.isRequired,

		/** Whether or not there is a max-width restriction on the site */
		isFullWidth: PropTypes.bool.isRequired,

		/** User's id */
		id: PropTypes.number,

		/** Whether or not a user is logged in */
		isUserLoggedIn: PropTypes.bool,

		/** The user's name */
		name: PropTypes.string,

		/** The user's first name */
		first_name: PropTypes.string,

		/** The users' username */
		username: PropTypes.string,

		/** User's account balance */
		account_balance: PropTypes.number,

		/** Error from the resetPassword function */
		resetPasswordError: PropTypes.string,

		/** If password reset action was successful */
		resetPasswordSuccess: PropTypes.bool,

		/** The number of bets in bet slip multi */
		count_bets: PropTypes.number,

		/** Whether or not the account summary is open */
		summaryOpen: PropTypes.bool,

		/** Any error messages */
		error: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),

		/** Extra classes */
		className: PropTypes.string,

		/** If we are waiting for a login response */
		loadingLogin: PropTypes.bool,

		/** If we are waiting for a reset password response */
		loadingResetPassword: PropTypes.bool,

		/** Whether or not to hide some of the Masthead fields */
		hideMastheadFields: PropTypes.bool,

		isMultiBetSlipOpen: PropTypes.bool.isRequired,

		goToAccountTransactionsPage: PropTypes.func,
	};

	static defaultProps = {
		id: null,
		isUserLoggedIn: false,
		name: '',
		first_name: '',
		username: '',
		account_balance: 0,
		count_bets: 0,
		userBetsCount: 0,
		summaryOpen: false,
		error: null,
		resetPasswordError: null,
		resetPasswordSuccess: false,
		className: null,
		loadingLogin: false,
		loadingResetPassword: false,
		loggedUserClassName: null,
		joinLoginClassName: null,
		hideMastheadFields: false,
		goToAccountTransactionsPage: null,
	};

	constructor(props) {
		super(props);

		this.state = {
			show_account_balance: true,
			username: '',
			password: '',
			email: '',
			showForgotPassword: false,
			error: null,
			resetPasswordError: null,
			prevProps: this.props,
			windowWidth: window.innerWidth,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const prevProps = prevState.prevProps;
		const error = prevProps.error !== nextProps.error ? nextProps.error : prevState.error;
		const resetPasswordError =
			prevProps.resetPasswordError !== nextProps.resetPasswordError
				? nextProps.resetPasswordError
				: prevState.resetPasswordError;
		return {
			error,
			resetPasswordError,
			prevProps: nextProps,
		};
	}

	onLogin = () => {
		if (!this.state.username || !this.state.password) {
			this.setState({
				error: this.props.t('Masthead__LoginError'),
			});
		} else {
			this.props.onLogin(this.state.username, this.state.password);
		}
	};

	/**
	 * Handle changes in the inputs
	 *
	 * @param name
	 * @param value
	 */
	handleChange = (name, value) => {
		this.setState({
			[name]: value,
			resetPasswordError: null,
		});
		this.props.clearErrors();
	};

	/**
	 * Opens the Forgot Password popover
	 */
	openForgotPassword = () => {
		this.setState({
			resetPasswordError: null,
			showForgotPassword: true,
		});
		this.props.openForgotPassword();
	};

	/**
	 * Closes the Forgot Password popover
	 */
	closeForgotPassword = () => {
		this.setState({
			showForgotPassword: false,
			email: '',
		});
	};

	/**
	 * Clears the errors
	 */
	clearError = () => {
		this.setState({
			error: null,
		});
	};

	/**
	 * Resets the password
	 */
	resetPassword = () => {
		this.props.resetPassword(this.state.email);
	};

	render() {
		const {
			t,
			account_balance,
			className,
			count_bets,
			userBetsCount,
			first_name,
			goToHome,
			goToRegistration,
			hideMastheadFields,
			id,
			isUserLoggedIn,
			loadingLogin,
			loadingResetPassword,
			name,
			onBetSlipClick,
			onJoinLogin,
			onMenuClick,
			onBetsClick,
			onAddFunds,
			resetPasswordSuccess,
			summaryOpen,
			toggleSummaryView,
			brandLogos,
			size,
			isFullWidth,
			isMultiBetSlipOpen,
			goToAccountTransactionsPage,
			isMobile,
			toggleShowBalance,
			showBalance,
			free_credit_balance,
			bonus_bet_enabled,
		} = this.props;
		const { email, error, password, resetPasswordError, showForgotPassword, username } = this.state;

		return (
			<StyledMasthead hideMastheadFields={hideMastheadFields} size={size}>
				<StyledMasthead__Wrapper isFullWidth={isFullWidth} size={size}>
					<div
						className={css`
							flex: 0.15 0.5 auto;
							display: flex;
							gap: 4px;
							justify-content: flex-start;
							align-items: center;
						`}
					>
						<StyledMasterhead__Hambuger__Menu mediaSize={size} onClick={onMenuClick}>
							<i className="fa fa-bars fa-2x" aria-hidden="true" />
						</StyledMasterhead__Hambuger__Menu>

						{brandLogos.main && brandLogos.secondary ? (
							// <StyledMasthead__Logo action={handleHomeClick} mediaSize={size}>
							<StyledMasthead__Logo action={goToHome} mediaSize={size}>
								<StyledMasthead__LogoImage
									key={size.width >= TABLET_MAX_WIDTH ? 'main' : 'secondary'}
									src={size.width >= TABLET_MAX_WIDTH ? brandLogos.main : brandLogos.main}
									alt="Logo"
									size={size}
									maxWidth={brandLogos.maxWidth}
									maxHeight={brandLogos.maxHeight}
								/>
							</StyledMasthead__Logo>
						) : null}
					</div>

					<TopNavigationMasthead size={size} />

					{hideMastheadFields ? (
						<StyledMasthead__KeepGoing type="alternate" size="-1" strong>
							{t('Masthead__KeepGoing')}!
						</StyledMasthead__KeepGoing>
					) : (
						<div
							className={css`
								display: flex;
								flex-direction: row;
								padding-right: 4px;
								gap: ${this.state.windowWidth <= TABLET_MAX_WIDTH ? '4px' : '16px'};
								align-items: center;
								justify-content: ${isUserLoggedIn ? 'space-evenly;' : 'flex-end;'}
								max-width: ${this.state.windowWidth <= TABLET_MAX_WIDTH ? '100%' : isUserLoggedIn ? '400px;' : '300px'};
								width: 100%;
								height: 40px;
								margin-left: auto;
							`}
						>
							<PrimaryButton
								style={{
									display: isUserLoggedIn ? 'block' : 'none'
								}}
								modifiers="nav"
								onClick={onBetsClick}
								className={css`
									 background-color: ${MASTHEAD_COLORS.active_bets_bg_color};
									
									&:hover {
									  background-color: ${MASTHEAD_COLORS.active_bets_hover_bg_color};
									}
								`}
							>
								<Text
									tag="span"
									type="primary"
									size="-2"
									textOverflow={size.width < 650 ? 'ellipsis' : null}
									style={{ fontSize: size.width < 650 && '10px',Color: MASTHEAD_COLORS.active_bets_font_color }}
									strong
									mediaSize={size}
									align="center"
								>
									Active Bets
								</Text>
							</PrimaryButton>
							{size.width <= SMALL_DESKTOP_MAX_WIDTH && (
								<PrimaryButton modifiers="nav" onClick={onBetSlipClick}  
								className={css`
									background-color: ${MASTHEAD_COLORS.active_bets_bg_color};
								   
								   &:hover {
									 background-color: ${MASTHEAD_COLORS.active_bets_hover_bg_color};
								   }
							   `}
								>
									<Text
										tag="span"
										type="primary"
										size="-2"
										textOverflow={size.width < 650 ? 'ellipsis' : null}
										style={{ fontSize: size.width < 650 && '10px', position: 'relative' }}
										strong
										mediaSize={size}
										align="center"
									>
										{count_bets > 0 ? 'Bets ' : 'Bets '} {count_bets > 0 ? count_bets : null}
										{userBetsCount > 0 ? (
											<PendingBetCount count={userBetsCount} top={-17} right={-25} type="white" />
										) : null}
									</Text>
								</PrimaryButton>
							)}

							{isUserLoggedIn ? (
								<LoggedUserElements
									size={size}
									account_balance={account_balance}
									first_name={first_name}
									id={id}
									name={name}
									onAddFunds={onAddFunds}
									onBetsClick={onBetsClick}
									openSummaryView={toggleSummaryView}
									show_account_balance={showBalance}
									summaryOpen={summaryOpen}
									username={this.props.username}
									goToAccountTransactionsPage={goToAccountTransactionsPage}
									toggleShowBalance={toggleShowBalance}
									goToPendingBets={this.props.goToPendingBets}
									free_credit_balance={free_credit_balance}
									bonus_bet_enabled={bonus_bet_enabled}
								/>
							) : (
								<StyledMasthead__JoinLoginElements
									//size={size}
									username={username}
									password={password}
									email={email}
									showForgotPassword={showForgotPassword}
									resetPasswordError={resetPasswordError}
									resetPasswordSuccess={resetPasswordSuccess}
									error={error}
									handleChange={this.handleChange}
									openForgotPassword={this.openForgotPassword}
									closeForgotPassword={this.closeForgotPassword}
									clearError={this.clearError}
									resetPassword={this.resetPassword}
									onLogin={this.onLogin}
									goToRegistration={goToRegistration}
									onJoinLogin={onJoinLogin}
									loadingLogin={loadingLogin}
									loadingResetPassword={loadingResetPassword}
								/>
							)}
						</div>
					)}
				</StyledMasthead__Wrapper>
			</StyledMasthead>
		);
	}
}

export default withNamespaces()(Masthead);
