import { connect } from 'react-redux';

import SetLimit from '../SetLimit';
import React, { Fragment, useState } from 'react';
import DepositContainer from '../../../../../containers/Deposit/DepositContainer/DepositContainer';
import styled from 'react-emotion';
import { Notification, Text } from '@tbh/ui-kit';

const StyledTitle = styled(Text)`
	display: flex;
	flex-direction: row;
	align-items: center;

	margin-top: 10px;
	margin-bottom: 10px;
	gap: 6px;
	color: black !important;

	span {
		font-bold: bold;
		color: black;
		padding: 2px 10px;
		background-color: #cdc8c8;
		border-radius: 999px;
	}
`;

export const LastStep = ({ first_name, onClose, renderOnPage, isRegistation, hasDepositLimit }) => {
	const [step, setStep] = useState(0);

	if (hasDepositLimit && !isRegistation) {
		return (
			<Fragment>
				<DepositContainer
					// isQuickDeposit
					trackingCategory="Quick Deposit"
					handleClose={onClose}
					renderOnPage={renderOnPage}
				/>
				<Notification
					type={'info'}
					message={
						'Note: Effective 11th June, 2024 credit cards will no longer be accepted for wagering due to changes in the Australian law.'
					}
				/>
			</Fragment>
		);
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
			<Text size="0" strong align="center">
				Congratulations, {first_name}!
			</Text>
			<Text size="0" className="mt-3" align="center">
				Your account has been created and you are ready to start playing.
			</Text>

			<StyledTitle size="-1" strong>
				<span>1</span>
				Select Deposit Limit
			</StyledTitle>

			<SetLimit isRegistration={true} nextStep={() => setStep(1)} onClose={onClose} />

			<StyledTitle size="-1" strong>
				<span>2</span>
				Add funds
			</StyledTitle>

			{step === 0 && <Text size="-2"> Choose a Deposit Limit option to deposit funds.</Text>}
			{step === 1 && (
				<Fragment>
					<DepositContainer
						isQuickDeposit
						trackingCategory="Quick Deposit"
						handleClose={onClose}
						renderOnPage={renderOnPage}
					/>
					<Notification
						type={'info'}
						message={
							'Note: Effective 11th June, 2024 credit cards will no longer be accepted for wagering due to changes in the Australian law.'
						}
					/>
				</Fragment>
			)}
		</div>
	);
};

const checkLimits = (limits, type) => {
	if (!limits) return false;

	const limitTypes = ['daily', 'weekly', 'monthly'];
	return limitTypes.some((period) => {
		const limit = limits[`${period}_${type}_limit`];
		return limit && limit.bet_limit !== -1;
	});
};

const mapStateToProps = (state, ownProps) => {
	const user = state.entities.users[state.application.authenticatedUser];
	let first_name = '';
	if (user) {
		first_name = user.first_name;
	}
	const hasDepositLimit = checkLimits(state.depositLimits, 'deposit');

	return {
		first_name,
		hasDepositLimit,
		...ownProps,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LastStep);
