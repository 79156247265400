// Check for offline mode
import env from './common/EnvironmentVariables';
import Cookies from 'universal-cookie';
const offlineConfig = env('offline', undefined);
const cookies = new Cookies();

let appIsOffline = false;
let backgroundColour = null;
let textColour = null;
let message = null;

if (offlineConfig) {
	appIsOffline = offlineConfig.active;
	backgroundColour = offlineConfig.backgroundColour;
	textColour = offlineConfig.textColour;
	message = offlineConfig.message;
}

import React from 'react';
import { hot } from 'react-hot-loader';

import RootApplication from './RootApplication';
import Offline from './components/features/Application/Offline/Offline';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';

let CookiesValue = cookies.get('AppAuthLogin');

const App = () => {
	if (appIsOffline) {
		return <Offline backgroundColour={backgroundColour} textColour={textColour} message={message} />;
	}

	return (
		<CacheBuster
			currentVersion={version}
			isEnabled={true}
			isVerboseMode={false}
			loadingComponent={null}
			metaFileDirectory={'.'}
		>
			<RootApplication />
		</CacheBuster>
	);
};

export default hot(module)(App);
