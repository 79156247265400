import { createAction } from '../../common/actions/actionHelpers';
import { serverDateTime } from '../../legacy/core/format';
import moment from 'moment';
import { batchActions } from 'redux-batched-actions';

import { fetchRaceWithSelections } from '../entities/actions/RaceActions';
import {
	fetchMeetingsAndRaces,
	fetchMeetingsAndRacesWithSelectionsForRace,
	fetchMeetingWithNextRaceSelections,
	fetchMeetingAndRacesFilterByDateAndType,
	updateMeeting,
	fetchMeetingAndRacesFilterByDateAndType_GRS,
} from '../entities/actions/MeetingActions';
import { getLoadedRaces } from '../entities/actions/RaceActions';
import { navigate, setBodyLoading, show404, scrollToTop, routeTo } from '../application/applicationActions';

import {
	RACING_HOME_SET_SELECTED_BET_TYPE,
	RACING_HOME_SET_MEETING_LOADING_MASK,
	RACING_HOME_NAVIGATE_TO_MEETING__BATCH,
	RACING_HOME_NAVIGATE_TO_NTJ_RACE__BATCH,
	RACING_HOME_NAVIGATE_TO_RACE__BATCH,
	RACING_HOME_NAVIGATE_TO_HOME__BATCH,
	RACING_HOME_SET_RACE_LOADING_MASK,
	RACING_HOME_SET_CONTAINER_LOADING_MASK,
	RACING_HOME_RESET_FILTERS,
	GRS_RACING_HOME_RESET_FILTERS,
	RACING_HOME_SET_SELECTED_DATE,
	RACING_HOME_SET_SHOWING_MEETINGS,
	RACING_HOME_SET_SHOWING_NTJ_RACES,
	RACING_HOME_SET_EXOTIC_POOLS,
	RACING_HOME_LOADING__BATCH,
	RACING_EXOTIC_QUADDIE_TOGGLE,
	RACING_EXOTIC_DAILYDOUBLE_TOGGLE,
	RACING_EXOTIC_SELECTIONS_TOGGLE,
	GRS_RACING_HOME_SET_SELECTED_TYPE,
	RACING_HOME_SET_BET_BUILDER_MEETING,
	BET_QUADDIES_RESET_LEG_SELECTIONS,
	BET_QUADDIES_LEG_SELECTIONS,
	SET_SELECTED_QUADDIES_RACES,
	GRS_RACING_HOME_SET_RACE_TYPE_FILTERS,
	SET_REQUEST_ID,
	RACING_HOME_SET_BET_TYPES,
} from './GRSracingHomeActionType';
import { RACING_NEW_BASE_URL, SELECTED_DATE_FORMAT } from '../../pages/GRSNewRacing/RacingNewConstrants';
import { fetchNewNextToJumpRaces_GRS } from '../newNextToJumpRaces/newNextToJumpRacesActions';
import { QUADDIES_BET_TYPES, RACING_NON_EXOTIC_BET_TYPE } from '../../common/constants/Racing';
import { buildWithDateGRSRouteToRace } from '../../routes/RacingNew';
import { getMeetings } from '../../pages/GRSNewRacing/RacingNewHome/racingSelectorsGRS';
import { getFeaturedRaces, getNextToJumpRaces, getRaceByID } from '../../pages/Racing/RacingHome/racingSelectorsGRS';
import { getFeatureRaces, getUpcomingRaces } from '../../pages/FeatureRacing/FeatureRacingHome/featureRacingSelectors';
// import { getRaceListIds } from '../../pages/Racing/RacingHome/racingSelectorsGRS';
// import { getSelectedMeeting } from '../../pages/GRSNewRacing/RacingNewHome/racingSelectorsGRS';

// import { buildWithDateGRSRouteToRace } from './../../routes/RacingNew';

/**
 * Choose loading mask based on state, show,
 * fetch data for given race ID and set selected Race in store.
 * Endpoint: 'combined/race/selections'
 *
 * @param {number} raceId
 * @param {number} meetingId
 */
export const navigateToRace = (raceId, meetingId) => (dispatch, getState) => {
	let setLoadingMask = getState().grsRacingHome.selectedRace ? setRaceLoadingMask : setMeetingLoadingMask;
	meetingId = meetingId || getState().grsRacingHome.selectedMeeting;

	const loadedRaces = dispatch(getLoadedRaces());
	const raceWasLoadedPreviously = loadedRaces ? loadedRaces.some((race) => race.id === raceId) : null;

	dispatch(resetRacingFilters(raceId, meetingId));
	dispatch(RACING_HOME_NAVIGATE_TO_RACE__BATCH);
	/**
	 * If the race was loaded previously (it's already in state with selections), don't show the loading mask and
	 * reset the racing filters to show the race, but still fetch the race to update it
	 */

	if (raceWasLoadedPreviously) {
		dispatch(resetRacingFilters(raceId, meetingId));
		return dispatch(fetchRaceWithSelections(raceId));
	}

	/**
	 * If the race wasn't loaded previously, show the loading mask, fetch the race and then reset the racing filters
	 */
	dispatch(setLoadingMask(true));

	return dispatch(fetchRaceWithSelections(raceId)).then(() => {
		dispatch(
			batchActions([resetRacingFilters(raceId, meetingId), setLoadingMask(false)], RACING_HOME_NAVIGATE_TO_RACE__BATCH),
		);
	});
};

export const navigateToLegRace = (raceId, meetingId) => (dispatch, getState) => {
	let setLoadingMask = getState().grsRacingHome.selectedRace ? setRaceLoadingMask : setMeetingLoadingMask;
	meetingId = meetingId || getState().grsRacingHome.selectedMeeting;

	const loadedRaces = dispatch(getLoadedRaces());
	const raceWasLoadedPreviously = loadedRaces ? loadedRaces.some((race) => race.id === raceId) : null;

	/**
	 * If the race was loaded previously (it's already in state with selections), don't show the loading mask and
	 * reset the racing filters to show the race, but still fetch the race to update it
	 */

	let selectedBetType = getState().grsRacingHome.selectedBetType;
	let boxed = getState().grsRacingHome.betTypeBoxed;
	if (!QUADDIES_BET_TYPES.includes(selectedBetType)) {
		selectedBetType = RACING_NON_EXOTIC_BET_TYPE;
		boxed = false;
	}
	let date = getSelectedDate(getState())
		? serverDateTime(moment(getSelectedDate(getState()))).format('YYYY-MM-DD')
		: null;

	if (raceWasLoadedPreviously) {
		dispatch(resetRacingFilters(raceId, meetingId, false));
		dispatch(setSelectedBetType(selectedBetType, boxed));
		dispatch(setSelectedDate(date));
		return dispatch(fetchRaceWithSelections(raceId));
	}

	/**
	 * If the race wasn't loaded previously, show the loading mask, fetch the race and then reset the racing filters
	 */
	dispatch(setLoadingMask(true));

	return dispatch(fetchRaceWithSelections(raceId)).then(() => {
		dispatch(
			batchActions(
				[
					resetRacingFilters(raceId, meetingId, false),
					setLoadingMask(false),
					setSelectedBetType(selectedBetType, boxed),
					setSelectedDate(date),
				],
				RACING_HOME_NAVIGATE_TO_RACE__BATCH,
			),
		);
	});
};

/**
 * Choose loading mask based on state, show,
 * fetch data for given race ID and set selected Race in store.
 * Endpoint: 'combined/race/selections'
 * @HW 12Dec2019
 * @param {string} type (ex:type=r, type=h, type=g)(r=gallops, h=harness, g=greyhounds)
 * @param {string} date
 * @param {number} raceId
 * @param {number} meetingId
 *
 */
export const navigateToRaceWithType = (type, date, raceId, meetingId) => async (dispatch, getState) => {
	const state = getState();
	const selectedMeeting = state.grsRacingHome.selectedMeeting;
	const selectedType = state.grsRacingHome.selectedType;
	const selectedDate = getSelectedDate(state);

	meetingId = meetingId || selectedMeeting;
	type = (type && type.toLowerCase()) || selectedType;
	date = date || (selectedDate ? serverDateTime(moment(selectedDate)).format('YYYY-MM-DD') : null);

	const meetings = getMeetings(state);
	const meeting = meetings.find((m) => m.id === meetingId);
	let meetingData = { id: meetingId };
	let raceData = { id: raceId, number: 0 };

	const setRaceData = (race) => {
		if (race) {
			if (race.number) {
				raceData.number = race.number;
			} else if (race.race_number) {
				raceData.number = race.race_number;
			}

			meetingData.name = race.meeting_name || (meeting ? meeting.name : undefined);
			meetingData.meetingType = race.type || (meeting ? meeting.type : undefined);
		}
	};

	if (meeting) {
		const race = meeting.races.find((r) => r.id === raceId);
		setRaceData(race);
	} else {
		let race =
			getRaceByID(state.entities, raceId) ||
			getUpcomingRaces(state).find((r) => r.id === raceId) ||
			getNextToJumpRaces(state).find((r) => r.id === raceId) ||
			getFeatureRaces(state).find((r) => r.id === raceId);

		if (race) {
			setRaceData(race);
		}
	}
	const route = buildWithDateGRSRouteToRace(type, date, meetingData, raceData);
	dispatch(routeTo(route));
};

/**
 * Show loading mask for types,
 * fetch data for given meeting and set selected meeting and next Race to jump.
 * Endpoint: 'combined/meeting/races/selections'
 * @param {string} date 'YYYY-MM-DD'
 * @param {string} type 'g' ,'t' and 'h'
 */
export const navigateToMeetingsWithTypeAndDate = (date, type) => (dispatch) => {
	// const state = getState();

	// let newdate =
	// 	serverDateTime(date).format('YYYY-MM-DD') || getSelectedDate(state)
	// 		? serverDateTime(moment(date)).format('YYYY-MM-DD')
	// 		: null;
	scrollToTop();
	dispatch(setMeetingLoadingMask(true));
	return dispatch(fetchMeetingAndRacesFilterByDateAndType(date, type)).then((response) => {
		dispatch(
			batchActions(
				[
					setSelectedType(type),
					setSelectedDate(date),
					setShowingMeetings(response.meetingIds),
					setExoticPools(response.exoticPools),
					setMeetingLoadingMask(false),
				],
				RACING_HOME_NAVIGATE_TO_MEETING__BATCH,
			),
		);
	});
};

/**
 * Show loading mask for meeting,
 * fetch data for given meeting and set selected meeting and next Race to jump.
 * Endpoint: 'combined/meeting/races/selections'
 *
 * @param meetingId
 */
export const navigateToMeeting = (meetingId) => (dispatch) => {
	scrollToTop();
	dispatch(setMeetingLoadingMask(true));
	return dispatch(fetchMeetingWithNextRaceSelections(meetingId)).then((response) => {
		dispatch(
			batchActions(
				[resetRacingFilters(response.data.selected_race, meetingId), setMeetingLoadingMask(false)],
				RACING_HOME_NAVIGATE_TO_MEETING__BATCH,
			),
		);
	});
};

/**
 * Show loading mask, fetch data for given meeting and set selected Race in store.
 * Endpoint: 'combined/meetings/races/selections'
 *
 * @param meetingId
 * @param raceId
 */
export const navigateToNextToJumpRace = (raceId, meetingId) => (dispatch, getState) => {
	let setLoadingMask;
	if (!raceId || !meetingId) {
		setLoadingMask = setContainerLoadingMask;
	} else {
		setLoadingMask = setMeetingLoadingMask;
	}

	dispatch(setLoadingMask(true));
	return dispatch(fetchMeetingsAndRacesWithSelectionsForRace(meetingId, raceId)).then((response) => {
		dispatch(
			batchActions(
				[
					resetRacingFilters(response.data.selected_race, meetingId),

					setShowingMeetings(response.meetingIds),
					setExoticPools(response.exoitcPools),
					setSelectedDate(null),
					setLoadingMask(false),
				],
				RACING_HOME_NAVIGATE_TO_NTJ_RACE__BATCH,
			),
		);
	});
};

/**
 * Show loading mask, fetch data for given meeting , meeting type and set selected Race in store.
 * Endpoint: 'combined/meetings/races/selections'
 * @HW 12Dec2019
 * @param {number} raceId
 * @param {number} meetingId
 * @param {string} type (ex:type=r, type=h, type=g)(r=gallops, h=harness, g=greyhounds)
 * @param {string} date
 */
export const navigateToNextToJumpRaceWithType = (type, date, raceId, meetingId, meetingName, raceNumber) => async (
	dispatch,
	getState,
) => {
	let setLoadingMask;
	const state = getState();

	let newdate =
		serverDateTime(date).format('YYYY-MM-DD') || getSelectedDate(state)
			? serverDateTime(moment(date)).format('YYYY-MM-DD')
			: null;
	if (!raceId || !meetingId) {
		setLoadingMask = setContainerLoadingMask;
	} else {
		setLoadingMask = setMeetingLoadingMask;
	}

	type = (type && type.toLowerCase()) || getState().grsRacingHome.selectedType;
	dispatch(setLoadingMask(true));
	// // Go direct to Race if the meeting already have a race
	const lastSelectedMeeting = getState().grsRacingHome.selectedMeeting;
	if (lastSelectedMeeting && lastSelectedMeeting == meetingId) {
		let [, meetingResponse] = await Promise.all([
			dispatch(fetchRaceWithSelections(raceId)),
			dispatch(fetchMeetingAndRacesFilterByDateAndType_GRS(newdate, type, meetingId)),
		]);
		dispatch(
			batchActions([
				resetRacingFilters(raceId, meetingId),
				setSelectedType(type),
				setRaceTypeFilter(type, true),
				setShowingMeetings(meetingResponse.meetingIds),
				setSelectedDate(date),
				setLoadingMask(false),
			]),
			RACING_HOME_NAVIGATE_TO_NTJ_RACE__BATCH,
		);
		return dispatch(setRaceTypeFilter(type, true));
	}

	let [response, meetingResponse] = await Promise.all([
		dispatch(fetchMeetingWithNextRaceSelections(meetingId, raceId)),
		dispatch(fetchMeetingAndRacesFilterByDateAndType_GRS(newdate, type, meetingId)),
	]);

	dispatch(
		batchActions([
			resetRacingFilters(raceId, meetingId),
			updateMeeting(response.data.data),
			setShowingMeetings(meetingResponse.meetingIds),
			setSelectedType(type),
			setSelectedDate(date),
			setLoadingMask(false),
		]),
		RACING_HOME_NAVIGATE_TO_NTJ_RACE__BATCH,
	);

	return dispatch(setRaceTypeFilter(type, true));
};

/**
 * Show loading mask, fetch meetings and clear filters.
 * Endpoint: '/combined/meetings/races'
 */
export const navigateToRacingHome = (newDate, type) => (dispatch, getState) => {
	const state = getState();
	let setLoadingMaskAction, serverDate;
	setLoadingMaskAction = setContainerLoadingMask;

	if (newDate) {
		serverDate = serverDateTime(newDate).format('YYYY-MM-DD');
	} else {
		serverDate = getSelectedDate(state) ? serverDateTime(moment(getSelectedDate(state))).format('YYYY-MM-DD') : null;
	}

	let route = '';

	if (type) {
		route = `/${RACING_NEW_BASE_URL}/${type.toLowerCase()}${serverDate ? `/${serverDate}` : ''}`;
	} else {
		route = `/${RACING_NEW_BASE_URL}${serverDate ? `/${serverDate}` : ''}`;
	}

	const currentRoute = state.application.currentRoute;
	if (currentRoute === route) {
		return;
	}

	dispatch(routeTo(route));
};

/**
 * Show loading mask, fetch meetings and clear filters with date and meeting type.
 * @param {*} newDate
 * @param {*} newType
 * @HW 12Dec2019
 */
export const navigateToGRSRacingHome = (newType) => async (dispatch, getState) => {
	const state = getState();
	let setLoadingMaskAction, serverDate, setType;

	serverDate = getSelectedDate(state) ? serverDateTime(moment(getSelectedDate(state))).format('YYYY-MM-DD') : null;

	if (newType) {
		setLoadingMaskAction = setContainerLoadingMask;
		setType = newType;
	} else {
		setLoadingMaskAction = setMeetingLoadingMask;
		setType = getSelectedType(state) ? getSelectedType(state) : null;
	}

	dispatch(setContainerLoadingMask(true));

	let requestId = new Date().getTime() * Math.random();
	dispatch(SetRequestId(requestId));
	const response = await dispatch(fetchMeetingAndRacesFilterByDateAndType(serverDate, setType));

	if (requestId !== getState().grsRacingHome.requestId) {
		return;
	}

	dispatch(
		batchActions(
			[
				resetRacingFilters(null, null),
				setShowingMeetings(response.meetingIds),
				setLoadingMaskAction(false),
				setSelectedDate(serverDate),
				setSelectedType(newType),
			],
			RACING_HOME_NAVIGATE_TO_HOME__BATCH,
		),
	);
};

/**
 * Navigates to the Featured Racing
 */
export const navigateToFeaturedRacing = () => (dispatch) => {
	return dispatch(navigate('/sports/feature-racing', { trigger: true }));
};

/**
 * Action to set chosen date. The date will be added with user's timezone.
 *
 * @param {string|moment} date YYYY-DD-MM
 */
export const setSelectedDate = (date) => {
	let selectedDate = moment(date);
	return createAction(RACING_HOME_SET_SELECTED_DATE, date && selectedDate.format(SELECTED_DATE_FORMAT));
};

/**
 * Build moment object from stored data
 * @param state
 * @return {null|moment}
 */
export const getSelectedDate = (state) => {
	if (state.grsRacingHome && state.grsRacingHome.selectedDate) {
		return moment.parseZone(state.grsRacingHome.selectedDate, SELECTED_DATE_FORMAT);
	} else {
		return undefined;
	}
};

/**
 * Action to set chosen meeting type
 * @param {string} type
 */
export const setSelectedType = (type) => {
	let selectedType = type;
	return createAction(GRS_RACING_HOME_SET_SELECTED_TYPE, type && selectedType);
};
/**
 * Action to set chosen meeting type
 * @param {string} type
 */
export const setRaceTypeFilter = (type, selecteOnly) => (dispatch, getState) => {
	const state = getState();
	let raceTypeFilters = state.grsRacingHome.raceTypeFilters;

	if (selecteOnly) {
		raceTypeFilters = [];
	}
	if (type) {
		if (raceTypeFilters.includes(type)) {
			raceTypeFilters = raceTypeFilters.filter((item) => item !== type);
		} else {
			raceTypeFilters = [...raceTypeFilters, type];
		}
	}

	return dispatch(createAction(GRS_RACING_HOME_SET_RACE_TYPE_FILTERS, raceTypeFilters));
};

export const setRaceFilters = (filters) => (dispatch) => {
	return dispatch(createAction(GRS_RACING_HOME_SET_RACE_TYPE_FILTERS, filters));
};

/**
 * Build moment object from stored data
 * @param {*} state
 * @return {null|moment}
 */
export const getSelectedType = (state) => {
	if (state.grsRacingHome && state.grsRacingHome.selectedType) {
		return state.grsRacingHome.selectedType;
	} else {
		return undefined;
	}
};

/**
 * Selects bet Type for showing race.
 * @param selectedBetType
 * @param betTypeBoxed
 */
export const setSelectedBetType = (selectedBetType, betTypeBoxed) => {
	return createAction(RACING_HOME_SET_SELECTED_BET_TYPE, null, { selectedBetType, betTypeBoxed });
};

/**
 *
 * @param {Array} meetingIds set in racing slice
 * @return {Object}
 */
export const setShowingMeetings = (meetingIds = []) => {
	return createAction(RACING_HOME_SET_SHOWING_MEETINGS, meetingIds);
};

/**
 *
 * @param {Array} action for getting the exotic_pools object from meetings
 * @return {Object}
 */
export const setExoticPools = (exoitcPools = []) => {
	return createAction(RACING_HOME_SET_EXOTIC_POOLS, exoitcPools);
};

/**
 * action for getting the bet build object from bet builder meetings
 * @param {*} selectedBuilderMeeting
 */
export const setSelectedBuilderMeeting = (meetingId) => {
	let selectedBuilderMeeting = meetingId;
	return createAction(RACING_HOME_SET_BET_BUILDER_MEETING, meetingId && selectedBuilderMeeting);
};

/**
 * Set in state list of showing Next to Jump Races. This is needed
 * so we keep the entities in the right section and the selection
 * for the component declared in the racing slice oh the state.
 *
 * @return {Object}
 * @param ntjRaceIds
 */
export const setShowingNTJRaces = (ntjRaceIds = []) => {
	return createAction(RACING_HOME_SET_SHOWING_NTJ_RACES, ntjRaceIds);
};

/**
 * Reset racing slice of the state. blank
 *
 * @param {number} raceId
 * @param {number} meetingId
 */
export const resetRacingFilters = (raceId, meetingId, resetQuaddies = true) => {
	return createAction(RACING_HOME_RESET_FILTERS, null, { raceId, meetingId, resetQuaddies });
};

/**
 * Set bet types in state
 * @param {Array} betTypes - Array of bet type objects
 */
export const setBetTypes = (betTypes) => {
	const action = createAction(RACING_HOME_SET_BET_TYPES, betTypes);
	return action;
};

/**
 * Reset racing slice of the state. blank
 *	@HW 12Dec2019
 * @param {number} raceId
 * @param {number} meetingId
 * @param {string} meetingType
 */
export const resetGRSRacingFilters = (raceId, meetingId, type, date, resetQuaddies = true) => {
	return createAction(GRS_RACING_HOME_RESET_FILTERS, null, { raceId, meetingId, type, date, resetQuaddies });
};

export const setContainerLoadingMask = (isLoading) => createAction(RACING_HOME_SET_CONTAINER_LOADING_MASK, isLoading);
export const setMeetingLoadingMask = (isLoading) => createAction(RACING_HOME_SET_MEETING_LOADING_MASK, isLoading);
export const setRaceLoadingMask = (isLoading) => createAction(RACING_HOME_SET_RACE_LOADING_MASK, isLoading);

export const loadRacingWidget = () => async (dispatch) => {
	/**
	 * Reset filters and render loading mask for the whole container
	 */
	dispatch(
		batchActions([setBodyLoading(true), resetRacingFilters(), setSelectedDate(null)], RACING_HOME_LOADING__BATCH),
	);

	try {
		const [response, ntjResponse] = await Promise.all([
			dispatch(fetchMeetingsAndRaces()),
			dispatch(fetchNewNextToJumpRaces_GRS()), // @HW
		]);

		// Verify betTypes is not undefined before dispatch
		const betTypesToSet = response.betTypes || [];

		// Dispatch separately to isolate the issue
		dispatch(setBetTypes(betTypesToSet));

		dispatch(
			batchActions([
				setShowingMeetings(response.meetingIds),
				setExoticPools(response),
				setShowingNTJRaces(ntjResponse.ntjRaceIds),
				setContainerLoadingMask(false),
				setBodyLoading(true),
				setBetTypes(response.betTypes),
			]),
		);
	} catch (e) {
		//console.log(e);
		dispatch(show404());
	}
};

/**
 * Toggle the exotic pools filter
 *
 * @return {Object}
 * @param state
 */
export const handleQuaddieFilterClick = () => {
	return { type: RACING_EXOTIC_QUADDIE_TOGGLE };
};

export const handleDailyDoubleFilterClick = () => {
	return { type: RACING_EXOTIC_DAILYDOUBLE_TOGGLE };
};

export const handleClearSelectionsClick = () => {
	return { type: RACING_EXOTIC_SELECTIONS_TOGGLE };
};

export const handleQuaddieSelections = (selection_id, selection_number, currentLeg, remove = false) => (
	dispatch,
	getState,
) => {
	const state = getState();

	let newQuaddieLegSelections = { ...state.grsRacingHome.quaddiesLegsSelections };
	const currentSelections = newQuaddieLegSelections[currentLeg];
	let newSelections = [];

	if (remove && currentSelections) {
		newSelections = currentSelections.filter((s) => s.selection_id !== selection_id);
		newQuaddieLegSelections[currentLeg] = newSelections;
		dispatch(createAction(BET_QUADDIES_LEG_SELECTIONS, newQuaddieLegSelections));
		return;
	}

	if (!currentSelections) {
		newSelections = [{ selection_id, selection_number }];
		newQuaddieLegSelections[currentLeg] = newSelections;
	} else {
		// Check if the selection is already in the array
		newSelections = [...currentSelections];
		const selectionIndex = newSelections.findIndex((s) => s.selection_id === selection_id);

		if (selectionIndex === -1) {
			// Add the selection to the array
			newSelections.push({ selection_id, selection_number });
		} else {
			// Remove the selection from the array
			newSelections = currentSelections.filter((s) => s.selection_id !== selection_id);
		}

		newQuaddieLegSelections[currentLeg] = newSelections;
	}

	dispatch(createAction(BET_QUADDIES_LEG_SELECTIONS, newQuaddieLegSelections));
};

export const CleanQuaddieSelections = (reset_quaddies_races) => (dispatch) => {
	dispatch(createAction(BET_QUADDIES_RESET_LEG_SELECTIONS, null));
	dispatch(createAction(RACING_EXOTIC_SELECTIONS_TOGGLE, null));
	if (reset_quaddies_races) {
		dispatch(createAction(SET_SELECTED_QUADDIES_RACES, []));
	}
};

export const SetSelectedQuaddieLegs = (racesId) => (dispatch) => {
	dispatch(createAction(SET_SELECTED_QUADDIES_RACES, racesId));
};

export const SetRequestId = (requestId) => (dispatch) => {
	dispatch(createAction(SET_REQUEST_ID, requestId));
};
