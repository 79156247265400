import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'react-emotion';
import { media, typography } from '@tbh/ui-kit';
import { FAMILYS, MEDIA_QUERIES,PROMOTION_COLOR } from '../../../constants/themes';
import { navigateToRacingHome } from '../../../store/GRSracingHome/GRSracingHomeActions';
import { serverDateTime } from '../../../legacy/core/format';
import moment from 'moment';
import { Skeleton } from '../../UI/Skeleton';

const PromotionListRow = styled('div')(
	(props) => css`
		display: grid;
		align-items: center;
		grid-template-columns: 1fr;
		grid-gap: 20px;
	`,
	media(
		css`
			grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
		`,
		300,
	),
	media(
		css`
			grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
		`,
        500,
	),
);

const PromotionListItemCell = styled('div')`
	border: 1px solid #f0eeed;
	background-color: #ffffff;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`;

const PromotionThumb = styled('div')`
    width: 100%;
    height: auto;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer; // Add cursor pointer to indicate clickable
`;

const PromotionThumbImage = styled('img')`
    width: 100%;
    height: auto;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`;

const PromotionContent = styled('div')`
    width: 100%;
    text-align: center;
    padding: 16px;
`;

const PromotionButtonSecondary = styled('button')`
    width: 100%;
    text-transform: uppercase;
    color: ${(props) => (props.isActive ? '#ffffff' : '#d7d7d7')};
	background-color: ${(props) => (props.isActive ? PROMOTION_COLOR.pro_button_bg_color : '#111111')}; 
    border-radius: 3px;
    transition: background-color 0.2s linear, color 0.2s linear;
    border: 0;
    padding: 0.375rem 0.75rem;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.5;
    font-family: ${(props) => typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
    cursor: pointer;

	&:hover {
		background-color: ${PROMOTION_COLOR.pro_button_bg_color};
		color: #ffffff;
	}

	&:focus {
		background-color: ${PROMOTION_COLOR.pro_button_bg_color};
		color: #ffffff;
		outline: none;
		box-shadow: none;
	}

	&:active {
		background-color: ${PROMOTION_COLOR.pro_button_bg_color};
		color: #ffffff;
		outline: none;
		box-shadow: none;
	}

	&:not(:last-of-type) {
		margin-right: 4px;
	}
`;

const PromotionsList = ({ size, promotions, navigateToTodaysRacing }) => {
    const handleImageClick = (clickurl) => {
        if (clickurl) {
            window.open(clickurl, '_blank');
        }
    };

    return (
        <PromotionListRow size={size}>
			{promotions.length === 0 && <p>No promotions available</p>}

            {promotions.map((promotion) => (
                <PromotionListItemCell key={promotion.bid}>
                    <PromotionThumb onClick={() => handleImageClick(promotion.clickurl)}>
                        <PromotionThumbImage src={promotion.imageurl} />
                    </PromotionThumb>
                    <PromotionContent>
                        <PromotionButtonSecondary onClick={navigateToTodaysRacing}>
                            Bet now
                        </PromotionButtonSecondary>
                    </PromotionContent>
                </PromotionListItemCell>
            ))}
        </PromotionListRow>
    );
};

export const LoadingPromotionsList = () => {
	return (
		<PromotionListRow>
			{Array.from({ length: 4 }).map((_, index) => (
				<PromotionListItemCell key={index}>
					<PromotionThumb>
						<Skeleton width="100%" height="160" />
					</PromotionThumb>
					<PromotionContent>
						<PromotionButtonSecondary>
							Bet now
						</PromotionButtonSecondary>
					</PromotionContent>
				</PromotionListItemCell>
			))}
		</PromotionListRow>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		navigateToTodaysRacing: () => {
			dispatch(navigateToRacingHome(serverDateTime(moment())));
		},
	};
};

export default connect(undefined, mapDispatchToProps)(PromotionsList);
