// import rg4js from 'raygun4js';
import env from './EnvironmentVariables';
import { each } from 'lodash-es';
import axios from 'axios';
const URI = require('urijs');

import { DEFAULT_LANGUAGE } from '../store/application/applicationConstants';
import { setTheAuthenticatedUser } from '../store/authentication/authenticationActions';

const PARAMS_PREFIX = '_tb_';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = env('apiUrl');
axios.defaults.headers = {
	locale: DEFAULT_LANGUAGE,
	'website-version': process.env.PACKAGE_VERSION,
	...env('requestHeaders'),
};
// axios.defaults.timeout = 1000; // used for testing timeout errors

axios.interceptors.response.use(
	(response) => response,
	(error) => {
		/**
		 * On 401 Errors, logout current User and pass on the rejected Promise.
		 */
		if (error.response && error.response.status === 401 && App && App.store) {
			// clear the authenticated user
			localStorage.removeItem('user_token');
			App.store.dispatch(setTheAuthenticatedUser(null));
			document.Sentry && document.Sentry.captureException(new Error(error));
			console.warn(error);
		}
		// Timeout errors have ECONNABORTED as code
		if (error.code === 'ECONNABORTED') {
			document.Sentry && document.Sentry.captureException(new Error(error));
			// rg4js('send', error);
			console.log('send', error);
		}

		return Promise.reject(error);
	},
);

/**
 * Set the default axios URL from the env getter
 *
 * @returns {*}
 */
export const setBaseUrl = () => (axios.defaults.baseURL = env('apiUrl'));
 
/**
 * Prefix a param or header key
 *
 * @param key
 * @param prefix
 * @returns {string}
 */
export const prefixRequestKey = (key, prefix = PARAMS_PREFIX) => `${prefix}${key}`;


export const mergeBearerToken = (token) => {
	axios.defaults.headers = {
		...axios.defaults.headers,
		Authorization: `Bearer ${token}`,
		Accept: 'application/json'
	};
};

export const removeBearerToken = () => {
	delete axios.defaults.headers.Authorization;
};

/**
 * Merge in new request headers
 *
 * @param requestHeaders
 */
export const mergeAxiosDefaultHeaders = (requestHeaders = {}) => {
	axios.defaults.headers = {
		...axios.defaults.headers,
		'website-version': process.env.PACKAGE_VERSION,
		...requestHeaders,
	};
};

/**
 * Remove a set of request headers
 *
 * @param requestHeaders
 */
export const removeAxiosDefaultHeaders = (requestHeaders = []) => {
	requestHeaders.forEach((header) => {
		delete axios.defaults.headers[header];
	});
};

/**
 * Generates a new URL for the ajax call, appending all browser URL params prefixed with _tb_
 *
 * @param url
 */
const appendTbQsParams = (url) => {
	let qsParams = URI().query(true);
	let params = {};
	each(qsParams, function (value, key) {
		if (key.indexOf('utm') === -1) {
			params[prefixRequestKey(key)] = value;
		}
	});
	return URI(url)
		.addQuery(params)
		.toString();
};

/**
 * Wrapper function to modify all ajax URL's with browser URL query string params.
 *
 * @param method
 * @returns {function(*=, ...[*])}
 */
const requestWithTbParams = function (method) {
	let action = (url, ...args) => {
		return method(appendTbQsParams(url), ...args);
	};

	// Attach the axios method to the function
	action._axiosMethod = method;

	return action;
};

/**
 * Ajax methods to match apache verbs
 */

// Removal of the tb_params in the requests
// export const post = requestWithTbParams(axios.post);
// export const get = requestWithTbParams(axios.get);
// export const put = requestWithTbParams(axios.put);
// export const patch = requestWithTbParams(axios.patch);
// export const remove = requestWithTbParams(axios.delete);


export const post = axios.post;
export const get = axios.get;
export const put = axios.put;
export const patch = axios.patch;
export const remove = axios.delete;
