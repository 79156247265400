// import {batchActions} from 'redux-batched-actions';
import { WELCOME_SUB_APP, WELCOME_BASE_URL } from '../pages/Welcome/WelcomeConstants';
import {
	show404,
	scrollToTop,
	navigate,
	setBodyLoading,
	routeTo,
	// setBodyLoading ,
	// closeSideBetSlip
} from '../store/application/applicationActions';
import { fetchGRSFeatureRacesWithoutDate } from '../store/entities/actions/FeatureRaceActions';
import { fetchGRSAllUpcomingRaces } from '../store/entities/actions/UpcomingAllRaceActions';
import { fetchNextToJumpRaces_GRS } from '../store/entities/actions/NextToJumpRaceActions';
import { NEXT_TO_JUMP_APP, NEXT_TO_JUMP_URL } from '../pages/NextToJump/NextToJumpConstrants';

const { dispatch } = App.store;

const WelcomeRouter = Backbone.Router.extend({
	routes: {
		[WELCOME_BASE_URL]: 'showWlcomePage',
	},

	showWlcomePage: async () => {
		if (window.innerWidth <= 500) {
			dispatch(routeTo(NEXT_TO_JUMP_URL));
			return;
		}
		dispatch(setBodyLoading(true));
		let type = 'fr'; // feature races
		// let selection_type = 'expert'; //selection_type = expert or favorite
		let limit = 16;
		App.startSubApp(WELCOME_SUB_APP);
		try {
			await Promise.all([
				dispatch(fetchNextToJumpRaces_GRS()),
				dispatch(fetchGRSFeatureRacesWithoutDate(type, 6)), // get feature races
				dispatch(fetchGRSAllUpcomingRaces('all', limit)), // get all upcoming races
			]);
			scrollToTop();
		} catch (e) {
			// console.log("caba",e);
			dispatch(show404());
		}
		dispatch(setBodyLoading(false));
	},
});

export default new WelcomeRouter();
