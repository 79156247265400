// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { spacings, media } from '@tbh/ui-kit';
import { connect } from 'react-redux';
import { SizeMe } from 'react-sizeme';
import { withNamespaces } from 'react-i18next';

// Actions
import { closeModal } from '../../../store/modal/modalActions';
// import { routeTo } from '../../../store/application/applicationActions';
import { fetchUserActiveTickets } from '../../../store/entities/actions/TicketActions';
import { fetchActiveBets } from '../../../store/entities/actions/BetActions';
import { getSortedActiveBets } from '../../../store/entities/selectors/BetSelectors';
import { getAllTickets } from '../../../store/entities/selectors/TicketSelectors';
//import { navigateToRace } from '../../../store/GRSracingHome/GRSracingHomeActions';
import { navigateToNextToJumpRaceWithType } from '../../../store/GRSracingHome/GRSracingHomeActions'; // newly added
import { selectEvent } from '../../../store/sportsHome/sportsHomeActions';
import { routeToTournament } from '../../../store/activeTournament/activeTournamentActions';
import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';

// Components
import { LoadingMask, Notification, Tabs, TabsItem } from '@tbh/ui-kit';
import ModalHeader from '../../../components/features/Application/ModalHeader/ModalHeader';
import BetSummaryHeader from '../../../components/features/Betting/BetSummary/BetSummaryHeader/BetSummaryHeader';
import BetSummaryBetList from '../../../components/features/Betting/BetSummary/BetSummaryBetList/BetSummaryBetList';
import BetSummaryTicketList from '../../../components/features/Betting/BetSummary/BetSummaryTicketList/BetSummaryTicketList';

// Constants
import { BET_TYPE_SPORT } from '../../../store/entities/constants/BetConstants';
import { ACTIVE_BETS, RECENT_BETS, ACTIVE_TICKETS } from './QuickBettingSummaryConstants';
// import {
// 	USER_ACCOUNT_ALL_TRANSACTIONS_URL,
// 	USER_ACCOUNT_TRANSACTIONS_URL,
// 	USER_ACCOUNT_V2_BASE_URL,
// } from '../../../pages/UserAccountV2/UserAccountPageConstants';
import { serverDateTime } from '../../../../src/legacy/core/format';
import { BET_SUMMARY_COLOR } from '../../../constants/themes';

// Styles
const StyledQuickBettingSummaryContainer__ModalHeader = styled(ModalHeader)(
	(props) => css`
		label: QuickBettingSummaryContainer__ModalHeader;

		padding-right: 0;
		padding-left: ${spacings(props).compact}px;
	`,
	(props) =>
		media(
			css`
				padding-left: ${spacings(props).cozy}px;
				padding-right: ${spacings(props).compact}px;
			`,
			500,
		),
);

const StyledQuickBettingSummaryContainer__BetList = styled(BetSummaryBetList)(
	(props) => css`
		label: QuickBettingSummaryContainer__Item;

		padding: ${spacings(props).cozy}px ${spacings(props).compact}px;
	`,
	(props) =>
		media(
			css`
				padding: ${spacings(props).cozy}px ${spacings(props).cozy}px;
			`,
			500,
		),
);
const StyledQuickBettingSummaryContainer__SummaryHeader = StyledQuickBettingSummaryContainer__BetList.withComponent(
	BetSummaryHeader,
);
const StyledQuickBettingSummaryContainer__TicketList = StyledQuickBettingSummaryContainer__BetList.withComponent(
	BetSummaryTicketList,
);

const cssActiveTabItems = css`
	label: ActiveTabItems;

	color: ${BET_SUMMARY_COLOR.active_tab_font_color};
	font-weight: 700;
`;
const cssInActiveTabItems = css`
	label: InActiveTabItems;
	color: ${BET_SUMMARY_COLOR.inactive_font_color};;
`;

class QuickBettingSummaryContainer extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Closes the modal */
		closeComponent: PropTypes.func.isRequired,

		/** Fetches the bets for the currently authenticated user */
		fetchBets: PropTypes.func.isRequired,

		/** Fetches the active tickets for the currently authenticated user */
		//fetchTickets: PropTypes.func.isRequired,

		/** Navigates to the bets in Account Transactions */
		navigateToAccountTransactions: PropTypes.func.isRequired,

		/** Navigates to the selected race bet */
		navigateToRaceEvent: PropTypes.func.isRequired,

		/** Navigates to the selected sport bet */
		navigateToSportEvent: PropTypes.func.isRequired,

		/** Navigates to the selected tournament */
		navigateToTournament: PropTypes.func.isRequired,

		/** GA tracking */
		trackGaEvent: PropTypes.func.isRequired,

		/** Whether the tournaments section is enabled or not */
		isTournamentsEnabled: PropTypes.bool.isRequired,

		/** All active and recent bets for the currently authenticated user */
		bets: PropTypes.shape({
			activeBets: PropTypes.array,
			recentBets: PropTypes.array,
		}),

		/** All active tickets for the currently authenticated user */
		tickets: PropTypes.array,
	};

	static defaultProps = {
		bets: null,
		tickets: null,
	};

	state = {
		error: null,
		loading: false,
		tab: ACTIVE_BETS,
	};

	/**
	 * Sets a loading mask while loading the bets and tickets
	 * Removes when done
	 */
	componentDidMount() {
		this.setLoadingMask(true);
		this.props.trackGaEvent('Quick Bet Summary', 'Filter', 'Active');

		return this.fetchBetsAndTickets()
			.catch((error) => {
				this.setState({
					error: error.response.data.errors || error.message,
				});
			})
			.finally(() => {
				this.setLoadingMask(false);
			});
	}

	/**
	 * Fetches all bets and tickets
	 */
	fetchBetsAndTickets = () => {
		//return Promise.all([this.props.fetchBets(), this.props.fetchTickets()]);
		return Promise.all([this.props.fetchBets()]);
	};

	/**
	 * Update state on tab click
	 *
	 * @param data
	 */
	handleTabChange = (data) => {
		this.setState({
			tab: data,
		});

		this.props.trackGaEvent('Quick Bet Summary', 'Filter', data);
	};

	/**
	 * Navigates to a racing or sport event depending on the bet type
	 *
	 * @param bet_type
	 * @param competition_id
	 * @param event_id
	 */
	handleBetClick = (bet_type, event_type, date, event_id, competition_id) => {
		if (bet_type === BET_TYPE_SPORT) {
			this.props.navigateToSportEvent(competition_id, event_id);
		} else {
			this.props.navigateToRaceEvent(event_type, serverDateTime(date).format('YYYY-MM-DD'), competition_id, event_id);
			//this.props.navigateToRaceEvent(event_type,serverDateTime(date).format('YYYY-MM-DD'), competition_id, event_id);
		}
	};

	/**
	 * Sets a loading mask
	 */
	setLoadingMask = (loading = false) => {
		this.setState({
			loading: loading,
		});
	};

	/**
	 * Clears the error message
	 */
	clearError = () => {
		this.setState({
			error: null,
		});
	};

	render() {
		const {
			t,
			bets,
			navigateToAccountTransactions,
			navigateToTournament,
			closeComponent,
			tickets,
			isTournamentsEnabled,
		} = this.props;
		const { error, loading, tab } = this.state;

		// const activeBetList = bets.activeBets.filter((bet) => bet.bet_selections.some((element) => element.leg_status === 'pending'));

		const activeBetList = bets.activeBets;
		return (
			<div>
				<LoadingMask loading={loading} topOffset={150} displayAtTop />

				<SizeMe>
					{({ size }) => (
						<div
							style={{
								maxHeight: 640,
								overflow: 'hidden',
								position: 'relative'
							}}
						>
							<StyledQuickBettingSummaryContainer__ModalHeader
								size={size}
								onClose={closeComponent}
								title={t('QuickBettingSummaryContainer__Title')}
								aside={
									<Tabs alternate alignment="right">
										<TabsItem
											action={this.handleTabChange}
											className={this.state.tab !== ACTIVE_BETS ? cssInActiveTabItems : cssActiveTabItems}
											data={ACTIVE_BETS}
											active={this.state.tab === ACTIVE_BETS}
										>
											{t('Active')}
										</TabsItem>
										<TabsItem
											action={this.handleTabChange}
											className={this.state.tab !== RECENT_BETS ? cssInActiveTabItems : cssActiveTabItems}
											data={RECENT_BETS}
											active={this.state.tab === RECENT_BETS}
										>
											{t('Settled')}
										</TabsItem>
										{/* {isTournamentsEnabled && (
											<TabsItem
												action={this.handleTabChange}
												data={ACTIVE_TICKETS}
												active={this.state.tab === ACTIVE_TICKETS}
											>
												{t('Tickets')}
											</TabsItem>
										)} */}
									</Tabs>
								}
							/>
							<div style={{
								maxHeight: 640,
								overflow: 'auto',
								zIndex: '1'
							}}>
								<div style={{
									position: 'sticky',
									marginBottom: '60px'
								}}>

									<StyledQuickBettingSummaryContainer__SummaryHeader size={size} />

									{error && (
										<Notification
											strong
											type={Notification.types.COLOUR_DANGER}
											message={error}
											buttonText={t('Dismiss')}
											buttonAction={this.clearError}
										/>
									)}

									{(() => {
										switch (tab) {
											case ACTIVE_BETS:
												return (
													<StyledQuickBettingSummaryContainer__BetList
														size={size}
														bets={activeBetList}
														onBetHeaderClick={navigateToAccountTransactions}
														onClickBetListItem={this.handleBetClick}
														selectedTab={this.state.tab}
													/>
												);

											case RECENT_BETS:
												return (
													<StyledQuickBettingSummaryContainer__BetList
														size={size}
														bets={bets.recentBets}
														onBetHeaderClick={navigateToAccountTransactions}
														onClickBetListItem={this.handleBetClick}
														selectedTab={this.state.tab}
													/>
												);

											case ACTIVE_TICKETS:
												return (
													<StyledQuickBettingSummaryContainer__TicketList
														size={size}
														tickets={tickets}
														onClickTicketItem={navigateToTournament}
													/>
												);
										}
									})()}
								</div>
							</div>
						</div>
					)}
				</SizeMe>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		bets: getSortedActiveBets(state),
		tickets: getAllTickets(state.entities),
		isTournamentsEnabled: state.featureToggles.features.tournaments.enabled,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeComponent: () => {
			return dispatch(closeModal('QuickBettingSummaryContainer'));
		},
		fetchBets: () => {
			return dispatch(fetchActiveBets());
		},
		/*fetchTickets: () => {
			return dispatch(fetchUserActiveTickets());
		},*/
		navigateToAccountTransactions: () => {
			// dispatch(routeTo(`/${USER_ACCOUNT_V2_BASE_URL}/${USER_ACCOUNT_TRANSACTIONS_URL}/${USER_ACCOUNT_ALL_TRANSACTIONS_URL}`, { trigger: true }));
			// return dispatch(closeModal('QuickBettingSummaryContainer'));
		},
		// navigateToRaceEvent: (meetingId, raceId) => {
		// 	dispatch(navigateToRace(raceId, meetingId));
		// 	App.startSubApp('Racing');
		// 	return dispatch(closeModal('QuickBettingSummaryContainer'));
		// },
		navigateToRaceEvent: (type, date, raceId, meetingId) => {
			dispatch(navigateToNextToJumpRaceWithType(type, date, raceId, meetingId));
			App.startSubApp('RacingNewPage');
			return dispatch(closeModal('QuickBettingSummaryContainer'));
		},
		navigateToSportEvent: (competitionId, eventId) => {
			dispatch(selectEvent(competitionId, eventId));
			App.startSubApp('Sports');
			return dispatch(closeModal('QuickBettingSummaryContainer'));
		},
		navigateToTournament: (tournamentId) => {
			dispatch(routeToTournament(tournamentId));
			return dispatch(closeModal('QuickBettingSummaryContainer'));
		},
		trackGaEvent: (category, action, label) => {
			return dispatch(trackGaEvent(category, action, label));
		},
	};
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(QuickBettingSummaryContainer));
