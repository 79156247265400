import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, typography, Text, Input, PlotElements, Checkbox, Button, ArrowIcon, Icon } from '@tbh/ui-kit';

import WithButton from '../../../ui-components/Forms/WithButton/WithButton';
import CurrencyInput from '../../Application/Currency/CurrencyInput/CurrencyInput';
import CurrencyDisplay from '../../Application/Currency/CurrencyDisplay/CurrencyDisplay';

import { calculatePossiblePayout } from '../../../../common/BetPlacement';
import {
	SELECTION_PRICE_NOT_APPLICABLE,
	TOTE_NAMES_BY_PRODUCT,
	toteProducts
} from '../../../../store/entities/constants/BetConstants';
import {
	RACING_BET_TYPE_EACHWAY_SHORT,
	QUADDIES_BET_TYPES,
	RACING_SAME_RACE_MULTI_TYPE,
	RACING_EXOTIC_BET_TYPES
} from '../../../../common/constants/Racing';
import BetStake from '../BetSelection/BetStake/BetStake';

import { RACING_BET_TYPE_PLACE_SHORT, RACING_BET_TYPE_WIN_SHORT } from '../../../../common/constants/Racing';
import { PriceBumpButton } from '../BetSelection/BetSelectionAndAmount/BetSelectionAndAmount';
import { GOAT_BET_TYPE_WIN } from '../../../../common/constants/GoatProducts';
const StyledBetAmountAndPayout__FlexiBox = styled(Input)`
	${(props) => css`
		label: BetAmountAndPayout__FlexiBox;
		flex: 0 0 70px;
		margin-left: ${spacings(props).compact}px;
	`};
`;

const StyledBetAmountAndPayout__Payout = styled(PlotElements)`
	${(props) => css`
		label: BetAmountAndPayout__Payout;

		text-align: right;
		// flex: 0 0 50%;
		color: ${typography(props).color_2};
		// margin-top: ${spacings(props).compact}px;
	`};
`;

const StyledBetCacheOut__Payout = styled(PlotElements)`
	${(props) => css`
		label: BetCacheOut__Payout;

		text-align: left;
		flex: 0 0 55%;
		color: ${typography(props).color_2};
		margin-top: ${spacings(props).compact}px;
	`};
`;

const StyledBetAmountAndPayout__PayoutValue = styled(Text)`
	${(props) => css`
		label: BetAmountAndPayout__PayoutValue;

		display: inline-block;
		margin-left: ${spacings(props).tight}px;
	`};
`;

const StyledBetAmountAndPayout__Amount = styled(Text)`
	${(props) => css`
		label: BetAmountAndPayout__Amount;
		display: flex;
		font-size: 11px;
		left: 188px;
		white-space: nowrap;
		top: 8px;
		position: absolute;
		font-weight: 600;
	`};
`;

const StyledBetAmountAndPayout__BetTypeLabel = styled('div')(
	(props) => css`
		label: BetAmountAndPayout__BetTypeLabel;
		display: inline-block;
		position: absolute;
		left: 54px;
		${props.isExotic && 'top: 8px;left:40px;'}
		font-size: 10px;
		font-weight: 600;
		padding: 4px 0px;
		color: #000000;
		white-space: nowrap;
		span {
			background: #dddddd;
			padding: 1px;
			border: 1px solid #000000;
			margin-left: 2px;
		}
	`
);

const StyledBetSelectionDetails__Stake = styled(BetStake)`
	label: BetSelectionDetails__Stake;

	align-self: flex-start;
`;

const StylePosiblePayoutAndCacheOut__Payout = styled('div')`
	label: PosiblePayoutAndCacheOut__Payout;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 4px;
`;

const StyledFlexiEnabledDisabled = styled(Checkbox)(
	(props) => css`
		label: ExoticBetTypeFilters__Checkbox;

		${props.checkboxSize === '12px' &&
			css`
				vertical-align: sub;
			`};
	`
);

const StyleContainerSelectBetType = styled('div')(
	(props) => css`
		position: relative;
		.arrow {
			top: 12px;
			right: 10px;
			position: absolute;
			z-index: 4;
		}
	`
);

const StyledSelectBetType = styled('select')`
	label: SelectBetType;

	outline: none;
	padding: 4px;
	border-radius: 4px;
	border: 1px solid #cacaca;
	font-size: 14px;
	width: 100px;
`;

const Pulse = styled('div')(
	(props) => css`
		background: ${props.color};
		@keyframes pulse {
			0% {
				opacity: 0.3;
			}
			50% {
				opacity: 0.5;
			}
			100% {
				opacity: 0.3;
			}
		}

		animation: pulse 0.65s;
		animation-iteration-count: 40;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.4;
	`
);

const StyledPriceArrow = styled(ArrowIcon)(
	(props) => css`
		label: PriceArrow;

		color: ${props.color === 'green' ? '#00b300' : '#cf1b41;'};
		margin-left: 4px;
	`
);

const AdditionalText = styled('p')`
	margin: 0;
	margin-right: 5px;
	font-weight: 600;
	font-size: 11px;
	text-transform: none;
	color: rgb(23, 87, 180);
`;
const CheckBoxContainer = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 4px;
		font-size: 11px;
		cursor: pointer;
		label {
			margin: 0;
		}
	`
);

const BetAmountAndPayout = (props) => {
	const {
		t,
		className,
		isBonusBet,
		inputLabel,
		placeHolder,
		stake,
		price,
		winPrice,
		placePrice,
		racingBetType,
		exoticType,
		onInputChange,
		button,
		showFlexiBox,
		flexiAmount,
		disableFlx,
		currencyType,
		isMultiLegValid,
		ClickFlx,
		betTypeLabel,
		oddsbettype,
		cash_out_enabled,
		isLeg,
		shouldPulse,
		hasTote,
		bet_code,
		eachway,
		pulse,
		combinations,
		handleBonusBetChange,
		bonus_bet_enabled,
		free_credit_flag,
		price_bump_enabled,
		onChangeBetType,
		selection_count,
		product_id,
		hasGBProduct,
		raceType
	} = props;
	const componentClasses = cx({
		[className]: className
	});

	const onInputFocus = (event) => {
		// Select the target input text on focus
		event.target.select();
	};

	let possiblePayout = calculatePossiblePayout(
		stake,
		price,
		isBonusBet,
		racingBetType,
		placePrice,
		winPrice,
		free_credit_flag
	);

	const isMultiLegBetType = QUADDIES_BET_TYPES.includes(exoticType);
	const isExotic = RACING_EXOTIC_BET_TYPES.includes(exoticType);

	const raceTypes = [
		{ label: t('Win'), value: RACING_BET_TYPE_WIN_SHORT },
		{ label: t('Place'), value: RACING_BET_TYPE_PLACE_SHORT },
		{ label: t('Each Way'), value: RACING_BET_TYPE_EACHWAY_SHORT }
	];

	const acceptedRaceTypes = ['W', 'P', 'EW'];
	const isSP = product_id === 15;
	const isTF = product_id === 14;
	const isTote = bet_code && toteProducts.includes(bet_code) && !isSP && !isTF;

	const isSpecialProduct = isSP || isTF || (isTote && (hasTote || toteProducts.includes(bet_code)));
	
	const filteredRaceTypes = raceTypes.filter((type) => {
		if (isSpecialProduct) {
			return type.value !== RACING_BET_TYPE_EACHWAY_SHORT;
		} else if (!isTote && eachway && eachway.win_product !== eachway.place_product) {
			return type.value !== RACING_BET_TYPE_EACHWAY_SHORT;
		}
		return true;
	});

	if (isExotic) {
		possiblePayout = null;
	} else if (isSP) {
		possiblePayout = 'TBD';
	}

	const betLabel = (racingBetType) => {
		return (
			<StyledBetAmountAndPayout__BetTypeLabel>
				{racingBetType === RACING_BET_TYPE_WIN_SHORT && !isSP ? t('WIN') : ''}
				{racingBetType === RACING_BET_TYPE_PLACE_SHORT ? t('PLACE') : ''}
				{racingBetType === RACING_BET_TYPE_EACHWAY_SHORT ? t('EACH WAY') : ''}

				{isTote &&
					`(${
						TOTE_NAMES_BY_PRODUCT[bet_code] && TOTE_NAMES_BY_PRODUCT[bet_code][raceType]
							? TOTE_NAMES_BY_PRODUCT[bet_code][raceType]
							: bet_code
					})`}
				{!isTote && racingBetType != RACING_BET_TYPE_EACHWAY_SHORT && bet_code != GOAT_BET_TYPE_WIN
					? ''
					: //t(' (FIXED)')
					  ''}
				{bet_code == GOAT_BET_TYPE_WIN && ` (${betTypeLabel})`}
			</StyledBetAmountAndPayout__BetTypeLabel>
		);
	};

	const bonusName = `useBonuBets_${Math.random()}`;

	const [arrow, setArrow] = useState('down');

	const showBonusBet = bonus_bet_enabled && acceptedRaceTypes.includes(racingBetType) && !isTote;
	const showPriceBump = price_bump_enabled && racingBetType === 'W' && !isTote;

	return (
		<div className={componentClasses}>
			<div
				className={css`
					display: flex;
					position: relative;
					gap: 4px;
					width: 100%;
					margin-top: 4px;
					margin-bottom: 4px;
					justify-content: space-between;
				`}
			>
				{oddsbettype === 'oddsgrid' ? (
					<div
						className={css`
							font-size: 12px;
							padding-top: 6px;
							padding-right: 4px;
						`}
					>
						{t('Stake Amount')}
					</div>
				) : (
					''
				)}
				<div
					onClick={(e) => {
						// e.preventDefault();
						// e.stopPropagation();
					}}
				>
					<WithButton
						className={css`
							flex: auto;

						`}
						value={stake ? stake.toString() : ' '}
						racingBetType={racingBetType}
						customInput={(props) => (
							<CurrencyInput
								className={css`
									flex: auto;
									max-width: 100px;
									align-self: flex-end;
									border: 1px solid #cacaca;
									background: #ffffff;
									border-radius: 4px;
									padding-left: 4px;
								`}
								onFocus={props.onFocus}
								onChange={props.onChange}
								value={props.value}
								onClick={(e) => e.preventDefault()}
								name="betAmount"
								racingBetType={racingBetType}
								label={inputLabel}
								placeholder={placeHolder}
								currencyType={currencyType}
								disabled={isMultiLegBetType ? !isMultiLegValid : false}
								autoComplete="off"
							/>
						)}
						label={inputLabel}
						placeHolder={placeHolder}
						onChange={onInputChange}
						onFocus={onInputFocus}
						button={button}
					/>
				</div>
				{racingBetType === RACING_BET_TYPE_PLACE_SHORT ? (
					<StyledBetAmountAndPayout__Amount>
						{!isTote ? `@ ${isSP ? 'SP' : isTF ? 'TF' : (placePrice / 100).toFixed(2)}` : '@ TBD'}
						{pulse && <StyledPriceArrow size="-4" color={pulse} direction={pulse === 'green' ? 'up' : 'down'} />}
					</StyledBetAmountAndPayout__Amount>
				) : null}
				{racingBetType === RACING_BET_TYPE_WIN_SHORT ? (
					<StyledBetAmountAndPayout__Amount>
						{!isTote ? `@ ${isSP ? 'SP' : isTF ? 'TF' : (winPrice / 100).toFixed(2)}` : '@ TBD'}
						{pulse && <StyledPriceArrow size="-4" color={pulse} direction={pulse === 'green' ? 'up' : 'down'} />}
					</StyledBetAmountAndPayout__Amount>
				) : null}

				{racingBetType === RACING_BET_TYPE_EACHWAY_SHORT && (
					<StyledBetAmountAndPayout__Amount>
						{' '}
						{t('@ ') +
							(isSP ? 'SP' : isTF ? 'TF' : eachway.win_dividend.toFixed(2) + '/' + eachway.place_dividend.toFixed(2))}
					</StyledBetAmountAndPayout__Amount>
				)}
				{racingBetType === 'multi' ||
					(racingBetType === RACING_SAME_RACE_MULTI_TYPE && (
						<StyledBetAmountAndPayout__Amount>
							{' '}
							{hasTote ? '@ TBD' : t('@  ') + (isSP ? 'SP' : isTF ? 'TF' : (price / 100).toFixed(2))}
						</StyledBetAmountAndPayout__Amount>
					))}
				<StyledBetAmountAndPayout__BetTypeLabel>{betLabel(racingBetType)}</StyledBetAmountAndPayout__BetTypeLabel>
				{/* {isMultiLegBetType && isMultiLegValid && showFlexiBox && (
					<StyledBetAmountAndPayout__FlexiBox
						autocomplete="off"
						onChange={props.onFlexiChange}
						onFocus={onInputFocus}
						name="flexiBox"
						label={`${t('Flexi')} (%)`}
						value={stake > 0 ? flexiAmount : '-'}
						type="tel"
					/>
				)} */}
				{/* {!isMultiLegBetType && showFlexiBox && (
						<StyledBetAmountAndPayout__FlexiBox
							onChange={props.onFlexiChange}
							onFocus={onInputFocus}
							name="flexiBox"
							label={`${t('Flexi')} (%)`}
							value={stake > 0 ? flexiAmount : '-'}
							type="tel"
						/>
					)} */}
				{showFlexiBox && (
					<Button
						className={css`
							max-width: 100px;
							border-radius: 4px;
							height: 34px;
							padding-top: 1px;
							border-color: #ddd;
							font-size: 10px;
						`}
						type={ClickFlx ? Button.types.CALLTOACTION : Button.types.PLAIN}
						key="confirm"
						autoFlex
						// action={disableFlx}
					>
						<span>
							&nbsp;
							{t('Flexi')}
							<br />
							{stake > 0 ? flexiAmount + '%' : '-'}
						</span>
					</Button>
				)}
			</div>
			{combinations ? <AdditionalText>Combos: {combinations}</AdditionalText> : null}

			{(showBonusBet || (showPriceBump && hasGBProduct)) && (
				<div
					className={css`
						display: flex;
						justify-content: space-between;
						width: 100%;
						padding: 4px 0;
					`}
				>
					{showBonusBet && (
						<CheckBoxContainer>
							<Checkbox
								disabled={bet_code == GOAT_BET_TYPE_WIN}
								id={bonusName}
								name={bonusName}
								checked={free_credit_flag}
								action={handleBonusBetChange}
							/>
							<Text as="label" size="-3" strong={free_credit_flag} htmlFor={bonusName}>
								{t('Use Bonus Bets')}
							</Text>
						</CheckBoxContainer>
					)}

					{showPriceBump && hasGBProduct && (
						<PriceBumpButton
							action={(e) => {
								// e.preventDefault();
								e.stopPropagation();
								onChangeBetType();
							}}
							active={bet_code == GOAT_BET_TYPE_WIN}
						>
							<i className="fa fa-rocket" aria-hidden="true" style={{ marginRight: 4 }}></i>
							<Icon icon="fire" className="fire" size="-4" />
							Odds Boost
						</PriceBumpButton>
					)}
				</div>
			)}

			<StylePosiblePayoutAndCacheOut__Payout>
				{/*  */}
				{cash_out_enabled === 1 && (
					<StyledBetCacheOut__Payout align="start">
						<Text
							size="-3"
							className={css`
								display: inline-block;
							`}
							type={ClickFlx ? Button.types.CALLTOACTION : Button.types.PLAIN}
							key="confirm"
							autoFlex
							// action={disableFlx}
						>
							{t('Eligible for Bail Out')}:
						</Text>
						<StyledBetAmountAndPayout__PayoutValue size="-3" strong currency>
							{showFlexiBox && flexiAmount ? (
								t('Dividend')
							) : !possiblePayout ? (
								t('Dividend')
							) : possiblePayout === SELECTION_PRICE_NOT_APPLICABLE ? (
								'$0.00'
							) : (
								<CurrencyDisplay type={currencyType} amount={0} imageWidth={11} />
							)}

							{bonus_bet_enabled && free_credit_flag && stake > 0 && ` - ${stake}`}
						</StyledBetAmountAndPayout__PayoutValue>
					</StyledBetCacheOut__Payout>
				)}
				{!isMultiLegBetType && (
					<StyledBetAmountAndPayout__Payout align="end">
						<Text
							size="-3"
							className={css`
								display: inline-block;
							`}
						>
							{cash_out_enabled === 1 ? t('Eligible for Bail Out') : t('PossiblePayout')}:
						</Text>
						<StyledBetAmountAndPayout__PayoutValue size="-3" strong currency>
							{(showFlexiBox || flexiAmount) && isExotic && !possiblePayout && t('TBD')}

							{!showFlexiBox && !flexiAmount && !isTote && possiblePayout === SELECTION_PRICE_NOT_APPLICABLE && '$0.00'}
							{!showFlexiBox &&
								!flexiAmount &&
								possiblePayout != SELECTION_PRICE_NOT_APPLICABLE &&
								possiblePayout === 'TBD' &&
								t('TBD')}
							{!showFlexiBox &&
								!flexiAmount &&
								!isTote &&
								possiblePayout != SELECTION_PRICE_NOT_APPLICABLE &&
								possiblePayout !== 'TBD' && (
									<CurrencyDisplay type={currencyType} amount={possiblePayout / 100} imageWidth={11} />
								)}

							{isTote && 'TBD'}
						</StyledBetAmountAndPayout__PayoutValue>
					</StyledBetAmountAndPayout__Payout>
				)}

				{!isLeg &&
					!isSP &&
					bet_code != GOAT_BET_TYPE_WIN &&
					selection_count > 4 &&
					filteredRaceTypes.length > 1 &&
					acceptedRaceTypes.includes(racingBetType) &&
					filteredRaceTypes.find((raceType) => raceType.value === props.racingBetType) && (
						<StyleContainerSelectBetType>
							<StyledSelectBetType
								value={props.racingBetType}
								key={props.racingBetType}
								onChange={(event) => {
									props.onBetTypeChange(event.target.value, isTote);
									setArrow('down');
								}}
								onFocus={() => setArrow('up')}
								onBlur={() => setArrow('down')}
							>
								{filteredRaceTypes.map((raceType, index) => (
									<option key={raceType.index} value={raceType.value}>
										{raceType.label}
									</option>
								))}
							</StyledSelectBetType>
							<ArrowIcon size="-4" direction={arrow} className={'arrow'} />
						</StyleContainerSelectBetType>
						// <SelectComponent options={raceTypes} value={props.racingBetType} onChange={(v) => props.onBetTypeChange(v,isTote) } />
					)}
			</StylePosiblePayoutAndCacheOut__Payout>
		</div>
	);
};

BetAmountAndPayout.defaultProps = {
	onFlexiChange: () => {},
	disableFlx: false,
	stake: null,
	price: null,
	placePrice: null,
	placeHolder: null,
	inputLabel: '',
	racingBetType: null,
	exoticType: null,
	showFlexiBox: false,
	isBonusBet: false,
	currencyType: 'currency',
	flexiAmount: null,
	button: null,
	isMultiLegValid: false,
	onFocus: () => {},
	onChange: () => {},
	value: '',
	className: null,
	bet: 0,
	newamountWflexi: null,
	ClickFlx: false
};

export default withNamespaces()(BetAmountAndPayout);
