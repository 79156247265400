import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'react-emotion';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';
import { ButtonGroup } from '@tbh/ui-kit';
import { CheckBoxLabel, StyledCounryFilter, StyledCounryFilterSelection } from './RaceCountryFilter.styled';
import { StyledCheckBox } from '../../../UI/Checkbox';
import { RACING_AU } from '../../../../common/constants/Racing';

const RaceCountryFilter = (props) => {
	const {
		t,
		className,
		onFilterItemClick,
		countryCodes,
		mode,
		size,
		counryGroup,
		resetFilterClick,
		raceCount,
		allActive,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	const handleClick = (type) => {
		if (type === RACING_AU) {
			onFilterItemClick([RACING_AU]);
		} else {
			// remove AU and NZ from the list
			if (Object.keys(counryGroup).includes('INT')) onFilterItemClick(['INT']);
		}
	};

	const isActive = (code) => {
		if (code === RACING_AU) {
			return countryCodes.includes(RACING_AU);
		} else {
			return countryCodes.includes('INT');
		}
	};

	const handleAll = () => {
		resetFilterClick();
	};

	const buttonStyles = {
		all: {
			button: allActive === false ? 'primary' : 'secondary',
			text: 'alternate',
		},
	};
	return (
		Object.keys(counryGroup).includes('INT') && (
			<StyledCounryFilter className={componentClasses} mode={mode}>
				<StyledCounryFilterSelection
					className={componentClasses}
					spacing={ButtonGroup.spacings.SPACING_TIGHT}
					right
					size={size}
				>
					<div
						className={css`
							display: flex;
							align-items: center;
						`}
					>
						<StyledCheckBox checked={isActive(RACING_AU)} action={() => handleClick(RACING_AU)} name="AUS" inline />
						<CheckBoxLabel onClick={() => handleClick(RACING_AU)} htmlFor="AU">
							AUS
						</CheckBoxLabel>
					</div>

					<div
						className={css`
							display: flex;
							align-items: center;
						`}
					>
						<StyledCheckBox checked={isActive('INT')} action={() => handleClick('INT')} name={'INT'} inline />
						<CheckBoxLabel onClick={() => handleClick('INT')} htmlFor="INT">
							INT
						</CheckBoxLabel>
					</div>
				</StyledCounryFilterSelection>
			</StyledCounryFilter>
		)
	);
};

RaceCountryFilter.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func,

	/** Action to handle when a filter is clicked on */
	onFilterItemClick: PropTypes.func.isRequired,

	/** Which mode should be rendered */
	mode: PropTypes.oneOf(['horizontal', 'vertical']),

	/** List of different race types */
	countryCodes: PropTypes.array,

	/** Extra classes */
	className: PropTypes.string,

	counryGroup: PropTypes.array.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}),

	resetFilterClick: PropTypes.func,
};

RaceCountryFilter.defaultProps = {
	countryCodes: null,
	className: undefined,
	size: undefined,
	t: undefined,
	mode: undefined,
	resetFilterClick: () => undefined,
};

export default withNamespaces()(RaceCountryFilter);
