// Libraries
import React from 'react';
import { connect } from 'react-redux';
// Components

import styled, { cx } from 'react-emotion';
import { Component } from 'react';
import { Dropdown } from './DropDown';
import { navigate, routeTo } from '../../../../../store/application/applicationActions';
import { WEIGHTS, FAMILYS, COLORS, TYPE_SCALE, MEDIA_QUERIES,FOOTER_DEFAULT_COLORS } from '../../../../../constants/themes';
import { RS_TIPS_BASE_URL } from '../../../../../pages/RSTips/RSTipsConstants';
import { PROMOTIONS_BASE_URL } from '../../../../../pages/Promotions/PromotionsPageConstants';
//import { LEGAL_BASE_URL } from '../../../../../pages/LegalPage/LegalConstants';
import Image from '../../../../UI/Image/Image';

const Nav = styled.nav`
	label: GRS_Nav_SideBar;

	font-weight: ${WEIGHTS.medium};
	font-family: ${FAMILYS.primary};

	display: flex;
	flex-direction: column;
	height: 100%;
	width: fit-content;
	overflow: hidden;
	overflow-y: auto;
	position: relative;
	background-color: white;
	z-index: 100;
	max-width: 240px;
`;

const MobileFooterWrapper = styled('div')`
	background-color: ${FOOTER_DEFAULT_COLORS.background_color};
	color: white;
	padding: 14px 10px;
	height: 100%;
	padding-top: calc(14px + env(safe-area-inset-bottom));
	@media ${MEDIA_QUERIES.laptopAndUp} {
		display: none;
	}
`;

const MobileFooterLinkSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 16px;
	flex-wrap: wrap;
`;

const MobileFooterLinkWrapper = styled.div`
	text-align: left;
	margin-bottom: 16px;
	order: ${props => props.block === 'PAYMENT METHODS' ? 4 : 0};
`;

const MobileFooterTitle = styled.h3`
	color: ${COLORS.white};
	font-weight: ${WEIGHTS.bold};
	font-size: ${TYPE_SCALE.header6};
	text-transform: uppercase;
	margin-bottom: 3px;
`;

const MobileDivder = styled.div`
	background-color: ${FOOTER_DEFAULT_COLORS.divider_color};
	width: 50px;
	height: 3px;
	margin-bottom: 6px;
`;

const MobileLinkList = styled.ul`
	display: flex;
	flex-direction: ${({ as }) => (as ? as : 'column')};
	align-items: ${({ as }) => (as ? 'center' : 'normal')};
	gap: ${({ as }) => (as ? '6px' : null)};
	list-style: none;
	padding: 0;
`;

const MobileLinkListItem = styled.li`
	padding-top: 6px;
	padding-bottom: 6px;
`;

const MobilePaymentIconWrapper = styled.div`
	background-color: ${COLORS.white};
	border-radius: 3px;
	padding: 4px;
	text-align: center;
`;

const MobileFooterLink = styled.a`
	color: #f3f3f3;
	font-weight: ${WEIGHTS.normal};
	font-size: ${TYPE_SCALE.paragraph};
	text-decoration: none;
	display: block;

	&:hover {
		color: ${COLORS.red[300]};
	}
`;

const onClickLink = (type) => {
	window.location.replace(type);
};

class GRSSideBarnavigation extends Component {
	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.sideBarData.today.children.length > 0) {
			return true;
		}
		return false;
	}

	getYesterdayDate = () => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        const year = yesterday.getFullYear();
        const month = String(yesterday.getMonth() + 1).padStart(2, '0');
        const day = String(yesterday.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

	render() {
		const { 
			onGoToRace, 
			navigateToPage, 
			sideBarData, 
			brandDetails, 
			footerLists, 
			className,
			handleClick,
			SportsIsEnabled,
		 } = this.props;

		const yesterdayDate = this.getYesterdayDate();
        const yesterdayRoute = `/racing-v3/${yesterdayDate}`;
		const yesterdayRouteTRoute = `/racing-v3/t/${yesterdayDate}`;
		const yesterdayRouteHRoute = `/racing-v3/h/${yesterdayDate}`;
		const yesterdayRouteGRoute = `/racing-v3/g/${yesterdayDate}`;


		const items = [
			{
				name: 'Racing',
				id: 'racing_painel',
				link: '',
				children: [sideBarData.today,sideBarData.tomorrow,sideBarData.dayAfterTomorrow,

					// sideBarData.previousResults,

					// New submenu item for previous results,
					{
						name: 'Previous Results',
						id: 'previous_results',
						link: '',
						step: 'step-1',
						children: [
							{
								name: 'THAROUGHBERD',
								id: 'THAROUGHBERD',
								link: '',
								step: 'step-2',
								action: () => { this.props.navigateToPage(yesterdayRouteTRoute); },
							},
							{
								name: 'HARNESS',
								id: 'HARNESS',
								link: '',
								step: 'step-2',
								action: () => { this.props.navigateToPage(yesterdayRouteHRoute); },
							},
							{
								name: 'GREYHOUNDS',
								id: 'GREYHOUNDS',
								link: '',
								step: 'step-2',
								action: () => { this.props.navigateToPage(yesterdayRouteGRoute); },
							}
						],
						action: () => { 
							this.props.navigateToPage(yesterdayRoute);
						},
					}
				],
				step: 'step-0',
				action: () => {
					onGoToRace();
				},
			},
			// {
			// 	name: 'Feature Races',
			// 	id: '',
			// 	action: () => navigate(''),
			// 	link: '/futures',
			// 	disabled: true,
			// 	children: [],
			// 	step: 'step-0'
			// },
			{
				name: 'Promos',
				id: '',
				link: 'betbulderurl',
				disabled: true,
				children: [],
				step: 'step-0',
				action: () => {
					this.props.navigateToPage(PROMOTIONS_BASE_URL);
				},
			},
			// {
			// 	name: 'Bet Builder',
			// 	id: '',
			// 	link: 'betbulderurl',
			// 	disabled: true,
			// 	children: [],
			// 	step: 'step-0',
			// 	action: () => {
			// 		this.props.navigateToPage('/betbuilder');
			// 	},
			// },
			{
				name: 'Tips',
				id: '',
				link: 'betbulderurl',
				icon: 'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/rns-logo.png',
				disabled: true,
				children: [],
				step: 'step-0',
				action: () => this.props.navigateToPage(RS_TIPS_BASE_URL),
			},
			// {
			// 	name: 'Racing Specials',
			// 	isComingSoon: true,
			// 	id: '',
			// 	link: 'betbulderurl',
			// 	disabled: true,
			// 	children: [],
			// 	step: 'step-0',
			// 	action: () => navigate(''),
			// },
			...(SportsIsEnabled ? [{
                name: 'Sports',
                id: '',
                isComingSoon: false,
                link: 'betbulderurl',
                disabled: true,
                children: [],
                step: 'step-0',
                action:{},
            }] : []),
			// {
			// 	name: 'In Play',
			// 	id: '',
			// 	link: 'betbulderurl',
			// 	disabled: true,
			// 	children: [],
			// 	step: 'step-0',
			// 	action: () => navigate(''),
			// },
			// {
			// 	name: 'Others',
			// 	id: 'others_painel',
			// 	step: 'step-0',
			// 	action: () => { },
			// 	children: [
			// 		{
			// 			name: 'Privacy Policy',
			// 			id: '',
			// 			link: 'privacy-policy',
			// 			action: () => {
			// 				this.props.navigateToPage(PRIVACY_POLICY_BASE_URL);
			// 			},
			// 			children: [],
			// 			step: 'step-2'
			// 		},
			// 		{
			// 			name: 'Terms and Conditions',
			// 			id: '',
			// 			link: 'terms-conditions',
			// 			action: () => {
			// 				this.props.navigateToPage(TERMS_AND_CONDITIONS_BASE_URL);
			// 			},
			// 			children: [],
			// 			step: 'step-2'
			// 		},
			// 		{
			// 			name: 'FAQS',
			// 			id: '',
			// 			link: 'http://lxh.bc2.myftpupload.com/',
			// 			action: () => onClickLink('http://lxh.bc2.myftpupload.com'),
			// 			children: [],
			// 			step: 'step-2'
			// 		},
			// 	],
			// },
			// {
			// 	name: 'Help',
			// 	id: 'help_painel',
			// 	step: 'step-0',
			// 	children: [
			// 		{
			// 			name: 'Live Chat',
			// 			id: '',
			// 			link: 'live-chat',
			// 			action: () => { },
			// 			children: [],
			// 			step: 'step-2',
			// 		},
			// 		{
			// 			name: 'Customer Support',
			// 			id: '',
			// 			link: 'customer-support',
			// 			action: () => { },
			// 			children: [],
			// 			step: 'step-2',
			// 		},
			// 		/*{
			// 			name: 'Live Betting',
			// 			id: '',
			// 			link: 'http://lxh.bc2.myftpupload.com/',
			// 			action: () => {},
			// 			children: [],
			// 			step: 'step-2',
			// 		},*/
			// 		// {
			// 		// 	name: 'Provide FeedBack',
			// 		// 	id: '',
			// 		// 	link: 'http://lxh.bc2.myftpupload.com/',
			// 		// 	action: () => { },
			// 		// 	children: [],
			// 		// 	step: 'step-2'
			// 		// },
			// 		{
			// 			name: 'FAQs',
			// 			id: '',
			// 			link: '',
			// 			action: () => this.props.navigateToPage(LEGAL_BASE_URL + '/faqs'),
			// 			children: [],
			// 			step: 'step-2',
			// 		},
			// 		{
			// 			name: 'About Us',
			// 			id: '',
			// 			link: '',
			// 			action: () => this.props.navigateToPage(LEGAL_BASE_URL + '/about-us'),
			// 			children: [],
			// 			step: 'step-2',
			// 		},
			// 		{
			// 			name: 'Privacy Policy',
			// 			id: '',
			// 			link: '',
			// 			action: () => {
			// 				this.props.navigateToPage(LEGAL_BASE_URL + '/privacy-policy');
			// 			},
			// 			children: [],
			// 			step: 'step-2',
			// 		},
			// 		{
			// 			name: 'Terms And Conditions',
			// 			id: '',
			// 			link: '',
			// 			action: () => {
			// 				this.props.navigateToPage(LEGAL_BASE_URL + '/terms-and-conditions');
			// 			},
			// 			children: [],
			// 			step: 'step-2',
			// 		},
			// 		{
			// 			name: 'Responsible Gambling',
			// 			id: '',
			// 			link: '',
			// 			action: () => {
			// 				this.props.navigateToPage(LEGAL_BASE_URL + '/responsible-gambling');
			// 			},
			// 			children: [],
			// 			step: 'step-2',
			// 		},
			// 		{
			// 			name: 'Dispute Resolution',
			// 			id: '',
			// 			link: '',
			// 			action: () => {
			// 				this.props.navigateToPage(LEGAL_BASE_URL + '/dispute-resolution');
			// 			},
			// 			children: [],
			// 			step: 'step-2',
			// 		},
			// 	],
			// },
		];
		const componentClasses = cx({
			[className]: className,
		});
		return (
			<Nav className={componentClasses}>
				{items.map((item, i) => (
					<Dropdown
						isFirst={i === 0}
						items={item.children}
						name={item.name}
						step={item.step}
						action={item.action}
						key={item.name + item.step}
						brandDetails={brandDetails}
						isComingSoon={item.isComingSoon}
						icon={item.icon}
					/>
				))}
				<MobileFooterWrapper>
					<MobileFooterLinkSection>
						{footerLists && footerLists.map((item) => (
							<MobileFooterLinkWrapper block={item.title} key={item.index}>
								<MobileFooterTitle>{item.title}</MobileFooterTitle>
								<MobileDivder></MobileDivder>
								<MobileLinkList as={item.itemsStyle ? 'row' : ''} key={item.index}>
									{item.links &&
										item.links.map((link) => (
											<MobileLinkListItem key={link.index}>
												{link.imageSrc ? (
													<MobilePaymentIconWrapper >
														<Image modifiers="static" src={link.imageSrc} />
													</MobilePaymentIconWrapper>
												) : (
													<MobileFooterLink
														href={link.href.startsWith('http') ? link.href : '#'}
														target="_blank"
														onClick={handleClick}
														data-url-param={link.href}
													>
														{link.icon && <i className={`fa-${link.icon === 'phone' ? 'solid' : 'brands'} fa-${link.icon}`}></i>} {link.linkText}

													</MobileFooterLink>
												)}
											</MobileLinkListItem>
										))}
								</MobileLinkList>
							</MobileFooterLinkWrapper>
						))}
					</MobileFooterLinkSection>
				</MobileFooterWrapper>
			</Nav>
		);
	}
}

const mapStateToProps = (state) => ({
	brandDetails: state.acl.brandDetails,
	SportsIsEnabled: state.featureToggles.features.Sports ? state.featureToggles.features.Sports.enabled : false,
});

const mapDispatchToProps = (dispatch) => {
	return {
		handleClick: (event) => {
			const urlParam = event.target.getAttribute('data-url-param');
			if (!urlParam.startsWith('http')) {
				event.preventDefault();
				dispatch(routeTo('/' + urlParam));
			}
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GRSSideBarnavigation);
