import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { spacings, typography, media, brand } from '@tbh/ui-kit';

// get the default silk image
import { GENERIC_SILK, SELECTION_NOT_SCRATCHED_STATUS } from '../../../../../common/constants/Racing';
import { allocationWeightCalculate } from '../../../../../common/allocationWeightCalc';
import { Image } from '@tbh/ui-kit';
import { Text } from '@tbh/ui-kit';
import moment from 'moment';

const StyledRaceSelectionsDetails = styled('div')`
	${(props) =>
		css`
			label: RaceSelectionsDetails;

			width: 100%;
			display: flex;
			align-items: end;
		`};
`;

const StyledRaceSelectionsDetailsWrapper = styled('div')`
	${(props) =>
		css`
			display: flex;
			align-items: center;
		`};
`;

const StyledRaceSelectionsDetails__Silk = styled('div')`
	${(props) =>
		css`
			label: RaceSelectionsDetails__Silk;

			float: left;
			width: 28px;
			margin-right: ${spacings(props).tight}px;
			align-self: center;

			${props.scratched &&
				css`
					opacity: 0.6;
					filter: grayscale(100%);
				`};
		`};

	${media(
		(props) =>
			css`
				margin-right: ${spacings(props).tight}px;
			`,
		500,
	)};

	${media(
		css`
			width: 45px;
		`,
		660,
	)};
`;

const StyledRaceSelectionsDetails__SilkImage = styled(Image)`
	height: 26px;

	${media(
		css`
			height: 35px;
		`,
		660,
	)};
`;

const StyledRaceSelectionsDetails__DetailsColumn = styled('div')`
	${(props) =>
		css`
			label: RaceSelectionsDetails__DetailsColumn;

			padding-top: ${spacings(props).tight}px;
			text-overflow: ellipsis;
			overflow: hidden;
			width: 160px;
			${props.scratched &&
				css`
					width: calc(100% - 45px);
					opacity: 0.6;
					filter: grayscale(100%);
				`};
		`};

	${media(
		css`
			padding-top: 0;
		`,
		660,
	)};
`;

const StyledRaceSelectionsDetails__LastStartsRow = styled(Text)`
	${(props) =>
		css`
			label: RaceSelectionsDetails__LastStartsRow;
			width: 35px;
			${props.scratched &&
				css`
					opacity: 0.6;
					filter: grayscale(100%);
				`};
		`};
`;

const StyledRaceSelectionsDetails__Name = styled(Text)`
	${(props) =>
		css`
			label: RaceSelectionsDetails__Name;
			margin-right: ${spacings(props).tight}px;
			color: #003a91;
			color: #000000 !important;
			&:after {
				content: ' ';
			}
			white-space: nowrap;
		`};

	${media(
		(props) =>
			css`
				font-size: ${typography(props)['size_-2']}px;
			`,
		660,
		{ sizeKey: 'mediaSize' },
	)};
`;

const StyledRaceSelectionsDetails__RunnerDetails = styled(Text)`
	${media(
		(props) =>
			css`
				font-size: ${typography(props)['size_-3']}PX;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			`,
		660,
		{ sizeKey: 'mediaSize' },
	)};
`;

const StyledRaceSelectionsDetails__Subtitle = styled(Text)`
	${(props) =>
		css`
			label: RaceSelectionsDetails__Subtitle;

			margin: ${spacings(props).constrictive}px 0;
		`};
`;

const StyledRaceSelectionsDetails_hotselection = styled(Text)`
	${(props) =>
		css`
			label: RaceSelectionsDetails_hotselection;
			position: absolute;
			top: -4px;
			left: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 70px;
			width: 70px;
			height: ${spacings(props).cozy}px;
			float: left;

			background: ${brand(props).rch_4};
			color: #fff;
			border-radius: 4px;
		`};
`;

const RaceSelectionDetails = React.memo((props) => {
	const {
		t,
		className,
		hideSilk,
		number,
		name,
		silk,
		jockey,
		trainer,
		barrier,
		last_starts_string,
		selection_status,
		subtitle,
		type_code,
		weight,
		allocation,
		size,
		id, // selection id
		is_jockey_hot,
		is_trainer_hot,
		hotSelection,
		betType,
		price,
		tes,
		sel,
		filter_trainer,
		scratching_time,
		onClickTogleForm,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});
	/**
	 * Create the jockey or driver label dependant on the race type code
	 * NOTE: no need to cater for greyhounds as it is not rendered
	 * last_starts_string.substring(last_starts_string.length , 5); This is the get last _starts_string in jocky.
	 * @type {string}
	 */
	let JockeyLable = 'J';
	let Driver = 'D';
	let Trainer = 'T';
	let jockeyLabel = type_code === 'H' ? Driver : JockeyLable;
	let weightLabel = 'W';

	let lastFiveStarts;
	if (last_starts_string) {
		lastFiveStarts = last_starts_string.substring(0, 5);
	}

	const scratched = selection_status !== SELECTION_NOT_SCRATCHED_STATUS;
	const trainerLabel = typeof trainer === 'object' ? null : trainer;

	const clickform = (e) => {
		e.preventDefault();
		onClickTogleForm();
	};

	return (
		<StyledRaceSelectionsDetails className={componentClasses} onClick={clickform} isFav={hotSelection == id}>
			{betType !== 'trainerwin' && betType !== 'trainert4' && (
				<StyledRaceSelectionsDetailsWrapper>
					{!hideSilk && (
						<StyledRaceSelectionsDetails__Silk scratched={scratched} size={size}>
							<StyledRaceSelectionsDetails__SilkImage
								size={size}
								src={silk ? silk : GENERIC_SILK}
								alt={name + 'racing silk'}
								substituteImageSrc={GENERIC_SILK}
							/>
							{last_starts_string && (
								<StyledRaceSelectionsDetails__LastStartsRow
									size="-4"
									align="center"
									strong
									scratched={scratched}
									mediaSize={size}
								>
									{lastFiveStarts}
								</StyledRaceSelectionsDetails__LastStartsRow>
							)}
						</StyledRaceSelectionsDetails__Silk>
					)}
					{selection_status === SELECTION_NOT_SCRATCHED_STATUS ? (
						<StyledRaceSelectionsDetails__DetailsColumn scratched={scratched} size={size}>
							<StyledRaceSelectionsDetails__Name
								lineThrough={scratched}
								mediaSize={size}
								size="-3"
								transform="uppercase"
								strong
							>
								{number && `${number}.`} {name}
								{barrier > 0 && (
									<StyledRaceSelectionsDetails__RunnerDetails tag="span" mediaSize={size} size="-4">
										{' '}
										({barrier})
									</StyledRaceSelectionsDetails__RunnerDetails>
								)}
							</StyledRaceSelectionsDetails__Name>
							{subtitle && (
								<StyledRaceSelectionsDetails__Subtitle size="-5">{subtitle}</StyledRaceSelectionsDetails__Subtitle>
							)}
							{trainerLabel && (
								<StyledRaceSelectionsDetails__RunnerDetails
									mediaSize={size}
									whiteSpace="nowrap"
									textOverflow="ellipsis"
									size="-5"
								>
									{Trainer}: <b>{trainerLabel}</b>{' '}
									{is_trainer_hot === 'true' ? (
										<img
											src={'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/fire.png'}
											className={css`
												padding-left: 0px;
												padding-top: 0px;
												width: 10px;
												height: 10px;
											`}
											alt="fire"
										/>
									) : null}
								</StyledRaceSelectionsDetails__RunnerDetails>
							)}
							{type_code && type_code !== 'G' && jockey ? (
								<StyledRaceSelectionsDetails__RunnerDetails
									mediaSize={size}
									size="-5"
									whiteSpace="nowrap"
									textOverflow="ellipsis"
								>
									{jockeyLabel}:{' '}
									<b>
										{jockey} {allocationWeightCalculate(weight, allocation)}
									</b>{' '}
									{is_jockey_hot === 'true' ? (
										<img
											src={'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/fire.png'}
											className={css`
												padding-left: 0px;
												padding-top: 0px;
												width: 10px;
												height: 10px;
											`}
											alt="fire"
										/>
									) : null}
								</StyledRaceSelectionsDetails__RunnerDetails>
							) : null}
							{weight > 0 && type_code !== 'H' && (
								<StyledRaceSelectionsDetails__RunnerDetails mediaSize={size} size="-5">
									<span>{weightLabel}: </span>
									<b>
										{weight}
										{t('AcronymKilogram')}
									</b>
								</StyledRaceSelectionsDetails__RunnerDetails>
							)}
						</StyledRaceSelectionsDetails__DetailsColumn>
					) : (
						<StyledRaceSelectionsDetails__DetailsColumn scratched={scratched} size={size}>
							<StyledRaceSelectionsDetails__Name
								lineThrough={scratched}
								mediaSize={size}
								size="-3"
								transform="uppercase"
								strong
								className={css`
									white-space: nowrap;
								`}
							>
								{number && `${number}.`} {name}
								{barrier > 0 && (
									<StyledRaceSelectionsDetails__RunnerDetails tag="span" mediaSize={size} size="-4">
										{' '}
										({barrier})
									</StyledRaceSelectionsDetails__RunnerDetails>
								)}
							</StyledRaceSelectionsDetails__Name>
							<StyledRaceSelectionsDetails__Name mediaSize={size} size="-2" transform="uppercase" strong>
								<StyledRaceSelectionsDetails__RunnerDetails tag="span" mediaSize={size} size="-5">
									{'Scratched'}
								</StyledRaceSelectionsDetails__RunnerDetails>
							</StyledRaceSelectionsDetails__Name>
							{scratching_time !== null ? (
								<StyledRaceSelectionsDetails__Name mediaSize={size} size="-2" transform="uppercase" strong>
									<StyledRaceSelectionsDetails__RunnerDetails tag="span" mediaSize={size} size="-5">
										{'Scratching Time '}{moment(scratching_time).format('DD-MM-YYYY, h:mm:ss a')}
									</StyledRaceSelectionsDetails__RunnerDetails>
								</StyledRaceSelectionsDetails__Name>
							) : null}
						</StyledRaceSelectionsDetails__DetailsColumn>
					)}
				</StyledRaceSelectionsDetailsWrapper>
			)}
			{betType === 'trainerwin' && tes === 1 && (
				<StyledRaceSelectionsDetailsWrapper>
					{selection_status === SELECTION_NOT_SCRATCHED_STATUS ? (
						<StyledRaceSelectionsDetails__DetailsColumn scratched={scratched} size={size}>
							<StyledRaceSelectionsDetails__Name
								lineThrough={scratched}
								mediaSize={size}
								size="-4"
								transform="uppercase"
								strong
							>
								{trainer}
							</StyledRaceSelectionsDetails__Name>
						</StyledRaceSelectionsDetails__DetailsColumn>
					) : (
						<StyledRaceSelectionsDetails__DetailsColumn scratched={scratched} size={size}>
							<StyledRaceSelectionsDetails__Name
								lineThrough={scratched}
								mediaSize={size}
								size="-4"
								transform="uppercase"
								strong
							>
								{trainer}
							</StyledRaceSelectionsDetails__Name>
						</StyledRaceSelectionsDetails__DetailsColumn>
					)}
				</StyledRaceSelectionsDetailsWrapper>
			)}

			{betType === 'trainert4' && (
				<StyledRaceSelectionsDetailsWrapper>
					{selection_status === SELECTION_NOT_SCRATCHED_STATUS ? (
						<StyledRaceSelectionsDetails__DetailsColumn scratched={scratched} size={size}>
							<StyledRaceSelectionsDetails__Name
								lineThrough={scratched}
								mediaSize={size}
								size="-4"
								transform="uppercase"
								strong
							>
								{trainer}
							</StyledRaceSelectionsDetails__Name>
						</StyledRaceSelectionsDetails__DetailsColumn>
					) : (
						<StyledRaceSelectionsDetails__DetailsColumn scratched={scratched} size={size}>
							<StyledRaceSelectionsDetails__Name
								lineThrough={scratched}
								mediaSize={size}
								size="-4"
								transform="uppercase"
								strong
							>
								{trainer}
							</StyledRaceSelectionsDetails__Name>
						</StyledRaceSelectionsDetails__DetailsColumn>
					)}
				</StyledRaceSelectionsDetailsWrapper>
			)}
		</StyledRaceSelectionsDetails>
	);
});

RaceSelectionDetails.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}),

	/** Selections race number */
	id: PropTypes.number.isRequired,

	/** The selection name */
	name: PropTypes.string.isRequired,

	/** The selection number */
	number: PropTypes.number,

	/** The race selection type */
	type_code: PropTypes.string,

	/** The jockey weight */
	weight: PropTypes.number,

	/** The jockey silk */
	silk: PropTypes.string,

	/** The jockey name */
	jockey: PropTypes.string,

	/** The trainer name */
	//trainer: PropTypes.oneOfType(PropTypes.string, PropTypes.object),
	trainer: PropTypes.string,

	/** The selection barrier */
	barrier: PropTypes.number,

	/** The last starts for the selection */
	last_starts_string: PropTypes.string,

	/** The selection status */
	selection_status: PropTypes.oneOf(['not scratched', 'scratched', 'late scratching']),

	/** The fixed price changes/flucs */
	fluctuations: PropTypes.string,

	/**
	 * If the silk should be hidden or not.
	 * In the race card we need to show a generic silk for the runners that don't have a silk,
	 * but hide the image for selections that don't have a silk (E.g.: in the derivative selections).
	 */
	hideSilk: PropTypes.bool,

	/** Extra text to display under the runner */
	subtitle: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,

	hotSelection: PropTypes.number,

	is_jockey_hot: PropTypes.string,

	is_trainer_hot: PropTypes.string,

	onClickTogleForm: PropTypes.func,
};

RaceSelectionDetails.defaultProps = {
	className: null,
	weight: null,
	silk: null,
	jockey: null,
	trainer: null,
	barrier: null,
	last_starts_string: null,
	selection_status: null,
	fluctuations: null,
	number: null,
	subtitle: null,
	type_code: null,
	hideSilk: false,
	size: {},
	hotSelection: null,
	is_jockey_hot: null,
	is_trainer_hot: null,
	onClickTogleForm: undefined,
};

export default withNamespaces()(RaceSelectionDetails);
