import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import titleize from 'underscore.string/titleize';
import moment from 'moment';
import { spacings, media, brand, Text, Notification } from '@tbh/ui-kit';
import { RNSAddToBackBook } from '../../../store/RNS/RNSAddtoBackBook';

import {
	openNotification,
	toggleMultiBetSlip,
	toggleSideBetSlip,
	openMultiBetSlip,
} from '../../../store/application/applicationActions';

import {
	buildBetsForRace,
	buildExoticProducts,
	buildMobileWinPlaceChoices,
	buildRacingExoticResults,
	buildWinPlaceProducts,
	buildWinPlaceResults,
	getFlucsKey,
	getSelectedRace,
	shouldRenderFormsButton,
	racingHomeRaceId,
	getGoatMarginButtLength,
	buildSelectionBetButtons,
	getBetbuilderSelectedMeeting,
	buildOddsGridPrice,
} from '../../../pages/GRSNewRacing/RacingNewHome/racingSelectorsGRS';

import {
	RACING_NON_EXOTIC_BET_TYPE,
	TRACKING_CATEGORY_RACE_ALL_FORM,
	TRACKING_CATEGORY_RACE_MARKET_SELECTOR,
	TRACKING_CATEGORY_RACE_SPEEDMAP_SELECTOR,
	TRACKING_CATEGORY_RACE_DERIVATIVE_SELECTOR,
	TRACKING_CATEGORY_RACE_CARD_PRODUCT_SELECTOR,
	TRACKING_CATEGORY_RACE_RUNNER_FORM,
	RACING_BET_TYPE_QUADDIE,
	RACING_BET_TYPE_DAILY_DOUBLE,
	TRACKING_CATEGORY_RACE_HOT_STATS_SELECTOR,
	TRACKING_CATEGORY_RACE_BET_BUILDER_SELECTOR,
	TRACKING_CATEGORY_RACE_COMPUTER_TIPS_SELECTOR,
	RACE_SELLING_STATUS,
	RACING_MULTIPLES_TYPE,
	QUADDIES_BET_TYPES,
	RACING_EXOTIC_MINIMUM_SELECTIONS,
	RACING_SAME_RACE_MULTI_TYPE,
	RACING_THOROUGHBRED_CODE,
	SELECTION_SCRATCHED_STATUS,
} from '../../../common/constants/Racing';

import {
	formatAndAddDerivativeSelectionToBetPrompt,
	formatAndAddExoticSelectionToBetPrompt,
	formatAndAddSingleSelectionToBetPrompt,
	resetBetPrompt,
	resetBetPromptTitle,
	formatExoticSelection,
} from '../../../store/betPrompt/betPromptActions';

import {
	fetchMeetingsAndRacesWithSelectionsForRace,
	fetchBetBulderMeetingWithRaceSelections,
} from '../../../store/entities/actions/MeetingActions';

import {
	setExoticPools,
	handleClearSelectionsClick,
	setSelectedBuilderMeeting,
	setSelectedBetType,
	handleQuaddieSelections,
	CleanQuaddieSelections,
	navigateToLegRace,
	SetSelectedQuaddieLegs,
} from '../../../store/GRSracingHome/GRSracingHomeActions';

import { getAuthenticatedUser } from '../../../store/application/applicationSelectors';

import {
	formatAndAddExoticSelectionBetslip,
	formatAndAddQuaddiesMultiBet,
	formatAndAddRacingMultiBet,
	formatAndAddSameRaceMultiBet,
} from '../../../common/actions/multiActions';

import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';
import { isBetReferred } from '../../Betting/bettingMemoizedSelectors';
import { triggerEventMessage } from '../../../common/actions/widgetActions';
import { quaddiePosition } from '../../../common/QuaddiePosition';
import { dailyDoublePosition } from '../../../common/DailyDoublePosition';
import { CacheOut } from '../../../store/CacheOut/CacheOutAction';

// Components
import Modal from '../../../components/controllers/Modal/Modal';
import DepositContainer from '../../Deposit/DepositContainer/DepositContainer';
import DocumentTitle from '../../../components/controllers/Meta/DocumentTitle';
import DescriptionMeta from '../../../components/controllers/Meta/DescriptionMeta';
import ModalHeader from '../../../components/features/Application/ModalHeader/ModalHeader';
import BetPromptContainer from '../../Betting/BetPromptContainer/BetPromptContainer';
import BrandContactPhone from '../../../components/ui-components/BrandContactPhone/BrandContactPhone';
import ModalFooter from '../../../components/features/Application/ModalFooter/ModalFooter';
import { routeTo } from '../../../store/application/applicationActions';
import { errorString, serverDateTime } from '../../../../src/legacy/core/format';
import GRSExpertSelectionsList from '../../../components/features/Racing/GRSExpertSelectionsList/GRSExpertSelectionsList';
import { getSameRaceMultiSelectionsForValidation } from '../../../pages/Racing/RacingHome/racingSelectorsGRS';
import { validateSameRaceMulti } from '../../../store/betPlacement/betPlacementActions';
import Switch from 'react-switch';
import EarlySpeed from '../../../components/features/Racing/Informatics/EarlySpeed/EarlySpeed';
import ComputerTips from '../../../components/features/Racing/Informatics/ComputerTips/ComputerTips';
import { getNotScratchedSelections } from '../../../pages/Racing/RacingHome/racingSelectorsGRS';
import NewRaceCard from '../../../components/features/Racing/RaceCard/NewRaceCard';
import SpeedmapLegend from '../../../components/features/Racing/Informatics/Speedmap/SpeedmapLegend/SpeedmapLegend';
import { SelectQuaddieType } from '../../../common/SelectQuaddieBet';
import { buildRaceLegSequence } from '../../../common/BuildRaceLegSequence';
import RaceTypeMenu from '../../../components/features/Racing/RaceCard/RaceTypeMenu';
import { MOBILE_MAX_WIDTH } from '../../../common/constants/Breakpoints';
import RaceResultsV2 from '../../../components/features/Racing/RaceResultsNew/RaceResultV2';

export const StyledRaceCardContainer_main = styled('div')`
	${(props) =>
		css`
			label: RaceCardContainer_main;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			width: 100%;
		`};

	${media(
		(props) =>
			css`
				clear: both;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				width: 100%;
			`,
		768,
	)};
`;

export const StyledRaceCardContainer_expertSections = styled('div')`
	${(props) => css`
		label: RaceCardContainer_expertSection;
		// flex: 0 0 99%;
		// max-width: 99%;

		// margin: 5px;
		width: 100%;
		box-shadow: 0px 2px 8px rgb(0 0 0 / 18%);
		transition: all 0.3s ease 0s;
	`};
	${media(
		(props) =>
			css`
				display: flex;
				justify-content: space-between;
				// flex: 0 0 ${props.isMultiBetSlipOpen === false ? '99%' : '99%'};
				// max-width: ${props.isMultiBetSlipOpen === false ? '99%' : '99%'};
				// margin: 5px;
				box-shadow: 0px 2px 8px rgb(0 0 0 / 18%);
				transition: all 0.3s ease 0s;
			`,
		768,
	)};
`;

export const StyledRaceCard_ExpertSection = styled('div')`
	label: RaceCard_ExpertSection;
	flex-wrap: wrap;
	display: flex;
	width: 100%;
	border: 0;
	${media(
		(props) =>
			css`
				display: flex;
				width: 100%;
			`,
		279,
	)};
`;

export const StyledRaceCard__RaceOverView = styled('div')`
	${(props) =>
		css`
			label: RaceCard__RaceOverView;

			background: #f0f1f3;
			flex: 1;
			margin-bottom: 20px;
		`};
	${media(
		(props) => css`
			flex: 1;
		`,
		500,
	)};
`;
export const StyledRaceCard__ExpertTopSelection = styled('div')`
	${(props) =>
		css`
			label: RaceCard__ExpertTopSelection;
			
			width: 100%;
			
			// padding-left: ${spacings(props).tight}px;
			// padding-right: ${spacings(props).cozy}px;
		`};
	${media(
		(props) => css`
			width: fit-content;
		`,
		500,
	)};
`;
export const StyledRaceCard__SubSectionHeader = styled('div')`
	${(props) =>
		css`
			label: RaceCard__Expert;
			padding: ${spacings(props).compact}px;
			width: 100%;
			min-width: none;
			height: 44px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;
			background-color: ${brand(props).default_app_bg_color ? brand(props).default_app_bg_color : '#1C1F21'};
			color: #fff;
		`};
	${media(
		(props) => css`
			min-width: 300px;
		`,
		500,
	)};
`;

export const StyledBackBook__Notification = styled(Notification)`
	label: BackBook__Notification;

	${(props) =>
		css`
			margin: 0 0 ${spacings(props).cozy}px;
		`};
`;

const StyledExpertTips = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: column;
		width: 100%;
		${props.isOpen &&
			`
			border: 1px solid #666666;
			border-top: 0;
		`}
	`,
);

const StyledExpertTips__Wrapper = styled('div')(
	(props) => css`
		display: grid;
		grid-template-columns: 100%;
		padding-bottom: 18px;
	`,
	media(
		(props) => css`
			grid-template-columns: 70% 30%;
		`,
		640,
	),
);

const SwitchBox = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: ${spacings(props).compact}px;
		padding: ${spacings(props).cozy}px;
	`,
);

const StyledCommentsSections = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: column;
		width: 100%;
	`,
);
const StyledComment = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: column;
		width: 100%;
		position: relative;
	`,
);

const StyledSwitchs = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;
	`,
);

const StyledRaceCard__Expert = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: column;
		width: 100%;
	`,
);

const StyledTips = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		background: #f0f1f3;
		padding: 8px;
	`,
);

const StyledSelectionsTips = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: column;
		width: 100%;
	`,
);

const ExpertTips = ({
	runningSelections,
	finalcomputerTipsSelections,
	race,
	isBettingAvailable,
	handleAddToSingle,
	handleAddToMulti,
	buildSelectionBetButtons,
	trackComputerTipsType,
	size,
	user,
	bets,
	selectedBetType,
	racingSpeedmapsEnabled,
}) => {
	const [isOpen, setIsOpen] = React.useState(false);

	const [option, setOption] = React.useState(null);
	const [clearBox, setClearBox] = React.useState(false);

	const handleSelect = (option) => {
		setClearBox(true);
		setOption(option);
	};

	// Calculate data availability
	const firstStarters = runningSelections.filter((selection) => !selection.last_starts_string);
	const lessThan50Percent = firstStarters.length === 0 || firstStarters.length / runningSelections.length < 0.5;
	const hasEarlySpeed = racingSpeedmapsEnabled && race.type === RACING_THOROUGHBRED_CODE && lessThan50Percent;
	const hasComputedTips = finalcomputerTipsSelections && finalcomputerTipsSelections.length > 0;
	const hasComments = !!race.comment;

	// Define specific condition checks
	const hasOnlyComments = hasComments && !hasEarlySpeed && !hasComputedTips;
	const hasOnlyEarlySpeed = hasEarlySpeed && !hasComputedTips && !hasComments;
	const hasOnlyComputedTips = hasComputedTips && !hasEarlySpeed && !hasComments;
	const hasTechnicalOnly = (hasEarlySpeed || hasComputedTips) && !hasComments;
	const hasAll = hasComments && (hasEarlySpeed || hasComputedTips);

	// Function to determine grid template based on conditions
	const getGridTemplate = () => {
		if (hasOnlyComments) return '0 100%'; // Condition 2: Only comments
		if (hasOnlyEarlySpeed || hasOnlyComputedTips || hasTechnicalOnly) return '100% 0'; // Condition 1 & 3: Only technical data
		if (hasAll) return '70% 30%'; // Both technical and comments
		return '100%'; // Default case
	};

	// Check if any data is available
	const hasAnyData = hasEarlySpeed || hasComputedTips || hasComments;

	// If no data is available, don't render anything
	if (!hasAnyData) {
		return null;
	}

	// Build available options
	const options = [];
	if (hasEarlySpeed) {
		options.push({
			label: 'Early Speed',
			value: 0,
			enabled: true,
			component: (
				<div className={css`display: flex; flex-direction: column; gap: 16px;`}>
					<EarlySpeed
						selections={runningSelections}
						handleClickSelection={handleAddToSingle}
						isBettingAvailable={isBettingAvailable}
						raceProducts={race.products}
					/>
					<SpeedmapLegend selections={runningSelections} size={size} />
				</div>
			),
		});
	}

	if (hasComputedTips) {
		options.push({
			label: 'Computed Tips',
			value: 1,
			enabled: true,
			component: (
				<ComputerTips
					size={size}
					raceRanking={finalcomputerTipsSelections}
					trackComputerTipsType={trackComputerTipsType}
					raceProducts={race.products}
					racingType={race.type}
					FilterClicked={(isChecked) => handleBoxChecked(isChecked)}
					clearBox={clearBox}
				/>
			),
		});
	}

	const filterOptions = options.filter(option => option.enabled);

	const handleBoxChecked = (isChecked) => {
		setClearBox(!isChecked);
		if (isChecked && !option) {
			setOption(filterOptions[0]);
		}
	};

	useEffect(() => {
		if (isOpen) {
			setIsOpen(false);
		}
	}, [race.id]);

	const isMobileWidth = size.width < MOBILE_MAX_WIDTH + 120;

	// Determine layout class based on available data
	const layoutClass = css`
    display: grid;
    grid-template-columns: ${getGridTemplate()};
    width: 100%;    
    ${hasTechnicalOnly && css`
        > *:first-child {
            grid-column: 1 / -1;
        }
    `}
    
    ${hasOnlyComments && css`
        > *:last-child {
            grid-column: 1 / -1;
        }
    `}
    
    @media (max-width: 640px) {
        grid-template-columns: 100%;
        
        > * {
            grid-column: 1 / -1;
        }
    }
`;

	return (
		<StyledExpertTips isOpen={isOpen}>
			<StyledRaceCard__SubSectionHeader isMobileWidth={isMobileWidth}>
				Expert Tips
				<Switch
					onChange={() => {
						setIsOpen(!isOpen);
						setOption(isMobileWidth ? null : filterOptions[0]);
					}}
					checked={isOpen}
					width={50}
					height={20}
				/>
			</StyledRaceCard__SubSectionHeader>

			{isOpen && (
				<StyledExpertTips__Wrapper className={layoutClass}>
					{(hasEarlySpeed || hasComputedTips) && (
						<StyledCommentsSections>
							{filterOptions.length > 1 && (
								<StyledSwitchs>
									{filterOptions.map((o) => (
										<SwitchBox key={o.value + o.label}>
											<Switch
												onChange={() => handleSelect(o)}
												checked={option && option.value === o.value}
												width={40}
												height={20}
											/>
											<Text size="-2">{o.label}</Text>
										</SwitchBox>
									))}
								</StyledSwitchs>
							)}
							<StyledComment>
								{option ? option.component : (filterOptions.length > 0 ? filterOptions[0].component : null)}
							</StyledComment>
						</StyledCommentsSections>
					)}

					{hasComments && (
						<StyledRaceCard__Expert>
							<StyledTips>
								<Text size="-2" paragraph>
									<img
										src={race.comment_source_logo}
										className={css`
                                            width: 2rem;
                                            height: 2rem;
                                            margin-right: 0.25rem;
                                        `}
									/>
									<b>
										{'Expert Tips by '}
										{race.comment_source}
									</b>
								</Text>
								<Text size="-3" paragraph>
									{race.comment}
								</Text>
							</StyledTips>
							<StyledSelectionsTips>
								<GRSExpertSelectionsList
									size={size}
									user={user}
									bets={bets}
									raceId={race.id}
									betType={selectedBetType}
									buildSelectionBetButtons={buildSelectionBetButtons}
									selections={race.top_selections}
									clickSingle={handleAddToSingle}
									clickMulti={handleAddToMulti}
									bettingAvailable={isBettingAvailable}
									raceProducts={race.products}
									winPlaceProducts={race.products}
								/>
							</StyledSelectionsTips>
						</StyledRaceCard__Expert>
					)}
				</StyledExpertTips__Wrapper>
			)}
		</StyledExpertTips>
	);
};

class NewRaceCardContainer extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
			height: PropTypes.number,
		}).isRequired,

		/** De-normalized race and details */
		race: PropTypes.shape({
			id: PropTypes.number.isRequired,
			status: PropTypes.string,
			fixed_odds_enabled: PropTypes.bool,
			number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			name: PropTypes.string,
			distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			start_date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
			exotic_bets_allowed: PropTypes.bool,
			selections: PropTypes.array,
			win_pool_total: PropTypes.number,
			place_pool_total: PropTypes.number,
			products: PropTypes.array,
		}).isRequired,

		/** Open a notification */
		onOpenNotification: PropTypes.func.isRequired,

		/** Action to fire when the bet slip badge is clicked on */
		onSideBetSlipClick: PropTypes.func.isRequired,

		/** Action to fire when the bet slip badge is clicked on */
		isSideBetSlipOpen: PropTypes.bool.isRequired,

		/** Function for hiding bet prompt */
		handleHideBetPrompt: PropTypes.func,

		/** Feature toggle for exotics */
		exoticsEnabled: PropTypes.bool.isRequired,

		/** Has the first race in the bet type pool finished or not */
		quaddiePoolClosed: PropTypes.bool,

		/** Has the first race in the bet type pool finished or not */
		dailyDoublePoolClosed: PropTypes.bool,

		/** Feature toggle for derivatives */
		racingDerivativesEnabled: PropTypes.bool.isRequired,

		/** Feature toggle for speedmaps */
		racingSpeedmapsEnabled: PropTypes.bool.isRequired,

		/** The currently authenticated user */
		user: PropTypes.object,

		/** Computed results to render */
		displayedResults: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
		displayedExoticResults: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

		/** Win/Place to build betting buttons */
		winPlaceProducts: PropTypes.arrayOf(
			PropTypes.shape({
				fixed: PropTypes.bool,
				bet_type: PropTypes.string,
			}),
		),

		/** Exotic products to bet combining runner positions */
		exoticProducts: PropTypes.array,

		/** Product choices to be displayed on small screens */
		winPlaceProductChoices: PropTypes.array,

		/** Fluctuations key to be rendered. False values hides Flucs column */
		fluctuationsKey: PropTypes.string,

		/** Action to build the list of selection bet buttons */
		buildSelectionBetButtons: PropTypes.func.isRequired,

		/** Add single selection when clicking on odds */
		formatAndAddSingleSelectionToBetPrompt: PropTypes.func.isRequired,

		/** Add exotic selection when clicking on Place Bet */
		formatAndAddExoticSelectionToBetPrompt: PropTypes.func.isRequired,

		/** Add derivative selection when clicking on odds */
		formatAndAddDerivativeSelectionToBetPrompt: PropTypes.func.isRequired,

		/** Add to multi bet slip */
		formatAndAddRacingMultiBet: PropTypes.func.isRequired,

		/** Actions for bet prompt */
		resetBetPrompt: PropTypes.func.isRequired,

		/** Reset the bet prompt title back to it's default value */
		resetBetPromptTitle: PropTypes.func.isRequired,

		/** Requirements from the bet prompt slice of state */
		betPrompt: PropTypes.shape({
			title: PropTypes.string.isRequired,
		}).isRequired,

		/** Passed via ownProps from parent, needed for bet placement */
		meetingId: PropTypes.number.isRequired,

		/** Passed via ownProps from parent, needed for bet placement */
		meetingName: PropTypes.string,

		/** Single bets place in this race */
		bets: PropTypes.array,

		/** Context name for prefixing custom events to be fired through middleware */
		eventContext: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

		/** Loading mask status to cover race card section */
		loadingRace: PropTypes.bool,

		/** Indicator whether the bet has been referred */
		betReferred: PropTypes.bool,

		/** Flag to render Forms button or not. Based of 'runner', under Selections. */
		shouldRenderFormsButton: PropTypes.bool,

		/** Extra class(es) to pass through */
		className: PropTypes.string,

		/** REST call to API to fetch and set state of exoticPools */
		updateExoticPools: PropTypes.func,

		/** Tracks bet type selection */
		trackGaMarketType: PropTypes.func,

		/** Tracks when speedmaps are toggled */
		trackGaSpeedmaps: PropTypes.func,

		/** Tracks when speedmaps are toggled */
		trackGRSHotStats: PropTypes.func,

		/** Tracks when derivative markets are filtered */
		trackGaDerivativeType: PropTypes.func,

		/** Tracking all form toggle */
		trackGaAllForm: PropTypes.func,

		/** Tracks single runner form toggle */
		trackGaRunnerForm: PropTypes.func,

		/** Tracks goat product being enabled */
		trackGaGoatType: PropTypes.func,

		/** Navigation to race id */
		navigateToRace: PropTypes.func,

		/** Brand name */
		brandName: PropTypes.string,

		/** Handle state toggle for clearSelections */
		handleClearSelectionsClick: PropTypes.func,

		/** Should checkbox selection state be cleared or not */
		clearSelections: PropTypes.bool,

		trackBetBuilder: PropTypes.func,

		/** REST call to API to fetch and set state of betbuilder */
		fetchBetBulder: PropTypes.func,

		/** Entity for selected bet builder meeting */
		betBuilderMeeting: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			state: PropTypes.string,
			country: PropTypes.string,
			type: PropTypes.string.isRequired,
			start_date: PropTypes.string.isRequired,
			races: PropTypes.array,
		}),

		navigateToBetBuilder: PropTypes.func.isRequired,

		meetingCountry: PropTypes.string,

		raceDistance: PropTypes.string,

		oddsGridPrice: PropTypes.array,

		trackComputerTipsType: PropTypes.func,

		/** Whether betting should be disabled or not */
		disableBetting: PropTypes.bool,

		/** If the bet slip is open or not */
		isMultiBetSlipOpen: PropTypes.bool,

		dailyBailOut: PropTypes.number,
	};

	static defaultProps = {
		user: null,
		bets: [],
		eventContext: '',
		loadingRace: false,
		meetingName: 'n/a',
		fluctuationsKey: null,
		className: null,
		displayedResults: [],
		displayedExoticResults: [],
		winPlaceProducts: [],
		oddsGridPrice: [],
		exoticProducts: [],
		quaddiePoolClosed: false,
		dailyDoublePoolClosed: false,
		winPlaceProductChoices: [],
		betReferred: false,
		shouldRenderFormsButton: false,
		isSideBetSlipOpen: false,
		clearSelections: false,
		updateExoticPools: () => {},
		handleClearSelectionsClick: () => {},
		handleHideBetPrompt: () => {},
		trackGaMarketType: () => {},
		trackGaAllForm: () => {},
		trackGaRunnerForm: () => {},
		trackGaSpeedmaps: () => {},
		trackGaDerivativeType: () => {},
		trackGaGoatType: () => {},
		brandName: '',
		navigateToRace: () => {},
		trackGRSHotStats: () => {},

		trackBetBuilder: () => {},
		fetchBetBulder: () => {},

		betBuilderMeeting: null,
		meetingCountry: '',
		raceDistance: null,

		trackComputerTipsType: () => {},
		disableBetting: false,
		isMultiBetSlipOpen: false,
		dailyBailOut: 0,
	};

	constructor(props) {
		super(props);

		this.state = {
			// For bet type filter use
			selectedBetType: RACING_NON_EXOTIC_BET_TYPE,
			boxed: false,
			raceId: props.race.id,

			selectedProduct: props.winPlaceProductChoices[0] && props.winPlaceProductChoices[0].betType,

			// Flag whether the bet prompt container is showing
			showBetPrompt: false,

			// Flag whether the Quick Deposit container is showing
			showQuickDepositPrompt: false,

			// Holds the status of the 'all form' open button
			allFormOpen: false,

			// Hold quaddie selections in memory across legs
			multiLegSelections: [],

			//Hot selection open button
			hotStats: false,

			backBookStatus: '',

			showMessageBox: false,

			// Same Race Multi Selected's Selections
			selectionsSameRaceMulti: [],
			lastChangedTime: null,
			timeOut: null,
			currentId: -1,
			currentSameRaceMultiPrice: null,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// Shared variables
		const selectedExotic = nextProps.betPrompt.exoticDetails ? nextProps.betPrompt.exoticDetails.id : false;

		const notQuaddieLeg = !quaddiePosition(nextProps.race);
		const notDDLeg = !dailyDoublePosition(nextProps.race);

		const notQuaddie = notQuaddieLeg && selectedExotic === RACING_BET_TYPE_QUADDIE;
		const notDailyDouble = notDDLeg && selectedExotic === RACING_BET_TYPE_DAILY_DOUBLE;

		const isQuaddie = prevState.selectedBetType === RACING_BET_TYPE_QUADDIE;
		const isDailyDouble = prevState.selectedBetType === RACING_BET_TYPE_DAILY_DOUBLE;

		const clearQuaddie = isQuaddie && notQuaddieLeg;
		const clearDailyDouble = isDailyDouble && notDDLeg;

		// If new race then clear the betType //
		if (nextProps.race.id !== prevState.raceId) {
			/* hide side bet slip if you change bet type to non quaddie/DD betType */

			if (notQuaddie) {
				nextProps.onSideBetSlipClick(false);
				nextProps.handleSelectedBetType(RACING_NON_EXOTIC_BET_TYPE, false, nextProps.selectedProduct);
			}

			if (notDailyDouble) {
				nextProps.onSideBetSlipClick(false);
				nextProps.handleSelectedBetType(RACING_NON_EXOTIC_BET_TYPE, false, nextProps.selectedProduct);
			}

			/* reset the selectedBetType to default if navigating from a dd bet and is not a dd race leg */
			const handleSelectedBetType =
				clearDailyDouble || clearQuaddie ? RACING_NON_EXOTIC_BET_TYPE : prevState.selectedBetType;
			/* clear selections if outside of the race group or changing between quaddie/dailydouble bet type */
			const handleSelections = notDailyDouble || notQuaddie ? {} : prevState.multiLegSelections;

			/* fetch exoticPools from meeting again to check exotic_pool_status has turned to "closed" */
			if (isQuaddie || isDailyDouble) {
				nextProps.updateExoticPools(nextProps.meetingId, nextProps.raceId);
			}

			/* return new state */
			return {
				selectedProduct: nextProps.winPlaceProductChoices[0] && nextProps.winPlaceProductChoices[0].betType,
				selectedBetType: handleSelectedBetType,
				multiLegSelections: handleSelections,
				boxed: false,
				raceId: nextProps.race.id,
				quaddiePoolClosed: nextProps.quaddiePoolClosed,
				dailyDoublePoolClosed: nextProps.dailyDoublePoolClosed,
			};
		}

		return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.timeOut !== prevState.timeOut) {
			clearTimeout(prevState.timeOut);
		}

		if (
			QUADDIES_BET_TYPES.includes(this.props.selectedBetType) &&
			!SelectQuaddieType(this.props.selectedBetType, this.props.race)
		) {
			this.handleSelectedBetType(RACING_NON_EXOTIC_BET_TYPE, false, this.props.selectedProduct);
		}

		if (prevProps.race.id !== this.props.race.id) {
			if (!QUADDIES_BET_TYPES.includes(this.props.selectedBetType)) {
				{
					//console.log(this.props.selectedBetType);
					this.handleSelectedBetType('winplace', false, this.props.selectedProduct);
				}
			}
			
			if (this.state.selectionsSameRaceMulti.length > 0) {
				this.setState({
					selectionsSameRaceMulti: []
				});
			}
		}
	}

	/**
	 * Get the bet prompt modal
	 *
	 * @returns {boolean|XML}
	 */
	buildBetPromptModal() {
		// Setup the modal configuration for the bet prompt
		// JASON: please help me finding a way to concentrate this logic somewhere.
		const MODAL_CONFIG = {
			mobileWidth: true,
			noBorder: true,
			hideClose: true,
			preventBackdropClose: true,

			beforeModalClose: () => {
				// if (this.props.betReferred) {
				// 	return false;
				// }
				this.setState({
					showBetPrompt: false,
				});
			},
		};

		return (
			this.state.showBetPrompt && (
				<Modal
					open={this.state.showBetPrompt}
					componentKey="event-controller__single-bet-prompt"
					store={App.store}
					config={MODAL_CONFIG}
				>
					<div>
						<ModalHeader
							onClose={MODAL_CONFIG.beforeModalClose}
							title={this.props.betPrompt.title}
							aside={<BrandContactPhone />}
						/>

						<BetPromptContainer
							eventContext={this.props.eventContext}
							handleCancel={this.handleHideBetPrompt}
							handleQuickDeposit={this.handleShowQuickDepositPrompt}
						/>

						<ModalFooter />
					</div>
				</Modal>
			)
		);
	}

	/**
	 * Get the side bet prompt
	 *
	 * @returns {boolean|XML}
	 */
	buildSideBetPrompt() {
		const { isSideBetSlipOpen, t } = this.props;

		if (isSideBetSlipOpen) {
			return ReactDOM.createPortal(
				<React.Fragment>
					<ModalHeader title={t('BetSlip')} onClose={this.handleHideSideBetPrompt} />
					<BetPromptContainer showQuickDeposit={false} handleCancel={this.handleHideSideBetPrompt} />
				</React.Fragment>,
				document.getElementById('side-betslip-portal'),
			);
		} else {
			null;
		}
	}

	/**
	 * Get the quick deposit prompt
	 */
	buildQuickDepositModal() {
		// Setup the modal configuration for the bet prompt
		const MODAL_CONFIG = {
			mobileWidth: true,
			noBorder: true,
			preventBackdropClose: true,
			hideClose: true,
		};

		return (
			this.state.showQuickDepositPrompt && (
				<Modal
					open={this.state.showQuickDepositPrompt}
					componentKey="event-controller__quick-deposit"
					store={App.store}
					config={MODAL_CONFIG}
				>
					<div>
						<ModalHeader
							onClose={this.handleHideQuickDepositPrompt}
							title={this.props.betPrompt.title}
							aside={<BrandContactPhone />}
						/>
						<DepositContainer
							isQuickDeposit
							trackingCategory="Quick Deposit"
							handleClose={this.handleHideQuickDepositPrompt}
						/>
						<ModalFooter />
					</div>
				</Modal>
			)
		);
	}

	/**
	 * Show the bet prompt
	 */
	handleShowBetPrompt = () => {
		this.setState({
			showBetPrompt: true,
		});
	};

	/**
	 * Hide the bet prompt
	 */
	handleHideBetPrompt = () => {
		this.setState({
			showBetPrompt: false,
		});
	};

	/**
	 * Hide the side bet prompt
	 */
	handleHideSideBetPrompt = () => {
		const { onSideBetSlipClick, handleClearSelectionsClick } = this.props;
		resetBetPrompt();
		onSideBetSlipClick(true);
		handleClearSelectionsClick();
		this.setState({ multiLegSelections: [] });
	};

	/**
	 * Show the bet prompt
	 */
	handleShowQuickDepositPrompt = () => {
		this.setState({
			showQuickDepositPrompt: true,
		});
	};

	/**
	 * Hide the bet prompt
	 */
	handleHideQuickDepositPrompt = () => {
		this.props.resetBetPromptTitle();
		this.setState({
			showQuickDepositPrompt: false,
		});
	};

	/**
	 * handleOnMeetingBetbuiderCilck
	 * @HW 06JUNE2020
	 */
	handleOnMeetingBetbuiderCilck = (meetingId) => {
		this.props.fetchBetBulder(meetingId);
	};

	/**
	 * Navigates to the selected bet builder meetingId
	 *
	 * @param meetingId
	 */
	handleGoToBetBulder = (meetingId) => {
		this.props.navigateToBetBuilder(meetingId);
	};

	/**
	 * Handles click on odds
	 * @param selectionId
	 * @param productId
	 * @param betType e.g. 'win', 'place''
	 */
	handleAddToSingle = (selectionId, productId, betType) => {
		const { meetingId, race, formatAndAddSingleSelectionToBetPrompt, eventContext, user } = this.props;
		formatAndAddSingleSelectionToBetPrompt(selectionId, race.id, meetingId, productId, betType).then(() => {
			triggerEventMessage(eventContext, 'selectionAddedToBetPrompt', true, user);
		});
		this.handleShowBetPrompt();
	};

	/**
	 * Handles opening the bet prompt for a derivative selection
	 *
	 * @param derivativeSelectionId
	 * @param derivativeMarketId
	 * @param derivativeMarketType
	 */
	handleAddDerivative = (derivativeSelectionId, derivativeMarketId, derivativeMarketType) => {
		this.props.formatAndAddDerivativeSelectionToBetPrompt(derivativeSelectionId, derivativeMarketId).then(() => {
			triggerEventMessage(this.props.eventContext, 'selectionAddedToBetPrompt', true, this.props.user);
		});
		this.props.trackGaDerivativeType(derivativeMarketType);

		this.handleShowBetPrompt();
	};
	/**
	 * Handles click on plus sign. Build data from de-normalized entities and dispatch action.
	 *
	 * @param selectionId
	 * @param productId
	 * @param betType
	 */
	handleAddToMulti = (selectionId, productId, betType, isCacheOutEnabled) => {
		const { meetingId, race, eventContext } = this.props;
		this.props
			.formatAndAddRacingMultiBet(
				selectionId,
				race.id,
				meetingId,
				productId,
				betType,
				null,
				this.props.race.cash_out_enabled,
			)
			.then(() => {
				triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
			});
	};
	/**
	 * Handles multi leg selections in local state.
	 * For quaddie and daily double bet types.
	 *
	 * @param {object} selectedCheckboxes
	 */
	handleMultiLegSelections = (selectedCheckboxes) => {
		const { meetingId, race } = this.props;
		const { selectedBetType, multiLegSelections } = this.state;
		const boxed = false;

		/* Format selections into correct exotic selections structure */
		const { betSelections } = formatExoticSelection(race, meetingId, selectedBetType, boxed, selectedCheckboxes);

		/* Take existing state and add the newest selections to the array */
		const raceLegSelections = [...multiLegSelections, ...betSelections];

		/* Remove duplicate selections */
		const removeDuplicates = raceLegSelections.reduce((unique, o) => {
			if (!unique.some((selection) => selection.id === o.id && selection.value === o.value)) {
				unique.push(o);
			}
			return unique;
		}, []);

		/* Append to state */
		this.setState({ multiLegSelections: removeDuplicates });

		return removeDuplicates;
	};

	isInvalidCombination = (selections) => {
		const counts = selections.reduce((acc, item) => {
			acc[item.product_id] = (acc[item.product_id] || 0) + 1;
			return acc;
		}, {});

		const wins = counts[36] || 0;
		const Top2s = counts[33] || 0;
		const Top3s = counts[34] || 0;
		const Top4s = counts[35] || 0;

		return wins > 1 || wins + Top2s > 2 || wins + Top2s + Top3s > 3 || wins + Top2s + Top3s + Top4s > 4;
	};

	handleSameRaceMultiSelections = (selectionId, productId, betType) => {
		const { selectionsSameRaceMulti, timeOut } = this.state;
		clearTimeout(timeOut);

		const currentTime = new Date();

		// Remove selection if it already exists
		const hasSelectionAndProduct = this.state.selectionsSameRaceMulti.find(
			(item) => item.id === selectionId && item.product_id === productId,
		);

		if (hasSelectionAndProduct) {
			const newSelections = selectionsSameRaceMulti.filter(
				(item) => item.id !== selectionId || item.product_id !== productId,
			);
			this.setState({
				selectionsSameRaceMulti: newSelections,
				currentId: currentTime.getTime(),
				currentSameRaceMultiPrice: null,
				timeOut: this.handleGetSameRaceMultiPrice(newSelections, currentTime.getTime()),
			});

			return;
		}

		const { race, winPlaceProducts } = this.props;
		const selections = race.selections;

		const selectedSelection = selections.find((item) => item.id === selectionId);

		const selectedProduct = winPlaceProducts.find((item) => item.product_id === productId && item.bet_type === betType);

		const selection = {
			id: selectedSelection.id,
			bet_type: selectedProduct.bet_type,
			product_code: selectedProduct.product_code,
			product_id: selectedProduct.product_id,
		};

		// Should not be able to have same selection in two products
		const hasSelection = this.state.selectionsSameRaceMulti.find((item) => item.id === selectionId);

		// If selection and product is already in the array, remove the old one and add the new one
		let newSelections;
		if (hasSelection) {
			newSelections = selectionsSameRaceMulti.filter((item) => item.id !== selectionId);
			newSelections = [...newSelections, selection];
		} else {
			newSelections = [...selectionsSameRaceMulti, selection];
		}

		if (this.isInvalidCombination(newSelections)) {
			return this.props.showError('Sorry, this combination is not allowed in Same Race Multi!');
		}
		
		this.setState({
			selectionsSameRaceMulti: newSelections,
			currentId: currentTime.getTime(),
			currentSameRaceMultiPrice: null,
			timeOut: this.handleGetSameRaceMultiPrice(newSelections, currentTime.getTime()),
		});
	};

	handleAddSameRaceMulti = (selectedSelections) => {
		const { meetingId, race, isMultiBetSlipOpen } = this.props;
		this.props.formatAndAddSameRaceMultiBet(race.id, meetingId, selectedSelections);
		this.props.onMultiBetSlipClick(false);

		this.setState({
			selectionsSameRaceMulti: [],
			currentSameRaceMultiPrice: null,
		});
	};

	handleGetSameRaceMultiPrice = async (selectedSelections, selectedTime) => {
		if (selectedSelections.length === 0) {
			return;
		}

		if (selectedSelections.length === 1) {
			this.setState({
				selectionsSameRaceMulti: [],
				currentSameRaceMultiPrice: null,
			});

			return null;
		}

		const { meetingId, race } = this.props;
		const selectionsForValidation = this.props.getSameRaceMultiSelectionsForValidation(
			race.id,
			meetingId,
			selectedSelections,
		);

		return setTimeout(async () => {
			if (selectedTime == this.state.currentId) {
				const data = await validateSameRaceMulti(selectionsForValidation);

				if (data.status != 200) {
					this.props.showError(data.message);
					this.setState({
						selectionsSameRaceMulti: [],
						currentSameRaceMultiPrice: null,
					});
					return;
				} else {
					if (selectedTime == this.state.currentId) {
						this.setState({
							currentSameRaceMultiPrice: data[0].price,
						});
					}
				}
			}
		}, 750);
	};
	/**
	 * Handles exotic bets. Expected input:
	 *  {
	 *      123: {2: true},
	 *      345: {3: true, 4: true}
	 *  }
	 *
	 * @param {object} selectedCheckboxes
	 */
	handleAddExotic = (selectedCheckboxes) => {
		const {
			meetingId,
			race,
			formatAndAddExoticSelection,
			onOpenNotification,
			eventContext,
			user,
			t,
			selectedBetType,
			boxed,
		} = this.props;

		const isMultiLegExotic =
			selectedBetType === RACING_BET_TYPE_QUADDIE || selectedBetType === RACING_BET_TYPE_DAILY_DOUBLE;
		const checkboxes = isMultiLegExotic ? this.handleMultiLegSelections(selectedCheckboxes) : selectedCheckboxes;

		try {
			if (selectedBetType === RACING_MULTIPLES_TYPE) {
				onOpenNotification(t('Please select exotics bet type'), 'danger');
			} else {
				formatAndAddExoticSelection(race.id, meetingId, selectedBetType, boxed, checkboxes, eventContext).then(() => {
					triggerEventMessage(eventContext, 'selectionAddedToBetPrompt', true, user);
				});
				// handleClearSelectionsClick();
			}
		} catch (e) {
			onOpenNotification(t(e.message), 'danger');
		}
	};

	/**
	 * Saves current selected bet type
	 * @param {string} selectedBetType
	 * @param {boolean} boxed represents which product accepts boxed state
	 * @param {string} selectedProduct
	 */
	handleSelectedBetType = (selectedBetType, boxed, selectedProduct) => {
		const { trackGaMarketType, onSideBetSlipClick, isSideBetSlipOpen, race } = this.props;

		/* close side bet slip if open when changing the bet type */
		if (isSideBetSlipOpen) {
			this.setState({ multiLegSelections: [] });
			onSideBetSlipClick(isSideBetSlipOpen);
		}

		trackGaMarketType(selectedBetType);
		this.setState({ selectedBetType, boxed, selectedProduct });

		if (QUADDIES_BET_TYPES.includes(selectedBetType) && this.props.selectedBetType != selectedBetType) {
			this.props.CleanQuaddieSelections(true);

			const selectedRaces = buildRaceLegSequence(
				this.props.races,
				race.id,
				SelectQuaddieType(selectedBetType, race),
				this.props.race.products.find((p) => p.bet_type == selectedBetType),
			);

			this.props.SetSelectedQuaddieLegs(selectedRaces);
		}

		this.props.setSelectedBetType(selectedBetType, boxed);
	};

	/**
	 * Assess race status and determine whether is available
	 * @param race
	 * @return {boolean}
	 */
	isBettingAvailable = (race) => {
		return race.status === RACE_SELLING_STATUS && !this.props.disableBetting;
	};

	handleCacheOut = (e, bet) => {
		// this.props.setLoading(true);
		this.props
			.CacheOut(bet)
			.then((response) => {
				if (response.status === 200) {
					//this.props.setLoading(false);
					//this.setState({isSucess:'success'})
					//this.props.RedirectMain();
				}
			})
			.catch((error) => {
				this.setState({ iserror: error });
				this.props.setLoading(false);
			});
	};

	handleAddToBackBook = (payload) => {
		this.props
			.RNSAddToBackBook(payload)
			.then((response) => {
				if (response.status === 200) {
					this.setState({ backBookStatus: response.data.Message, showMessageBox: true });
				}
			})
			.catch((error) => { });
	};

	/**
	 * Removes the error message
	 */
	clearErrorMessage = () => {
		this.setState({
			showMessageBox: false,
		});
	};
	/**
	 * Group by relavent field
	 * @param array
	 */
	groupBy = (data, key) => {
		return data.reduce(function (acc, item) {
			(acc[item[key]] = acc[item[key]] || []).push(item);
			return acc;
		}, {});
	};

	/**
	 * selections array with computer tips
	 * @param array selections
	 */
	getSelectionsComputerTips = (selections = []) => {
		const tipSelections = [];
		const race_rankingArray = [];

		const selection = selections.map((sel) => {
			if (sel.race_ranking !== null) {
				Object.entries(sel.race_ranking).forEach(([key, value]) => {
					return race_rankingArray.push({
						id: sel.id,
						name: sel.name,
						tab: sel.number,
						tips_type: key,
						points: Number(value),
						barrier: sel.barrier,
						silk: sel.silk,
						selection_status: sel.selection_status,
						prices: [sel.prices.find((price) => price.product_id === 16)], // product_code: "GRSF"
					});
				});
			}
		});
		if (selection) {
			tipSelections.push(race_rankingArray);
		}

		return tipSelections;
	};

	/**
	 * get all types
	 * @param array searchArray
	 */
	getTipsTypes = (searchArray) => {
		const { race } = this.props;
		const computerTipsGroup = this.groupBy(searchArray, 'tips_type');
		//  console.log(computerTipsGroup);
		const tips = Object.keys(computerTipsGroup).map((group, key) => {
			return group;
		});
		return tips;
	};

	handleAddSelectionQuaddiesBet = (selectionId, remove) => {
		const { race, selectedQuaddiesRaces } = this.props;
		// const currentLeg = selectedQuaddiesRaces.findIndex((r) => r === race.id);
		const selection = race.selections.find((item) => item.id === selectionId);
		if (selection.selection_status !== SELECTION_SCRATCHED_STATUS) {
			this.props.handleQuaddieSelections(selectionId, selection.number, race.id, remove);
		}
	};

	navigateToLeg = (race_id) => {
		this.props.navigateToLegRace(race_id, this.props.meeting_id);
	};

	placeQuaddieBet = () => {
		const { race, selectedQuaddiesRaces, quaddiesLegsSelections, selectedBetType } = this.props;

		const product = race.products.find((item) => item.bet_type === selectedBetType);

		if (!product) return;

		this.props.formatAndAddQuaddiesMultiBet(
			selectedQuaddiesRaces,
			quaddiesLegsSelections,
			selectedBetType,
			product.product_id,
		);

		this.props.onMenuClick();
	};

	render() {
		const {
			size,
			user,
			bets,
			race,
			meetingId,
			meetingName,
			meetingCountry,
			raceDistance,
			loadingRace,
			winPlaceProductChoices,
			fluctuationsKey,
			buildSelectionBetButtons,
			displayedResults,
			displayedExoticResults,
			shouldRenderFormsButton,
			className,
			clearSelections,
			handleClearSelectionsClick,
			exoticsEnabled,
			quaddiePoolClosed,
			dailyDoublePoolClosed,
			racingDerivativesEnabled,
			racingSpeedmapsEnabled,
			trackGaMarketType,
			trackGaAllForm,
			trackGaRunnerForm,
			trackGaSpeedmaps,
			trackGaDerivativeType,
			trackGaGoatType,
			winPlaceProducts,
			oddsGridPrice,
			brandName,
			trackGRSHotStats,
			trackBetBuilder,
			betBuilderMeeting,
			trackComputerTipsType,
			isMultiBetSlipOpen,
			cashout_limit,
			dailyBailOut,
			bailOutIsEnabled,
			boxed,
			selectedBetType,
			onMenuClick,
		} = this.props;

		const { selectedProduct, backBookStatus, showMessageBox } = this.state;

		const containerClasses = cx({
			[className]: className,
		});

		const titles = {
			race: titleize(race.name),
			meeting: titleize(meetingName),
		};

		const isBettingAvailable = this.isBettingAvailable(race);
		const runningSelections = getNotScratchedSelections(race.selections, false);
		const computerTipsSelections = this.getSelectionsComputerTips(race.selections);
		const finalcomputerTipsSelections = computerTipsSelections[0];
		const computerTips = this.getTipsTypes(finalcomputerTipsSelections);

		const products = race.products ? race.products : [];

		const raceProductBetTypes = products.reduce((acc, product) => {
			// Check that the min selections matches the number of runners on the race
			const productKey = product.bet_type;

			if (acc.includes(productKey)) {
				return acc;
			}
			const minimumRunningSelections =
				!RACING_EXOTIC_MINIMUM_SELECTIONS[productKey] ||
				runningSelections.length >= RACING_EXOTIC_MINIMUM_SELECTIONS[productKey];

			if (minimumRunningSelections && QUADDIES_BET_TYPES.includes(productKey)) {
				/* only include quaddie/dailydouble if current race is in exotic pool */
				const position = SelectQuaddieType(productKey, race, race.number === this.props.races.length);
				const startRace = position && this.props.races.find((r) => r.number === product.first_leg);
				if (startRace && startRace.status === RACE_SELLING_STATUS) {
					acc.push(productKey);
				}
			} else if (productKey === RACING_SAME_RACE_MULTI_TYPE) {
				if (minimumRunningSelections) acc.push(productKey);
			} else {
				acc.push(productKey);
			}

			return acc;
		}, []);

		// console.log("race",racingSpeedmapsEnabled)

		return (
			<div className={containerClasses}>
				{this.buildBetPromptModal()}
				{this.buildQuickDepositModal()}
				{this.buildSideBetPrompt()}
				<DocumentTitle>{`${titles.meeting} Race ${race.number} ${titles.race} Odds${brandName ? ' | ' + brandName : ''
					}`}</DocumentTitle>
				<DescriptionMeta>{`Bet on Race ${race.number} at ${titles.meeting} & View ${titles.race
					} odds, runners & results${brandName ? ' at ' + brandName : ''}.`}</DescriptionMeta>

				{showMessageBox ? (
					<StyledBackBook__Notification
						type={Notification.types.COLOUR_INFO}
						message={errorString(backBookStatus)}
						buttonAction={this.clearErrorMessage}
					/>
				) : (
					''
				)}
				<StyledRaceCardContainer_main size={size}>
					<ExpertTips
						runningSelections={runningSelections}
						computerTipsSelections={computerTipsSelections}
						finalcomputerTipsSelections={finalcomputerTipsSelections}
						computerTips={computerTips}
						race={race}
						isBettingAvailable={isBettingAvailable}
						handleAddToSingle={this.handleAddToSingle}
						handleAddToMulti={this.handleAddToMulti}
						buildSelectionBetButtons={(prices, displayedBetProducts, betType) =>
							buildSelectionBetButtons(prices, displayedBetProducts, betType, race.type)
						}
						trackComputerTipsType={trackComputerTipsType}
						size={size}
						user={user}
						bets={bets}
						selectedBetType={selectedBetType}
						racingSpeedmapsEnabled={racingSpeedmapsEnabled}
					/>

					<RaceResultsV2
						className={className}
						t={this.props.t}
						results={race.results}
						exoticResults={race.exotic_results}
						totes_results={race.tote_results ? race.tote_results : []}
						result_string={race.result_string}
						race={race}
					/>
					{isBettingAvailable && (
						<RaceTypeMenu
							size={size}
							handleBetTypeChange={this.handleSelectedBetType}
							activeBetType={selectedBetType}
							activeProduct={selectedProduct}
							isBoxedSelected={boxed}
							raceProductBetTypes={raceProductBetTypes}
						/>
					)}
					<NewRaceCard
						size={size}
						user={user}
						bets={bets}
						race={race}
						boxed={boxed}
						onMenuClick={onMenuClick}
						clearSelections={clearSelections}
						handleClearSelections={handleClearSelectionsClick}
						quaddiePoolClosed={quaddiePoolClosed}
						dailyDoublePoolClosed={dailyDoublePoolClosed}
						selectedBetType={selectedBetType}
						selectedProduct={selectedProduct}
						disableDerivatives={!racingDerivativesEnabled}
						disableExotics={!exoticsEnabled}
						disableSpeedmaps={!racingSpeedmapsEnabled}
						displayedResults={displayedResults}
						displayedExoticResults={displayedExoticResults}
						loadingRace={loadingRace}
						fluctuationsKey={fluctuationsKey}
						buildSelectionBetButtons={(prices, displayedBetProducts, betType) =>
							buildSelectionBetButtons(prices, displayedBetProducts, betType, race.type)
						}
						getGoatMarginButtLength={getGoatMarginButtLength}
						shouldRenderFormsButton={shouldRenderFormsButton}
						onBetTypeChange={this.handleSelectedBetType}
						onClickSingle={this.handleAddToSingle}
						onClickExotic={this.handleAddExotic}
						onClickMulti={this.handleAddToMulti}
						onDerivativeSelectionClick={this.handleAddDerivative}
						trackGaMarketType={trackGaMarketType}
						trackGaAllForm={trackGaAllForm}
						trackGaRunnerForm={trackGaRunnerForm}
						trackGaSpeedmaps={trackGaSpeedmaps}
						trackGaDerivativeType={trackGaDerivativeType}
						trackGaGoatType={trackGaGoatType}
						winPlaceProducts={winPlaceProducts}
						oddsGridPrice={oddsGridPrice}
						winPlaceProductChoices={winPlaceProductChoices}
						trackGRSHotStats={trackGRSHotStats}
						meetingCountry={meetingCountry}
						raceDistance={raceDistance}
						// bet builder
						trackBetBuilder={trackBetBuilder}
						meetingId={meetingId}
						onclickBetBuilder={this.handleOnMeetingBetbuiderCilck}
						betBuilderMeeting={betBuilderMeeting}
						onGoToBetBulder={this.handleGoToBetBulder}
						handlecacheOut={this.handleCacheOut}
						cashout_limit={cashout_limit}
						trackComputerTipsType={trackComputerTipsType}
						handleAddToBackBook={this.handleAddToBackBook}
						backBookResponse={this.state.backBookStatus}
						dailyBailOut={dailyBailOut}
						bailOutIsEnabled={bailOutIsEnabled}
						currentSameRaceMultiPrice={this.state.currentSameRaceMultiPrice}
						selectionsSameRaceMulti={this.state.selectionsSameRaceMulti}
						handleSameRaceMultiSelections={this.handleSameRaceMultiSelections}
						handleAddSameRaceMulti={this.handleAddSameRaceMulti}
						handleQuaddieSelections={this.handleAddSelectionQuaddiesBet}
						quaddiesLegsSelections={this.props.quaddiesLegsSelections}
						navigateToLeg={this.navigateToLeg}
						placeQuaddieBet={this.placeQuaddieBet}
						selectedQuaddiesRaces={this.props.selectedQuaddiesRaces}
						races={this.props.races}
						exoticLeg={SelectQuaddieType(selectedBetType, race) - 1}
						cleanQuaddies={this.props.CleanQuaddieSelections}
					/>
					{/* {renderExpertSections} */}
				</StyledRaceCardContainer_main>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	let dailyBailOut;

	const betReferred = isBetReferred(state);
	const exoticsEnabled = state.featureToggles.features.exotics.enabled;

	const exoticPools = state.grsRacingHome.exoticPools
		? state.grsRacingHome.exoticPools.find((meeting) => meeting.meetingId === props.meetingId || false) &&
		state.grsRacingHome.exoticPools.find((meeting) => meeting.meetingId === props.meetingId || false).exoticPools
		: false;

	const quaddieProduct =
		exoticPools && exoticPools.find((product) => product.bet_type_name === RACING_BET_TYPE_QUADDIE || false);
	const dailyDoubleProduct =
		exoticPools && exoticPools.find((product) => product.bet_type_name === RACING_BET_TYPE_DAILY_DOUBLE || false);

	const quaddiePoolClosed = quaddieProduct ? quaddieProduct.pool_status_code === 'Closed' : false;
	const dailyDoublePoolClosed = dailyDoubleProduct ? dailyDoubleProduct.pool_status_code === 'Closed' : false;
	const authenticatedUser = state.application.authenticatedUser;

	if (authenticatedUser) {
		dailyBailOut = state.entities.users[authenticatedUser].weekly_bet_limit;
	}

	const race = getSelectedRace(state, racingHomeRaceId);

	return {
		betReferred,
		exoticsEnabled,
		quaddiePoolClosed,
		dailyDoublePoolClosed,
		quaddiesLegsSelections: state.grsRacingHome.quaddiesLegsSelections,
		selectedBetType: state.grsRacingHome.selectedBetType,
		boxed: state.grsRacingHome.betTypeBoxed,
		bets: buildBetsForRace(state),
		betPrompt: state.betPrompt,
		isMultiBetSlipOpen: state.application.showMultiBetSlip,
		brandName: state.acl.brandDetails && state.acl.brandDetails.name,
		cashout_limit:
			state.entities.users[authenticatedUser] !== undefined
				? state.entities.users[authenticatedUser].cashout_limit
				: state.entities.users[authenticatedUser],
		dailyBailOut,
		// bailOutIsEnabled: state.featureToggles.features.bailOut ? !!state.featureToggles.features.bailout.enabled : false,
		bailOutIsEnabled: false,
		user: getAuthenticatedUser(state),
		race,
		clearSelections: state.grsRacingHome.clearSelections,
		loadingRace: state.grsRacingHome.loadingRace,
		showQuaddieRaces: state.grsRacingHome.showQuaddieRaces,
		isSideBetSlipOpen: state.application.showSideBetSlip,
		shouldRenderFormsButton: shouldRenderFormsButton(state, racingHomeRaceId),
		winPlaceProducts: buildWinPlaceProducts(state, racingHomeRaceId),
		oddsGridPrice: buildOddsGridPrice(state, racingHomeRaceId),
		winPlaceProductChoices: buildMobileWinPlaceChoices(state, racingHomeRaceId),
		fluctuationsKey: getFlucsKey(state, racingHomeRaceId),
		displayedResults: buildWinPlaceResults(state, racingHomeRaceId),
		exoticProducts: exoticsEnabled ? buildExoticProducts(state, racingHomeRaceId) : [],
		displayedExoticResults: exoticsEnabled ? buildRacingExoticResults(state, racingHomeRaceId) : [],
		racingDerivativesEnabled: state.featureToggles.features.racingDerivatives.enabled,
		racingSpeedmapsEnabled: state.featureToggles.features.enableSpeedmaps.enabled,
		betBuilderMeeting: getBetbuilderSelectedMeeting(state),
		races: props.races,
		selectedQuaddiesRaces: state.grsRacingHome.selectedQuaddiesRaces,
	};
};
const mapDispatchToProps = (dispatch) => ({
	showError: (message) =>
		dispatch(
			openNotification(message, 'danger', {
				autoClose: 1500,
			}),
		),
	onMenuClick: () => {
		return dispatch(openMultiBetSlip());
	},
	buildSelectionBetButtons: (prices, displayedBetProducts, betType, raceType) =>
		dispatch(buildSelectionBetButtons(prices, displayedBetProducts, betType, raceType)),
	formatAndAddDerivativeSelectionToBetPrompt: (derivativeId, marketId) =>
		dispatch(formatAndAddDerivativeSelectionToBetPrompt(derivativeId, marketId)),
	formatAndAddSingleSelectionToBetPrompt: (selectionId, raceId, meetingId, productId, betType) =>
		dispatch(formatAndAddSingleSelectionToBetPrompt(selectionId, raceId, meetingId, productId, betType, false)),
	formatAndAddRacingMultiBet: (selectionId, raceId, meetingId, productId, betType, amount, cash_out_enabled) =>
		dispatch(formatAndAddRacingMultiBet(selectionId, raceId, meetingId, productId, betType, amount, cash_out_enabled)),
	formatAndAddExoticSelectionToBetPrompt: (raceId, meetingId, betType, boxed, selectedCheckboxes) =>
		dispatch(formatAndAddExoticSelectionToBetPrompt(raceId, meetingId, betType, boxed, selectedCheckboxes)),
	formatAndAddExoticSelection: (raceId, meetingId, betType, boxed, selectedCheckboxes) =>
		dispatch(formatAndAddExoticSelectionBetslip(raceId, meetingId, betType, boxed, selectedCheckboxes)),
	formatAndAddSameRaceMultiBet: (race_id, meeting_id, selectedSelections) =>
		dispatch(formatAndAddSameRaceMultiBet(race_id, meeting_id, selectedSelections)),
	formatAndAddQuaddiesMultiBet: (races_ids, selectedSelections, bet_type, product_id) =>
		dispatch(formatAndAddQuaddiesMultiBet(races_ids, selectedSelections, bet_type, product_id)),
	getSameRaceMultiSelectionsForValidation: (race_id, meeting_id, selectedSelections) =>
		dispatch(getSameRaceMultiSelectionsForValidation(race_id, meeting_id, selectedSelections)),

	resetBetPrompt: () => dispatch(resetBetPrompt()),
	trackGaSpeedmaps: (event) => dispatch(trackGaEvent(TRACKING_CATEGORY_RACE_SPEEDMAP_SELECTOR, 'Select', event)),
	trackGRSHotStats: (event) => dispatch(trackGaEvent(TRACKING_CATEGORY_RACE_HOT_STATS_SELECTOR, 'Select', event)), // hot stats

	trackBetBuilder: (event) => {
		dispatch(trackGaEvent(TRACKING_CATEGORY_RACE_BET_BUILDER_SELECTOR, 'Select', event));
	}, // bet builder

	trackGaDerivativeType: (type) => dispatch(trackGaEvent(TRACKING_CATEGORY_RACE_DERIVATIVE_SELECTOR, 'Select', type)),
	trackGaGoatType: (goatProduct) =>
		dispatch(trackGaEvent(TRACKING_CATEGORY_RACE_CARD_PRODUCT_SELECTOR, 'Select', goatProduct)),
	trackGaMarketType: (type) => dispatch(trackGaEvent(TRACKING_CATEGORY_RACE_MARKET_SELECTOR, 'Click', type)),
	trackGaAllForm: () => dispatch(trackGaEvent(TRACKING_CATEGORY_RACE_ALL_FORM, 'View', 'All Form')),
	trackGaRunnerForm: () => dispatch(trackGaEvent(TRACKING_CATEGORY_RACE_RUNNER_FORM, 'View', 'Runner Form')),
	resetBetPromptTitle: (title) => dispatch(resetBetPromptTitle()),
	onOpenNotification: (message) => dispatch(openNotification(message, 'danger')),

	onMultiBetSlipClick: (isMultiBetSlipOpen) => {
		return dispatch(toggleMultiBetSlip(isMultiBetSlipOpen));
	},
	onSideBetSlipClick: (isSideBetSlipOpen) => {
		return dispatch(toggleSideBetSlip(isSideBetSlipOpen));
	},
	handleClearSelectionsClick: (clearSelections) => dispatch(handleClearSelectionsClick(clearSelections)),
	updateExoticPools: (meetingId, raceId) =>
		dispatch(fetchMeetingsAndRacesWithSelectionsForRace(meetingId, raceId)).then((response) => {
			const payload = response.exoticPools;
			dispatch(setExoticPools(payload));
		}),
	// add bet builder
	fetchBetBulder: (meetingId) => {
		dispatch(trackGaEvent(TRACKING_CATEGORY_RACE_BET_BUILDER_SELECTOR, 'Select', 'BetBuilder'));
		dispatch(fetchBetBulderMeetingWithRaceSelections(meetingId)).then((response) => {
			const payload = response.data.data.id;
			dispatch(setSelectedBuilderMeeting(payload));
		});
	},

	// navigate to bet builder
	navigateToBetBuilder: (meetingId) => {
		dispatch(routeTo('/betbuilder/' + serverDateTime(new Date()).format('YYYY-MM-DD') + '/' + meetingId));
		App.startSubApp('BetBuilder');
	},

	trackComputerTipsType: (type) =>
		dispatch(trackGaEvent(TRACKING_CATEGORY_RACE_COMPUTER_TIPS_SELECTOR, 'Select', type)),

	CacheOut: (data) => dispatch(CacheOut(data)),

	RNSAddToBackBook: (payload) => dispatch(RNSAddToBackBook(payload)),
	setSelectedBetType: (betType, boxed) => dispatch(setSelectedBetType(betType, boxed)),
	handleQuaddieSelections: (selection_id, selection_number, currentLeg, remove) =>
		dispatch(handleQuaddieSelections(selection_id, selection_number, currentLeg, remove)),
	CleanQuaddieSelections: (reset_quaddies_races) => dispatch(CleanQuaddieSelections(reset_quaddies_races)),
	navigateToLegRace: (raceId, meetingId) => dispatch(navigateToLegRace(raceId, meetingId)),
	SetSelectedQuaddieLegs: (legs) => dispatch(SetSelectedQuaddieLegs(legs)),
});

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(NewRaceCardContainer));
