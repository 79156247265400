import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { typography, spacings, Button, ButtonGroup } from '@tbh/ui-kit';
import { TODAY_RACES_COLOR } from '../../../../../constants/themes';

import CurrencyDisplay from '../CurrencyDisplay/CurrencyDisplay';

const StyledCurrencyButton = styled(Button)`
	${(props) =>
		css`
			label: CurrencyButton;
			border-radius: 4px;
			flex: 1;
			margin: ${spacings(props).tight}px;
			font-size: ${typography(props)['size_-3']};

			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}	
			color: ${props.type === 'primary' ? TODAY_RACES_COLOR.currency_active_font_color:TODAY_RACES_COLOR.currency_inactive_font_color};
			background: ${props.type === 'primary' ? TODAY_RACES_COLOR.currency_active_bg_color:TODAY_RACES_COLOR.currency_inactive_bg_color};
			border: 1px solid ${props.type === 'primary' ? TODAY_RACES_COLOR.currency_active_border_color:TODAY_RACES_COLOR.currency_inactive_border_color};;
			border-bottom: 2px solid ${props.type === 'primary' ? TODAY_RACES_COLOR.currency_active_border_color:TODAY_RACES_COLOR.currency_inactive_border_color};;

			&:hover {
				color: ${props.type === 'primary' ? TODAY_RACES_COLOR.currency_active_font_color:TODAY_RACES_COLOR.currency_inactive_hover_font_color};;
				background: ${props.type === 'primary' ?TODAY_RACES_COLOR.currency_active_bg_color:TODAY_RACES_COLOR.currency_inactive_hover_bg_color};;
				border: 1px solid ${props.type === 'primary' ? TODAY_RACES_COLOR.currency_active_bg_color:TODAY_RACES_COLOR.currency_inactive_bg_color};;
				border-bottom: 2px solid ${props.type === 'primary' ? TODAY_RACES_COLOR.currency_active_border_color:TODAY_RACES_COLOR.currency_inactive_bg_color};;
			}	
		`};
`;

const CurrencyEntryKeypad = (props) => {
	const { activeButton, currencyType, className, buttonSize, buttonValues, buttonAction, customButton } = props;

	const componentClasses = cx({
		[className]: className,
	});

	/**
	 * Generate the button rows for display
	 *
	 * @param buttonRowValues
	 */
	const generateButtonRow = (buttonRowValues) => {
		return buttonRowValues.map((button) => {
			const buttonValue = typeof button === 'object' ? button.amount : parseInt(button);
			const buttonLabel =
				typeof button === 'object'
					? button.label
						? button.label
						: `${parseInt(button.amount) / 100}`
					: `${parseInt(button) / 100}`;
			return (
				<StyledCurrencyButton
					square
					key={`${buttonValue}_${buttonLabel}`}
					size={buttonSize}
					action={buttonAction}
					data={buttonValue}
					type={buttonValue === activeButton ? 'primary' : null}
				>
					<CurrencyDisplay imageWidth={12} amount={buttonLabel} applyConversion={false} type={currencyType} />
				</StyledCurrencyButton>
			);
		});
	};

	/**
	 * Works out how many buttons to display per row.
	 * Basically divides total buttons supplied in half and rounds up.
	 * - so it will either display the same number of buttons per row if even amount  of buttons supplied
	 * - OR it will either display one extra button on the top row if odd amount of buttons supplied
	 */
	let buttonsPerRow = Math.ceil(buttonValues.length / 2);
	const topRowValues = buttonValues.slice(0, buttonsPerRow);
	const bottomRowValues = buttonValues.slice(buttonsPerRow);

	return (
		<div className={componentClasses}>
			<ButtonGroup flex noWrap>
				{generateButtonRow(topRowValues)}
			</ButtonGroup>

			<ButtonGroup flex noWrap>
				{generateButtonRow(bottomRowValues)}

				{customButton &&
					Object.keys(customButton).length > 0 && (
						<StyledCurrencyButton
							key="customButton"
							square
							size={buttonSize}
							type={customButton.type ? customButton.type : 'primary'}
							action={customButton.action}
							className={css`&:hover { border-color: #cacaca!important; }`} // TODO: This is a temporary fix to make the button look like the others
						>
							{customButton.label ? customButton.label : ''}
						</StyledCurrencyButton>
					)}
			</ButtonGroup>
		</div>
	);
};

CurrencyEntryKeypad.propTypes = {
	/** Function to use when the buttons are clicked */
	buttonAction: PropTypes.func.isRequired,

	/** The value of the button to mark as active */
	activeButton: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

	/** An object or array of button values (in cents) that will be displayed on the keypad */
	buttonValues: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.shape({
				amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
				label: PropTypes.string,
			}),
		),
		PropTypes.arrayOf(PropTypes.number),
	]),

	/** The size of the buttons */
	buttonSize: PropTypes.string,

	/**
	 * An object containing the properties for the custom button.
	 * The object item contains it's action, type & label
	 */
	customButton: PropTypes.shape({
		action: PropTypes.func,
		type: PropTypes.string,
		label: PropTypes.string,
	}),

	/** Extra style class(es) to pass through */
	className: PropTypes.string,

	/** Currency type being displayed - this should match the currency configuration names */
	currencyType: PropTypes.string,
};

CurrencyEntryKeypad.defaultProps = {
	activeButton: null,
	buttonValues: [100, 200, 500, 1000, 2500, 5000, 10000, 25000, 50000],
	buttonSize: 'medium',
	customButton: {},
	className: null,
	currencyType: 'currency',
};

export default CurrencyEntryKeypad;
