import { PROMOTIONS_BASE_URL, PROMOTIONS_SUB_APP } from '../pages/Promotions/PromotionsPageConstants';
import { scrollToTop,setBodyLoading, show404 } from '../store/application/applicationActions';
import { fetchNextToJumpRaces_GRS } from '../store/entities/actions/NextToJumpRaceActions';
const { dispatch } = App.store;

const PromotionsV2Router = Backbone.Router.extend({
	/* standard routes can be mixed with appRoutes/Controllers above */
	routes: {
		[PROMOTIONS_BASE_URL]: 'showPromotions',
	},


	showPromotions: async function() {
		
		dispatch(setBodyLoading(true));
		App.startSubApp(PROMOTIONS_SUB_APP);
		try {
			scrollToTop();

			await Promise.all([dispatch(fetchNextToJumpRaces_GRS())]);
		} catch (e) {
			dispatch(show404());
		}
	},
});

export default new PromotionsV2Router();
