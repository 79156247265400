// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import { FaChevronDown } from 'react-icons/fa';
import styled, { css } from 'react-emotion';
import { LoadingMask, media, spacings, Text } from '@tbh/ui-kit';
import moment from 'moment';
import { SizeMe } from 'react-sizeme';
import { withNamespaces } from 'react-i18next';
import { COLORS, TYPE_SCALE, WEIGHTS, MEDIA_QUERIES } from '../../constants/themes';
//import VerificationStatus from '../../components/features/UserAccount/Profile/VerificationStatus/VerificationStatus'
// Actions
import {
	resetUserAccount,
	setSelectedMenuItemV2,
	setTransactionFilter,
} from '../../store/userAccount/userAccountActions';
import { openNotification, showDeposits } from '../../store/application/applicationActions';
import { getUserAccountV2MenuItems } from '../../store/userAccount/userAccountSelectors';
import { routeTo } from '../../store/application/applicationActions';
import { launchLiveChat } from '../../store/application/applicationActions';
import { trackGaEvent } from '../../store/trackingPixels/trackingActions';

// Components
import Route from '../../components/controllers/Route/Route';
import { createErrorBoundary } from '../../containers/ErrorBoundary/ErrorBoundaryActions';
import EventContext from '../../contexts/eventContext';
import UserAccountV2MenuContainer from '../../containers/UserAccount/UserAccountMenuContainer/UserAccountV2MenuContainer';
import Profile from '../../components/features/UserAccount/Profile/Profile';
import DepositsPromptContainer from '../../containers/Deposit/DepositsPromptContainer';
// import UserTransactionsContainer from '../../containers/UserAccount/UserTransactions/UserTransactionsContainer';
import WithdrawalPromptContainer from '../../containers/WithdrawalPrompt/WithdrawalPromptContainer';
import PendingWithdrawalsContainer from '../../containers/UserAccount/PendingWithdrawals/PendingWithdrawalsContainer';
import UpdatePasswordContainer from '../../containers/UserAccount/UpdatePassword/UpdatePasswordContainer';
// import DefaultBetAmountsContainer from '../../containers/ResponsibleGambling/DefaultBetAmountsContainer/DefaultBetAmountsContainer';
import RNSContainer from '../../containers/UserAccount/R&S/RNSContainer';
import FileUploadGreenidContainer from '../../containers/UserAccount/UploadGreenIdDocument/FileUploadGreenidContainer';

// import TakeABreakContainer from '../../containers/ResponsibleGambling/TakeABreakContainer/TakeABreakContainer';
// import SelfExclusionContainer from '../../containers/ResponsibleGambling/SelfExclusionContainer/SelfExclusionContainer';
// import DepositLimitsContainer from '../../containers/ResponsibleGambling/DepositLimitsContainer/DepositLimitsContainer';
// import LossLimitsContainer from '../../containers/ResponsibleGambling/LossLimitsContainer/LossLimitsContainer';
// import SpendLimitsContainer from '../../containers/ResponsibleGambling/SpendLimitsContainer/SpendLimitsContainer';
// import RealityCheckContainer from '../../containers/ResponsibleGambling/RealityCheck/RealityCheckContainer';
import BrandContactPhone from '../../components/ui-components/BrandContactPhone/BrandContactPhone';
import DocumentTitle from '../../components/controllers/Meta/DocumentTitle';
import Alerts from '../../components/UI/Alerts/Alerts';
import { HeaderBar } from '@tbh/ui-kit';
import { FONT_SIZE_15 } from '../../store/application/applicationConstants';

import { USER_ACCOUNT_V2_BASE_URL } from '../../pages/UserAccountV2/UserAccountPageConstants';

// Constants
import { SERVER_DATE_FORMAT } from '../../common/constants/DateFormats.js';
import { get } from '../../common/Ajax';
import {
	USER_ACCOUNT_ALL_TRANSACTIONS_URL,
	USER_ACCOUNT_OVERVIEW_URL,
	USER_ACCOUNT_DEPOSIT_URL,
	USER_ACCOUNT_SETTINGS_URL,
	USER_ACCOUNT_WITHDRAW_URL,
	USER_ACCOUNT_TRANSACTIONS_URL,
	USER_ACCOUNT_VERIFICATION,
	USER_ACCOUNT_RESPONSIBLE_GAMBLING_URL,
	USER_ACCOUNT_RNS_ACCOUNT_URL,
	USER_ACCOUNT_BONUS_BET_TRANSACTION_URL,
} from './UserAccountPageConstants';
import { DEPOSIT_USER_ACCOUNT } from '../../store/deposits/depositsReducerNames';
import DescriptionMeta from '../../components/controllers/Meta/DescriptionMeta';
import USER_ACCOUNT_PAGES from './UserAccountV2Page.data';
import { OverviewContainer } from '../../containers/UserAccountV2/ContentUserAccount/Overview/OverviewContainer';
//import { MenuUsefulLinks } from '../../containers/UserAccountV2/ContentUserAccount/UseFullLinks';
import SettingsContainer from '../../containers/UserAccountV2/ContentUserAccount/Settings/SettingsContainer';
import UserTransactionsContainer from '../../containers/UserAccountV2/ContentUserAccount/Transactions/TransactionsContainer';
import UserBonusBetTransactions from '../../containers/UserAccountV2/ContentUserAccount/BonusBetTransactions/BonusBetTransactionsContainer';
import RealityCheckContainer from '../../containers/UserAccountV2/ContentUserAccount/ResponsibleGambling/RealityCheck/RealityCheckContainer';
import LossLimitsContainer from '../../containers/UserAccountV2/ContentUserAccount/ResponsibleGambling/LossLimits/LossLimitsContainer';
import TakeABreakContainer from '../../containers/UserAccountV2/ContentUserAccount/ResponsibleGambling/TakeABreak/TakeABreakContainer';
import SelfExclusionContainer from '../../containers/UserAccountV2/ContentUserAccount/ResponsibleGambling/SelfExclusion/SelfExclusionContainer';
import DepositLimitsContainer from '../../containers/UserAccountV2/ContentUserAccount/ResponsibleGambling/DepositLimit/DepositLimitContainer';
import { Modal } from '../../components/UI/Modal';
import VerificationFormV2 from '../../containers/UserAccountV2/ContentUserAccount/Verifications/VerificationFormV2';
import { VerifyProgress } from '../../components/features/Application/VerifyMenuProcess/VerifyProgress';
import { verifyEmail } from '../../store/userProfile/userProfileActions';
import { MOBILE_MAX_WIDTH } from '../../common/constants/Breakpoints.js';
// import { USER_ACCOUNT_SUBMENU_DEPOSIT_LIMIT, USER_ACCOUNT_SUBMENU_LOSS_LIMITS, USER_ACCOUNT_SUBMENU_REALITY_CHECK, USER_ACCOUNT_SUBMENU_SELF_EXCLUSION, USER_ACCOUNT_SUBMENU_SPEND_LIMIT } from '../UserAccount/UserAccountPageConstants';
// import DepositContainer from '../../containers/UserAccountV2/ContentUserAccount/Deposit/DepositContainer';
const MENU_ITEMS_WIDTH = 210;

const StyledUserAccountPage = styled('div')(
	(props) =>
		css`
			label: UserAccountPage;
			display: flex;
			width: 100%;
			display: flex;
			flex-direction: column;
			height: 100%;
			// min-height: 100vh;
			width: 100%;
			padding: ${spacings(props).cozy}px;
			background-color: white;
			border-radius: 10px;
		`,
	(props) =>
		media(
			css`
				padding: ${spacings(props).comfortable}px;
			`,
			MOBILE_MAX_WIDTH,
		),
);

const StyledUserAccountPage__Header = styled(HeaderBar)(
	css`
		label: UserAccountPage__Header;

		display: none;
		font-size: ${FONT_SIZE_15};
	`,
);

const StyledUserAccountPage__MenuItems = styled(UserAccountV2MenuContainer)(
	css`
		label: UserAccountPage__MenuItems;
		min-width: ${MENU_ITEMS_WIDTH}px;
		height: fit-content;
		&:active {
			color: #000000;
		}

		margin-bottom: 10px;
		background: white;
		border-radius: 8px;
	`,
);

const StyledUserAccountPage__Wrapper = styled('div')(
	(props) => css`
		label: UserAccountPage__Wrapper;
		background: white;
		border-radius: 8px;
    	// padding-left:5%;
		flex: 1 1 auto;
		max-width: 100%;
		margin-bottom:10px;
        display: flex;
        padding: 0;
        flex-direction: column;
        // gap: ${spacings(props).comfortable}px;
	`,
);

const StyledHeaderText = css`
	font-size: 15px;
	color: #ffffff;
`;

const StyledPageTitle = styled.h1`
	font-size: 24px;
	font-weight: bold;
	flex: 1 0 20%;
`;

const StyledTopWizardWrapper = styled('div')`
	border: 1px solid #dfdfdf;
	border-radius: 4px;
	padding: 16px;
	width: 100%;
	color: ${COLORS.gray[600]};
`;

const StyledUserFullLinksWrapper = styled('div')`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 16px;
	border-bottom: 1px solid #dfdfdf;
`;

const DropdownContentLink = styled('a')`
	color: ${COLORS.gray[500]};
	font-weight: ${WEIGHTS.bold};
	font-size: ${TYPE_SCALE.paragraph};
	padding: 12px 16px;
	display: block;
	text-decoration: none;

	&:hover {
		background-color: #dddddd;
	}
`;

const DropdownButtonType = styled('button')`
	background-color: ${COLORS.red[400]};
	color: ${COLORS.white};
	padding: 12px;
	font-size: ${TYPE_SCALE.header6};
	border: 1px solid ${COLORS.red[400]};
	border-radius: 0;
	min-width: 200px;
	text-transform: uppercase;
	cursor: pointer;

	&:hover ${DropdownContainer} {
		background-color: ${COLORS.red[600]};
		color: ${COLORS.white};
		transition: max-height 0.25s ease-in;
		border: 1px solid ${COLORS.red[600]};
	}
`;

const DropdownContainer_Content = styled('div')`
	position: absolute;
	background-color: ${COLORS.gray[100]};
	z-index: 98;
	max-height: 0;
	min-width: 200px;
	transition: max-height 0.15s ease-out;
	overflow: hidden;
`;

const DropdownContainer = styled('div')`
	position: relative;
	display: inline-block;

	&:hover ${DropdownContainer_Content} {
		max-height: 500px;
		max-width: 200px;
		transition: max-height 0.25s ease-in;
	}
`;

const NotificationBlockWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	width: 100%;

	@media ${MEDIA_QUERIES.tabletAndUp} {
		flex-direction: row;
	}
`;

const StyledContentAccount = styled('div')(
	(props) => css`
		label: Styled_Content_Account;
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		gap: 20px;
		// font-family: 'Roboto', sans-serif !important;
	`,
	media(
		(props) => css`
			flex-direction: column;
		`,
		660,
	),
);
const StyledUserAccountPage__Profile = StyledUserAccountPage__Wrapper.withComponent(Profile);
const StyledUserAccountPage__UpdatePassword = StyledUserAccountPage__Wrapper.withComponent(UpdatePasswordContainer);
const StyledUserAccountPage__File_Upload_Green_Id = StyledUserAccountPage__Wrapper.withComponent(
	FileUploadGreenidContainer,
);
const StyledUserAccountPage__Deposits = StyledUserAccountPage__Wrapper.withComponent(DepositsPromptContainer);
const StyledUserAccountPage__Transactions = StyledUserAccountPage__Wrapper.withComponent(UserTransactionsContainer);
const StyledUserAccountPage__BonusBet__Transactions = StyledUserAccountPage__Wrapper.withComponent(
	UserBonusBetTransactions,
);
const StyledUserAccountPage__Withdrawals = StyledUserAccountPage__Wrapper.withComponent(WithdrawalPromptContainer);
const StyledUserAccountPage__rnsAccount = StyledUserAccountPage__Wrapper.withComponent(RNSContainer);
const StyledUserAccountPage__CancelWithdrawals = StyledUserAccountPage__Wrapper.withComponent(
	PendingWithdrawalsContainer,
);

class UserAccountPage extends React.Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** If there is an authenticated user or not */
		authenticatedUser: PropTypes.bool.isRequired,

		/** Closes the user account page */
		closeUserAccountPage: PropTypes.func.isRequired,

		/** Clears the state for the user account */
		resetUserAccount: PropTypes.func.isRequired,

		/** Menu items */
		menuItems: PropTypes.array.isRequired,

		/** Set the currently selected menu item */
		setSelectedMenuItem: PropTypes.func.isRequired,

		/** Function to mark that the container has stopped loading - NOTE: automatically passed in by a wrapper in web context */
		setContainerHasLoaded: PropTypes.func,

		/** Show routing and meta components from the page */
		useRouting: PropTypes.bool,

		/** Show the headers around containers */
		showHeaders: PropTypes.bool,

		/** Used for routing and tracking */
		selectedMenuItem: PropTypes.shape({
			action: PropTypes.func,
			defaultUrlFragments: PropTypes.string,
			id: PropTypes.string,
			route: PropTypes.string,
			trackingTitle: PropTypes.string,
		}),

		/**
		 * Account Profile
		 */
		/** Opens the intercom so the user can complete the verification */
		onCompleteVerification: PropTypes.func.isRequired,

		/** The current user's email */
		email: PropTypes.string,

		/** The current user's first name */
		first_name: PropTypes.string,

		/** The current user's mobile number */
		mobile: PropTypes.string,

		/** The current user's username */
		username: PropTypes.string,

		/** The current user's address */
		address: PropTypes.string,

		/** The current user's country */
		country: PropTypes.string,

		/** The current user's dob */
		dob: PropTypes.string,

		/** The current user's last name */
		last_name: PropTypes.string,

		/** The current user's postcode */
		postcode: PropTypes.string,

		/** The current user's state */
		state: PropTypes.string,

		/** The current user's suburb */
		suburb: PropTypes.string,

		/** If it's a verified user or not */
		userIsVerified: PropTypes.bool,

		user_green_id: PropTypes.string,

		/** Brand name */
		brandName: PropTypes.string,

		/**
		 * Transactions
		 */
		/** Saves the transaction filter into state */
		setTransactionFilter: PropTypes.func.isRequired,

		/** Current transaction filter */
		filter: PropTypes.string,

		send_email: PropTypes.string.isRequired,

		/**
		 * Deposits
		 */
		/** If the receipt is being displayed or not **/
		showDepositReceipt: PropTypes.bool,

		green_id_overall_status: PropTypes.string,

		green_id_status: PropTypes.string,
	};

	static defaultProps = {
		useRouting: true,
		showHeaders: true,
		setContainerHasLoaded: null,
		selectedMenuItem: {},

		// Account Profile
		address: '',
		country: '',
		dob: '',
		email: '',
		first_name: '',
		mobile: '',
		last_name: '',
		postcode: '',
		state: '',
		suburb: '',
		username: '',
		userIsVerified: false,
		brandName: '',
		user_green_id: '',
		// Transactions
		filter: USER_ACCOUNT_ALL_TRANSACTIONS_URL,

		// Deposits
		showDepositReceipt: false,
		green_id_overall_status: null,
		green_id_status: null,
	};

	constructor(props) {
		super(props);

		// Sets the route to display the right content
		const { selectedMenuItem } = props;
		let page, route;
		if (selectedMenuItem && selectedMenuItem.id) {
			page = selectedMenuItem.id;
			route = selectedMenuItem.defaultUrlFragments
				? selectedMenuItem.route + '/' + props.filter
				: selectedMenuItem.route;
		} else {
			page = USER_ACCOUNT_OVERVIEW_URL;
			route = '/user-v2';
		}

		this.state = {
			loading: false,
			page: page,
			route: route,
			greendIDInitialised: false,
			green_id_user: null,
			request: null,
		};

	}

	/**
	 * Checks the selected Menu Item to display the correct content
	 *
	 * @param nextProps
	 * @param prevState
	 */
	static getDerivedStateFromProps(nextProps, prevState) {
		const selectedMenuItem = nextProps.selectedMenuItem;

		if (!nextProps.authenticatedUser) {
			nextProps.closeUserAccountPage();
			return null;
		}

		// if the selectedMenuItem is a page and not a modal
		if (USER_ACCOUNT_PAGES.indexOf(selectedMenuItem.id) !== -1) {
			// if it's a different page, set the page to be displayed and route and reset the filter
			if (selectedMenuItem.id !== prevState.page) {
				return {
					page: selectedMenuItem.id,
					route: selectedMenuItem.defaultUrlFragments
						? selectedMenuItem.route + '/' + selectedMenuItem.defaultUrlFragments
						: selectedMenuItem.route,
				};
			} else {
				// if it's the same page
				return {
					route: selectedMenuItem.defaultUrlFragments
						? selectedMenuItem.route + '/' + nextProps.filter
						: selectedMenuItem.route,
				};
			}
		}

		return null;
	}



	/**
	 * If the user is not authenticated, close the user account page
	 */
	componentDidMount() {
		const {
			authenticatedUser,
			closeUserAccountPage,
			setContainerHasLoaded,
			menuItems,
			selectedMenuItem,
			setSelectedMenuItem,
		} = this.props;

		// If the user is not authenticated, then closes the user account page
		if (!authenticatedUser) {
			return closeUserAccountPage();
		}


		// If the selected menu item is not valid, then set it to the first item
		// const activeItem = menuItems.some((item) => item.id === selectedMenuItem.id) ? selectedMenuItem : menuItems[0];
		// if (selectedMenuItem.id !== activeItem.id) {
		//     setSelectedMenuItem(activeItem);
		// }

		if (setContainerHasLoaded) {
			setContainerHasLoaded();
		}
	}

	componentDidUpdate() {
		// If selectedItem changes, change the page and route
		if (this.props.selectedMenuItem.id !== this.state.page) {
			this.setState({
				page: this.props.selectedMenuItem.id,
				route: this.props.selectedMenuItem.defaultUrlFragments
					? this.props.selectedMenuItem.route + '/' + this.props.filter
					: this.props.selectedMenuItem.route,
			});
		}
	}

	/**
	 * Clears the state when the User Account page is closed
	 */
	componentWillUnmount() {
		this.props.resetUserAccount();
	}

	/**
	 * Set a loading mask
	 */
	setLoadingMask = (loading = false) => {
		this.setState({
			loading: loading,
		});
	};

	/**
	 * Set the transactions filter to update the route and active tab/selection on Account Transactions
	 *
	 * @param filter
	 */
	setTransactionFilter = (filter) => {
		this.props.setTransactionFilter(filter);
	};

	openVerificationModal = () =>
		this.setState({
			greendIDInitialised: true,
		});

	closeVerificationModal = () => {
		this.setState({ greendIDInitialised: false });
		// document.greenId.abandon();
	};

	renderContainer = (containerSize) => {
		const {
			t,
			useRouting,
			showHeaders,
			authenticatedUser,
			send_email,
			verifiedName,
			verifiedEmail,
			verifiedPhoneNumber,
			// Account Profile
			filter,
			brandName,
			user,
			menuItems,
			selectedMenuItem,
			setSelectedMenuItem,
			suburb,
			country,
			dob,
			email,
			mobile,
			first_name,
			last_name,
			postcode,
			state,
			address,
			username,
			userIsVerified,
			onCompleteVerification,
			// Deposits
			showDepositReceipt,
			closeUserAccountPage,
		} = this.props;
		const verificationItem = menuItems.find((item) => item.id === USER_ACCOUNT_VERIFICATION);

		const { name, msisdn, green_id_status } = user;

		const accountIsVerified = green_id_status === 'VERIFIED' && !!send_email && !!msisdn && !!name;

		return (
			<div>
				<StyledUserAccountPage__MenuItems
					size={containerSize}
					showHeader={showHeaders}
					menuItems={this.props.menuItems}
					isMobile={false}	
					isBonusBets={this.props.isBonusBets}				
				/>
				<StyledContentAccount>
					{this.state.page === USER_ACCOUNT_OVERVIEW_URL && !accountIsVerified && (
						<VerifyProgress
							phone={verifiedPhoneNumber}
							email={verifiedEmail}
							green_id_status={this.props.green_id_status}
						/>
					)}

					<SizeMe noPlaceholder refreshMode="debounce">
						{({ size }) => {
							switch (this.state.page) {
								case USER_ACCOUNT_OVERVIEW_URL:
									return (
										<StyledUserAccountPage__Wrapper size={size}>
											<OverviewContainer
												openDeposits={this.props.openDeposits}
												goToWithdrawal={this.props.goToWithdrawal}
												goToResponsibleGambling={this.props.goToResponsibleGambling}
												email={verifiedEmail}
												user={this.props.user}
												verificationItem={verificationItem}
												onClickChangeToVerificationPage={setSelectedMenuItem}
												verifyEmail={this.props.verifyEmail}
											/>
										</StyledUserAccountPage__Wrapper>
									);

								case USER_ACCOUNT_SETTINGS_URL:
									return (
										<StyledUserAccountPage__Wrapper size={size}>
											<SettingsContainer />
										</StyledUserAccountPage__Wrapper>
									);
								case USER_ACCOUNT_DEPOSIT_URL:
									return (
										<StyledUserAccountPage__Wrapper size={size}>
											{showHeaders && (
												<StyledUserAccountPage__Header
													size={containerSize}
													type={HeaderBar.types.PRIMARY}
													aside={<BrandContactPhone />}
												>
													<Text className={StyledHeaderText}>
														{' '}
														{showDepositReceipt
															? t('UserAccountPage__DepositReceipt')
															: t('UserAccountPage__MakeDeposit')}{' '}
													</Text>
												</StyledUserAccountPage__Header>
											)}
											{/* <DepositContainer
                        user={this.props.user}
                        makeDepositWithNewCard={() => {}}
                        verifyCreditCard={() => {}}
                    /> */}
											<StyledUserAccountPage__Deposits size={size} renderOnPage />
										</StyledUserAccountPage__Wrapper>
									);

								case USER_ACCOUNT_TRANSACTIONS_URL:
									return (
										<EventContext.Consumer>
											{(eventContext) => (
												<StyledUserAccountPage__Transactions
													size={size}
													currentRequest={this.state.request}
													setRequest={(request) => this.setState({ request })}
													parentSize={containerSize}
													transactionType={this.state.page}
													setTransactionFilter={this.setTransactionFilter}
													filter={filter}
													eventContext={eventContext}
													showHeader={showHeaders}
												/>
											)}
										</EventContext.Consumer>
									);

								case USER_ACCOUNT_BONUS_BET_TRANSACTION_URL:
									return (
										<React.Fragment>
											{this.props.isBonusBets && (
												<EventContext.Consumer>
													{(eventContext) => (
														<StyledUserAccountPage__BonusBet__Transactions
															size={size}
															currentRequest={this.state.request}
															setRequest={(request) => this.setState({ request })}
															parentSize={containerSize}
															transactionType={this.state.page}
															setTransactionFilter={this.setTransactionFilter}
															filter={filter}
															eventContext={eventContext}
															showHeader={showHeaders}
														/>
													)}
												</EventContext.Consumer>
											)}
										</React.Fragment>
									);

								case USER_ACCOUNT_WITHDRAW_URL:
									return (
										<StyledUserAccountPage__Wrapper size={size}>
											{showHeaders && (
												<StyledUserAccountPage__Header size={containerSize} type={HeaderBar.types.PRIMARY}>
													<Text className={StyledHeaderText}>{t('UserAccountPage__CancelWithdrawal')}</Text>
												</StyledUserAccountPage__Header>
											)}

											<StyledUserAccountPage__Withdrawals size={size} accountIsVerified={accountIsVerified} />
										</StyledUserAccountPage__Wrapper>
									);

								case USER_ACCOUNT_RESPONSIBLE_GAMBLING_URL:
									return (
										<StyledUserAccountPage__Wrapper size={size}>
											{showHeaders && (
												<StyledUserAccountPage__Header size={containerSize} type={HeaderBar.types.PRIMARY}>
													<Text className={StyledHeaderText}>{t('UserAccountPage__SetDepositLimits')}</Text>
												</StyledUserAccountPage__Header>
											)}

											<DepositLimitsContainer />

											<LossLimitsContainer />

											{/* <SpendLimitsContainer /> */}

											<TakeABreakContainer />

											<RealityCheckContainer />

											<SelfExclusionContainer />
										</StyledUserAccountPage__Wrapper>
									);

								case USER_ACCOUNT_RNS_ACCOUNT_URL:
									return (
										<StyledUserAccountPage__Wrapper size={size}>
											<RNSContainer />
										</StyledUserAccountPage__Wrapper>
									);

								default:
									return (
										<StyledUserAccountPage__Wrapper size={size}>
											{showHeaders && (
												<StyledUserAccountPage__Header size={containerSize} type={HeaderBar.types.PRIMARY}>
													<Text className={StyledHeaderText}>
														{' '}
														{brandName ? `${brandName} ` : ''}
														{t('UserAccountPage__AccountProfile')}
													</Text>
												</StyledUserAccountPage__Header>
											)}

											<StyledUserAccountPage__Profile
												size={size}
												onCompleteVerification={onCompleteVerification}
												city={suburb}
												country={country}
												dob={moment(dob, SERVER_DATE_FORMAT).format(SERVER_DATE_FORMAT)}
												email={email}
												first_name={first_name}
												last_name={last_name}
												msisdn={mobile}
												postcode={postcode}
												state={state}
												street={address}
												username={username}
												userIsVerified={userIsVerified}
												formDisabled
											/>
										</StyledUserAccountPage__Wrapper>
									);
							}
						}}
					</SizeMe>

					{this.state.greendIDInitialised && (
						<Modal closeModal={this.closeVerificationModal} title={'Verify Your Identity'}>
							<VerificationFormV2 closeModal={this.closeVerificationModal} />
						</Modal>
					)}
				</StyledContentAccount>
			</div>
		);
	};

	render() {
		const {
			t,
			useRouting,
			showHeaders,
			authenticatedUser,

			// Account Profile
			address,
			country,
			dob,
			email,
			first_name,
			last_name,
			mobile,
			postcode,
			state,
			suburb,
			username,
			filter,
			onCompleteVerification,
			userIsVerified,
			brandName,
			green_id_overall_status,
			green_id_status,
			user_green_id,
			send_email,
			menuItems,
			selectedMenuItem,
			setSelectedMenuItem,
			// Deposits
			showDepositReceipt,
		} = this.props;

		const { route } = this.state;

		const verificationItemProfile = menuItems.find((item) => item.id === USER_ACCOUNT_VERIFICATION);

		if (!authenticatedUser) {
			return null;
		}

		const alertInfo = {
			label: userIsVerified ? '' : 'Verify Now',
			status: userIsVerified ? 'success' : 'error',
			message: userIsVerified ? 'Account Verified' : 'Account Not Verified',
			description: userIsVerified
				? 'Your account is verified.'
				: 'Your account is currently restricted and must be verified before you can make withdrawals.',
		};

		return (
			<SizeMe>
				{({ size: containerSize }) => (
					<StyledUserAccountPage size={containerSize}>
						{useRouting && <Route route={route} />}

						<DocumentTitle>
							{`${t('RacingHomePage__Title')}${brandName ? ` ${t('at')} ${brandName}` : ''}`}
						</DocumentTitle>
						<DescriptionMeta>
							{`${t('RacingHomePage__Description')}${brandName ? ` ${t('with')} ${brandName}` : ''}. ${t(
								'RacingHomePage__DescriptionBonus',
							)}`}
						</DescriptionMeta>
						{<LoadingMask loading={this.props.loading} />}
						<NotificationBlockWrapper>
							<Text strong size="2">
								My Account
							</Text>
							{(this.state.page === USER_ACCOUNT_OVERVIEW_URL || this.state.page === USER_ACCOUNT_WITHDRAW_URL) &&
								green_id_status !== 'VERIFIED' && (
									<Alerts
										verificationItem={verificationItemProfile}
										onClickChangeToVerificationPage={this.openVerificationModal}
										{...alertInfo}
									/>
								)}
						</NotificationBlockWrapper>
						{this.renderContainer(containerSize)}
					</StyledUserAccountPage>
				)}
			</SizeMe>
		);
	}
}

const mapStateToProps = (state) => {
	const authenticatedUser = state.application.authenticatedUser;
	const user = state.entities.users[authenticatedUser] || {};
	
	
	let green_id_status, name, email, msisdn;

	if (authenticatedUser) {
		green_id_status = state.entities.users[authenticatedUser].green_id_status;
		name = state.entities.users[authenticatedUser].name;
		email = state.entities.users[authenticatedUser].email;
		msisdn = state.entities.users[authenticatedUser].msisdn;
	}

	return {
		verifiedName: name,
		verifiedEmail: user.send_email,
		verifiedPhoneNumber: msisdn,
		authenticatedUser: Boolean(authenticatedUser),
		selectedMenuItem: state.userAccount.selectedMenuItemV2,
		menuItems: getUserAccountV2MenuItems(state),
		// Account Profile
		address: user.street,
		country: user.country,
		dob: user.dob,
		email: user.email,
		first_name: user.first_name,
		last_name: user.last_name,
		mobile: user.msisdn,
		postcode: user.postcode,
		state: user.state,
		suburb: user.city,
		username: user.username,
		userIsVerified: user.verified,
		brandName: state.acl.brandDetails && state.acl.brandDetails.name,
		green_id_overall_status: user.green_id_overall_status,
		green_id_status,
		user_green_id: user.green_id_trans_id,
		send_email: user.send_email,
		user: user,
		// Transactions
		filter: state.userAccount.transactionsFilter,
		loading: state.application.isBodyRegionLoading,
		isBonusBets:state.featureToggles.features.bonusBets.enabled,
		// Deposits
		showDepositReceipt: state[DEPOSIT_USER_ACCOUNT].lastDepositId && !state[DEPOSIT_USER_ACCOUNT].depositLoading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		resetUserAccount: () => {
			return dispatch(resetUserAccount());
		},
		setTransactionFilter: (filter) => {
			return dispatch(setTransactionFilter(filter));
		},
		closeUserAccountPage: () => {
			return dispatch(routeTo('/welcome'));
		},
		onCompleteVerification: () => {
			dispatch(trackGaEvent('MyAccount', 'Click', 'Complete Verification'));
			return dispatch(launchLiveChat());
		},
		setSelectedMenuItem: (item) => dispatch(setSelectedMenuItemV2(item)),

		goToWithdrawal: () => {
			dispatch(trackGaEvent('AccountMenu', 'Click', 'Withdraw'));
			return dispatch(routeTo(`${USER_ACCOUNT_V2_BASE_URL}/withdraw`));
		},
		goToResponsibleGambling: () => {
			dispatch(trackGaEvent('AccountMenu', 'Click', 'Withdraw'));
			return dispatch(routeTo(`${USER_ACCOUNT_V2_BASE_URL}/responsible-gambling`));
		},
		openDeposits: () => {
			return dispatch(showDeposits());
		},
		verifyEmail: async () => {
			return await dispatch(await verifyEmail());
		},
		sendNotification: (message, type) => {
			dispatch(
				openNotification(message, type, {
					autoClose: 1500,
				}),
			);
		},
	};
};

export default withNamespaces()(createErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(UserAccountPage)));
