import React, { useEffect } from 'react'; // { Component }
import styled, { css } from 'react-emotion';
import { StyledSectionButton } from '../Components';
import { createAction } from '../../../../common/actions/actionHelpers';
import { WENT_TO_USER_ACCOUNT_PAGE_VERIFICATION } from '../../../../store/userAccount/userAccountActionTypes';
import { createErrorBoundary } from '../../../ErrorBoundary/ErrorBoundaryActions';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { routeTo } from '../../../../store/application/applicationActions';
import {
	VERIFICATION_ERROR,
	VERIFICATION_RESET,
	VERIFICATION_SET_TOKEN,
} from '../../../../store/verifications/VerificationsActionType';
import {
	getUserVerificationToken,
	updateVerificationStatus,
} from '../../../../store/verifications/VerificationsActions';
import env from '../../../../common/EnvironmentVariables';
import { LoadingSpanner } from '../../../../components/features/Application/LoadingSpinner/LoadingSpinner';
import { Button, Icon } from '@tbh/ui-kit';
import { clearRedirectRoute } from '../../../../store/registration/registrationActions';
import { VerifyProgress } from '../../../../components/features/Application/VerifyMenuProcess/VerifyProgress';

const FormContainer = styled('form')`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Message = styled('p')`
	width: 100%;
	text-align: center;
	font-size: 1rem;
	margin: 0;
`;

const VerificationFormV2 = ({
	user,
	localUser,
	goToDepositLimit,
	goToWelcome,
	onClose,
	token,
	verified,
	warning,
	error,
	setToken,
	setError,
	updateVerificationStatus,
	getUserVerificationToken,
	loading,
	accountId,
	apiCode,
}) => {
	const onSessionComplete = (verificationToken, overallState) => {
		getUserVerificationToken(localUser.green_id_trans_id);
		// if (acceptedStatuses.includes(overallState)) {
		// 	getUserVerificationToken(localUser.green_id_trans_id);
		// }
	};

	const onSourceAttemptCallback = (verificationToken, sourceName, checkState, overallState) => {
		// console.log('onSourceAttemptCallback', verificationToken, sourceName, checkState, overallState);
		// updateVerificationStatus(localUser.green_id_trans_id, verificationToken, overallState);
	};

	const handleGreenIdError = (error) => {
		if (!accountId || !apiCode) {
			document.Sentry && document.Sentry.captureException(new Error('GreenId Error: Missing accountId or apiCode'));
		} else {
			document.Sentry && document.Sentry.captureException(new Error(error));
		}

		setError('There was an error with the verification process. Please try again later.');
	};

	const onError = (verificationToken, errorName, code, error) => {
		console.log('onError', verificationToken, errorName, code, error);

		handleGreenIdError('Error durring FORM: ', error);
	};

	const onValidation = (data) => {
		return true;
	};

	const onRegister = (verificationId, userData) => {
		setToken(verificationId);
	};

	useEffect(() => {
		getUserVerificationToken(localUser.green_id_trans_id);
	}, []);

	useEffect(() => {
		if (token) {
			try {
				if (!document.greenIdInit) {
					document.greenId.init({
						onSessionComplete,
						onSourceAttemptCallback,
						onValidation,
						onRegister,
						onError,
						form_id: 'green-form',
						frame_id: 'green-greenid-div',
						accountId,
						apiCode,
						session_token: token,
					});
				} else {
					document.greenId.show(accountId, apiCode, token);
				}
			} catch (e) {
				handleGreenIdError('Error while setting up GreenId: ', e.message);
			}
		}
	}, [token]);

	const [timer, setTimer] = React.useState(0);
	const [localInterval, setLocalInterval] = React.useState(null);

	useEffect(() => {
		if (verified) {
			setLocalInterval(setInterval(() => setTimer((timer) => timer + 1), 1000));
		}
	}, [verified]);

	useEffect(() => {
		if (timer >= 3) {
			clearInterval(localInterval);
			goToDepositLimit();
		}
	}, [timer]);

	const format = (dob) => {
		const date = new Date(dob);

		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();

		return `${day}/${month}/${year}`;
	};

	if (error) {
		return (
			<FormContainer style={{ justifyContent: 'center', gap: '8px' }}>
				<Icon
					icon="warning"
					size={50}
					className={css`
						border: 1px solid #cf1b41;
						border-radius: 999px;
						padding: 8px;
						color: #cf1b41;
					`}
				/>
				<Message style={{ color: 'red' }}>{error}</Message>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '12px',
						width: '100%',
					}}
				>
					<Button type="secondary" style={{ borderRadius: 4, width: '100%' }} action={() => goToWelcome()}>
						Go to Home
					</Button>
				</div>
			</FormContainer>
		);
	}

	if (warning) {
		return (
			<FormContainer style={{ justifyContent: 'center', gap: '8px' }}>
				<Icon
					icon="warning"
					size={50}
					className={css`
						border: 1px solid #f8dc1e;
						border-radius: 999px;
						padding: 8px;
						color: #f8dc1e;
					`}
				/>
				<Message style={{ color: '#ee9c15' }}>{warning}</Message>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '12px',
						width: '100%',
					}}
				>
					<Button type="secondary" style={{ borderRadius: 4, width: '100%' }} action={() => goToWelcome()}>
						Go to Home
					</Button>
				</div>
			</FormContainer>
		);
	}

	if (verified) {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '12px',
					width: '100%',
				}}
			>
				<Icon
					icon="checkmark"
					size={50}
					className={css`
						border: 1px solid #00b559;
						border-radius: 999px;
						padding: 8px;
						color: #00b559;
					`}
				/>
				<Message style={{ fontSize: '24px', fontWeight: 'bolder' }}>Success!</Message>
				<Message>Your account has been verified successfully.</Message>
				<Message>You will be redirected to the deposit limit step in {3 - timer > 0 ? 3 - timer : 0} seconds.</Message>

				{timer >= 3 && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '12px',
							width: '100%',
						}}
					>
						<Message>If you are not redirected, click the button below.</Message>
						<Button type="secondary" style={{ borderRadius: 4, width: '100%' }} action={() => goToDepositLimit()}>
							Go to Deposit Limit
						</Button>
					</div>
				)}
			</div>
		);
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
			{loading && <LoadingSpanner />}
			<VerifyProgress
				phone={localUser.msisdn}
				email={localUser.sendEmail}
				green_id_status={localUser.green_id_status}
			/>
			<div id={'green-greenid-div'}>
				<FormContainer name={'green-form'} id={'green-form'} method="post">
					<input
						id="givenNames"
						name="givenNames"
						className="form-control"
						type="hidden"
						value={localUser.first_name}
					/>
					<input id="surname" name="surname" className="form-control" type="hidden" value={localUser.last_name} />

					<select
						style={{ display: 'none' }}
						type="hidden"
						id="state"
						name="state"
						className="form-control"
						value={localUser.state}
					>
						<option value="">Select your State/Territory...</option>
						<option value="NSW">NSW</option>
						<option value="VIC">VIC</option>
						<option value="QLD">QLD</option>
						<option value="SA">SA</option>
						<option value="WA">WA</option>
						<option value="ACT">ACT</option>
						<option value="NT">NT</option>
						<option value="TAS">TAS</option>
					</select>

					<input type="hidden" id="streetName" name="streetName" className="form-control" value={localUser.street} />

					<input type="hidden" id="postcode" name="postcode" className="form-control" value={localUser.postcode} />
					{/* <label htmlFor="dob" className="control-label">Date of Birth (DD/MM/YYYY)</label> */}
					<input
						name="dob"
						id="dob"
						className="form-control"
						aria-required="true"
						type="hidden"
						value={format(localUser.dob)}
					/>
					{/* <label htmlFor="email" className="control-label">Email</label> */}
					<input id="email" name="email" className="form-control" type="hidden" value={localUser.email} />
					{/* <label htmlFor="homePhone" className="control-label">homePhone</label> */}
					<input id="homePhone" name="homePhone" className="form-control" type="hidden" value={localUser.msisdn} />

					<input type="hidden" id="accountId" name="accountId" value={accountId} />
					<input type="hidden" id="apiCode" name="apiCode" value={apiCode} />
					<input type="hidden" id="ruleId" name="ruleId" value="default" />
					<input type="hidden" id="usethiscountry" value="AU" name="country" />
					<input type="hidden" id="dnbCreditHeaderConsentGiven" name="dnbCreditHeaderConsentGiven" value="true" />

					<StyledSectionButton value="Submit details" type="submit" style={{ display: 'none' }} id="submitbob">
						Submit details
					</StyledSectionButton>
				</FormContainer>
			</div>
		</div>
	);
};
const mapStateToProps = (state, ownProps) => {
	const user = state.entities.users[state.application.authenticatedUser];
	return {
		...ownProps,
		loading: state.application.isBodyRegionLoading,
		token: state.verifications.token,
		verified: state.verifications.verified,
		error: state.verifications.error,
		warning: state.verifications.warning,
		user,
		accountId: state.acl.accountId,
		apiCode: state.acl.apiCode,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	goToDepositLimit: () => {
		// dispatch(clearRedirectRoute());
		// document.greenIdInit = false;
		dispatch(createAction(WENT_TO_USER_ACCOUNT_PAGE_VERIFICATION, {}));
		ownProps.nextStep();
	},
	setError: (error) => dispatch(createAction(VERIFICATION_ERROR, error)),
	goToWelcome: () => {
		dispatch(clearRedirectRoute());
		dispatch(routeTo('/welcome'));
		// document.greenIdInit = false;
		dispatch(createAction(WENT_TO_USER_ACCOUNT_PAGE_VERIFICATION, {}));
	},
	setToken: (token) => dispatch(createAction(VERIFICATION_SET_TOKEN, token)),
	updateVerificationStatus: (green_id_trans_id, session_token, status) =>
		dispatch(updateVerificationStatus(green_id_trans_id, session_token, status)),
	getUserVerificationToken: (green_id_trans_id) => dispatch(getUserVerificationToken(green_id_trans_id)),
	onClose: () => {
		dispatch(createAction(VERIFICATION_RESET, {}));
		ownProps.onClose();
	},
});

export default withNamespaces()(createErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(VerificationFormV2)));
